import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { createArticle, uploadComplete } from '../../redux/modules/Media/actions'
import SuccessModal from './SuccessModal'
import S3Uploader from './S3Uploader'
import 'react-day-picker/lib/style.css'


class ArticleEntry extends Component {
  constructor(props){
    super(props)
    const adminName = JSON.parse(localStorage.getItem('admin'))['name']
    this.state = {
       title: '',
       author: '',
       news_type: '',
       source: '',
       release_date: '',
       image_url: '',
       article_url: '',
       article_description: '',
       body: '',
       display: true,
       uploaded_by: adminName,
     }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const {  title, author, news_type, source, release_date, image_url, article_url, article_description, display, uploaded_by } = this.state
    const article = { title, author, source, news_type, release_date, image_url, article_url, article_description, display, uploaded_by }
    this.props.createArticle(article)
    this.setState({
      title: '',
      author: '',
      source: '',
      release_date: '',
      image_url: '',
      article_url: '',
      article_description: '',
      body: '',
      display: true,
    })
  }

  handleDayChange = (e) => {
    if(e){
      this.setState({ release_date: e })
    } else {
      this.setState({ release_date: null })
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  handleClose = () => {
    this.props.uploadComplete()
    this.setState({ news_type: '' })
  }

  handleTypeSelect = (e) => {
    e.preventDefault()
    this.setState({ news_type: e.target.innerText.toLowerCase() })
  }

  handleResponse = r => {
    const { state } = this
    state["image_url"] = r.publicUrl
    this.setState(state)
  }

  render() {
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={`${this.state.news_type === "article" ? "Article" : "Podcast"} successfully added.`}
        />

        <div id="upload-form-container">
          { !!this.props.errors ? <span id="update-error-span">Error: {this.props.errors[0]}</span> : null }
          <form onSubmit={this.handleSubmit} id="admin-upload-form">
            <label className="upload-input-label">Title:</label>
            <input
              className="media-info-input admin-input"
              name='title'
              type='text'
              value={this.state.title}
              onChange={this.handleOnChange}

            />
            <label className="upload-input-label">This is a:</label>
            <div id="pr-double-btn">
              <button className={this.state.news_type === "article" ? "selected-btn" : null} onClick={this.handleTypeSelect}>Article</button>
              <button className={this.state.news_type === "podcast" ? "selected-btn" : null} onClick={this.handleTypeSelect}>Podcast</button>
            </div>
            {
              this.state.news_type ==="article" ?
              <div>
                <label className="upload-input-label">Author:</label>
                <input
                  className="media-info-input admin-input"
                  name='author'
                  type='text'
                  value={this.state.author}
                  onChange={this.handleOnChange}

                />
              </div>
              :
              null
            }
            <label className="upload-input-label">Source:</label>
            <input
              className="media-info-input admin-input"
              name='source'
              type='text'
              value={this.state.source}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Release Date:</label>
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={this.state.date || "YYYY-M-D"}
              style={{ color: "Gray" }}
            />
            <label className="upload-input-label">Image Url:</label>
            <S3Uploader
              params={{}}
              apiPath={"carousel_objects/s3/sign"}
              handleResponse={this.handleResponse}
              handle
            />
            <label className="upload-input-label">Article Url:</label>
            <input
              className="media-info-input admin-input"
              name='article_url'
              type='text'
              value={this.state.article_url}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Article Description:</label>
            <textarea
              className="media-info-input admin-input"
              name='article_description'
              value={this.state.article_description}
              onChange={this.handleOnChange}
            />

            <input id="upload-button" type='submit' label='submit' value="Submit" />
          </form>
        </div>
      </div>
    )
  }


}

const mapStateToProps = (state) => ({
  isUploading: state.articles.isUploading,
  successfulUpload: state.articles.successfulUpload,
  errors: state.articles.errors
})

export default withRouter(connect(mapStateToProps, { createArticle, uploadComplete })(ArticleEntry))


            // <label className="upload-input-label">Article Body:</label>
            // <textarea
            //   className="media-info-input"
            //   name='body'
            //   type=''
            //   value={this.state.body}
            //   onChange={this.handleOnChange}
            // />
            // <input
            //   className="media-info-input"
            //   name='image_url'
            //   type='text'
            //   value={this.state.image_url}
            //   onChange={this.handleOnChange}
            // />
