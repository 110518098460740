import TaxDocService from '../../../services/TaxDocService'
import { unsuccessfulFetchRequest } from '../../appTransactions';

const successfulTaxDocUpload = doc => {
  return {
    type: 'SUCCESSFUL_TAX_DOC_UPLOAD',
    doc
  }
}

const startTaxDocUpload = doc => {
  return {
    type: 'STARTING_TAX_DOC_UPLOAD'
  }
}

const uploadComplete = doc => {
  return {
    type:'TAX_DOC_UPLOAD_COMPLETE'
  }
}

const failedUpload = doc => ({
  type: 'FAILED_TAX_DOC_UPLOAD',
  doc
})

const successfulTaxDocsFetch = docs => ({
  type: 'SUCCESSFUL_TAX_DOCS_FETCH',
  docs
})

const clearTaxDocsWhileUpdating = () => ({
  type: 'CLEAR_TAX_DOCS'
})

export const fetchTaxDocs = () => {
  return dispatch => {
    TaxDocService.fetchTaxDocs()
    .then( docs => dispatch(successfulTaxDocsFetch(docs)) )
  }
}

export const updateTaxDoc = (id, data) => {
  return dispatch => {
    dispatch(clearTaxDocsWhileUpdating())
    TaxDocService.updateTaxDoc(id, data)
    .then( docs => {
      if (docs.errors) {
        dispatch(failedUpload(docs))
      } else if (docs.error) {
        alert("Update failed: Server Error")
      } else {
        dispatch(successfulTaxDocsFetch(docs))
      }
    })
  }
}

export const deleteTaxDoc = id => {
  return dispatch => {
    dispatch(clearTaxDocsWhileUpdating())
    TaxDocService.deleteTaxDoc(id)
    .then(docs => {
      if (docs.errors) {
        dispatch(failedUpload(docs))
      } else if (docs.error) {
        alert("Update failed: Server Error")
      } else {
        dispatch(successfulTaxDocsFetch(docs))
      }
    })
  }
}

export const addNewTaxDoc = (data) => {
  return dispatch => {
    dispatch(startTaxDocUpload())
    TaxDocService.addNewTaxDoc(data)
    .then(doc => {
      if (doc.errors) {
        dispatch(failedUpload(doc))
      } else if (doc.error){
        console.log(doc.error)
        alert("Upload failed: Server Error")
      } else {
        dispatch(successfulTaxDocUpload(doc))
      }
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const taxDocUploadComplete = () => {
  return dispatch => {
    dispatch(uploadComplete())
  }
}
