import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uploadImportant, uploadComplete } from '../../redux/modules/ImportantTaxDocs/actions'
import { fetchAllFunds } from '../../redux/modules/Funds/actions'
import SuccessModal from './SuccessModal'
// import Select from '../../components/Select'
// import DayPickerInput from 'react-day-picker/DayPickerInput'
import S3Uploader from './S3Uploader'
import { yearOptions } from '../TableTools'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class ImportantTaxUploader extends Component {
  constructor(){
    super()

    const adminName = JSON.parse(localStorage.getItem('admin'))['name']

    this.state = {
      name: "",
      fund_code: null,
      year: null,
      filepath: "",
      uploaded_by: adminName,
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchAllFunds()
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  handleChange = (e) => {
    const { state } = this
    const { name, value } = e.target
    state[name] = value
    this.setState(state)
  }

  handleSelectChange = (e) => {
    if(e){
      this.setState({
        fund_code: e.value
      })
    } else {
      this.setState({
        fund_code: null
      })
    }
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    // const hasEmptyValues = Object.values(this.state).some( val => !val )
    // if (!!hasEmptyValues) {
    //   document.getElementById("upload-failed").style.display = "inline-block"
    // } else {
      document.getElementById("upload-failed").style.display = "none"
      this.props.uploadImportant(this.state)
      this.setState({
        fund_code: null,
        filepath: "",
        year: "",
        name: "",
        showSubmit: false
      })
      document.getElementsByName("s3-uploader")[0].value = ''
    // }
  }


  render(){
    // const { funds } = this.props
    // let options
    // if(funds){
    //   options = funds.map( fund => ({
    //     value: fund.fund_code, label: fund.fundshortname
    //   }) )
    // }
	const options = this.props.funds?.reduce( ( result, { fund_code, fundshortname, flagship } ) => flagship ? [ ...result, { value: fund_code, label: fundshortname } ] : result, [] )
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Important Tax Info successfully added"}
        />
        <div id="upload-form-container">
          <h3>Important Tax Information Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!this.props.errors ? <span id="update-error-span">Error: {this.props.errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Fund Series: (example: "7.25% Tax Advantaged Cumulative Pfd Stock")</label>
            <input name="name" className="media-info-input admin-input" onChange={this.handleChange} value={this.state.name}></input>
            <label className="upload-input-label">Select Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.fund_code}
            />
            <br />
            <label className="upload-input-label">Date of Action:</label>
            <br />
            <select name="year" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.year}>
              <option value="">Select a Year</option>
              {yearOptions(1999)}
            </select>
            <br />
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={{}}
              apiPath="tax_informations/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  funds: state.funds,
  fundsWithImportantDocs: state.importantTaxDocs.funds,
  successfulUpload: state.importantTaxDocs.successfulUpload,
  errors: state.importantTaxDocs.errors
})

export default connect(mapStateToProps, { uploadImportant, uploadComplete, fetchAllFunds })(ImportantTaxUploader)
