import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { fetchFunds } from '../../redux/modules/Funds/actions';
import { fetchHoldings, updateClosedEndHoldings } from '../../redux/modules/NavClosedEnds/actions';

const holdingsCodes = [ 704, 708, 706, 464, 465, 460, 404, 406, 471, 2760, -112, 405, 1794, 2761, -113, 401, 442, 441, 403, 444, 8, 443, 9, 407, 408, 470, 402, 1785, 840, -114, -115, -118, -117, -116, -119, -120, -122, -123, -124, -111, 3630, 3634, 2993, 2994]

const EditHoldings = () => {

	const [ editMessage, setShowEditMessage ] = useState( null );

	const flashMessage = message => {
		setShowEditMessage( message );
		setTimeout( () => setShowEditMessage( null ), 3000 );
	};

	const [ fundToEditHoldingsFor, setFundToEditHoldingsFor ] = useState( 0 );

	const [ holdingToEdit, setHoldingToEdit ] = useState( {} );

	const handleFundToEditChange = ( { target } ) => setFundToEditHoldingsFor( target.value );

	const handleHoldingEditFormChange = ( { target } ) => setHoldingToEdit( { ...holdingToEdit, [ target.name ]: target.value } );

	const submitHoldingEdit = () => {
		dispatch( updateClosedEndHoldings( holdingToEdit ) );
		setHoldingToEdit( {} );
		setFundToEditHoldingsFor( 0 );
		dispatch( fetchHoldings( 0 ) );
		flashMessage( 'Changes saved successfully' );
	};

	const dispatch = useDispatch();

	const funds = useSelector( state => state.funds );
	const holdings = useSelector( state => state.closedEnds.holdings );

	const fundSelectDropdownOptions = funds?.reduce( ( result, { fund_code, fundshortname } ) => {
		return holdingsCodes.includes( fund_code ) ? [ ...result, <option key={ fund_code } value={ fund_code }>{ fundshortname }</option> ] : result;
	}, [] );

	useEffect( () => dispatch( fetchFunds() ), [ dispatch ] );

	useEffect( () => dispatch( fetchHoldings( fundToEditHoldingsFor ) ), [ dispatch, fundToEditHoldingsFor ] );

	return <>

		{ editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> }

		<h1>Manually edit fund holdings</h1>

		<label htmlFor='select_fund'>Select a fund:&nbsp;</label>
		<select name='select_fund' value={ fundToEditHoldingsFor } onChange={ handleFundToEditChange }>
			<option key={ 0 } value={ 0 } disabled>Select a fund</option>
			{ fundSelectDropdownOptions }
		</select>

		{ !!fundToEditHoldingsFor && <StyledHoldingsTable>
			<div>
				<span style={ { flex: 1 } }>Sec. symb</span>
				<span style={ { flex: 4 } }>Sec. name</span>
				<span style={ { flex: 1 } }>%</span>
				<span style={ { flex: 1 } }>Mkt. val.</span>
				<span style={ { flex: 1 } }>As of</span>
				<span style={ { flex: 1 } }>Edit</span>
			</div>
			{/* { asof_date, asset_type, ctry_of_domicile, fund_code, id, legalname, market_value_base, port_id, port_pct, quarter, sec_name, sec_symb, sec_type_name, total_portfolio, year } */}
			{ holdings?.map( holding => holding.id === holdingToEdit.id ? <div key={ holding.id }>
				<input style={ { flex: 1 } } name='sec_symb' value={ holdingToEdit.sec_symb } onChange={ handleHoldingEditFormChange }/>
				<input style={ { flex: 4 } } name='sec_name' value={ holdingToEdit.sec_name } onChange={ handleHoldingEditFormChange }/>
				<input style={ { flex: 1 } } type='number' name='port_pct' value={ holdingToEdit.port_pct } onChange={ handleHoldingEditFormChange }/>
				<input style={ { flex: 1 } } type='number' name='market_value_base' value={ holdingToEdit.market_value_base } onChange={ handleHoldingEditFormChange }/>
				<input style={ { flex: 1 } } type='date' name='asof_date' value={ holdingToEdit.asof_date } onChange={ handleHoldingEditFormChange }/>
				<span style={ { flex: 1 } }>
					<button onClick={ submitHoldingEdit }>✔Save</button>
					<button onClick={ () => setHoldingToEdit( {} ) }>✕Cancel</button>
				</span>
			</div> : <div key={ holding.id }>
				<span style={ { flex: 1 } }>{ holding.sec_symb }</span>
				<span style={ { flex: 4 } }>{ holding.sec_name }</span>
				<span style={ { flex: 1 } }>{ Math.round( holding.port_pct * 10000 ) / 100 }</span>
				<span style={ { flex: 1 } }>{ holding.market_value_base }</span>
				<span style={ { flex: 1 } }>{ holding.asof_date }</span>
				<span style={ { flex: 1 } }>
					<button onClick={ () => setHoldingToEdit( holding ) }>✎</button>
				</span>
			</div> ) }
		</StyledHoldingsTable> }
	</>;

};

export default EditHoldings;

const StyledHoldingsTable = styled.div`
	display: flex;
	flex-flow: column nowrap;
	& div {
		display: flex;
		flex-flow: row nowrap;
		// justify-content: space-around;
	}
	& div span {
		// text-align: left;
	}
`;