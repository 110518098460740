import FundsCarouselService from '../../../services/FundsCarouselService'

const successfulCarouselFetch = carousel => {
  return {
    type: "SUCCESSFUL_CAROUSEL_FETCH",
    carousel
  }
}

const successfulCarouselArchiveFetch = archive => {
  return {
    type: "SUCCESSFUL_CAROUSEL_ARCHIVE_FETCH",
    archive
  }
}

const successfulCarouselUpdate = newCarouselItem => {
  return {
    type: "SUCCESSFUL_CAROUSEL_UPDATE",
    newCarouselItem
  }
}

const successfulCarouselArchive = updatedCarousel => {
  return {
    type: "SUCCESSFUL_CAROUSEL_ARCHIVE_UPDATE",
	carousel: updatedCarousel
  }
}

const successfulCarouselDelete = deletedItemKey => {
  return {
    type: "SUCCESSFUL_CAROUSEL_DELETE",
    deletedItemKey
  }
}

const completeUpload = () => {
  return {
    type: "UPLOAD_COMPLETE"
  }
}

const failedCarouselAction = (carousel) => {
  return {
    type: "FAILED_CAROUSEL_ACTION",
    carousel
  }
}

export const fetchCarousel = () => {
  return dispatch => {
    FundsCarouselService.fetchCarousel()
    .then( json => dispatch(successfulCarouselFetch(json)) )
    .catch(e => console.log(e))
  }
}

export const fetchCarouselArchive = () => {
  return dispatch => {
    FundsCarouselService.fetchCarouselArchive()
    .then( json => dispatch(successfulCarouselArchiveFetch(json)) )
    .catch(e => console.log(e))
  }
}

export const addCarouselObject = (carouselObject) => {
  return dispatch => {
    FundsCarouselService.addCarouselObject(carouselObject)
    .then(json => {
      if(json.errors){
        dispatch(failedCarouselAction(json))
      } else {
        dispatch(successfulCarouselUpdate(json))
      }
    })
    .catch(e => console.log(e))
  }
}

export const updateCarouselObject = (id, data) => {
  return dispatch => {
    FundsCarouselService.updateCarouselObject(id, data)
    .then(json => {
      if(json.errors){
        dispatch(failedCarouselAction(json))
      } else {
        dispatch(successfulCarouselUpdate(json))
      }
    })
  }
}

export const archiveCarouselObject = (id) => {
  return dispatch => {
    FundsCarouselService.archiveCarouselObject(id)
    .then(json => {
      if(json.errors){
        dispatch(failedCarouselAction(json))
      } else {
        dispatch(successfulCarouselArchive(json))
      }
    })
  }
}

export const unarchiveCarouselObject = (id) => {
  return dispatch => {
    FundsCarouselService.unarchiveCarouselObject(id)
    .then(json => {
      if(json.errors){
        dispatch(failedCarouselAction(json))
      } else {
        dispatch(successfulCarouselArchive(json))
      }
    })
  }
}

export const deleteCarouselObject = (id) => {
  return dispatch => {
    FundsCarouselService.deleteCarouselObject(id)
    .then(json => {
      if(json.errors){
        dispatch(failedCarouselAction(json))
      } else {
        dispatch(successfulCarouselDelete(json))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
