import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const PreferredIssuesService = {
  addNewPreferredDoc(data) {
    return fetch(`${API_URL}/preferred_docs`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  },
}

export default PreferredIssuesService;
