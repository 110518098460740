import PublicEventRegistrationsService from "../../../services/PublicEventRegistrationsService";
import { generateRegistrantSpreadsheet } from "../../../views/generateEventRegistrantSpreadsheet";

const successfulPublicEventRegistrationsFetch = publicEventRegistrations => {
	return { type: 'PUBLIC_EVENT_REGISTRATIONS_FETCHED_SUCCESSFULLY', publicEventRegistrations }
};

const publicEventRegistrationsFetchFailed = () => {
	return { type: 'PUBLIC_EVENT_REGISTRATIONS_FETCH_FAILED' }
};

export const fetchPublicEventRegistrations = publicEvent => {
	return dispatch => {
		PublicEventRegistrationsService.fetchPublicEventRegistrations( publicEvent.id )
		.then( publicEventRegistrations => {
			dispatch( successfulPublicEventRegistrationsFetch( publicEventRegistrations ) );
			generateRegistrantSpreadsheet( publicEvent.name.replace( /\s/g, '' ).slice( 0, 10 ), publicEventRegistrations );
		} )
		.catch( () => dispatch( publicEventRegistrationsFetchFailed() ) );
	};
};
