import { API_URL } from '../constants'

export const headers = () => {
	const token = localStorage.getItem('token');
	return {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json',
	  'Authorization': `Bearer: ${token}`,
	}
}

const CompositeService = {
	fetchComposites() {
		return fetch( `${API_URL}/composites` ).then( response => response.json() );
	},
	updateComposite( id, composite ) {
		return fetch( `${ API_URL }/composites/${ id }`, {
		  method: 'PATCH',
		  body: JSON.stringify( composite ),
		  headers: headers()
		} ).then( response => response.json() );
	  }
};

export default CompositeService;
