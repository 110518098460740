import FundsService from '../../../services/FundsService';
import PreferredIssuesService from '../../../services/PreferredIssuesService'
import { unsuccessfulFetchRequest } from '../../appTransactions';

const successfulFundsFetch = funds => {
  return {
    type: 'SUCCESSFUL_FUNDS_FETCH',
    funds
  }
}

const successfulPreferredDocUpload = doc => {
  return {
    type: 'SUCCESSFUL_PREFERRED_UPLOAD',
    doc
  }
}

const startPrefDocUpload = () => {
  return {
    type: 'UPLOADING_PREFERRED_DOC'
  }
}

const uploadComplete = () => {
  return {
    type: 'PREFERRED_UPLOAD_COMPLETE'
  }
}

export const fetchIssues = () => {
  return dispatch => {
    FundsService.fetchClosedEndsWithFilings()
      .then(funds => {
        dispatch(successfulFundsFetch(funds))
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const addNewPreferredDoc = (data) => {
  return dispatch => {
    dispatch(startPrefDocUpload())
    PreferredIssuesService.addNewPreferredDoc(data)
    .then(doc => {
      dispatch(successfulPreferredDocUpload(doc))
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const preferredDocUploadComplete = () => {
  return dispatch => {
    dispatch(uploadComplete())
  }
}
