export default (state = [], action) => {
  switch(action.type) {
    case 'UPLOADING_FILING':
      return {
        ...state,
        isUploading: true,
        successfulUpload: false
      }
    case 'SUCCESSFUL_FILING_UPLOAD':
      return {
        ...state,
        filing: action.filing,
        successfulUpload: true,
        isUploading: false
      }
    case 'SUCCESSFUL_FILINGS_FETCH':
      return {
        ...state,
        filings: action.filings
      }
    case 'FILING_UPLOAD_COMPLETE':
      return {
        ...state,
        filings: [],
        successfulUpload: false,
        isUploading: false
      }
    case 'FILING_UPLOAD_FAILED':
      return {
        ...state,
        errors: action.filing.errors,
        successfulUpload: false,
        isUploading: false,
      }
    case 'CURRENTLY_FETCHING_FILINGS':
      return {
        ...state,
        filings: []
      }
    default:
      return state;
  }
}
