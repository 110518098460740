import React from 'react'
import S3Uploader from './S3Uploader'

const CarouselAdd = (props) => {
  const { handleSubmit, handleChange, handleResponse, handleProgress, state } = props
  return(
    <div id="admin-upload-container">
      <div id="upload-form-container">
        <h3>Funds Homepage Carousel Editor</h3>
        <h4>Add an Item:</h4>
        <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
        <form id="admin-upload-form" onSubmit={handleSubmit}>
          <label className="upload-input-label">Headline:</label>
          <input type="text" className="media-info-input admin-input" value={state.header} name="header" onChange={handleChange}></input>
          <label className="upload-input-label">Caption:</label>
          <input type="text" className="media-info-input admin-input" value={state.caption} name="caption" onChange={handleChange}></input>
          <label className="upload-input-label">Summary:</label>
          <input type="text" className="media-info-input admin-input" value={state.summary} name="summary" onChange={handleChange}></input>
          <label className="upload-input-label">Insight/Commentary URL path:</label>
          <input
            type="text"
            className="media-info-input admin-input"
            value={state.link}
            name="link"
            onChange={handleChange}
            placeholder="ex: /funds/insights/..."
          >
          </input>
          <label className="upload-input-label">Image:</label>
          <S3Uploader
            handleResponse={handleResponse}
            accept='image/*'
            apiPath="carousel_objects/s3/sign"
            handleProgress={handleProgress}
          />
          <input id="upload-button" type="submit" value="Upload" disabled={ state.showSubmit ? "" : "disabled" }/>
        </form>
      </div>
    </div>
  )
}

export default CarouselAdd
