import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FundHoldingsService = {
  uploadHoldingsCSV(formData) {
    return fetch(`${API_URL}/fund_holdings`, {
      method: 'POST',
      body: formData
    }).then( r => r.json() )
  }

}

export default FundHoldingsService
