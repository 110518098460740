import PublicEventsService from "../../../services/PublicEventsService";

const successfulPublicEventsFetch = publicEvents => {
	return { type: 'PUBLIC_EVENTS_FETCHED_SUCCESSFULLY', publicEvents }
};

const successfulPublicEventUpdate = publicEvent => {
	return { type: 'PUBLIC_EVENTS_UPDATED_SUCCESSFULLY', publicEvent }
};

const successfulPublicEventsCreate = publicEvent => {
	return { type: 'PUBLIC_EVENTS_CREATED_SUCCESSFULLY', publicEvent }
};

const successfulPublicEventsDeletion = publicEvents => {
	return { type: 'PUBLIC_EVENT_DELETED_SUCCESSFULLY', publicEvents }
};

const publicEventsFetchFailed = () => {
	return { type: 'PUBLIC_EVENTS_FETCH_FAILED' }
};

const publicEventUpdateFailed = () => {
	return { type: 'PUBLIC_EVENTS_UPDATE_FAILED' }
};

const publicEventCreateFailed = errors => {
	return { type: 'PUBLIC_EVENTS_CREATION_FAILED', errors }
};

const publicEventDeleteFailed = () => {
	return { type: 'PUBLIC_EVENTS_UPDATE_FAILED' }
};

export const fetchPublicEvents = () => {
	return dispatch => {
		PublicEventsService.fetchPublicEvents()
		.then( publicEvents => dispatch( successfulPublicEventsFetch( publicEvents ) ) )
		.catch( () => dispatch( publicEventsFetchFailed() ) );
	};
};

export const createPublicEvent = newPublicEvent => {
	return dispatch => {
		PublicEventsService.createPublicEvent( newPublicEvent )
		.then( publicEvent => {
			if ( publicEvent.errors && publicEvent.errors.length ) dispatch( publicEventCreateFailed( publicEvent.errors ) )
			else dispatch( successfulPublicEventsCreate( publicEvent ) )
		} );
	};
};

export const updatePublicEvent = ( id, event ) => {
	return dispatch => {
	  PublicEventsService.updatePublicEvent( id, event )
	  .then( events => dispatch( successfulPublicEventUpdate( events ) ) )
	  .catch( () => dispatch( publicEventUpdateFailed() ) );
	};
};

export const deletePublicEvent = ( id ) => {
	return dispatch => {
	  PublicEventsService.deletePublicEvent( id )
	  .then( events => dispatch( successfulPublicEventsDeletion( events ) ) )
	  .catch( () => dispatch( publicEventDeleteFailed() ) );
	};
};
