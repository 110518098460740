export default (state = {}, action) => {
  switch (action.type) {
    case "FETCHED_CHATS":
      return {
        ...state,
        activeChats: action.activeChats
      }
    case "UPDATE_CHAT": {
      const { activeChats } = state
      const index = activeChats.findIndex( chat => chat.id === action.chatData.id)
      activeChats[index].messages = action.chatData.messages
      activeChats[index]["gab_rep"] = action.chatData["gab_rep"]
      return {
        ...state,
        refresh: true,
        activeChats: [...activeChats]
      }
    }
    case "CHAT_DEACTIVATED":
      return {
        ...state,
        activeChats: action.chats
      }
    case "HANDLE_BROADCAST": {
      const { broadcast } = action
      const index = state.activeChats.findIndex( chat => chat.id === broadcast.chat.id)
      const { activeChats } = state
      if(index > -1) {
        activeChats[index].messages = broadcast.messages
        activeChats[index]["gab_rep"] = broadcast["gab_rep"]
      } else {
        const { chat } = broadcast
        chat.messages = broadcast.messages
        activeChats.unshift(chat)
      }
      return {
        ...state,
        activeChats: [...activeChats]
      }
    }
    default:
      return state
  }
}
