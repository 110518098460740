export default (state={}, action) => {
  switch(action.type){
    case 'SUCCESSFUL_LEADS_FETCH':
      return {
        ...state,
        leads: action.leads
      }
    case 'SUCCESSFUL_LEADS_EXPORT':
      return {
        ...state,
        export: action.email
      }
    case 'FAILED_LEADS_EXPORT':
      return {
        ...state,
        export: false
      }
    default:
      return state
  }
}
