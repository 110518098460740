export default (state = [], action) => {
  switch(action.type) {
    case 'SUCCESSFUL_GAVS_FETCH':
      return action.gavs;

    case 'REPLACE_GAV':
      const index = state.findIndex(gav => gav.id === action.gav.id)
        return [
          ...state.slice(0, index),
          action.gav,
          ...state.slice(index + 1)
        ];


    default:
      return state;
  }
}
