import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Spinner } from '@blueprintjs/core'
import { addNewPortfolioManager } from '../../redux/modules/PortfolioManagers/actions'
import { yearOptions } from '../TableTools'
import S3Uploader from './S3Uploader'

class PortfolioManagerNew extends Component {
  constructor(){
    super()

    this.state = {
      name: "",
      last_name: "",
      title: "",
      start_year: "",
      bio: "",
      img: "",
      showSubmit: false
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.portfolioManager.id !== this.props.portfolioManager.id) {
      this.props.history.push(`/admin/portfolio-manager-admin/${this.props.portfolioManager.id}/edit`)
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { state } = this
    const hasEmptyValues = Object.values(state).some( val => !val )
    if(hasEmptyValues) {
      document.getElementById('upload-failed').style.display= "inline-block"
    } else {
      state.bio = `<p>${state.bio.replace(/\n\n/g, "</p><p>")}</p>`
      this.props.addNewPortfolioManager(state)
      this.props.history.push("/admin/portfolio-manager-admin")
    }
  }

  handleResponse = (response) => {
    this.setState({ img: response.publicUrl })
  }

  handleProgress = (progress) => {
    if(progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  render(){
    const { errors } = this.props
    return(
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>New Portfolio Manager</h3>
          <span id="upload-failed" >Creation failed. All fields are required.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          {
            this.props.currentlyUpdating ? <Spinner size={100} className="funds-spinner"/> :
            <form id="admin-upload-form" onSubmit={this.handleSubmit}>
              <label className="upload-input-label">Full Name:</label>
              <br />
              <input className="media-info-input admin-input" onChange={this.handleChange} name="name"type='text' value={this.state.name}/>
              <br />
              <label className="upload-input-label">Last Name:</label>
              <br />
              <input className="media-info-input admin-input" onChange={this.handleChange} name="last_name"type='text' value={this.state.last_name}/>
              <br />
              <label className="upload-input-label">Title: &nbsp;<small>(entering "Research Analyst" will hide person from "Our Team" page)</small></label>
              <br />
              <input className="media-info-input admin-input" onChange={this.handleChange} name="title"type='text' value={this.state.title}/>
              <br />
              <label className="upload-input-label">Start year:</label>
              <br />
              <select className="media-info-input admin-select" onChange={this.handleChange} name="start_year"type='text'value={this.state.start_year}>
                <option value=""></option>
                {yearOptions()}
              </select>
              <br />
              <label className="upload-input-label">Bio:</label>
              <br />
              <textarea className="media-info-input" onChange={this.handleChange} name="bio" value={this.state.bio}/>
              <br />
              <label className="upload-input-label s3-upload-label">Upload Headshot:</label>
              <S3Uploader
                handleResponse={this.handleResponse}
                handleProgress={this.handleProgress}
                accept='image/*'
                apiPath="portfolio_managers/s3/sign"
              />
              <input id="upload-button" type="submit" value="Create" className="admin-input" disabled={ this.state.showSubmit ? "" : "disabled" }/>
            </form>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioManagers: state.portfolioManagers.pms || [],
  portfolioManager: state.portfolioManagers.pm || {},
  currentlyUpdating: state.portfolioManagers.updatingPm || false,
  updateSuccessful: state.portfolioManagers.updateSuccessful || false,
  errors: state.portfolioManagers.errors,
})

export default withRouter(connect(mapStateToProps, { addNewPortfolioManager })(PortfolioManagerNew))
