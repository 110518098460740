import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core'
import { withRouter } from 'react-router-dom'
import { featuredFundsFetch } from '../../redux/modules/FeaturedFunds/actions'
import FFCard from './card'
import './index.css'

class FeaturedFunds extends Component {

  componentWillMount(){
    this.props.featuredFundsFetch()
  }

  handleClick = (e, fundCode, fundType) => {
    e.stopPropagation()
    const { className } = e.target
    if (className !== 'ff-doc-link'){
      this.props.history.push(`/funds/${fundType}/${fundCode}`)
    }
  }

  render(){
    const { featuredFunds, fundType } = this.props
    const cards = featuredFunds ? featuredFunds[fundType].map( ff => <FFCard key={ff.fund_code} ff={ff} handleClick={this.handleClick} /> ) : null
    return(
      <div id="ff-card-wrapper">
        { this.props.featuredFunds ? cards : <h5><Spinner size={100} className="funds-spinner"/>&nbsp;</h5> }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  featuredFunds: state.featuredFunds.funds
})

export default withRouter(connect(mapStateToProps, { featuredFundsFetch })(FeaturedFunds))

// <div className='funds-home-featured-funds-header'>
// Featured Funds
// </div>
// <div className='funds-home-featured-funds-header-tagline'>
// Top Performing Funds (YTD)
// <hr className='funds-home-featured-funds-header-underline'/>
// </div>
