import FundHoldingsService from '../../../services/FundHoldingsService'

const beginHoldingsUpload = () => ({
  type: 'UPLOADING_HOLDINGS'
})

const successfulHoldingsUpload = () => ({
  type: 'SUCCESSFUL_HOLDINGS_UPLOAD'
})

export const uploadHoldingsCSV = (formData) => {
  return dispatch => {
    dispatch(beginHoldingsUpload())
    FundHoldingsService.uploadHoldingsCSV(formData)
    .then( json => {
      if (json.success) {
        dispatch(successfulHoldingsUpload())
      } else if (json.errors) {
        alert("Not all record were uploaded. Please contact the Web Team.")
      } else {
        alert("CSV upload failed. Check data." + json)
      }
    }).catch( e => console.log(e) )
  }
}

export const resetHoldingsUpload  = () => {
  return dispatch => dispatch({ type: 'RESET_HOLDINGS_UPLOAD' })
}
