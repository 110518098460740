import Form8937Service from '../../../services/Form8937Service'

const successful8937sFetch = forms => ({
  type: "SUCCESSFUL_8937S_FETCH",
  forms
})

const successful8937sListFetch = forms => ({
  type: "SUCCESSFUL_8937S_LIST_FETCH",
  forms
})

const failedUpload = form => ({
  type: "FAILED_8937_UPLOAD",
  form
})

const clearFormsDuringUpdate = () => ({
  type: "CLEAR_FORMS_DURING_UPDATE"
})

export const fetch8937s = () => {
  return dispatch => {
    Form8937Service.fetchForms()
    .then( forms => dispatch(successful8937sFetch(forms)) )
  }
}

export const fetch8937sList = () => {
  return dispatch => {
    Form8937Service.fetchFormsList()
    .then( forms => dispatch(successful8937sListFetch(forms)) )
  }
}


export const upload8937 = (data) => {
  return dispatch => {
    Form8937Service.uploadNew(data)
    .then( form => {
      if (form.errors) {
        dispatch(failedUpload(form))
      } else if (form.error) {
        console.log(form.error)
        alert("Upload failed: Server Error")
      } else {
        dispatch({ type: 'SUCCESSFUL_8937_UPLOAD'})
      }
    })
  }
}

export const update8937 = (id, data) => {
  return dispatch => {
    dispatch(clearFormsDuringUpdate())
    Form8937Service.updateForm(id, data)
    .then( forms => {
      if(forms.error) {
        alert("Update Failed: Server Error")
      } else if (forms.errors) {
        dispatch(failedUpload(forms))
      } else {
        dispatch(successful8937sListFetch(forms))
      }
    })
  }
}

export const deleteForm = id => {
  return dispatch => {
    dispatch(clearFormsDuringUpdate())
    Form8937Service.deleteForm(id)
    .then( forms => {
      if(forms.error) {
        alert("Update Failed: Server Error")
      } else if (forms.errors) {
        dispatch(failedUpload(forms))
      } else {
        dispatch(successful8937sListFetch(forms))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch({ type: '8937_UPLOAD_COMPLETE'})
  }
}
