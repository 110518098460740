import React, { Component } from 'react'
import S3Uploader from './S3Uploader'
import SuccessModal from './SuccessModal'

class LinkGenerator extends Component {
  constructor(){
    super()

    this.state = {
      success: false,
      link: ""
    }
  }

  handleResponse = r => {
    this.setState( {
      success: true,
      link: r.publicUrl
	} )
  }

  handleClose = () => {
    this.setState({ success: false })
  }

  render(){
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={this.state.success}
          handleClose={this.handleClose}
          phrase={"Here is your link: "}
          link={this.state.link}
        />
        <div id="upload-form-container">
          <h3>Link Generator (for images)</h3>
          <S3Uploader
            params={{}}
            accept='image/*'
            apiPath="insights/s3/sign_image"
            handleResponse={this.handleResponse}
          />
        </div>
      </div>
    )
  }
}

export default LinkGenerator
