import React, { Component } from 'react'
import FeaturedFunds from '../FeaturedFunds'
import FeaturedFundsSelect from './FeaturedFundsSelect'
import FeaturedFundLanding from './FeaturedFundLanding'
import { connect } from 'react-redux'
import { setFeaturedFunds, fetchFundsList } from '../../redux/modules/FeaturedFunds/actions'
import { Spinner } from '@blueprintjs/core'
// import './Admin.css'
//import './AdminFeaturedFunds.css'

class AdminFeaturedFunds extends Component {
  constructor(){
    super()

    this.state = {
      fundType: "",
      funds: {
        fund1: null,
        fund2: null,
        fund3: null
      }
    }
  }

  componentDidMount(){
    this.props.fetchFundsList()
  }

  changeFundType = (e) => {
    const { value } = e.target
    this.setState({
      fundType: value,
      funds: {
        fund1: null,
        fund2: null,
        fund3: null
      }
    })
  }

  handleChange1 = e => {
    const { funds } = this.state
    if(!!e) {
      funds.fund1 = e
      this.setState({ funds })
    } else {
      funds.fund1 = null
      this.setState({ funds })
    }
  }

  handleChange2 = e => {
    const { funds } = this.state
    if(!!e) {
      funds.fund2 = e
      this.setState({ funds })
    } else {
      funds.fund2 = null
      this.setState({ funds })
    }
  }

  handleChange3 = e => {
    const { funds } = this.state
    if(!!e) {
      funds.fund3 = e
      this.setState({ funds })
    } else {
      funds.fund3 = null
      this.setState({ funds })
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const { fundType, funds } = this.state
    const fundCodes = Object.values(funds)
    const hasNullValues = fundCodes.some(val => !val)
    if(!!hasNullValues){
      alert("Three funds must be selected!")
    } else {
      const funds = {}
      for(let i = 0; i < 3; i++){
        funds[`fund${i+1}`] = fundCodes[i].value
      }
      this.props.setFeaturedFunds(fundType, funds)
    }
  }

  render(){
    const { fundsList } = this.props
    if(!!this.state.fundType){
      return(
        <div id="admin-funds-edit">
          <div>
            { fundsList ?
              <FeaturedFundsSelect
              handleSubmit={this.handleSubmit}
              handleChange1={this.handleChange1}
              handleChange2={this.handleChange2}
              handleChange3={this.handleChange3}
              funds={this.state.funds}
              changeFundType={this.changeFundType}
              fundType={this.state.fundType}
              fundsList={fundsList}
              /> :
              <Spinner size={100} className="funds-spinner" />
            }
          </div>
          <FeaturedFunds fundType={this.state.fundType.replace(" ", "_").toLowerCase()}/>
        </div>
      )
    } else {
      return(
        <FeaturedFundLanding changeFundType={this.changeFundType} />
      )
    }
  }
}

const mapStateToProps = (state) => ({
  fundsList: state.featuredFunds.list
})

export default connect(mapStateToProps, { setFeaturedFunds, fetchFundsList })(AdminFeaturedFunds)
