import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FundReturnDataService = {

  updateFundReturnData(data){
    return fetch(`${API_URL}/fund_return_datas`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  }
}

export default FundReturnDataService
