import { API_URL } from '../constants'

export const headers = () => {
  const token = localStorage.getItem('token');
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer: ${token}`,
  }
}


const FeaturesService = {
  submitRequest(request) {
    return fetch(`${API_URL}/feature_requests`, {
      method: 'POST',
      body: JSON.stringify({ request }),
      headers: headers()
    }).then(response => response.json())
  },

  fetchWishlist() {
    return fetch(`${API_URL}/feature_requests`)
    .then( r => r.json() )
  },

  updateStatus(id, status) {
    return fetch(`${API_URL}/feature_requests/${id}`, {
      method: 'PATCH',
      body: JSON.stringify({ status: status }),
      headers: headers()
    }).then( r => r.json() )
  }
}

export default FeaturesService;
