import React, { useState } from 'react'
// import Select from '../../components/Select'
import { Popover } from '@blueprintjs/core'
import SelectWithAutofill from '../../components/SelectWithAutofill'

const SicavDocResult = (props) => {
  const { s, funds, deleteSicavDoc, updateSicavDoc } = props
  const [ isEditing, setIsEditing ] = useState(false)
  const [ formData, setFormData ] = useState({
    doc_type: s.doc_type,
    filepath: s.filepath,
    currency: s.currency,
    fund_code: s.fund_code,
    language: s.language,
    name: s.name
  })

  const handleChange = e => {
    const { name, value } = e.target
    const updatedFormData = { ...formData }
    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleSelectChange = e => {
    if(e){
      const { value } = e
      const updatedFormData = { ...formData }
      updatedFormData.fund_code = value
      setFormData(updatedFormData)
    } else {
      setFormData({
        ...formData,
        fund_code: null
      })
    }
  }

  const toggleEditing = () => setIsEditing(!isEditing)

  if(isEditing){
    return (
      <div>
        <form>
          <label className="upload-input-label">Document Name:</label>
          <input name="name" className="media-info-input admin-input" value={formData.name} onChange={handleChange}/>
          <label className="upload-input-label">Doc type:</label>
          <select className="media-info-input admin-select" name="doc_type" value={formData.doc_type} onChange={handleChange}>
            <option value=""></option>
            <option value="annual_report">Annual Report</option>
            <option value="incorporation">Articles of Incorporation</option>
            <option value="commentary">Commentary</option>
            <option value="factsheet">Factsheet</option>
            <option value="kiid">KIID</option>
            <option value="notice">Notice to Shareholders</option>
            <option value="prospectus">Prospectus</option>
            <option value="semi_annual_report">Semi-Annual Report</option>
          </select>
          <label className="upload-input-label">Language:</label>
          <select className="media-info-input admin-select" name="language" value={formData.language} onChange={handleChange}>
            <option value=""></option>
            <option>Danish</option>
            <option>Dutch</option>
            <option>English</option>
            <option>French</option>
            <option>German</option>
            <option>Italian</option>
            <option>Norwegian</option>
            <option>Spanish</option>
            <option>Swedish</option>
          </select>
          <label className="upload-input-label">Currency:</label>
          <select name="currency" className="media-info-input admin-select" onChange={handleChange} value={formData.currency}>
            <option value=""></option>
            <option>USD</option>
            <option>EUR</option>
            <option>GBP</option>
            <option>CHF</option>
            <option>SEK</option>
          </select>
          <label className="upload-input-label">Fund Code:</label>
          <SelectWithAutofill
            options={funds}
            className="select-tag media-info-input admin-select"
            placeholder="Enter a fund..."
            handleChange={handleSelectChange}
            onClear={(e) => this.handleSelectChange(null)}
            value={formData.fund_code}
          />
          <label className="upload-input-label">Filepath:</label>
          <input className="media-info-input admin-input" name="filepath" value={formData.filepath} onChange={handleChange}></input>
        </form>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={ () => updateSicavDoc(s.id, formData) }>Update</button>
          <button className="admin-docs-edit-btn cancel-btn" onClick={toggleEditing}>Cancel</button>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  } else {
    return(
      <div className="admin-docs-result">
        <h5>
          <a href={s.filepath} target="_blank" rel="noreferrer noopener">{s.name}</a>
        </h5>
        <p className="admin-docs-result-data">Type: {s.doc_type}</p>
        <p className="admin-docs-result-data">Currency: {s.currency}</p>
        <p className="admin-docs-result-data">Language: {s.language}</p>
          <p className="admin-docs-result-data">Uploaded on {s.updated_at.split("T")[0]}{ !!s.uploaded_by ? ` by ${ s.uploaded_by }` : "" }</p>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={toggleEditing}>Edit</button>
          <Popover minimal={true}>
            <button className="admin-docs-dlt-btn" >Delete</button>
            <div className="popover-content">
              <h4>Are you sure?</h4>
              <p>Would you like to delete this item?</p>
              <button className=" bp3-popover-dismiss">Cancel </button>
              <button className="cc-delete-btn popover-btn" onClick={() => deleteSicavDoc(s.id)}>Delete</button>
            </div>
          </Popover>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  }
}


export default SicavDocResult
