export default (state = [], action) => {
  switch(action.type) {
    case 'GAB_REP_AUTHENTICATION_REQUEST':
      return {
        ...state,
        isAuthenticating: true
      }

    case 'GAB_REP_AUTHENTICATION_SUCCESS':
      return {
        isAuthenticating: false,
        isAuthenticated: true,
      }

    case 'GAB_REP_UNSUCCESSFUL_REQUEST':
      return {
        isAuthenticating: false,
        isAuthenticated: false,
        authFailed: true
      }

    case 'GAB_REP_LOGOUT':
      return {
        isAuthenticating: false,
        isAuthenticated: false
      }
    default:
      return state;
  }
}
