export default (state = [], action) => {
  switch(action.type) {

      case 'SUCCESSFUL_MM_LIQUIDITY_FETCH':
        return action.mmLiquidity;

    default:
      return state;
  }
}
