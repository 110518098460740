export default (state = [], action) => {
  switch(action.type) {
    case 'START_MEDIA_UPLOAD':
      return {
        ...state,
        isUploading: true
      }
    case 'MEDIA_UPLOAD_SUCCESSFUL':
      return {
        ...state,
        isUploading: false,
        successfulUpload: true
      }
    case 'COMPLETE_MEDIA_UPLOAD':
      return {
        ...state,
        isUploading: false,
        successfulUpload: false
      }
    case 'SUCCESSFUL_MEDIA_UPLOADS_FETCH':
      return {
        ...state,
        media: action.media
      }
    default:
      return state;
  }
}
