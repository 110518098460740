import { API_URL } from '../constants'

const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const TeamService = {
  fetchTeams() {
    return fetch(`${API_URL}/teams`)
    .then(response => response.json())
  },

  fetchTeam(id) {
    return fetch(`${API_URL}/teams/${id}`)
    .then(response => response.json())
  },

  addNewTeam(newTeam) {
    return fetch(`${API_URL}/teams`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(newTeam),
      headers: headers()
    }).then(response => response.json())
  },

  updateTeam(id, data) {
    return fetch(`${API_URL}/teams/${id}`,{
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: headers()
    }).then(response => response.json())
  },

  deleteTeam(id) {
    return fetch(`${API_URL}/teams/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then(response => response.json())
  }

}

export default TeamService
