import AnalystsService from '../../../services/AnalystsService'

const successfulAnalystsFetch = analysts => {
  return {
    type: "SUCCESSFUL_ANALYSTS_FETCH",
    analysts
  }
}

const successfulAnalystFetch = analyst => {
  return {
    type: "SUCCESSFUL_ANALYST_FETCH",
    analyst
  }
}

const unsuccessfulAnalystCreate = (errors) => {
  return {
    type: "UNSUCCESSFUL_ANALYST_CREATE",
    errors
  }
}

export const fetchAnalysts = () => {
  return dispatch => {
    AnalystsService.fetchAnalysts()
    .then(analysts => dispatch(successfulAnalystsFetch(analysts)))
  }
}

export const fetchAnalyst = id => {
  return dispatch => {
    AnalystsService.fetchAnalyst(id)
    .then(analyst => dispatch(successfulAnalystFetch(analyst)))
  }
}

export const createAnalyst = (data) => {
  return dispatch => {
    AnalystsService.createAnalyst(data)
    .then(analysts => {
      if(analysts.errors) {
        dispatch(unsuccessfulAnalystCreate(analysts.errors))
      } else {
        dispatch(successfulAnalystsFetch(analysts))
      }
    })
  }
}

export const updateAnalyst = (data) => {
  return dispatch => {
    AnalystsService.updateAnalyst(data)
    .then(analysts => {
      if(analysts.error){
        alert(analysts.error)
      } else {
        dispatch(successfulAnalystsFetch(analysts))
      }
    })
  }
}

export const deleteAnalyst = (id) => {
  return dispatch => {
    AnalystsService.deleteAnalyst(id)
    .then(analysts => dispatch(successfulAnalystsFetch(analysts)))
  }
}
