import MediaService from '../../../services/MediaService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';


const successfulTvAppearancesFetch = tvAppearances => {
  return {
    type: 'SUCCESSFUL_TV_APPEARANCES_FETCH',
    tvAppearances
  }
}

const successfulTvAppearanceFetch = tvAppearance => {
  return {
    type: 'SUCCESSFUL_TV_APPEARANCE_FETCH',
    tvAppearance
  }
}

// const replaceTvAppearance = tv => {
//   return {
//     type: 'REPLACE_TV_APPEARANCE',
//     tv
//   }
// }
const postingTvAppearance = () => {
    return {
      type: 'POSTING_TV_APPEARANCE',
    }
}
const addAppearance = tv => {
  return {
    type: 'POST_TV_APPEARANCE_SUCCESS',
    tv
  }
}

const errorCreatingTvAppearance = errors => ({
  type: 'TV_APPEARANCE_ERROR',
  errors
})
// const successfulUpload = tv => {
//   return {
//     type: 'SUCCESSFUL_TV_POST',
//     tv
//   }
// }

const completeUpload = () => {
  return {
    type: "TV_POST_COMPLETE"
  }
}

const startVideoListFetch = () => ({
  type: "START_VIDEO_LIST_REFRESH"
})

const successfulTvUpdate = tvs => ({
  type: "SUCCESSFUL_TV_UPDATE",
  tvs
})

const resetUpdate = () => ({
  type: "RESET_TV_UPDATE"
})



export const fetchTvAppearances = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.fetchTvAppearances()
      .then(tvAppearances => {
        dispatch(finishFetchRequest())
        dispatch(successfulTvAppearancesFetch(tvAppearances))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchTvAppearance = (id) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.fetchTvAppearance(id)
      .then(tvAppearance => {
        dispatch(finishFetchRequest())
        dispatch(successfulTvAppearanceFetch(tvAppearance))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}


export const updateTvAppearance = (tv) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    dispatch(resetUpdate())
    MediaService.updateTvAppearance(tv)
      .then(tvs => {
        dispatch(finishFetchRequest())
        dispatch(successfulTvUpdate(tvs))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const createTvAppearance = (tv) => {
    return dispatch => {
      dispatch(postingTvAppearance())
      MediaService.addTvAppearance(tv)
        .then(tv => {
          if(tv.errors){
            dispatch(errorCreatingTvAppearance(tv.errors))
          } else {
            dispatch(addAppearance(tv))
          }
        })
        .catch(error => {
          dispatch(unsuccessfulFetchRequest())
        })
    }
}

export const refreshVideoList = () => {
  return dispatch => {
    dispatch(startVideoListFetch())
    MediaService.refreshVideoList()
    .then(tvAppearances => {
      dispatch(finishFetchRequest())
      dispatch(successfulTvAppearancesFetch(tvAppearances))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
