import React, { useState } from 'react'
import QuarterSelector from '../AdminPortal/QuarterSelector'
import { Popover } from '@blueprintjs/core'

function FilingResult(props) {
  const { f, deleteFiling, updateFiling } = props
  const [ isEditing, setIsEditing ] = useState(false)
  const [ formData, setFormData ] = useState({
        doc_type: f.doc_type,
        filepath: f.filepath,
        quarter: f.quarter,
        year: f.year
      })

  function handleChange(e){
    const { name, value } = e.target
    const updatedFormData = { ...formData }
    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const toggleEditing = () => setIsEditing(!isEditing)

  if(isEditing){
    return (
      <div>
        <form>
          <label className="upload-input-label">Doc type:</label>
          <select className="media-info-input admin-select" name="doc_type" value={formData.doc_type} onChange={handleChange}>
            <option value=""></option>
            <option>10Q</option>
            <option>10K</option>
            <option>Annual Report</option>
            <option>Proxy</option>
            <option>XBRL</option>
          </select>
          <QuarterSelector
            handleChange={handleChange}
            state={formData}
            type={"corporate_filing_update"}
          />
          <label className="upload-input-label">Filepath:</label>
          <input className="media-info-input admin-input" name="filepath" value={formData.filepath} onChange={handleChange}></input>
        </form>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={ () => updateFiling(f.id, formData) }>Update</button>
          <button className="admin-docs-edit-btn cancel-btn" onClick={toggleEditing}>Cancel</button>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  } else {
    return(
      <div className="admin-docs-result">
        <h5>
          <a href={f.filepath} target="_blank" rel="noreferrer noopener">{`${f.doc_type} ${f.quarter ? `${f.quarter}Q` : ''} ${f.year}`}</a>
        </h5>
          <p className="admin-docs-result-data">Uploaded on {f.updated_at.split("T")[0]}{ !!f.uploaded_by ? ` by ${ f.uploaded_by }` : "" }</p>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={toggleEditing}>Edit</button>
          <Popover minimal={true}>
            <button className="admin-docs-dlt-btn" >Delete</button>
            <div className="popover-content">
              <h4>Are you sure?</h4>
              <p>Would you like to delete this item?</p>
              <button className=" bp3-popover-dismiss">Cancel </button>
              <button className="cc-delete-btn popover-btn" onClick={() => deleteFiling(f.id)}>Delete</button>
            </div>
          </Popover>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  }
}

export default FilingResult
