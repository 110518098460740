import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Spinner } from '@blueprintjs/core'
import { fetchDocuments, deleteUpload, updateDoc } from '../../redux/modules/DocumentUploads/actions'
import { fetchAllFunds } from '../../redux/modules/Funds/actions'
import { fetchPressReleases, deletePr, updatePr } from '../../redux/modules/PressReleases/actions'
import { fetchFilings, deleteFiling, updateFiling } from '../../redux/modules/Filings/actions'
import { fetchSicavDocs, deleteSicavDoc, updateSicavDoc } from '../../redux/modules/SicavDocs/actions'
import { fetch8937sList, deleteForm, update8937 } from '../../redux/modules/Form8937s/actions'
import { fetchTaxDocs, updateTaxDoc, deleteTaxDoc } from '../../redux/modules/TaxDocs/actions'
import Filter from './Filter'
import DocResult from './DocResult'
import PrResult from './PrResult'
import FilingResult from './FilingResult'
import SicavDocResult from './SicavDocResult'
import Form8937Result from './Form8937Result'
import TaxDocResult from './TaxDocResult'
import PageSelector from '../DocumentArchive/PageSelector'
import '../DocumentArchive/index.css'

const DocViewer = props => {
  const { funds, docs, pr, filings, sicavDocs, form8937s, taxDocs } = props
  const [ filterTerm, setFilterTerm ] = useState("")
  const [ filterFund, setFilterFund ] = useState(null)
  const [ docType, setDocType ] = useState("Product Literature")
  const defaultSubCategories = [
    "annual_report",
    "commentary",
    "factsheet",
    "holdings",
    "prospectus",
    "prospectus_supp",
    "proxy_statement",
    "quarterly_report",
    "sai",
    "semi_annual_report",
    "soi",
    "summary_prospectus"
  ]
  const [ subCategories, setSubCategories ] = useState(defaultSubCategories)
  const [ limit, setLimit ] = useState(10)
  const [ deleteFn, setDeleteFn ] = useState(() => props.deleteUpload)
  const [ updateFn, setUpdateFn ] = useState(() => props.updateDoc)

  const { fetchDocuments, fetchAllFunds } = props
  useEffect( () => {
    fetchDocuments()
    fetchAllFunds()
  }, [fetchDocuments, fetchAllFunds])

  useEffect( () => {
    const isPr = docType === "Press Releases"
    const isFilings = docType === "Corporate Filings"
    const isSicavDocs = docType === "SICAV Documents"
    const is8937s = docType === "Form 8937s"
    const isTaxDocs = docType === "Tax Documents"

    if (isPr && !pr.length) {
      props.fetchPressReleases()
    } else if (isFilings && !Object.keys(filings).length){
      props.fetchFilings()
    } else if (isSicavDocs && !sicavDocs.length) {
      props.fetchSicavDocs()
    } else if (is8937s && !form8937s.length) {
      props.fetch8937sList()
    } else if (isTaxDocs && !taxDocs.length) {
      props.fetchTaxDocs()
    }
  }, [docType, filings, form8937s.length, pr.length, props, sicavDocs.length, taxDocs.length])

  const changePage = e => {
    const { innerHTML } = e.target
    const limit = parseInt(innerHTML) * 10
    setLimit(limit)
    window.scrollTo(0, 0)
  }

  const handlePrev = () => {
    if(limit > 10) {
      const newLimit = limit - 10
      setLimit(newLimit)
    }
    window.scrollTo(0, 0)
  }

  const handleNext = resultsLength => {
    if (limit < 200 && limit < resultsLength) {
      const newLimit = limit + 10
      setLimit(newLimit)
    }
    window.scrollTo(0, 0)
  }

  const handleSearchChange = e => {
    const { value } = e.target
    setLimit(10)
    setFilterTerm(value)
  }

  const handleFundSelect = e => {
    if(e){
      const { value } = e
      setFilterFund(value)
    } else {
      setFilterFund(null)
    }
    setLimit(10)
  }

  const handleTypeChange = e => {
    const { value } = e.target.parentNode.attributes.name
    switch(value){
      case 'Product Literature':
        setDeleteFn( () => props.deleteUpload )
        setUpdateFn( () => props.updateDoc )
        break
      case 'Press Releases':
        setDeleteFn( () => props.deletePr )
        setUpdateFn( () => props.updatePr )
        break;
      case 'Corporate Filings':
        setDeleteFn( () => props.deleteFiling )
        setUpdateFn( () => props.updateFiling )
        break;
      case 'SICAV Documents':
        setDeleteFn( () => props.deleteSicavDoc )
        setUpdateFn( () => props.updateSicavDoc )
        break;
      case 'Form 8937s':
        setDeleteFn( () => props.deleteForm )
        setUpdateFn( () => props.update8937 )
        break;
      case 'Tax Documents':
        setDeleteFn( () => props.deleteTaxDoc )
        setUpdateFn( () => props.updateTaxDoc )
        break;
      default:
        setDeleteFn( () => console.log("No Delete Function") )
        setUpdateFn( () => console.log("No Update Function") )
    }
    setDocType(value)
    setLimit(10)
  }

  const handleSubCategoryClick = e => {
    const { value } = e.target
    const isAlreadySelected = subCategories.includes(value)
    if (isAlreadySelected) {
      const newArray = subCategories.filter( c => c !== value )
      setSubCategories(newArray)
    } else {
      const newArray = subCategories.concat(value)
      setSubCategories(newArray)
    }
  }

  const clearAllSubCats = () => {
    setSubCategories([])
  }

  const setAllSubCats = () => {
    setSubCategories(defaultSubCategories)
  }

  const filterResults = (name, fundCode) => {
    const nameIsMatching = name.toLowerCase().includes(filterTerm.toLowerCase())
    const fundCodeMatching = (fundCode === filterFund) || (filterFund === null)
    return nameIsMatching && fundCodeMatching
  }

  const fundsOptions = funds.map( f => {
    if(f.flagship) {
      return { value: f.fund_code, label: `${f.fundshortname} (Common)` }
    } else {
      return { value: f.fund_code, label: f.fundshortname }
    }
  })
  fundsOptions.unshift({ value: 0, label: "GAMCO Investors" })
  let isFetched
  let docResults
  let docsToShow
  switch (docType) {
    case "Product Literature":
      isFetched = !!docs.length
      docResults = docs.filter( doc => {
        return filterResults(doc.name, doc.fund_code) && subCategories.includes(doc.doc_type)
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( d => (
        <DocResult key={d.id} d={d} funds={fundsOptions} deleteUpload={deleteFn} updateDoc={updateFn}/>
      ))
      break;
    case "Press Releases":
      console.log(pr)
      isFetched = !!pr.length
      docResults = pr.filter( pr => {
        return filterResults(pr.title, pr.fund_code)
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( pr => (
        <PrResult key={pr.id} pr={pr} funds={fundsOptions} deletePr={deleteFn} updatePr={updateFn} />
      ))
      break;
    case "Corporate Filings":
      isFetched = !!Object.values(filings).length
      let allFilings = []
      Object.values(filings).forEach( v => {
        allFilings = allFilings.concat(v)
      })
      docResults = allFilings.filter( f => {
        const filingInfo = `${f.doc_type} ${f.quarter ? `${f.quarter}Q` : ''} ${f.year}`
        return filingInfo.toLowerCase().includes(filterTerm.toLowerCase())
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( f => (
        <FilingResult key={f.id} f={f} deleteFiling={deleteFn} updateFiling={updateFn} />
      ))
      break;
    case "SICAV Documents":
      isFetched = !!sicavDocs.length
      docResults = sicavDocs.filter( s => {
        return filterResults(s.name, s.fund_code)
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( s => (
        <SicavDocResult key={s.id} s={s} funds={fundsOptions} deleteSicavDoc={deleteFn} updateSicavDoc={updateFn} />
      ))
      break;
    case "Form 8937s":
      isFetched = !!form8937s.length
      docResults = form8937s.filter( f => {
        return filterResults(f.as_of, f.fund_code)
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( f => (
        <Form8937Result key={f.id} f={f} funds={fundsOptions} deleteForm={deleteFn} updateForm={updateFn} />
      ))
      break;
    case "Tax Documents":
      isFetched = !!taxDocs.length
      docResults = taxDocs.filter( doc => {
        return filterResults(doc.as_of, doc.fund_code)
      })
      docsToShow = docResults.slice((limit - 10), (limit - 1)).map( doc => (
        <TaxDocResult doc={doc} key={doc.id} funds={fundsOptions} deleteDoc={deleteFn} updateDoc={updateFn} />
      ))
      break;
    default:
      docResults = []
      docsToShow = []
  }

  return (
    <div id="admin-upload-container">
      <div id="admin-docs-viewer-container">
        <div id="doc-viewer-filter-container">
          <Filter
            fundsOptions={fundsOptions}
            handleFundSelect={handleFundSelect}
            handleTypeChange={handleTypeChange}
            handleSubCategoryClick={handleSubCategoryClick}
            filterFund={filterFund}
            docType={docType}
            subCategories={subCategories}
            clearAllSubCats={clearAllSubCats}
            setAllSubCats={setAllSubCats}
          />
        </div>
        <div id="archive-search-container">
          <input id="search-bar" placeholder=" Filter Results" onChange={handleSearchChange} value={filterTerm}/>
        </div>
        <h5 id="archive-results-header" className="admin-docs-results">{ limit - 9 } to { limit > docResults.length ? docResults.length : limit } of { docResults.length } Results </h5>
      {
        docResults.length || isFetched ?
        <div>
          <div id="doc-viewer-results">
            {docsToShow}
          </div>
          <PageSelector
            changePage={changePage}
            handlePrev={handlePrev}
            handleNext={ () => handleNext(docResults.length) }
            limit={limit}
            docResults={docResults}
          />
        </div> : <Spinner className="funds-spinner" size={100} />
      }
      </div>
    </div>
  )

}

const mapStateToProps = state => ({
  docs: state.docs.documents || [],
  funds: state.funds,
  pr: state.pr.pr || [],
  filings: state.filings.filings || {},
  sicavDocs: state.sicavDocs.docs || [],
  form8937s: state.form8937s.formsList || [],
  taxDocs: state.taxDocs.docs || []
})

export default connect(
  mapStateToProps,
  {
    fetchDocuments,
    fetchAllFunds,
    fetchPressReleases,
    fetchFilings,
    deleteUpload,
    updateDoc,
    deletePr,
    updatePr,
    deleteFiling,
    updateFiling,
    fetchSicavDocs,
    deleteSicavDoc,
    updateSicavDoc,
    fetch8937sList,
    deleteForm,
    update8937,
    fetchTaxDocs,
    deleteTaxDoc,
    updateTaxDoc
  })(DocViewer)
