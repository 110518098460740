import React from 'react'
import PDFUploader from './PDFUploader'
import S3Uploader from './S3Uploader'
import AsOfSelector from '../AdminPortal/AsOfSelector'
import { Tag, RadioGroup, Radio } from "@blueprintjs/core";

// const authors = [
//   { label: "A. Carolina Jolly", value: "A. Carolina Jolly" },
//   { label: "Adam Trivison", value: "Adam Trivison" },
//   { label: "Brett Harris", value: "Brett Harris" },
//   { label: "Brian Sponheimer", value: "Brian Sponheimer" },
//   { label: "Caesar M.P. Bryan", value: "Caesar M.P. Bryan" },
//   { label: "Christina Alfandary", value: "Christina Alfandary" },
//   { label: "Christopher Desmarais", value: "Christopher Desmarais" },
//   { label: "Christopher Marangi", value: "Christopher Marangi" },
//   { label: "Chris Ward", value: "Chris Ward" },
//   { label: "Daniel M. Miller", value: "Daniel M. Miller" },
//   { label: "Evan Miller", value: "Evan Miller" },
//   { label: "Gian Maria Magrini", value: "Gian Maria Magrini" },
//   { label: "Hendi Susanto", value: "Hendi Susanto" },
//   { label: "Howard F. Ward", value: "Howard F. Ward" },
//   { label: "Ian Lapey", value: "Ian Lapey" },
//   { label: "James Dinsmore", value: "James Dinsmore" },
//   { label: "Jane O'Keeffe", value: "Jane O'Keeffe" },
//   { label: "Jeffrey J. Jonas", value: "Jeffrey J. Jonas" },
//   { label: "Jennie Tsai", value: "Jennie Tsai" },
//   { label: "Jing He", value: "Jing He" },
//   { label: "Jose Garza", value: "Jose Garza" },
//   { label: "Joseph Gabelli", value: "Joseph Gabelli" },
//   { label: "Judith Raneri", value: "Judith Raneri" },
//   { label: "Justin Bergner", value: "Justin Bergner" },
//   { label: "Kevin V. Dreyer", value: "Kevin V. Dreyer" },
//   { label: "Kevin Kedra", value: "Kevin Kedra" },
//   { label: "Mario Gabelli", value: "Mario Gabelli" },
//   { label: "Melody Bryant", value: "Melody Bryant" },
//   { label: "Regina M. Pitaro", value: "Regina M. Pitaro" },
//   { label: "Robert D. Leininger", value: "Robert D. Leininger" },
//   { label: "Ronald S. Eaker", value: "Ronald S. Eaker" },
//   { label: "Sara Wojda", value: "Sara Wojda" },
//   { label: "Sarah Donnelly", value: "Sarah Donnelly" },
//   { label: "Sergey Dluzhevskiy", value: "Sergey Dluzhevskiy" },
//   { label: "Shawn Kim", value: "Shawn Kim" },
//   { label: "Thomas Dinsmore", value: "Thomas Dinsmore" },
//   { label: "Timothy Winter", value: "Timothy Winter" },
//   { label: "Tony Bancroft", value: "Tony Bancroft" },
//   { label: "Vincent Hugonnard-Roche", value: "Vincent Hugonnard-Roche" },
//   { label: "Wayne C. Plewniak", value: "Wayne C. Plewniak" },
// ]

const InsightInfoForm = (props) => {

  const {
    handleInfoChange,
    handleChangeCategory,
    handleAddAuthor,
    handleRemoveAuthor,
    handleDelete,
    selectRef,
    selectedAuthors,
    insight,
    errors,
    portfolioManagers,
    analysts
  } = props
  const formFieldsFromInsight = (key) => {
      return (insight ? insight[key] : undefined)
  }
  const authors = [...portfolioManagers, ...analysts]
  const generateOptions = () => {
    const authorsList = authors.filter( a => !!a && !selectedAuthors.some(sel => sel.id === a.id && sel.title === a.title) )
    .map( ( a, i ) => <option key={i} value={`${a.id}-${a.title}`} >{a.name}</option> )
    authorsList.unshift(<option key={ -1 } value="default" hidden>Select an author(s)</option>)
    return authorsList
  }

  const selectedAuthorsTags = () => {
    return selectedAuthors.map( sel => {
      return (
        <Tag
          key={sel.id || sel.value}
          onRemove={(e) => handleRemoveAuthor(sel)}
          round={false}
          style={{ marginRight: '5px' }}
        >
          {sel.name || sel.value}
        </Tag>
      )
    })
  }

  const handleDeleteClick = () => {
    const confirmed = window.confirm("Are you sure you would like to delete this insight?")
    if (confirmed){
      handleDelete(insight.id)
    }
  }
  return(
    <div id="info-form-container">
      { !!errors.length ? <span id="update-error-span">Error: {errors[0]}</span> : null }
      <form id="info-form" onSubmit={props.handleSubmit}>
        <label className="upload-info-label" >Title (70 character max):</label><br />
        <input
          className="admin-input"
          name="title"
          type="text"
          onChange={handleInfoChange}
          value={formFieldsFromInsight("title")}
          maxLength="70"
          required
        />
        <br />
        <label className="upload-info-label" >Author:</label>
        { insight.id && (new Date("2020-03-11") > new Date(insight.date)) ? <span style={{ color: 'red', fontWeight: 'bold' }}>Please remove and re-select authors to properly update!</span> : null }
        <br />
        <select
          className="index-fund-filter-select admin-select"
          onChange={handleAddAuthor}
          ref={selectRef}
          defaultValue="default"
        >
          {generateOptions()}
        </select>
        <div>
          {selectedAuthorsTags()}
        </div>
        <AsOfSelector
          className="admin-select"
          type="insight"
          handleDayChange={props.handleDayChange}
          date={props.insight.date}
        />
        <label className="upload-info-label" >Banner/Thumbnail Short Description (135 character max):</label><br />
        <input
          className="admin-input"
          name="description"
          type="text"
          onChange={handleInfoChange}
          value={formFieldsFromInsight("description")}
          required
          maxLength="135"
        />
        <br />
        <label className="upload-info-label" >Subheading:</label><br />
        <input
          className="admin-input"
          name="caption"
          type="text"
          onChange={handleInfoChange}
          value={formFieldsFromInsight("caption")}
          required
        />
        <br />
        <label className="upload-info-label" >Category:</label>
        <br />
        <select
          className="index-fund-filter-select admin-select"
          onChange={handleChangeCategory}
          value={formFieldsFromInsight("category")}
        >
          <option value=""></option>
          <option value="blog">Blog</option>
          <option value="esg">ESG</option>
          <option value="closed_ends">Closed-End Funds</option>
          <option value="investment_strategies">Investment Strategies</option>
          <option value="wealth_management">Wealth Management</option>
        </select>
        <br />
        {
          props.insight.pdf_link ?
          <div><button id="insight-change-img-btn" onClick={props.resetPdfUrl}>Change PDF</button></div>
          :
          <div>
            <label className="upload-info-label" >Upload PDF </label>
            <br />
            <PDFUploader
              bindPdfToInsight={props.bindPdfToInsight}
              handleProgress={props.handlePdfProgress}
            />
          </div>
        }
        <br />
        {
          props.insight.photo ?
          <div><button id="insight-change-img-btn" onClick={props.resetPhotoUrl}>Change Image</button></div>
          :
          <div>
            <label className="upload-info-label" >Upload an Image <small> (*Image must be larger than 50 KB and <em>over</em> 1920 pixels wide.)</small></label><br />
            <S3Uploader
              isNewInsight={!(props.insight && props.insight.photo)}
              bindImageToInsight={props.bindImageToInsight}
              handleProgress={props.handleProgress}
            />
            <br />
          </div>
        }
        <RadioGroup
            label="Display:"
            onChange={handleInfoChange}
            selectedValue={formFieldsFromInsight("display")}
            large
        >
          <Radio label="Show" value={"true"} />
          <Radio label="Hide" value={"false"} />
        </RadioGroup>
        <input type="submit" value="Submit Article" id="insight-submit" disabled={false}/>
      </form>
      <div id="back-btn-container">
        <button onClick={props.handleBack} id="insight-back-btn"><b>Back</b></button>
        { insight.id ? <button onClick={handleDeleteClick} id="insight-back-btn" className="insight-delete-btn"><b>Delete</b></button> : null }
      </div>
    </div>
  )
}

export default InsightInfoForm

// <Select
//   defaultValue={formFieldsFromInsight("author")}
//   isMulti
//   name="author"
//   options={authors}
//   className=""
//   onChange={handleMultiSelect}
// />

// props.insight.photo ? "" : "disabled"
