import React from 'react'
import { withRouter } from 'react-router-dom'

const AdminPortal = ( { location } ) => {
  const admin = JSON.parse(localStorage.getItem('admin'))
  const adminFullName = admin.name || admin["full_name"]
  const adminFirstName = adminFullName.split(" ")[0]
  const userComingFromProhibitedPage = !!location.search.match( 'prohibited' );
  return(
    <div id="admin-portal-landing">
		{ userComingFromProhibitedPage && <p style={ { fontWeight: '600', textAlign: 'center', color: 'red' } } >Basic users cannot access that page. Contact Gabelli IT to update your account role if you need to access this feature.</p> }
      <h3 id="admin-greeting" >Hello, {adminFirstName}</h3>
      <p className="admin-intro" >
        Welcome to the Admin Portal.
      </p>
      <p className="admin-intro" >
        Please use the menu on the left to navigate the administrator section.
      </p>
    </div>
  )
}

export default withRouter(AdminPortal);
