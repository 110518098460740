import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

class MediaAdminNav extends Component {

  render() {
    return (
      <div className="media-admin-nav">
        <div>
          <NavLink className="media" to='/admin/media-admin/news'>In The News</NavLink>
        </div>
        <div>
          <NavLink className="media" to='/admin/media-admin/tv'>TV Appearances</NavLink>
        </div>
        <div>
          <NavLink className="media" to='/admin/media-admin/podcast'>Podcasts</NavLink>
        </div>
      </div>
    )
  }
}

export default withRouter(MediaAdminNav);
