import DivEstimateService from '../../../services/DivEstimateService';

const successfulEstimateAdd = estimate => {
  return {
    type: 'SUCCESSFUL_ESTIMATE_ADD',
    estimate
  }
}

export const failedEstimateAdd = errors => ({
  type: "FAILED_EST_ADD",
  errors
})

const startAddEstimate = () => ({
  type: "START_ADD_ESTIMATE"
})

const startDeleteEstimate = () => ({
  type: "START_DELETE_ESTIMATE"
})

const successfulEstimatesFetch = estimates => ({
  type: "ESTIMATES_FETCH",
  estimates
})

const successfulEstimateDelete = estimates => ({
  type: "SUCCESSFUL_ESTIMATE_DELETE",
  estimates
})

const startTextSubmit = () => ({
  type: "START_TEXT_SUBMIT"
})

const successfulTextSubmit = text => ({
  type: "SUCCESSFUL_TEXT_SUBMIT",
  text: text.text
})

const successfulTextFetch = text => ({
  type: "SUCCESSFUL_TEXT_FETCH",
  text: text.text
})

export const addEstimate = data => {
  return dispatch => {
    dispatch(startAddEstimate())
    DivEstimateService.addEstimate(data)
    .then( estimates => {
      if (estimates.errors) {
        dispatch(failedEstimateAdd(estimates.errors))
      } else if (estimates.error) {
        alert("Upload failed: Server Error")
      } else {
        dispatch(successfulEstimateAdd(estimates))
      }
    })
  }
}

export const fetchEstimates = () => {
  return dispatch => {
    DivEstimateService.fetchEstimates()
    .then( estimates => {
      dispatch(successfulEstimatesFetch(estimates))
    })
  }
}

export const deleteEstimate = id => {
  return dispatch => {
    dispatch(startDeleteEstimate())
    DivEstimateService.deleteEstimate(id)
    .then( estimates => {
      dispatch(successfulEstimateDelete(estimates))
    })
  }
}

export const fetchText = () => {
  return dispatch => {
    dispatch(startTextSubmit())
    DivEstimateService.fetchText()
    .then( text => dispatch(successfulTextFetch(text)) )
  }
}

export const submitText = text => {
  return dispatch => {
    dispatch(startTextSubmit())
    DivEstimateService.submitText(text)
    .then( text => dispatch(successfulTextSubmit(text)) )
  }
}
