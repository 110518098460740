import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FilingsService = {
  fetchFilings(){
    return fetch(`${API_URL}/corporate_filings`)
    .then(r => r.json())
  },

  uploadFiling(filing) {
    return fetch(`${API_URL}/corporate_filings`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(filing),
      headers: headers()
    }).then( r => r.json() )
    .catch( e => console.log(e) )
  },

  updateFiling(id, corporate_filing) {
    return fetch(`${API_URL}/corporate_filings/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: headers(),
      body: JSON.stringify({ corporate_filing })
    }).then( r => r.json() )
  },

  deleteFiling(id){
    return fetch(`${API_URL}/corporate_filings/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }
}

export default FilingsService;
