import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchWishlist } from '../../../redux/modules/Features/actions'

class Wishlist extends Component {
  componentDidMount(){
    this.props.fetchWishlist()
  }

  parseStatus = status => {
    switch(status){
      case 'ip':
        return "In Progress"
      case 'new':
        return "New"
      case 'complete':
        return "Complete"
      case 'rejected':
        return "Rejected";
      default:
        return 'Error'
    }
  }
  render(){
    const requests = this.props.wishlist.map( req => {
      return (
        <li>
          <Link style={{ color: '#00aeef' }} to={"/admin/wishlist/" + req.id}>{req.feature}</Link>
           &nbsp; - &nbsp;
          {this.parseStatus(req.status)}
        </li>
      )
    })
    return (
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>Wishlist <small>(Click to Edit)</small></h3>
          <ul className="wishlist">
          {requests}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  wishlist: state.features.wishlist || []
})

export default connect(mapStateToProps, { fetchWishlist })(Wishlist)
