import React, { Component } from 'react'
// import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Icon } from '@blueprintjs/core'
// import Select from 'react-select'
import SelectWithAutofill from '../../components/SelectWithAutofill'
// import { fetchTeam, updateTeam } from '../../redux/modules/Teams/actions'

class TeamCard extends Component {
  constructor(props){
    super(props)

    this.state = {
      isEditing: false,
      name: props.team.name,
      summary: props.team.summary,
      portfolioManagers: props.team.portfolio_managers,
      portfolioManagersToAdd: [],
      funds: props.team.funds,
      fundsToAdd: []
    }
  }

  componentDidUpdate(){
    const portfolioManagers = this.props.team.portfolio_managers
    const funds = this.props.team.funds
    if( portfolioManagers !== this.state.portfolioManagers){
      this.setState({
        portfolioManagers,
        portfolioManagersToAdd : []
      })
    }
    if( funds !== this.state.funds){
      this.setState({
        funds,
        fundsToAdd: []
      })
    }
  }

  toggleEdit = () => {
    this.setState({
      isEditing: !this.state.isEditing
    })
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { id } = this.props.team
    this.props.updateTeam(id, this.state)
    this.setState({
      isEditing: !this.state.isEditing
    })
  }

  // handleDelete = () => {
  //   const { id } = this.props.matchParams
  //   this.props.deleteTeam(id)
  // }

  removePersistedPM(pmId) {
    const { portfolioManagers } = this.state
    const pmToRemove = portfolioManagers.find( pm => pm.id === pmId )
    const pmIndex = portfolioManagers.indexOf(pmToRemove)
    if(pmIndex > -1){ portfolioManagers.splice(pmIndex, 1) }
    this.setState({ portfolioManagers })
  }

  removeAddedPM(pmId) {
    const { portfolioManagersToAdd } = this.state
    const pmToRemove = portfolioManagersToAdd.find( pm => pm.id === pmId )
    const pmIndex = portfolioManagersToAdd.indexOf(pmToRemove)
    if(pmIndex > -1){ portfolioManagersToAdd.splice(pmIndex, 1) }
    this.setState({ portfolioManagersToAdd })
  }

  removePersistedFund(fundCode) {
    const { funds } = this.state
    const fundToRemove = funds.find( fund => fund.fund_code === fundCode )
    const fundIndex = funds.indexOf(fundToRemove)
    if(fundIndex > -1){ funds.splice(fundIndex, 1) }
    this.setState({ funds })
  }

  removeAddedFund(fundCode) {
    const { fundsToAdd } = this.state
    const fundToRemove = fundsToAdd.find( fund => fund.fundCode === fundCode )
    const fundIndex = fundsToAdd.indexOf(fundToRemove)
    if(fundIndex > -1){ fundsToAdd.splice(fundIndex, 1) }
    this.setState({ fundsToAdd })
  }

  handlePmSelect = (pmOption) => {
    const pmToAdd = {
      id: pmOption.value,
      name: pmOption.label
    }
    this.setState({
      portfolioManagersToAdd: [...this.state.portfolioManagersToAdd, pmToAdd]
    })
  }

  handleFundSelect = (fundOption) => {
    const fundToAdd = {
      fundCode: fundOption.value,
      fundshortname: fundOption.label
    }
    this.setState({
      fundsToAdd: [...this.state.fundsToAdd, fundToAdd]
    })
  }

  render(){
    const { team } = this.props
    const persistedPortfolioManagers = this.state.portfolioManagers.map( pm => {
      return (
        <li className="pm-edit-funds-li" key={pm.id}>
          {this.state.isEditing ? <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removePersistedPM(pm.id)}/> : null}
          {pm.name}
        </li>
      )
    })
    const pmsToAdd = this.state.portfolioManagersToAdd.map( pm => {
      return (
        <li className="pm-edit-funds-li" key={pm.id}>
          {this.state.isEditing ? <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removeAddedPM(pm.id)}/> : null}
          {pm.name}
        </li>
      )
    })
    const pmOptions = (
      this.props.portfolioManagers.filter( pm => !this.state.portfolioManagersToAdd.some(addedPm => addedPm.id === pm.id) )
      .filter( pm => !this.state.portfolioManagers.some(addedPm => addedPm.id === pm.id) )
      .map( pm => ({ value: pm.id, label: pm.name}))
    )

    const persistedFunds = this.state.funds.map( fund => {
      return (
        <li className="pm-edit-funds-li" key={fund.fund_code}>
          {this.state.isEditing ? <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removePersistedFund(fund.fund_code)}/> : null}
          {fund.fundshortname}
        </li>
      )
    })
    const fundsToAdd = this.state.fundsToAdd.map( fund => {
      return (
        <li className="pm-edit-funds-li" key={fund.fundCode}>
          {this.state.isEditing ? <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removeAddedFund(fund.fundCode)}/> : null}
          {fund.fundshortname}
        </li>
      )
    })

    const fundOptions = (
      this.props.funds.filter( fund => !this.state.fundsToAdd.some(addedFund => addedFund.fundCode === fund.fund_code) )
      .filter( fund => !this.state.funds.some(addedFund => addedFund.fund_code === fund.fund_code) )
      .map( fund => ({ value: fund.fund_code, label: fund.fundshortname}))
    )

    if (this.state.isEditing) {
      return (
        <div className="carousel-card pm-team-admin-card">
          <form className="cc-card-edit pm-team-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Team Name:</label>
            <br />
            <input
              name="name"
              className="media-info-input"
              value={this.state.name}
              onChange={this.handleChange}
              type="text"
            />
            <br />
            <label className="upload-input-label">Summary:</label>
            <br />
            <textarea
              name="summary"
              className="media-info-input"
              value={this.state.summary}
              onChange={this.handleChange}
              type="textarea"
            />
            <br />
            <label className="upload-input-label">Portfolio Managers:</label>
            <SelectWithAutofill
              options={pmOptions}
              className="select-fund"
              onChange={this.handlePmSelect}
              placeholder="Choose a PM to add ..."
            />
            <ul className="admin-team-pms-list">
              {persistedPortfolioManagers}
              {pmsToAdd}
            </ul>
            <br />
            <label className="upload-input-label">Funds:</label>
            <SelectWithAutofill
              options={fundOptions}
              className="select-fund"
              onChange={this.handleFundSelect}
              placeholder="Choose a Fund to add ..."
            />
            <ul className="admin-team-pms-list">
              {persistedFunds}
              {fundsToAdd}
            </ul>
            <br />
            <button onClick={this.toggleEdit} className="pm-team-back-btn">Back</button>
            <input id="upload-button" type="submit" value="Update"/>
          </form>
        </div>
      )
    } else {
      return (
        <div className="carousel-card">
          <div className="cc-data-container">
            <span className="cc-label">Team Name:</span>
            <br />
            <span className="cc-item-data">{team.name}</span>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Summary:</span>
            <br />
            <p>{team.summary}</p>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Portfolio Managers:</span>
            <ul className="admin-team-pms-list">
              {persistedPortfolioManagers}
            </ul>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Funds:</span>
            <ul className="admin-team-funds-list">
              {persistedFunds}
            </ul>
          </div>
          <div className="admin-team-button-container">
            <button onClick={this.toggleEdit}>Edit</button>
            <button onClick={() => this.props.deleteTeam(team.id)}>Delete</button>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(TeamCard)
