import AdministratorsService from '../../../services/AdministratorsService'

const successfulAdministratorsFetch = administrators => {
  return {
    type: "SUCCESSFUL_ADMINISTRATORS_FETCH",
    administrators
  }
}

export const fetchAdministrators = () => {
  return dispatch => {
    AdministratorsService.fetchAdministrators()
    .then(administrators => dispatch(successfulAdministratorsFetch(administrators)))
  }
}

export const createAdministrator = (data) => {
  return dispatch => {
    AdministratorsService.createAdministrator(data)
    .then(administrators => dispatch(successfulAdministratorsFetch(administrators)))
  }
}

export const updateAdministrator = (data) => {
  return dispatch => {
    AdministratorsService.updateAdministrator(data)
    .then(administrators => dispatch(successfulAdministratorsFetch(administrators)))
  }
}

export const deleteAdministrator = (id) => {
  return dispatch => {
    AdministratorsService.deleteAdministrator(id)
    .then(administrators => dispatch(successfulAdministratorsFetch(administrators)))
  }
}
