import { API_URL } from '../constants'

export const headers = () => {
	const token = localStorage.getItem('token');
	return {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json',
	  'Authorization': `Bearer: ${token}`,
	}
}

const PublicEventSpeakersService = {
	fetchPublicEventSpeakers( publicEventId ) {
		return fetch( `${API_URL}/public_events/${ publicEventId }/speakers` ).then( response => response.json() );
	},
};

export default PublicEventSpeakersService;
