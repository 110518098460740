export default (state = [], action) => {
  switch(action.type) {
    case 'SUCCESSFUL_ANALYSTS_FETCH':
      return {
        ...state,
        analysts: action.analysts
      }
    case 'SUCCESSFUL_ANALYST_FETCH':
      return {
        ...state,
        analyst: action.analyst
      }
    case 'UNSUCCESSFUL_ANALYST_CREATE':
      return {
        ...state,
        errors: action.errors
      }
    default:
      return state
  }
}
