import React from 'react'

const PmVideo = props => {
  const { removeVideo, isEditing, link } = props
  return(
    <div className="pm-gtv-video">
      {
        isEditing ?
        <div className="remove-pm-video">
          <button className="pm-remove-video" onClick={removeVideo}>
            Remove X
          </button>
        </div>
        : null
      }
      <iframe
        title={link}
        width="560"
        height="315"
        src={link}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      >
      </iframe>
    </div>
  )
}

export default PmVideo
