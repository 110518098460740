import MediaService from '../../../services/MediaService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulNewsArticlesFetch = articles => {
  return {
    type: 'SUCCESSFUL_NEWS_ARTICLES_FETCH',
    articles
  }
}

const successfulArticleUpdate = article => {
  return {
    type: 'SUCCESSFUL_ARTICLE_UPDATE',
    article
  }
}

const successfulPodcastsFetch = podcasts => {
  return {
    type: 'SUCCESSFUL_PODCASTS_FETCH',
    podcasts
  }
}

// const replaceNewsArticle = article => {
//   return {
//     type: 'REPLACE_NEWS_ARTICLE',
//     article
//   }
// }

const currentlyUploading = () => {
  return {
    type: 'UPLOADING_ARTICLE',
  }
}

const successfulUpload = article => {
  return {
    type: 'SUCCESSFUL_ARTICLE_UPLOAD',
    article
  }
}

const completeUpload = () => {
  return {
    type: "ARTICLE_UPLOAD_COMPLETE"
  }
}

const failedUpload = article => ({
  type: 'FAILED_ARTICLE_UPLOAD',
  article
})

const resetUpdate = () => ({
  type: 'RESET_ARTICLE_UPDATE'
})

export const fetchArticles = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.fetchArticles()
      .then(articles => {
        dispatch(finishFetchRequest())
        dispatch(successfulNewsArticlesFetch(articles))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const importArticles = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.importArticles()
      .then(articles => {
        dispatch(finishFetchRequest())
        dispatch(successfulNewsArticlesFetch(articles))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchPodcasts = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.fetchPodcasts()
      .then(podcasts => {
        dispatch(finishFetchRequest())
        dispatch(successfulPodcastsFetch(podcasts))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const importPodcasts = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.importPodcasts()
      .then(podcasts => {
        dispatch(finishFetchRequest())
        dispatch(successfulPodcastsFetch(podcasts))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const deletePodcast = ( podcastId ) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    MediaService.deletePodcast( podcastId )
      .then(podcasts => {
        dispatch(finishFetchRequest())
        dispatch(successfulPodcastsFetch(podcasts))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const updateArticle = (article) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    dispatch(resetUpdate())
    MediaService.updateArticle(article)
      .then(articles => {
        dispatch(finishFetchRequest())
        dispatch(successfulArticleUpdate(articles))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const createArticle = (article) => {
    return dispatch => {
      dispatch(currentlyUploading())
      MediaService.addArticle(article)
        .then(article => {
          if (article.errors) {
            dispatch(failedUpload(article))
          } else if (article.error){
            console.log(article.error)
            alert("Upload failed: Server Error")
          } else {
            dispatch(successfulUpload(article))
          }
        })
        .catch(error => {
          dispatch(unsuccessfulFetchRequest())
        })
    }

}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
