import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Select from '../../components/Select'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { uploadXbrl, uploadComplete } from '../../redux/modules/Xbrl/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import S3Uploader from './S3Uploader'
import SuccessModal from './SuccessModal'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class XBRLUpload extends Component {
  constructor(){
    super()

    this.state = {
      fund_code: null,
      filename: "",
      doc_type: "",
      report_date: null,
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchFunds()
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  handleChange = (e) => {
    const { state } = this
    const { name, value } = e.target
    state[name] = value
    this.setState(state)
  }

  handleDayChange = (date) => {
    this.setState({
      report_date: date
    })
  }

  handleSelectChange = (e) => {
    if(e){
      this.setState({
        fund_code: e.value,
        flagship_name: e.label
      })
    } else {
      this.setState({
        fund_code: null,
        flagship_name: null
      })
    }
  }

  handleResponse = (response) => {
    this.setState({ filename: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const hasEmptyValues = Object.values(this.state).some( val => !val )
    if (!!hasEmptyValues) {
      document.getElementById("upload-failed").style.display = "inline-block"
    } else {
      document.getElementById("upload-failed").style.display = "none"
      this.props.uploadXbrl(this.state)
      this.setState({
        fund_code: null,
        filename: "",
        report_date: null,
        doc_type: "",
        showSubmit: false
      })
      document.getElementsByName("s3-uploader")[0].value = ''
    }
  }

  render(){
    const { fundsList } = this.props
    const { errors } = this.props.xbrl
    // const fundsList = [
    //   { fund_code: 1, fundshortname: "GAMCO Test AAA" },
    //   { fund_code: -90, fundshortname: "GAMCO Test Trust" }
    // ]
    let options
    if(fundsList){
      options = fundsList.filter( fund => fund.flagship ).map( fund => ({
        value: fund.fund_code, label: fund.fundshortname
      }))
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"XBRL report successfully added"}
        />
        <div id="upload-form-container">
          <h3>XBRL Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Select Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.fund_code}
            />
            <label className="upload-input-label">Report Date:</label>
            <br />
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={this.state.report_date || "YYYY-M-D"}
            />
            <br />
            <label className="upload-input-label">XBRL Type:</label>
            <br />
            <select onChange={this.handleChange} name="doc_type" className="media-info-input admin-select" value={this.state.doc_type}>
              <option></option>
              <option>Annual Fund Operating Expenses</option>
              <option>Annual Total Returns</option>
              <option>Average Annual Total Returns</option>
              <option>BarChart</option>
              <option>Expense Example</option>
              <option>Expense Example, No Redemption</option>
              <option>Risk Return Detail Data</option>
              <option>Risk Return Summary</option>
              <option>Risk Return Summary Multi</option>
              <option>Shareholder Fees</option>
              <option>XBRL</option>
              <option>XBRL485B</option>
              <option>XBRL497C</option>
              <option>XBRL497Cmulti</option>
            </select>
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={{ "doc_type": "xbrl" }}
              apiPath="xbrl_reports/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  xbrls: state.xbrls || [],
  xbrl: state.xbrls.xbrl || {},
  successfulUpload: state.xbrls.successfulUpload || false,
  fundsList: state.funds
})

export default withRouter(connect(mapStateToProps, { uploadXbrl, uploadComplete, fetchFunds })(XBRLUpload))
