export default (state = {}, action) => {
  switch(action.type) {
    case "ESTIMATES_FETCH":
      return {
        ...state,
        estimates: action.estimates,
        success: null,
        errors: []
      }
    case "START_ADD_ESTIMATE":
      return {
        ...state,
        success: null
      }
    case "START_DELETE_ESTIMATE":
      return {
        ...state,
        success: null
      }
    case "SUCCESSFUL_ESTIMATE_ADD":
      console.log( { state, action } );
      return {
        ...state,
        estimates: state.estimates.concat( action.estimate ),
        success: true,
        errors: []
      }
    case "SUCCESSFUL_ESTIMATE_DELETE":
      return {
        ...state,
        estimates: action.estimates,
        success: true,
        errors: []
      }
    case "FAILED_EST_ADD":
      return {
        ...state,
        success: false,
        errors: action.errors
      }
    case "START_TEXT_SUBMIT":
      return {
        ...state,
        success: false,
        text: ""
      }
    case "SUCCESSFUL_TEXT_SUBMIT":
      return {
        ...state,
        success: true,
        text: action.text
      }
    case "SUCCESSFUL_TEXT_FETCH":
      return {
        ...state,
        success: false,
        text: action.text
      }
    default:
      return state
  }
}
