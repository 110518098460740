import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { fetchS3Commentaries } from '../../redux/modules/S3Commentaries/actions';
import { parseDate2 } from '../TableTools';

const S3Commentaries = () => {

	const dispatch = useDispatch();

	const successRedirect = new URLSearchParams( window.location.search ).get( 'success' );

	useEffect( () => dispatch( fetchS3Commentaries() ), [ dispatch ] );

	useEffect( () => {
		if ( successRedirect ) flashMessage( successRedirect );
	}, [ successRedirect ] );

	const [ editMessage, setShowEditMessage ] = useState( null );

	const { commentaries } = useSelector( state => state.s3Commentaries );

	const flashMessage = message => {
		setShowEditMessage( message );
		setTimeout( () => setShowEditMessage( null ), 3000 );
	};

	return <>

		{ editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> }
	
		{ commentaries?.map( ( { id, title, s3_key, created_at } ) => <li
			key={ id }
		>
			<Link to={ `/admin/s3_commentaries/${ s3_key }` }>{ title }</Link> (created { parseDate2( created_at ) })
		</li> ) }

		<Link to={ `/admin/s3_commentaries/new` }>Create new commentary</Link>

	</>;

};

export default S3Commentaries;
