import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = props => {
  return (
    <div id="page-not-found-container">
      <h1>Page not found</h1>
      <p>This page does not exist. Try going back or click <Link to="/" className="home-link-404">here</Link> to return to the admin homepage.</p>
    </div>
  )
}

export default PageNotFound
