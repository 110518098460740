import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import DayPickerInput from 'react-day-picker/DayPickerInput'
// import Select from '../../components/Select'
import S3Uploader from './S3Uploader'
import { createTvAppearance, uploadComplete } from '../../redux/modules/TVAppearances/actions'
import { fetchPortfolioManagers } from '../../redux/modules/PortfolioManagers/actions'
import { fetchAnalysts } from '../../redux/modules/Analysts/actions'
import SuccessModal from './SuccessModal'
import 'react-day-picker/lib/style.css'
import SelectWithAutofill from '../../components/SelectWithAutofill'


class TvEntry extends Component {
  constructor(props){
    super(props)
    const adminName = JSON.parse(localStorage.getItem('admin'))['name']
    this.state = {
       title: '',
       duration: '',
       source: '',
       publish_date: '',
       image_url: '',
       video_url: '',
       tv_description: '',
       disclosure: '',
       gabellitv: false,
       display: true,
       portfolio_manager_id: '',
       analyst_id: '',
       uploaded_by: adminName,
       personnelType: null
     }
  }

  componentDidMount = () => {
    this.props.fetchPortfolioManagers()
    this.props.fetchAnalysts()
  }

  componentDidUpdate = (prevProps) => {
    const { successfulUpload, errors } = this.props
    if ((prevProps !== this.props) && (successfulUpload && !errors.length)){
      this.setState({
        title: '',
        duration: '',
        source: '',
        publish_date: '',
        image_url: '',
        video_url: '',
        tv_description: '',
        disclosure: '',
        portfolio_manager_id: '',
        analyst_id: '',
        personnelType: null
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { title, duration, source, publish_date, video_url, image_url, tv_description, display, uploaded_by, portfolio_manager_id, analyst_id, disclosure } = this.state
    const tvAppearance = { title, duration, source, publish_date, video_url, image_url, tv_description, display, uploaded_by, portfolio_manager_id, analyst_id, disclosure }
    console.log("TvEntry state on submit:", this.state)
    this.props.createTvAppearance(tvAppearance)
    // this.setState({
    //   title: '',
    //   duration: '',
    //   source: '',
    //   publish_date: '',
    //   image_url: '',
    //   video_url: '',
    //   tv_description: '',
    //   portfolio_manager_id: '',
    // })

  }

  handleDayChange = (e) => {
    if(e){
      this.setState({ publish_date: e })
    } else {
      this.setState({ publish_date: null })
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  handleSelectChange = (e) => {
    if(e){
      if(this.state.personnelType === "pm"){
        this.setState({
          portfolio_manager_id: e.value,
        })
      } else if (this.state.personnelType === "analyst"){
        this.setState({
          analyst_id: e.value
        })
      }
    } else {
      this.setState({
        portfolio_manager_id: null,
      })
    }
  }


  handleClose = () => {
    this.props.uploadComplete()
  }

  handleResponse = r => {
    const { state } = this
    state["image_url"] = r.publicUrl
    this.setState(state)
  }

  handlePersonnelTypeChange = e => {
    e.preventDefault()
    const { innerHTML } = e.target
    if (innerHTML === "Analyst") {
      this.setState({ personnelType: "analyst", portfolio_manager_id: "" })
    } else if (innerHTML === "PM") {
      this.setState({ personnelType: "pm", analyst_id: '' })
    }
  }

  render() {
    const { pmList, analystList, errors } = this.props
    const pmOptions = pmList.map( pm => ({
      value: pm.id, label: pm.name
    }))
    const analystOptions = analystList.map( a => ({
      value: a.id, label: a.name
    }))
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase="TV Appearance successfully added."
        />
        <div id="upload-form-container">
          { errors && errors.length ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Title:</label>
            <input
              className="media-info-input admin-input"
              name='title'
              type='text'
              value={this.state.title}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Duration:</label>
            <input
              className="media-info-input admin-input"
              name='duration'
              type='text'
              value={this.state.duration}
              onChange={this.handleOnChange}
            />

            <label className="upload-input-label">Source:</label>
            <input
              className="media-info-input admin-input"
              name='source'
              type='text'
              value={this.state.source}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Publish Date:</label>
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={this.state.date || "YYYY-M-D"}
              style={{ color: "Gray" }}
            />

            <label className="upload-input-label">Image Url:</label>
            <S3Uploader
              params={{}}
              apiPath={"carousel_objects/s3/sign"}
              handleResponse={this.handleResponse}
              handle
            />
            <label className="upload-input-label">Video Url:</label>
            <input
              className="media-info-input admin-input"
              name='video_url'
              type='text'
              value={this.state.video_url}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Description:</label>
            <textarea
              className="media-info-input admin-input"
              name='tv_description'
              value={this.state.tv_description}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Additional Disclosure:</label>
            <textarea
              className="media-info-input admin-input"
              name='disclosure'
              value={this.state.disclosure}
              onChange={this.handleOnChange}
            />
            <label className="upload-input-label">Select a personnel type:</label>
            <div id="personnel-selector-container">
              <button
                className={`personnel-selector ${this.state.personnelType === "pm" ? 'selected-personnel-type' : null}`}
                onClick={this.handlePersonnelTypeChange}
              >
                PM
              </button>
              <button
                className={`personnel-selector ${this.state.personnelType === "analyst" ? 'selected-personnel-type' : null}`}
                onClick={this.handlePersonnelTypeChange}
              >
                Analyst
              </button>
            </div>
            <label className="upload-input-label" style={ this.state.personnelType !== "pm" ? { color: 'LightGray' } : { color: 'Black' } }>Portfolio Manager:</label>
            <SelectWithAutofill
              options={pmOptions}
              className="select-tag media-info-input personnel-selector-input admin-select"
              placeholder="Select a Portfolio Manager"
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.portfolio_manager_id}
              disabled={this.state.personnelType !== "pm"}
            />
            <label className="upload-input-label" style={ this.state.personnelType !== "analyst" ? { color: 'LightGray' } : { color: 'Black' } }>Analyst:</label>
            <SelectWithAutofill
              options={analystOptions}
              className="select-tag media-info-input personnel-selector-input admin-select"
              placeholder="Select an Analyst"
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.analyst_id}
              selectKey={1}
              disabled={this.state.personnelType !== "analyst"}
            />
            <input id="upload-button" type='submit' label='submit' />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isUploading: state.tvAppearances.isUploading,
  successfulUpload: state.tvAppearances.successfulUpload,
  pmList: state.portfolioManagers.pms || [],
  analystList: state.analysts.analysts || [],
  errors: state.tvAppearances.errors
})

export default withRouter(connect(mapStateToProps, { createTvAppearance, uploadComplete, fetchPortfolioManagers, fetchAnalysts })(TvEntry))


// <input
//   className="media-info-input"
//   name='image_url'
//   type='text'
//   value={this.state.image_url}
//   onChange={this.handleOnChange}
// />
