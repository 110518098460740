import React from 'react'
import ReactS3Uploader from 'react-s3-uploader'

import { API_URL } from '../../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const S3Uploader = (props) => {

  const onFinish = file => {
    const img = new Image()
    img.onload = () => {
      props.bindImageToInsight(file.fileName)
    }
    img.src = `https://s3.us-east-2.amazonaws.com/gab-commentary-images/${file.fileName}`
  }

  return(
    <ReactS3Uploader
      signingUrl={`${API_URL}/insights/s3/sign`}
      signingUrlMethod='GET'
      signingUrlWithCredentials={ false }
      signingUrlHeaders={headers}
      onFinish={onFinish}
      uploadRequestHeaders={{}}
      accept='image/*'
      s3path='/'
      className="upload-field"
      name="s3-uploader"
      onProgress={props.handleProgress}
      value={props.value}
      required
    />
  )
}

export default S3Uploader

// const onFinish = file => {
//   const img = new Image()
//   img.onload = () => {
//     if (img.naturalWidth > 1920) {
//       props.bindImageToInsight(file.fileName)
//     } else {
//       alert("Error: selected image is less than 1920 pixels wide. Please select another image.")
//     }
//   }
//   img.src = `https://s3.us-east-2.amazonaws.com/gab-commentary-images/${file.fileName}`
// }
//
// const beforeUpload = (file, next) => {
//   if (file.size < 50000) {
//     alert("Image is too small. Please upload an image > 50 KB")
//   } else {
//     next(file)
//   }
// }
