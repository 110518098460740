import { API_URL } from '../constants'

export const headers = () => {
  const token = localStorage.getItem('token');
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer: ${token}`,
  }
}

const AdminService = {
	fetchAdminsIndex() {
		return fetch(`${API_URL}/admins`).then(response => response.json())
	},

	updateAdmin( id, updatedAdmin ) {
		return fetch(`${API_URL}/admins/${id}`, {
		  method: 'PATCH',
		  headers: headers(),
		  body: JSON.stringify( updatedAdmin ),
		  credentials: 'include'
		}).then( r => r.json() )
	},

	lostPassword( id ) {
		return fetch(`${API_URL}/admins/lost_password/${id}`, {
		  method: 'PATCH',
		  headers: headers(),
		  credentials: 'include'
		}).then( r => r.json() )
	},

	deleteAdmin( id ) {
		return fetch(`${API_URL}/admins/delete_admin/${id}`, {
		  method: 'DELETE',
		  headers: headers(),
		  credentials: 'include'
		}).then( r => r.json() )
	},

  fetchLogin(admin){
    return fetch(`${API_URL}/admins/login`,{
      method: 'POST',
      headers: headers(),
      credentials: 'include',
      body: JSON.stringify({ admin })
    })
  },

  refresh(){
    return fetch(`${API_URL}/admins/refresh`, {
      method: 'POST',
      headers: headers(),
      credentials: 'include',
    }).then( r => r.json() )
  },

  resetPassword(password){
    return fetch(`${API_URL}/admins/reset_password`, {
      method: 'POST',
      headers: headers(),
      credentials: 'include',
      body: JSON.stringify({ password })
    }).then( r => r.json() )
  },


  // fetchLogin(admin){
  //   return fetch(`${API_URL}/admins/local_login`,{
  //     method: 'POST',
  //     headers: headers(),
  //     credentials: 'include',
  //     body: JSON.stringify({ admin })
  //   })
  // },
  //
  // refresh(){
  //   return fetch(`${API_URL}/admins/local_refresh`, {
  //     method: 'POST',
  //     headers: headers(),
  //     credentials: 'include',
  //   }).then( r => r.json() )
  // },
  //
  // resetPassword(password){
  //   return fetch(`${API_URL}/admins/local_reset_password`, {
  //     method: 'POST',
  //     headers: headers(),
  //     credentials: 'include',
  //     body: JSON.stringify({ password })
  //   }).then( r => r.json() )
  // },
  //
  fetchLogout(){
    return fetch(`${API_URL}/admins/logout`, {
      method: 'DELETE',
      headers: headers(),
      credentials: 'include',
    }).then( r => r.json() )
  }

}

export default AdminService
