import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComposites, updateComposite } from '../../redux/modules/Composites/actions'

const CompositesPage = () => {

	const dispatch = useDispatch();
	const composites = useSelector( state => state.composites );
	useEffect( () => dispatch( fetchComposites() ), [ dispatch ] );

	const [ editForm, setEditForm ] = useState( null );
	const [ editMessage, setShowEditMessage ] = useState( null );

	const flashMessage = message => {
		setShowEditMessage( message );
		setTimeout( () => setShowEditMessage( null ), 3000 );
	};

	const handleEditFormChange = ( { target } ) => setEditForm( { ...editForm, [ target.name ]: target.value } );

	const handleEditFormSubmit = editFormSubmission => {
		editFormSubmission.preventDefault();
		dispatch( updateComposite( editForm.id, { name: editForm.name, inception: editForm.inception, aum: editForm.aum, aum_asof: editForm.aum_asof } ) );
		flashMessage( `${ editForm.name } updated successsfully!` );
		setEditForm( null );
	};
	
	return <div style={ { fontFamily: 'Gotham' } }>

		{ editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> }

		<h1>Composites</h1>

		<table style={ { width: '75%', margin: '0 auto', borderCollapse: 'collapse' } }>
			{ composites?.map( ( { id, name, inception, aum, aum_asof } ) => <tbody key={ id }>
				<tr style={ { borderBottom: aum ? 'none' : '1px solid black', fontWeight: 600 } }>
					<td>
						<span style={ { fontSize: '14pt' } }>{ name }</span>
						&nbsp;
						<span style={ { fontSize: '9pt' } }>Since { dateLocaleString( inception ) }</span>
					</td>
					<td>&nbsp;</td>
					<td><button onClick={ () => setEditForm( editForm?.id === id ? null : { id, name, inception, aum, aum_asof } ) }>
						{ editForm?.id === id ? 'Cancel' : 'Edit' }
					</button></td>
				</tr>
				{ aum && <tr style={ { borderBottom: '1px solid black', fontWeight: 600 } }>
					<td style={ { fontSize: '9pt', fontStyle: 'italic' } }>{ `${ parseLargeCurrencyFigure( aum ) } under management as of ${ dateLocaleString( aum_asof ) }` }</td>
				</tr> }
			</tbody> ) }
		</table>

		{ editForm && <form style={ { display: 'flex', flexFlow: 'column wrap', border: '1px solid black', padding: '10px' } } onSubmit={ handleEditFormSubmit }>
			<span>
				<label>Name: </label>
				<input name='name' size='60' required value={ editForm?.name } onChange={ handleEditFormChange } />
			</span>
			<span>
				<label>Inception: </label>
				<input type='date' name='inception' required value={ editForm?.inception } onChange={ handleEditFormChange } />
			</span>
			<span>
				<label>AUM: </label>
				<input type='number' name='aum' value={ editForm?.aum } onChange={ handleEditFormChange } />&nbsp;
				<label>as of&nbsp;</label>
				<input type='date' name='aum_asof' value={ editForm?.aum_asof } onChange={ handleEditFormChange } />
			</span>
			<input type='submit' />
		</form> }

	</div>;

};

export default CompositesPage;


const parseLargeCurrencyFigure = figure => {
	const millions = Number( ( figure / 1_000_000 )?.toFixed( 2 ) ), billions = Number( ( figure / 1_000_000_000 )?.toFixed( 2 ) );
	const isMillions = millions >= 1, isBillions = billions >= 1;
	return !figure ? '…' : `$${ isBillions ? billions : isMillions ? millions : figure.toLocaleString( 'currency' ) }${ isBillions ? 'b' : isMillions ? 'm' : '' }`;
};

const dateLocaleString = date => date && new Date( date ).toLocaleString( 'default', { month: 'long', day: 'numeric', year: 'numeric' } );
