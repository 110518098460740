export default (state = [], action) => {
  switch(action.type) {
    case 'UPLOADING_PREFERRED_DOC':
      return {
        ...state,
        isUploading: true,
        successfulUpload: false
      }
    case 'SUCCESSFUL_PREFERRED_UPLOAD':
      return {
        ...state,
        successfulUpload: true,
        isUploading: false
      }
    case 'PREFERRED_UPLOAD_COMPLETE':
      return {
        successfulUpload: false,
        isUploading: false
      }
    case 'PREFERRED_UPLOAD_FAILED':
      return {
        ...state,
        errors: action.doc.errors,
        successfulUpload: false,
        isUploading: false,
      }
    default:
      return state;
  }
}
