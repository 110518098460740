import FundsService from '../../../services/FundsService';
import { unsuccessfulFetchRequest } from '../../appTransactions';

const successfulFundsFetch = funds => {
  return {
    type: 'SUCCESSFUL_FUNDS_FETCH',
    funds
  }
}

export const fetchFunds = () => {
  return dispatch => {
    FundsService.fetchFunds()
      .then(funds => {
        dispatch(successfulFundsFetch(funds))
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchAllFunds = () => {
  return dispatch => {
    FundsService.fetchAllFunds()
      .then(funds => {
        dispatch(successfulFundsFetch(funds))
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchSicavs = () => {
  return dispatch => {
    FundsService.fetchSicavs()
    .then(funds => {
      dispatch(successfulFundsFetch(funds))
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchAllClosedEndFunds = () => {
  return dispatch => {
    FundsService.fetchAllClosedEndFunds()
    .then( funds => {
      dispatch(successfulFundsFetch(funds))
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchClosedEndFlagshipsWithTaxDocs = () => {
  return dispatch => {
    FundsService.fetchClosedEndFlagshipsWithTaxDocs()
    .then( funds => {
      dispatch(successfulFundsFetch(funds))
    })
  }
}
