import React, { useState } from 'react'
// import Select from '../../components/Select'
import { Popover } from '@blueprintjs/core'
import SelectWithAutofill from '../../components/SelectWithAutofill'

const TaxDocResult = props => {
  const { doc, funds, deleteDoc, updateDoc } = props
  const [ isEditing, setIsEditing ] = useState(false)
  const [ formData, setFormData ] = useState({
    fund_code: doc.fund_code,
    as_of: doc.as_of,
    filepath: doc.filepath,
  })

  const handleChange = e => {
    const { name, value } = e.target
    const updatedFormData = { ...formData }
    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleSelectChange = e => {
    if(e) {
      const { value } = e
      const updatedFormData = { ...formData }
      updatedFormData.fund_code = value
      setFormData(updatedFormData)
    } else {
      setFormData({
        ...formData,
        fund_code: null
      })
    }
  }

  const toggleEditing = () => setIsEditing(!isEditing)

  const fundIsSelectable = funds.find( fund => fund.value === formData.fund_code)

  const fundName = fundIsSelectable ? fundIsSelectable.label : `(Fund Code: ${formData.fund_code})`

  if (isEditing) {
    return (
      <div>
        <form>
          <label className="upload-input-label">Fund:</label>
          <SelectWithAutofill
            options={funds}
            className="select-tag media-info-input admin-select"
            placeholder="Enter a fund..."
            handleChange={handleSelectChange}
            onClear={(e) => handleSelectChange(null)}
            value={formData.fund_code}
          />
          <label className="upload-input-label">Date of Action:</label>
          <input className="media-info-input admin-select" name="as_of" value={formData.as_of} onChange={handleChange}/>
          <label className="upload-input-label">Filepath:</label>
          <input className="media-info-input admin-input" name="filepath" value={formData.filepath} onChange={handleChange} />
        </form>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={ () => updateDoc(doc.id, formData) }>Update</button>
          <button className="admin-docs-edit-btn cancel-btn" onClick={toggleEditing}>Cancel</button>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  } else {
    return (
      <div className="admin-docs-result">
        <h5>
          <a href={doc.filepath} target="_blank" rel="noreferrer noopener">{fundName} {doc.as_of}</a>
        </h5>
          <p className="admin-docs-result-data">Uploaded on {doc.updated_at.split("T")[0]}{ !!doc.uploaded_by ? ` by ${ doc.uploaded_by }` : "" }</p>
        <div className="admin-docs-btn-container">
          <button className="admin-docs-edit-btn" onClick={toggleEditing}>Edit</button>
          <Popover minimal={true}>
            <button className="admin-docs-dlt-btn" >Delete</button>
            <div className="popover-content">
              <h4>Are you sure?</h4>
              <p>Would you like to delete this item?</p>
              <button className=" bp3-popover-dismiss">Cancel </button>
              <button className="cc-delete-btn popover-btn" onClick={() => deleteDoc(doc.id)}>Delete</button>
            </div>
          </Popover>
        </div>
        <hr style={{ width: '100%' }}/>
      </div>
    )
  }

}


export default TaxDocResult
