import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const PortfolioManagersService = {
  fetchPortfolioManagers() {
    return fetch(`${API_URL}/portfolio_managers`)
    .then(response => response.json())
  },

  fetchPortfolioManager(id) {
    return(fetch(`${API_URL}/portfolio_managers/${id}`))
    .then(response => response.json())
  },

  // fetchManagedFunds(id) {
  //   return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
  //   .then(response => response.json())
  // },

  newPortfolioManager(pmData) {
    return fetch(`${API_URL}/portfolio_managers`,{
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(pmData),
      headers: headers()
    }).then(response => response.json())
    .catch( e => console.log(e) )
  },

  updatePortfolioManager(pmData) {
    return fetch(`${API_URL}/portfolio_managers/${pmData.id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(pmData),
      headers: headers()
    }).then( r => r.json() )
  },

  deletePortfolioManager(pmId) {
    return fetch(`${API_URL}/portfolio_managers/${pmId}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then(r => r.json())
  }
}

export default PortfolioManagersService;

// const PortfolioManagersService = {
//   fetchPortfolioManagers() {
//     return fetch(`${API_URL}/pms`)
//     .then(response => response.json())
//   },
//
//   fetchPortfolioManager(id) {
//     return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
//     .then(response => response.json())
//   },
//
//   fetchManagedFunds(id) {
//     return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
//     .then(response => response.json())
//   }
// }
