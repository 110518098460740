import LeadsService from '../../../services/LeadsService';

const successfulLeadsFetch = leads => ({
  type: 'SUCCESSFUL_LEADS_FETCH',
  leads
})

const successfulLeadsExport = email => ({
  type: 'SUCCESSFUL_LEADS_EXPORT',
  email
})

const failedLeadsExport = () => ({
  type: 'FAILED_LEADS_EXPORT'
})

export const fetchLeads = () => {
  return dispatch => {
    LeadsService.fetchLeads()
    .then( leads => dispatch(successfulLeadsFetch(leads)) )
  }
}

export const exportLeads = email => {
  return dispatch => {
    LeadsService.exportLeads(email)
    .then( r => {
      if (r.error || r.errors) {
        console.log(r.error || r.errors)
        dispatch(failedLeadsExport())
      } else {
        dispatch(successfulLeadsExport(r.email))
      }
    })
  }
}
