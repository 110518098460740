import React from 'react'

const CarouselEdit = (props) => {

	const parseCarousel = props.archive?.map( card => ( { createdOn: Object.keys( card )[ 0 ], ...Object.values( card )[ 0 ] } ) );

  	return <div id="admin-upload-container">
		<div id="carousel-card-container">
			<h3>Edit Funds Carousel</h3>
			{props.carouselCards}
		</div>
		<div id="carousel-card-container">
			<h3>Manage Carousel Archive</h3>
			{ parseCarousel?.map( ( { createdOn, header, caption } ) => <li key={ createdOn }>
				<b>{ header } - { caption }</b>
				&nbsp;
				<em>({ createdOn })</em>
				{ ' - ' }
				<button onClick={ () => props.handleUnarchiveCarouselObject( createdOn ) }>Restore</button>
			</li> ) || <em>Archive empty</em> }
		</div>
    </div>;
}

export default CarouselEdit
