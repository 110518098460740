export default (state = [], action) => {
  switch(action.type) {
    case 'SUCCESSFUL_FEATURED_FUNDS_FETCH':
      return { funds: action.featuredFunds, list: state.list}
    case 'SUCCESSFUL_FUNDS_LIST_FETCH':
      return { funds: state.funds, list: action.funds}
    default:
      return state;
  }
}
