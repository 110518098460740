export default (state = {}, action) => {
  switch(action.type) {
    case 'UPLOADING_PR':
      return {
        isUploading: true,
        successfulUpload: false
      };
    case 'SUCCESSFUL_UPLOAD':
      return {
        successfulUpload: true,
        isUploading: false,
        pr: action.pr
      };
    case 'SUCCESSFUL_PR_FETCH':
      return {
        ...state,
        pr: action.pr
      };
    case 'SUCCESSFUL_CEPR_FETCH':
      return action.pr;
    case 'PR_UPLOAD_FAILED':
      return {
        ...state,
        errors: action.errors,
        successfulUpload: false,
        isUploading: false
      };
    case 'UPLOAD_COMPLETE':
      return {
        successfulUpload: false,
        isUploading: false
      };
    case 'CURRENTLY_FETCHING_PR':
      return {
        ...state,
        pr: []
      };
    default: return state;
  }
}
