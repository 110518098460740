export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_SICAVS_FETCH':
      return {
        ...state,
        funds: action.sicavs
      };

    case 'SUCCESSFUL_SICAVS_2_FETCH':
      return {
        ...state,
        sicavs2: action.sicavs
      };

    case 'SUCCESSFUL_SICAV_FETCH':
      return {
        ...state,
        sicav: action.sicav
      }

    case 'SUCCESSFUL_FUND_PRICES_FETCH':
      return {
        ...state,
        fundPrices: action.fundPrices
      }

    case 'SUCCESSFUL_FUND_ASSETS_FETCH':
      return {
        ...state,
        fundAssets: action.fundAssets
      }

    case 'SUCCESSFUL_FUND_SUPPS_FETCH':
      return {
        ...state,
        fundSupps: action.fundSupps
      }

    case 'SUCCESSFUL_CALENDAR_YR_RETURNS_FETCH':
      return {
        ...state,
        calendarYearReturns: action.returns
      }

    case 'SUCCESSFUL_GROWTH_OF_10K_FETCH':
      return {
        ...state,
        growthOf10k: action.growth
      }

    case 'SUCCESSFUL_HOLDINGS_FETCH':
      return {
        ...state,
        holdings: action.holdings
      }

    case 'SUCCESSFUL_MANAGERS_FETCH':
      return {
        ...state,
        managers: action.managers
      }

    case 'SUCCESSFUL_SIBLINGS_FETCH':
      return {
        ...state,
        siblings: action.siblings
      }

    case 'SUCCESSFUL_MEDIA_FETCH':
      return {
        ...state,
        media: action.media
      }

    case 'SUCCESSFUL_DISTRIBUTIONS_FETCH':
      return {
        ...state,
        distributions: action.distributions
      }

    case 'SUCCESSFUL_PERFORMANCE_FETCH':
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        starting_price: action.payload.starting_price,
        ending_price: action.payload.ending_price,
        ttl_return: action.payload.ttl_return,
        avg_annual: action.payload.avg_annual,
        time_calculated: action.payload.time
      }

    case 'REPLACE_SICAV':
      const index = state.findIndex(sicav => sicav.id === action.sicav.id)
        return [
          ...state.slice(0, index),
          action.sicav,
          ...state.slice(index + 1)
        ];
    case 'CLEAR_FUND':
      return {
        funds: state.funds
      }
    default:
      return state;
  }
}
