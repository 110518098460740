import React, { useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { Icon } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import 'leaflet/dist/leaflet.css';

export const gamcoHqCoordinates = { lat: 40.9729706, lng: -73.718407 };

const CoordinatesMap = ( { coordinates, setCoordinates } ) => {

	const mapRef = useRef(), markerRef = useRef();
	
	const [ addressToGeocode, setAddressToGeocode ] = useState( '' );

	const geocodeAddress = () => {
		fetch( `https://www.mapquestapi.com/geocoding/v1/address?key=ykMHI0x0mOZofv81iveUVWtLVMjariS1&location=${ addressToGeocode }` ).then( response => response.json() ).then( ( { results } ) => {
			if ( mapRef.current ) mapRef.current.panTo( results[ 0 ].locations[ 0 ].latLng );
			setCoordinates( results[ 0 ].locations[ 0 ].latLng );
		} );
	};

	const eventHandlers = useMemo( () => ( {
		dragend() {
			if ( markerRef.current ) {
				if ( mapRef.current ) mapRef.current.panTo( markerRef.current.getLatLng() );
				setCoordinates( markerRef.current.getLatLng() );
			}
		}
	} ), [ setCoordinates ] );

	return <div>
		<div>
			<input placeholder="Enter an address:" value={ addressToGeocode } onChange={ ( { target } ) => setAddressToGeocode( target.value ) } ></input>
			<button onClick={ geocodeAddress }>Convert to Coordinates</button>
		</div>
		<label htmlFor='longitude'>Longitude:&nbsp;</label>
		<input name='longitude' value={ coordinates.lat } onChange={ ( { target } ) => setCoordinates( { lat: parseFloat( target.value ), lng: coordinates.lng } ) }></input>
		<label htmlFor='latitude'>Latitude:&nbsp;</label>
		<input name='latitude' value={ coordinates.lng } onChange={ ( { target } ) => setCoordinates( { lat: coordinates.lat, lng: parseFloat( target.value ) } ) }></input>
		<MapContainer
			center={ coordinates }
			zoom={ 12 }
			scrollWheelZoom={ true }
			ref={ mapRef }
			style={ { height: '400px', width: '600px' } }
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker
				draggable={ true }
				eventHandlers={ eventHandlers }
				position={ coordinates }
				ref={ markerRef }
				icon={ new Icon( { iconUrl: markerIconPng, iconSize: [ 25, 41 ], iconAnchor: [ 12, 41 ] } ) }
			/>
		</MapContainer>
	</div>;

};

export default CoordinatesMap;
