import ImportantTaxDocService from '../../../services/ImportantTaxDocService'

const successfulTaxDocsFetch = funds => ({
  type: "SUCCESSFUL_IMPORTANT_DOCS_FETCH",
  funds
})

const successfulTaxDocsUpload = funds => ({
  type: 'SUCCESSFUL_IMPORTANT_DOCS_UPLOAD',
  funds
})

const failedUpload = doc => ({
  type: 'FAILED_IMPORTANT_TAX_DOC_UPLOAD',
  doc
})

export const fetchImportantTaxDocs = () => {
  return dispatch => {
    ImportantTaxDocService.fetchFlagshipsWithDocs()
    .then( funds => dispatch(successfulTaxDocsFetch(funds)) )
  }
}

export const uploadImportant = (data) => {
  return dispatch => {
    ImportantTaxDocService.uploadImportant(data)
    .then( doc => {
      if (doc.errors) {
        dispatch(failedUpload(doc))
      } else if (doc.error){
        console.log(doc.error)
        alert("Upload failed: Server Error")
      } else {
        dispatch(successfulTaxDocsUpload(doc))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch({ type: 'IMPORTANT_INFO_UPLOAD_COMPLETE' })
  }
}
