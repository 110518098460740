import React, { Component } from 'react'
import { connect } from 'react-redux'
import SuccessModal from '../AdminPortal/SuccessModal'
import { submitText, fetchText } from '../../redux/modules/DivEstimates/actions'

class Intro extends Component {
  constructor(){
    super()

    this.state = {
      intro: null,
      showSuccess: false
    }
  }

  componentDidMount(){
    this.props.fetchText()
  }

  componentDidUpdate(prevProps){
    const { intro, success } = this.props
    if (!prevProps.intro && !!intro) {
      this.setState({ intro })
    }
    if (!prevProps.success && !!success) {
      this.setState({ showSuccess: true })
    }
  }

  handleChange = e => {
    const intro = { ...this.state.intro, text: e.target.value }
    this.setState({ intro })
  }

  handleDateChange = e => {
    const intro = { ...this.state.intro, date: e.target.value }
    this.setState({ intro })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.submitText(this.state.intro)
  }

  render(){
    const { intro } = this.state
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={this.state.showSuccess}
          handleClose={() => this.setState({ showSuccess: false })}
          phrase={"Paragraph successfully updated"}
        />
        <div id="upload-form-container">
          <h3>Update Div Estimate Intro</h3>
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Update "estimates as of" date:</label>
            <br />
            <input type="text" className="media-info-input admin-input" onChange={this.handleDateChange} value={intro ? intro.date : ""}/>
            <label className="upload-input-label">Update intro:</label>
            <br />
            <textarea className="media-info-input admin-input" onChange={this.handleChange} value={intro ? intro.text : ""}/>
            <input id="upload-button" className="admin-input" type="submit" value="Submit"/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  intro: state.estimates.text || "",
  success: state.estimates.success
})

export default connect(mapStateToProps, { submitText, fetchText })(Intro)
