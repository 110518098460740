import CompositeService from "../../../services/CompositesService";

const successfulCompositeFetch = composites => {
	return { type: 'COMPOSITES_FETCHED_SUCCESSFULLY', composites }
};

const successfulCompositeUpdate = composites => {
	return { type: 'COMPOSITES_UPDATED_SUCCESSFULLY', composites }
};

const compositeFetchFailed = () => {
	return { type: 'COMPOSITES_FETCH_FAILED' }
};

const compositeUpdateFailed = () => {
	return { type: 'COMPOSITES_UPDATE_FAILED' }
};

export const fetchComposites = () => {
	return dispatch => {
	  CompositeService.fetchComposites()
	  .then( composites => dispatch( successfulCompositeFetch( composites ) ) )
	  .catch( () => dispatch( compositeFetchFailed() ) );
	}
};

export const updateComposite = ( id, composite ) => {
	return dispatch => {
	  CompositeService.updateComposite( id, composite )
	  .then( composites => dispatch( successfulCompositeUpdate( composites ) ) )
	  .catch( () => dispatch( compositeUpdateFailed() ) );
	}
};
