import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FeaturedFundsService = {
  fetchFeaturedFunds() {
    return fetch(`${API_URL}/featured_funds`)
    .then(response => response.json())
  },

  setFeaturedFunds(fundType, fundCodes) {
    return fetch(`${API_URL}/featured_funds/set`, {
      method: "POST",
      body: JSON.stringify({ type: fundType, fundCodes }),
      credentials: 'include',
      headers: headers()
    })
    .then(response => response.json())
  },

  fetchFundsList() {
    return fetch(`${API_URL}/featured_funds/list`)
    .then(response => response.json())
  }
}

export default FeaturedFundsService;
