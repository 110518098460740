import FeaturedFundsService from '../../../services/FeaturedFundsService';
import { unsuccessfulFetchRequest } from '../../appTransactions';

const successfulFeaturedFundsFetch = featuredFunds => {
  return {
    type: 'SUCCESSFUL_FEATURED_FUNDS_FETCH',
    featuredFunds
  }
}

const successfulFundsListFetch = funds => {
  return {
    type: 'SUCCESSFUL_FUNDS_LIST_FETCH',
    funds
  }
}

export const featuredFundsFetch = () => {
  return dispatch => {
    FeaturedFundsService.fetchFeaturedFunds()
      .then(featuredFunds => {
        dispatch(successfulFeaturedFundsFetch(featuredFunds))
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const setFeaturedFunds = (fundType, fundCodes) => {
  return dispatch => {
    FeaturedFundsService.setFeaturedFunds(fundType, fundCodes)
    .then(featuredFunds => {
      dispatch(successfulFeaturedFundsFetch(featuredFunds))
    })
    .catch(error => console.log(error))
  }
}

export const fetchFundsList = () => {
  return dispatch => {
    FeaturedFundsService.fetchFundsList()
    .then(funds => {
      dispatch(successfulFundsListFetch(funds))
    })
  }
}
