import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const TagsService = {
  fetchTags() {
    return fetch(`${API_URL}/tags`)
    .then( r => r.json() )
  }
}

export default TagsService;
