import DocumentUploadService from '../../../services/DocumentUploadService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulUpload = doc => {
  return {
    type: 'SUCCESSFUL_UPLOAD',
    doc
  }
}

const currentlyUploading = () => {
  return {
    type: 'UPLOADING_DOC',
  }
}

const clearDocumentsWhileFetching = () => ({
  type: 'CURRENTLY_FETCHING'
})

const successfulDocumentsFetch = (docs) => {
  return {
    type: "SUCCESSFUL_DOCS_FETCH",
    docs
  }
}

const successfulSicavDocumentsFetch = (docs) => {
  return {
    type: "SUCCESSFUL_SICAV_DOCS_FETCH",
    docs
  }
}

const successfulProxyStatementsFetch = (docs) => {
  return {
    type: "SUCCESSFUL_PROXY_STATEMENTS_FETCH",
    docs
  }
}

const successfulMMQrepsByYearFetch = (qreps) => {
  return {
    type: "SUCCESSFUL_MM_QREPS_BY_YEAR_FETCH",
    qreps
  }
}

const successfulCEQrepsByYearFetch = (qreps) => {
  return {
    type: "SUCCESSFUL_CE_QREPS_BY_YEAR_FETCH",
    qreps
  }
}

const successfulOEQrepsByYearFetch = (qreps) => {
  return {
    type: "SUCCESSFUL_OE_QREPS_BY_YEAR_FETCH",
    qreps
  }
}

const successfulSoisFetch = sois => ({
  type: 'SUCCESSFUL_SOIS_FETCH',
  sois
})

const completeUpload = () => {
  return {
    type: "UPLOAD_COMPLETE"
  }
}

const failedUpload = (doc) => {
  return {
    type: "UPLOAD_FAILED",
    doc
  }
}

export const uploadDocument = (doc, fundType=null) => {
  return dispatch => {
      dispatch(currentlyUploading())
    DocumentUploadService.uploadDocument(doc, fundType)
      .then(doc => {
        if (doc.errors){
          dispatch(failedUpload(doc))
        } else if (doc.error){
          console.log(doc.error)
          alert("Upload failed: Server Error")
        } else {
          dispatch(successfulUpload(doc))
        }
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchDocuments = (fundCode = null) => {
  return dispatch => {
    DocumentUploadService.fetchDocuments(fundCode)
    .then( docs => {
      dispatch(successfulDocumentsFetch(docs))
    })
    .catch( error => {
      console.log(error)
    })
  }
}

export const fetchSicavDocuments = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    DocumentUploadService.fetchSicavDocuments(fund_code)
    .then(docs => {
      dispatch(finishFetchRequest())
      dispatch(successfulSicavDocumentsFetch(docs))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchProxyStatements = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    DocumentUploadService.fetchProxyStatements()
    .then(docs => {
      dispatch(finishFetchRequest())
      dispatch(successfulProxyStatementsFetch(docs))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchMMQrepsByYear = (year) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    DocumentUploadService.fetchMMQrepsByYear(year)
    .then(qreps => {
      dispatch(finishFetchRequest())
      dispatch(successfulMMQrepsByYearFetch(qreps))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchCEQrepsByYear = (year) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    DocumentUploadService.fetchCEQrepsByYear(year)
    .then(qreps => {
      dispatch(finishFetchRequest())
      dispatch(successfulCEQrepsByYearFetch(qreps))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchOEQrepsByYear = (year) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    DocumentUploadService.fetchOEQrepsByYear(year)
    .then(qreps => {
      dispatch(finishFetchRequest())
      dispatch(successfulOEQrepsByYearFetch(qreps))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchAllSois = () => {
  return dispatch => {
    DocumentUploadService.fetchAllSois()
    .then( sois => {
      dispatch(successfulSoisFetch(sois))
    })
    .catch( error => {
      console.log(error)
    })
  }
}

export const deleteUpload = (id) => {
  return dispatch => {
    dispatch(clearDocumentsWhileFetching())
    DocumentUploadService.deleteUpload(id)
    .then( docs => {
      if (docs.error){
        console.log(docs.error)
        alert("There was an error deleting this document.")
      } else {
        dispatch(successfulDocumentsFetch(docs))
      }
    })
  }
}

export const updateDoc = (id, data) => {
  return dispatch => {
    dispatch(clearDocumentsWhileFetching())
    DocumentUploadService.updateDoc(id, data)
    .then( docs => {
      if (docs.error){
        alert("There was an error updating this document.")
      } else {
        dispatch(successfulDocumentsFetch(docs))
      }
    })
  }
}


export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
