import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const DivEstimateService = {
  fetchEstimates() {
    return fetch(`${API_URL}/div_estimates`)
    .then( r => r.json() )
  },

  addEstimate(data) {
    return fetch(`${API_URL}/div_estimates`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: headers()
    }).then( r => r.json() )
  },

  deleteEstimate(id) {
    return fetch(`${API_URL}/div_estimates/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  },

  submitText(text) {
    return fetch(`${API_URL}/div_estimates/update_intro`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ text }),
      headers: headers()
    }).then( r => r.json() )
  },

  fetchText() {
    return fetch(`${API_URL}/div_estimates/intro`)
    .then( r => r.json() )
  }
}

export default DivEstimateService;
