import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FundsSectorService = {

  fetchSectors(){
    return fetch(`${API_URL}/funds_sectors`)
    .then(r => r.json())
  },

  updateFundsSectors(fund_code, sectors){
    return fetch(`${API_URL}/funds_sectors`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ fund_code, sectors }),
      headers: headers()
    }).then( r => r.json() )
  }
}

export default FundsSectorService;
