import SicavDocService from '../../../services/SicavDocService';
// import {
//   unsuccessfulFetchRequest
// } from '../../appTransactions';

const successfulUpload = doc => {
  return {
    type: 'SUCCESSFUL_SICAV_DOC_UPLOAD',
    doc
  }
}

const clearSicavDocsWhileFetching = () => ({
  type: 'CLEAR_SICAV_DOCS'
})

const successfulSicavDocsFetch = (docs) => {
  return {
    type: "SUCCESSFUL_SICAV_DOCS_FETCH",
    docs
  }
}

const failedUpload = (doc) => {
  return {
    type: "SICAV_DOC_UPLOAD_FAILED",
    doc
  }
}

export const fetchSicavDocs = () => {
  return dispatch => {
    SicavDocService.fetchSicavDocs()
    .then( docs => dispatch(successfulSicavDocsFetch(docs)) )
  }
}

export const addSicavDoc = (doc) => {
  return dispatch => {
    //dispatch(currentlyUploading())
    SicavDocService.uploadSicavDoc(doc)
    .then(doc => {
      if (doc.errors){
        dispatch(failedUpload(doc))
      } else if (doc.error){
        console.log(doc.error)
        alert("Upload failed: Server Error")
      } else {
        dispatch(successfulUpload(doc))
      }
    })
  }
}

export const updateSicavDoc = (id, data) => {
  return dispatch => {
    dispatch(clearSicavDocsWhileFetching())
    SicavDocService.updateSicavDoc(id, data)
    .then( docs => {
      if (docs.errors) {
        dispatch(failedUpload(docs))
      } else if (docs.error){
		  console.log( 'updateSicav error:', docs.error)
        alert("Update failed: Server Error")
      } else {
        dispatch(successfulSicavDocsFetch(docs))
      }
    })
  }
}

export const deleteSicavDoc = id => {
  return dispatch => {
    dispatch(clearSicavDocsWhileFetching())
    SicavDocService.deleteSicavDoc(id)
    .then( docs => {
      if (docs.error) {
		  console.log( 'deleteSicav error:', docs.error )
        alert("There was an issue deleting this document.")
      } else {
        dispatch(successfulSicavDocsFetch(docs))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch({ type: 'SICAV_DOC_UPLOAD_COMPLETE' })
  }
}
