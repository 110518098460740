import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const InsightsService = {
  fetchInsights() {
    return fetch(`${API_URL}/insights`)
    .then(response => response.json())
  },

  fetchInsight(id) {
    return fetch(`${API_URL}/insights/` + id,)
    .then( r => r.json() )
  },

  fetchLatestInsight() {
    return fetch(`${API_URL}/insights/recent`)
    .then(response => response.json())
  },

  fetchFundsHomeInsights(){
    return fetch(`${API_URL}/funds_home_insights`)
    .then(response => response.json())
  },

  postNewInsight(insight) {
    return fetch(`${API_URL}/insights`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ insight }),
      headers: headers()
    }).then( r => r.json() )
  },

  updateInsight(insight, id){
    return fetch(`${API_URL}/insights/` + id, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({ insight }),
      headers: headers()
    }).then( r => r.json() )
  },

  deleteInsight(id){
    return fetch(`${API_URL}/insights/${id}`, {
      method: "DELETE",
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }

}

export default InsightsService;
