import FundsSectorService from '../../../services/FundsSectorService'

const successfulSectorsFetch = sectors => ({
  type: 'SUCCESSFUL_SECTORS_FETCH',
  sectors
})

export const fetchSectors = () => {
  return dispatch => {
    FundsSectorService.fetchSectors()
    .then( json => dispatch(successfulSectorsFetch(json)) )
  }
}

export const updateSectors = (fundCode, sectors) => {
  return dispatch => {
    FundsSectorService.updateFundsSectors(fundCode, sectors)
    .then( json => console.log(json))
  }
}
