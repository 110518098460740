import React from 'react'
import { Spinner } from  '@blueprintjs/core'
// import Form from 'muicss/lib/react/form';
// import Input from 'muicss/lib/react/input';
// import Button from 'muicss/lib/react/button';


const Login = (props) => {
  const { authFailed, isAuthenticating, handleLogin, refreshing } = props
  return(
    <div style={{ textAlign: 'center' }}>
      { authFailed ? <span id="login-failed" >Login failed. Please try again.</span> : null }
      { isAuthenticating || !!refreshing ?
        <Spinner /> :
        <form id="login-form" onSubmit={handleLogin}>
          <input className="login-form-field" type='text' placeholder="Username"/>
          <input className="login-form-field" type='password' placeholder="Password" />
          <input id="login-button" className="login-form-field" type='submit' value="Login" />
        </form>
      }
    </div>
  )
}

export default Login
