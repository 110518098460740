import React from 'react'
import ReactS3Uploader from 'react-s3-uploader'

import { API_URL } from '../../constants'

const headers = () => ({
  'Accept': 'application/json',
  'Content-Type': 'application/json'
})

const PDFUploader = props => {

  const onFinish = file => {
    props.bindPdfToInsight(file.fileName)
  }

  return (
    <ReactS3Uploader
      signingUrl={`${API_URL}/insights/s3/sign_pdf`}
      signingUrlMethod='GET'
      signingUrlWithCredentials={false}
      signingUrlHeaders={headers}
      onFinish={onFinish}
      uploadRequestHeaders={{}}
      accept='application/pdf'
      s3path='/'
      className="upload-field"
      name="s3-pdf-uploader"
      onProgress={props.handleProgress}
      value={null}
    />
  )

}

export default PDFUploader
