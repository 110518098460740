import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
    'Content-Type': 'application/json'

  }
};

const MediaService = {
  fetchArticles(){
    return fetch(`${API_URL}/news_articles`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  importArticles(){
    return fetch(`${API_URL}/news_articles/import`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  updateArticle(article){
    return fetch(`${API_URL}/news_articles/${article.id}`, {
       method: 'PUT',
       headers: headers(),
       body: JSON.stringify({ article })
    }).then(resp => resp.json())
  },

  fetchPodcasts(){
    return fetch(`${API_URL}/podcasts`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  importPodcasts(){
    return fetch(`${API_URL}/podcast_rss`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  deletePodcast( podcastId ){
	return fetch( `${ API_URL }/podcasts/${ podcastId }`, {
		method: 'DELETE',
		headers: headers(),
	} ).then( response => response.json() )
  },

  fetchTvAppearances(){
    return fetch(`${API_URL}/tv_appearances`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  refreshVideoList(){
    return fetch(`${API_URL}/tv_appearances/refresh`, {
      headers: headers()
    }).then(resp => resp.json())
  },

  fetchTvAppearance(tvId){
    return fetch(`${API_URL}/tv_appearances/${tvId}`, {
       headers: headers()
    }).then(resp => resp.json())
  },

  updateTvAppearance(tv){
    return fetch(`${API_URL}/tv_appearances/${tv.id}`, {
       method: 'PUT',
       headers: headers(),
       body: JSON.stringify({ tv })
    }).then(resp => resp.json())
  },

  addArticle(article) {
    return fetch(`${API_URL}/news_articles`, {
       method: 'POST',
       headers: headers(),
       body: JSON.stringify({ article })
    }).then(resp => resp.json())
  },

  addTvAppearance(tv) {
    return fetch(`${API_URL}/tv_appearances`, {
       method: 'POST',
       headers: headers(),
       body: JSON.stringify({ tv })
    }).then(resp => resp.json())
  },
}



export default MediaService
