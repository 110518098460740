import DivEstimateService from '../../../services/DivEstimateService';
import PressReleaseService from '../../../services/PressReleaseService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulUpload = pr => {
  return {
    type: 'SUCCESSFUL_UPLOAD',
    pr
  }
}

const clearPrsWhileFetching = () => ({
  type: 'CURRENTLY_FETCHING_PR'
})

const currentlyUploading = () => {
  return {
    type: 'UPLOADING_PR',
  }
}

export const failedUpload = errors => {
  return {
    type: "PR_UPLOAD_FAILED",
    errors
  }
}

const successfulPrFetch = pr => {
  return {
    type: "SUCCESSFUL_PR_FETCH",
    pr: Array.isArray(pr) ? pr : pr.pr,
  }
}

const successfulCePrFetch = (pr) => {
  return {
    type: 'SUCCESSFUL_CEPR_FETCH',
    pr
  }
}

const startDeleteEstimate = () => ({
  type: "START_DELETE_ESTIMATE"
})

const successfulEstimateDelete = estimates => ({
  type: "SUCCESSFUL_ESTIMATE_DELETE",
  estimates
})

const completeUpload = () => {
  return {
    type: "UPLOAD_COMPLETE"
  }
}


export const uploadPressRelease = (pr) => {
  return dispatch => {
      dispatch(currentlyUploading())
    PressReleaseService.uploadPressRelease(pr)
      .then(pr => {
        if(pr.errors){
          dispatch(failedUpload( pr.errors ))
        } else if (pr.error){
          console.log(pr.error)
          alert("Upload failed: Server Error")
        } else {
          dispatch(successfulUpload(pr))
        }
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const replaceEstimateWithPressRelease = ( pr, estimateId ) => {
  return dispatch => {
    dispatch( currentlyUploading() );
    PressReleaseService.uploadPressRelease( pr ).then( prPromise => {
      if ( prPromise.errors ) dispatch( failedUpload( prPromise.errors ) );
      else if ( pr.error ) {
        console.log( pr.error );
        alert( "Upload failed: server error");
      } else {
        dispatch( successfulUpload( pr ) );
        dispatch( startDeleteEstimate() );
        DivEstimateService.deleteEstimate( estimateId ).then( estimates => dispatch( successfulEstimateDelete( estimates ) ) )
      }
    } ).catch( () => dispatch( unsuccessfulFetchRequest() ) );
  }
}

export const fetchPressReleases = (fundCode = null) => {
  return dispatch => {
    PressReleaseService.fetchPressReleases(fundCode)
    .then( pr => {
      dispatch(successfulPrFetch(pr))
    })
    .catch( error => {
      console.log(error)
    })
  }
}

export const fetchCePrs = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    PressReleaseService.fetchCePressReleases()
    .then(pr => {
      dispatch(finishFetchRequest())
      dispatch(successfulCePrFetch(pr))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const updatePr = (id, data) => {
  return dispatch => {
    dispatch(clearPrsWhileFetching())
    PressReleaseService.updatePr(id, data)
    .then( prs => {
      if(prs.errors) {
        alert("There was an issue updating this press release.")
      } else {
        dispatch(successfulPrFetch(prs))
      }
    })
  }
}

export const deletePr = (id) => {
  return dispatch => {
    dispatch(clearPrsWhileFetching())
    PressReleaseService.deletePr(id)
    .then( prs => {
      if (prs.error) {
        alert("There was an issue deleting this press release.")
      } else {
        dispatch(successfulPrFetch(prs))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
