export default (state = [], action) => {
  switch(action.type) {

    case 'SUCCESSFUL_MONTHLY_PERFS_FETCH':
      return {
        ...state,
        monthlyPerfs: action.monthlyPerfs
      }

    case 'SUCCESSFUL_QUARTERLY_PERFS_FETCH':
      return {
        ...state,
        quarterlyPerfs: action.quarterlyPerfs
      }

    default:
      return state;
  }
}
