import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { refreshLogin } from '../redux/modules/Admin/actions'
import AdminLogin from '../views/AdminPortal/AdminLogin'

class MatchAuthenticated extends Component {

  constructor(props){
    super()
    props.refreshLogin()
  }

  render(){
    const { props } = this
    const Component = props.component
	if ( ( props.excludeBasicUsers && props.adminRole > 1 ) || ( props.allowOnlySuperUsers && props.adminRole > 0 ) ){
		return <Redirect to='/?prohibited'/>
	} else if ( props.authFailed ){
		return <Redirect to='/admin_login'/>
    } else if ( props.isAuthenticated ){
		return <Route render={ () => <Component cableApp={props.cableApp} matchParams={props.computedMatch.params} adminRole={props.adminRole} /> }/>
    } else {
		return <AdminLogin refreshing={true}/>
	}
  }
}

const mapStateToProps = state => ({
  authFailed: state.admin.authFailed
})

export default connect(mapStateToProps, { refreshLogin })(MatchAuthenticated)


// if(props.isAuthenticated){ return <Component pmId={props.computedMatch.params.id} cableApp={props.cableApp}/> }
// //if(props.isAuthenticating){ return <h1>Loading</h1> }
// if(!props.isAuthenticated){ return <Redirect to={{ pathname }}/> }
