import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchLeads } from '../../redux/modules/Leads/actions'
import { downloadLeadsAsSpreadsheet } from './downloadLeadsAsSpreadsheet'

const Leads = () => { 

  const [ limit, setLimit ] = useState( 10 );
  const [ filter, setFilter ] = useState( "all" );
  const [ filterYear, setFilterYear ] = useState( 'all' );
  const [ ascending, setAscending ] = useState( false );

  const dispatch = useDispatch();
  
  const leads = useSelector( state => state.leads.leads || [] );
  
  let filteredLeads = filter === "all" ? leads :
  	filter === "newsletter (all)" ? leads.filter( ( { request_type } ) => request_type?.match( "newsletter" ) ) :
  	filter === "conference info (all)" ? leads.filter( ( { request_type } ) => request_type?.match( "conference" ) ) :
  	filter === "omaha info (all)" ? leads.filter( ( { request_type } ) => request_type?.match( "omaha" ) ) :
	leads.filter( ( { request_type } ) => filter === request_type );
	console.log( filteredLeads );
  
  if ( filterYear !== 'all' ) {
	filteredLeads = filteredLeads.filter( ( { created_at } ) => new Date( created_at ).getFullYear() === Number( filterYear ) );
  }

  const leadsList = filteredLeads.sort( ( thisLead, thatLead ) => {
    const thisDate = new Date( thisLead.created_at ), thatDate = new Date( thatLead.created_at );
    return ascending ? thisDate - thatDate : thatDate - thisDate;
  } ).slice( limit - 10, limit ).map( lead => ( 
    <ListItem key={ lead.id }>
      <h4>{ lead.email }</h4>
      <p>Request Type: { lead[ 'request_type' ] }</p>
      <small>{ new Date( lead.created_at ).toLocaleString() }</small>
    </ListItem>
  ) );

  // new Set() is a dumb way to remove duplicates from an array of primitives
  // NOT space efficient... but quick, easy, declarative and standard
  const leadTypes = new Set( [ "all", "newsletter (all)", "conference info (all)", "omaha info (all)", ...leads.map( lead => lead.request_type ) ] );

  useEffect( () => dispatch( fetchLeads() ), [ dispatch ] );

  const changeLimit = i => { 
    switch ( i ) {
      case "prev":
        setLimit( limit <= 10 ? 10 : limit - 10 );
        break;
      case "next":
        if ( limit < filteredLeads.length ) setLimit( limit + 10 );
        break;
      default: break;
    }
  };

  return ( 
    <div id="admin-upload-container">
      <div id="upload-form-container">
        <h3>Leads <small>( { limit - 9 } to { limit > filteredLeads.length ? filteredLeads.length : limit } of { filteredLeads.length } )</small></h3>
        <label htmlFor="category-filter">Now filtering: </label>
        &nbsp;
        <select name="category-filter" onChange={ ( { target } ) => setFilter( target.value ) }>
          {  leadTypes.map( leadType => <option key={  leadType  } value={  leadType  }>{  leadType  }</option> )  }
        </select>
        &nbsp;
		<label htmlFor="year-filter">Filter by year:</label>
		<select name="year-filter" onChange={ ( { target } ) => setFilterYear( target.value ) } value={ filterYear }>
			{  [ 'all', 2021, 2022, 2023 ].map( year => <option key={  year  } value={ year  }>{ year }</option> )  }
		</select>
        <label htmlFor="sort">Now sorting: </label>
        &nbsp;
        <LeadsButton onClick={ () => setAscending( !ascending ) }>{ ascending ? "▼ Oldest to newest" : "▲ Newest to oldest" }</LeadsButton>
        &nbsp;
        <LeadsButton onClick={ () => downloadLeadsAsSpreadsheet( filter, filteredLeads, [ "first_name", "last_name", "email", "company", "phone", "request_type", "created_at" ] ) }>Export</LeadsButton>
        <LeadsList>
          { leadsList }
        </LeadsList>
        { filteredLeads.length > 10 ? <div>
          <PageButton onClick={ () => changeLimit( "prev" ) }>Prev</PageButton>
          <PageButton onClick={ () => changeLimit( "next" ) }>Next</PageButton>
        </div> : null }
      </div>
    </div>
  );

 }

export default Leads

const LeadsList = styled.ul `
  margin: 30px auto;
  list-style: none;
  padding-left: 10px;
`

const ListItem = styled.li `
  padding-bottom: 5px;
  border-bottom: 1px solid LightGray;
  margin-bottom: 15px;
`

const LeadsButton = styled.button `
  color: #005b97;
  background-color: white;
  padding-left: 10px;
  border: none;
  &:hover { 
    color: #00aeef;
  }
`

const PageButton = styled.button `
  background-color: #005b97;
  color: white;
  margin-top: 50px;
  margin-right: 30%;
  padding: 5px 10px;
  width: 70px;
`
