import React, { Component } from 'react'
// import Select from '../../components/Select'
import { Popover } from '@blueprintjs/core'
import QuarterSelector from '../AdminPortal/QuarterSelector'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class DocResult extends Component {
  constructor(props){
    super()

    const { d } = props

    let year
    let quarter
    if(d.quarter){
      year = d.quarter.slice(-4)
      quarter = d.quarter.substring(0, d.quarter.length - 4)
    }
    this.state = {
      isEditing: false,
      formData: {
        name: d.name,
        fund_code: d.fund_code,
        as_of: d.as_of,
        doc_type: d.doc_type,
        filepath: d.filepath,
        quarter,
        year
      }
    }
  }

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  handleChange = (e) => {
    const { formData } = this.state
    const { name, value } = e.target
    formData[name] = value
    this.setState({ formData })
  }

  handleSelectChange = (e) => {
    const { formData } = this.state
    if(e){
      const { value } = e
      formData.fund_code = value
      this.setState({ formData })
    } else {
      formData.fund_code = null
      this.setState({ formData })
    }
  }

  handleUpdateDoc = (id, data) => {
    data.quarter = `${data.quarter}${data.year}`
    delete data.year
    this.props.updateDoc(id, data)

  }

  render(){
    const { d, funds, deleteUpload } = this.props
    const { isEditing, formData } = this.state
    if(isEditing){
      return (
        <div>
          <form>
            <label className="upload-input-label">Name:</label>
            <input className="media-info-input admin-input" name="name" value={formData.name} onChange={this.handleChange}></input>
            <label className="upload-input-label">Fund:</label>
            <SelectWithAutofill
              options={funds}
              className="select-tag media-info-input admin-input"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.formData.fund_code}
            />
            <label className="upload-input-label">Current as of:</label>
            <input className="media-info-input admin-input" name="as_of" value={formData.as_of} onChange={this.handleChange}></input>
            <label className="upload-input-label">Doc type:</label>
            <select className="media-info-input admin-select" name="doc_type" value={formData.doc_type} onChange={this.handleChange}>
              <option value=""></option>
              <option value="annual_report">Annual Report</option>
              <option value="commentary">Commentary</option>
              <option value="factsheet">Factsheet</option>
              <option value="prospectus">Prospectus</option>
              <option value="prospectus_supp">Prospectus Supplement</option>
              <option value="proxy_statement">Proxy Statement</option>
              <option value="quarterly_report">Quarterly Report</option>
              <option value="sai">Statement of Additional Information</option>
              <option value="soi">Sched. of Investments</option>
              <option value="summary_prospectus">Summary Prospectus</option>
            </select>
            <label className="upload-input-label">Filepath:</label>
            <input className="media-info-input admin-input" name="filepath" value={formData.filepath} onChange={this.handleChange}></input>
            <QuarterSelector
              handleChange={this.handleChange}
              state={formData}
              type={formData.doc_type}
            />
          </form>
          <div className="admin-docs-btn-container">
            <button className="admin-docs-edit-btn" onClick={ () => this.handleUpdateDoc(d.id, formData) }>Update</button>
            <button className="admin-docs-edit-btn cancel-btn" onClick={this.toggleEditing}>Cancel</button>
          </div>
          <hr style={{ width: '100%' }}/>
        </div>
      )
    } else {
      return(
        <div className="admin-docs-result">
          <h5>
            <a href={d.filepath} target="_blank" rel="noreferrer noopener">{d.name}</a>
          </h5>
          <p className="admin-docs-result-data">Doc Type: {d.doc_type}</p>
          <p className="admin-docs-result-data">Uploaded on {d.updated_at.split("T")[0]}{ !!d.uploaded_by ? ` by ${ d.uploaded_by }` : "" }</p>
          <p className="admin-docs-result-data">As of: {d.as_of}</p>
          <div className="admin-docs-btn-container">
            <button onClick={this.toggleEditing} className="admin-docs-edit-btn">Edit</button>
            <Popover minimal={true}>
              <button className="admin-docs-dlt-btn">Delete</button>
              <div className="popover-content">
                <h4>Are you sure?</h4>
                <p>Would you like to delete this item?</p>
                <button className=" bp3-popover-dismiss">Cancel </button>
                <button className="cc-delete-btn popover-btn " onClick={() => deleteUpload(d.id)}>Delete</button>
              </div>
            </Popover>
          </div>
          <hr style={{ width: '100%' }}/>
        </div>
      )
    }
  }
}

export default DocResult
