export default (state = [], action) => {
  switch(action.type) {
    case 'SUCCESSFUL_TEAMS_FETCH':
      return action.teams
    case 'SUCCESSFUL_TEAM_FETCH':
      return action.teams
    case 'SUCCESSFUL_TEAM_ADD':
      return action.teams
    case 'SUCCESSFUL_TEAM_DELETE':
      return action.teams
    default:
      return state;
  }
}

// export default (state = [], action) => {
//   switch(action.type){
//     case 'SUCCESSFUL_PMS_FETCH':
//       return { pms: action.pms }
//     case 'SUCCESSFUL_PM_FETCH':
//       return {
//         pms: [...state.pms],
//         pm: {
//           pm: action.pm.pm,
//           videos: action.pm.videos,
//           managedFunds: action.pm.funds
//         }
//       }
//     default:
//       return state;
//   }
// }
