export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_ADMINS_FETCH': return { ...state, admins: action.admins }
	case 'ADMIN_AUTHENTICATION_REQUEST':
      return {
        ...state,
        isAuthenticating: true
      }
    case 'ADMIN_AUTHENTICATION_SUCCESS':
      return {
        isAuthenticating: false,
        isAuthenticated: true,
		role: action.role,
      }
    case 'ADMIN_UNSUCCESSFUL_REQUEST':
      return {
        isAuthenticating: false,
        isAuthenticated: false,
        authFailed: true
      }
    case 'ADMIN_LOGOUT':
      return {
        isAuthenticating: false,
        isAuthenticated: false
      }
    case 'PASSWORD_RESET_FAILED':
      return {
        ...state,
        message: action.message,
        error: true
      }
    case 'PASSWORD_RESET_SUCCESS':
      return {
        ...state,
        message: "Update Successful",
        success: true
      }
    case 'START_PASSWORD_RESET':
      return {
        ...state,
        message: null,
        error: null,
        success: null,
      }
    default:
      return state;
  }
}
