import React from 'react'
import { Icon } from '@blueprintjs/core'
import PmCard from '../PortfolioManagers/PmCard'

const AdminPmCard = (props) => {
  const { pm, handleClick, currentYear } = props
  return (
    <div className="pm-admin-card" key={pm.id} onClick={ () => handleClick(pm) }>
      <div className="pm-edit-overlay">
        <p>
          Edit
          <br />
          <Icon icon="edit" iconSize={Icon.SIZE_LARGE} color="white"/>
        </p>
      </div>
      <PmCard currentYear={currentYear} pm={pm}/>
    </div>
  )
}

export default AdminPmCard
