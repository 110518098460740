import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

const AsOfSelector = (props) => {
  const { type, handleDayChange, date } = props
  return (
    <div>
      <label className="upload-input-label">{type === "insight" ? "Date:": "As of:"}</label>
      <br />
      <DayPickerInput
        onDayChange={handleDayChange}
        className="media-info-input admin-input"
        value={date || "YYYY-M-D"}
        required
      />
    </div>
  )
}

export default AsOfSelector
