import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Select from '../../components/Select'
import SelectWithAutofill from '../../components/SelectWithAutofill'
import { uploadDocument, uploadComplete } from '../../redux/modules/DocumentUploads/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import S3Uploader from './S3Uploader'
import QuarterSelector from './QuarterSelector'
import AsOfSelector from './AsOfSelector'
import SuccessModal from './SuccessModal'
import { uploadType } from '../TableTools'
// import { filenameFromFund } from '../FilenameTools'

import 'react-day-picker/lib/style.css'

class DocumentUpload extends Component {
  constructor(props){
    super(props)

    this.state = {
       fund_code: null,
       filepath: "",
       as_of: null,
       quarter: "",
       year: "",
       uploaded_by: JSON.parse( localStorage.getItem( 'admin' ) ).full_name,
       showSubmit: false,
       fundType: ""
     }
  }

  componentDidMount(){
    this.props.fetchFunds()
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  handleChange = (e) => {
    const { state } = this
    state[e.target.name] = e.target.value
    this.setState(state)
  }

//   handleSelectChange = e => {
//     if (e){
//       this.setState({
//         fund_code: e.value,
//         flagship_name: e.label
//       })
//     } else {
//       this.setState({
//         fund_code: null,
//         flagship_name: null
//       })
//     }
//   }

  handleSelectChange = changeEvent => this.setState( { fund_code: changeEvent?.value, flagship_name: changeEvent?.label } );

  handleDayChange = (e) => {
    if(e){
      this.setState({ as_of: e })
    } else {
      this.setState({ as_of: null})
    }
  }

  handleTypeSelect = (e, fundType) => {
    e.preventDefault()
    this.setState({ fundType })
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if(progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    //create one object with all neccessary keys
    const { fund_code, flagship_name, filepath, quarter, year, as_of, uploaded_by } = this.state
    const doc_type = this.props.matchParams.type
    const doc = {
      doc_type,
      uploaded_by,
      filepath
    }
    if (doc_type !== "commentary") {
      doc["fund_code"] = fund_code
      doc["flagship_name"] = flagship_name
    }
	// if ( doc_type === 'summary_prospectus ') {}
    //add certain traits depending on fields that were input (which depends on document type/form)
    if(!!as_of){ doc["as_of"] = as_of }
    if(!!quarter && !!year){ doc["quarter"] = `${quarter}${year}` }
    // check to see if there are any empty values and show the neccessary message to the user
     const hasEmptyValues = Object.values(doc).some( val => !val )
     
     if(!!hasEmptyValues) {
       document.getElementById("upload-failed").style.display = "inline-block"
     } else {
      //no empty values will remove any error message, upload coument, and reset local state
      document.getElementById("upload-failed").style.display = "none"
      this.props.uploadDocument(doc, this.state.fundType)
      this.setState({
        fund_code: null,
        filepath: "",
        quarter: "",
        year: "",
        flagship_name: "",
        showSubmit: false
      })
      document.getElementsByName("s3-uploader")[0].value = ''
    }
  }

  parseAsOf = (date) => {
    const monthWithZero = `0${date.getUTCMonth() + 1}`
    const dayWithZero = `0${date.getUTCDate()}`
    return `${monthWithZero.slice(-2)}${dayWithZero.slice(-2)}${date.getUTCFullYear()}`
  }

  render(){
	// console.log( filenameFromFund( this.state.fund_code, this.state.fundType, this.props.matchParams.type, this.state.as_of ) );
	// console.log( this.state );
    const { fundsList, errors } = this.props
    const { fundType } = this.state
    const { type } = this.props.matchParams
    let options
    if(fundsList){
		options = fundsList.filter( fund => fund["fund_type"].toLowerCase().includes(fundType) )
		.map( fund => ({
			value: fund.fund_code, label: fund.fundshortname
		}))
    }
    const asOf = new Date(this.state.as_of)
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Document successfully uploaded"}
        />
        <div id="upload-form-container">
          <h3>{uploadType(type)} Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <QuarterSelector
              handleChange={this.handleChange}
              state={this.state}
              type={type}
            />
            <AsOfSelector
              handleDayChange={this.handleDayChange}
              type={type}
              date={this.state.date}
              value={this.state.date || "YYYY-M-D"}
            />
            <label className="upload-input-label">Select Fund:</label>
            <div id="pr-double-btn" className="upload-triple-btn">
              <button className={this.state.fundType === "open" ? "selected-btn" : null} onClick={ e => this.handleTypeSelect(e, "open") }>Open Ends</button>
              <button className={this.state.fundType === "closed" ? "selected-btn" : null} onClick={ e => this.handleTypeSelect(e, "closed") }>Closed Ends</button>
              <button className={this.state.fundType === "activeshare" ? "selected-btn" : null} onClick={ e => this.handleTypeSelect(e, "activeshare") }>ETFs</button>
            </div>
            {
              type === "commentary" ?
              null
              :
              <SelectWithAutofill
                options={ options }
                className="select-tag media-info-input admin-input"
                placeholder="Enter a fund..."
                handleChange={ this.handleSelectChange }
                onClear={ () => this.handleSelectChange( null ) }
                value={ this.state.fund_code }
              />
            }
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={ {
				doc_type: this.props.matchParams.type,
				as_of: this.parseAsOf( asOf ),
				// objectName: filenameFromFund( this.state.fund_code, this.state.fund_type, this.props.matchParams.type, this.state.as_of )
			  } }
              accept='application/pdf'
              apiPath="uploads/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
              isDisabled={!this.state.as_of}
            />
            <input id="upload-button" className="admin-input" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fundsList: state.funds,
  isUploading: state.docs.isUploading,
  successfulUpload: state.docs.successfulUpload,
  errors: state.docs.errors
})

export default withRouter(connect(mapStateToProps, { fetchFunds, uploadDocument, uploadComplete })(DocumentUpload))

// <label className="upload-input-label">Document Name:</label>
// <input name="name" className="media-info-input" onChange={this.handleChange} value={this.state.name}></input>
