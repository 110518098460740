import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../redux/modules/Admin/actions'
import { Position, Toaster, Intent } from '@blueprintjs/core'

const AppToaster = Toaster.create({
  className: "pm-toaster",
  position: Position.TOP,
  timeout: 0
})

class AdminSettings extends Component {
  constructor(){
    super()

    this.state = {
      current: "",
      new: "",
      confirm: ""
    }
  }

  componentDidUpdate(prevProps){
    if(!prevProps.admin.error && this.props.admin.error){
      const message = this.props.admin.message.includes("Internal Server Error") ? "Session has expired. Log out and log back in." : this.props.admin.message
      AppToaster.show({ message, intent: Intent.DANGER})
    } else if (!prevProps.admin.success && this.props.admin.success){
      AppToaster.show({ message: this.props.admin.message, intent: Intent.SUCCESS})
      this.setState({ current: "", new: "", confirm: "" })
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.resetPassword(this.state)
  }

  render(){
    return (
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>Settings</h3>
          <h4>Password Reset</h4>
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Enter Current Password:</label>
            <br />
            <input className="media-info-input" type="password" name="current" value={this.state.current}onChange={this.handleChange}/>
            <br />
            <br />
            <label className="upload-input-label">Enter New Password:</label>
            <br />
            <input className="media-info-input" type="password" name="new" value={this.state.new}onChange={this.handleChange}/>
            <br />
            <br />
            <label className="upload-input-label">Confirm New Password:</label>
            <br />
            <input className="media-info-input" type="password" name="confirm" value={this.state.confirm}onChange={this.handleChange}/>
            <br />
            <br />
            <input id="upload-button" type="submit" value="Reset Password"/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  admin: state.admin
})

export default connect(mapStateToProps, { resetPassword })(AdminSettings)
