import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { yearOptions } from '../TableTools'
import S3Uploader from './S3Uploader'
import { createAnalyst } from '../../redux/modules/Analysts/actions'
import { createAdministrator } from '../../redux/modules/Administrators/actions'

class PersonnelNew extends Component {
  constructor(){
    super()

    this.state = {
      name: "",
      title: "",
      start_year: "",
      bio: "",
      img: "",
      showSubmit: false
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { personnelType } = this.props.matchParams
    const { state } = this
    const hasEmptyValues = Object.values(state).some( val => !val )
    if(hasEmptyValues){
      document.getElementById('upload-failed').style.display = "inline-block"
    } else {
      if (personnelType === "administrators"){this.props.createAdministrator(state)}
      if (personnelType === "analysts"){this.props.createAnalyst(state)}
      this.props.history.push(`/admin/${personnelType}`)
    }
  }

  handleResponse = (response) => {
    this.setState({ img: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100) {
      this.setState({ showSubmit: true })
    }
  }

  render(){
    const { personnelType } = this.props.matchParams
    return(
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>Create {personnelType.charAt(0).toUpperCase() + personnelType.slice(1)}</h3>
          <span id="upload-failed" >Creation failed. All fields are required.</span>
          { !!this.props.analystErrors ? <span id="update-error-span">Error: {this.props.analystErrors[0]}</span> : null }
          { !!this.props.adminErrors ? <span id="update-error-span">Error: {this.props.adminErrors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Name:</label>
            <br />
            <input className="media-info-input admin-input" onChange={this.handleChange} name="name"type='text' value={this.state.name}/>
            <br />
            <label className="upload-input-label">Title:</label>
            <br />
            <input className="media-info-input admin-input" onChange={this.handleChange} name="title"type='text' value={this.state.title}/>
            <br />
            <label className="upload-input-label">Start year:</label>
            <br />
            <select className="media-info-input admin-select" onChange={this.handleChange} name="start_year"type='text'value={this.state.start_year}>
              <option value=""></option>
              {yearOptions()}
            </select>
            <br />
            <label className="upload-input-label">Bio:</label>
            <br />
            <textarea className="media-info-input admin-input" onChange={this.handleChange} name="bio" value={this.state.bio}/>
            <br />
            <label className="upload-input-label s3-upload-label">Upload Headshot:</label>
            <S3Uploader
              handleResponse={this.handleResponse}
              accept='image/*'
              apiPath={`${personnelType}/s3/sign`}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" className="admin-input" type="submit" value="Create" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  analystErrors: state.analysts.errors,
  adminErrors: state.administrators.errors
})

export default withRouter(connect(mapStateToProps, { createAnalyst, createAdministrator })(PersonnelNew))
