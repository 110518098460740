export default (state = [], action) => {
  switch(action.type){
    case 'SUCCESSFUL_MARKET_DATA_FETCH':
      return action.marketData
    default:
      return state;
  }
}


// const initialState = {
//   items: [],
//   item: {}
// }
//
// export default function(state = initialState, action) {
//   switch(action.type){
//     case 'FETCH_MARKET_DATA':
//       return {
//         ...state,
//         items: action.payload
//       };
//       default:
//         return state;
//   }
// }
