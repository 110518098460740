import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchPodcasts, importPodcasts, deletePodcast } from '../../redux/modules/Media/actions'
// import AdminArticleCard from '../Media/AdminArticleCard'
import MediaAdminNav from './MediaAdminNav'


const MediaAdminPodcast = () => {

  const dispatch = useDispatch()
  const podcasts = useSelector(state => state.articles.podcasts || [])

  useEffect(() => {
    dispatch(fetchPodcasts())
  }, [dispatch])

  const handleRefresh = () => {
    dispatch(importPodcasts())
  }

  const pods = podcasts?.map(p => (
    <PodCard key={ p.id }>
      <PodImage>
        <img src={p.image_url} alt={p.title}/>
		<button
			style={ podDeleteButtonStyle }
			onClick={ () => dispatch( deletePodcast( p.id ) ) }
		>
			✗
		</button>
      </PodImage>
      <PodInfo>
        <span className="video-title">{p.title}</span>
        <p>{p.source}</p>
        <p>{p.publish_date}</p>
        <p>{p.pod_description}</p>
      </PodInfo>
    </PodCard>
  ))

  return (
    <div id='below-search'>
      <MediaAdminNav />
      <div className="article-buttons">
          <div className="refresh-button">
            <span onClick={ handleRefresh }>Refresh Podcasts</span>
          </div>
          {/* <ArticleBtnDiv className="refresh-button">
            <NavLink to='/admin/media-admin/article_entry'><AddArticleBtn>Add Article</AddArticleBtn></NavLink>
          </ArticleBtnDiv> */}
        </div>
        <div className='article-div'>
          { pods }
        </div>

      
    </div>
  )
}

export default MediaAdminPodcast

const PodCard = styled.article `
  float: left;
  margin: 2% 2% 5% 0;
  box-sizing: border-box;
  border: 0;
  width: 23%;
  margin-bottom: 23px;
  height: auto;
`
const PodInfo = styled.div `
  height: 270px;
  overflow: scroll;
`
const PodImage = styled.div `
	display: flex;
	justify-content: flex-start;
	gap: 10px;
	button {
		width: 20px;
		height: 20px;
	}
	img {
		height: 12.6vw;
		max-height: 268px;
		padding-bottom: 10px;
	}
`
const podDeleteButtonStyle = { border: 'none', borderRadius: '5px', backgroundColor: '#f00', color: '#fff' };
