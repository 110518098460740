import React, { Component } from 'react'
import { connect } from 'react-redux'
import { upload8937, uploadComplete } from '../../redux/modules/Form8937s/actions'
import { fetchAllFunds } from '../../redux/modules/Funds/actions'
import SuccessModal from './SuccessModal'
// import Select from '../../components/Select'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import S3Uploader from './S3Uploader'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class Form8937Uploader extends Component {
  constructor(){
    super()

    this.state = {
      fund_code: null,
      as_of: null,
      doc_type: "8937",
      filepath: "",
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchAllFunds()
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  handleChange = (e) => {
    const { state } = this
    const { name, value } = e.target
    state[name] = value
    this.setState(state)
  }

  handleDayChange = (date) => {
    this.setState({
      as_of: date
    })
  }

  handleSelectChange = (e) => {
    if(e){
      this.setState({
        fund_code: e.value
      })
    } else {
      this.setState({
        fund_code: null
      })
    }
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const hasEmptyValues = Object.values(this.state).some( val => !val )
    if (!!hasEmptyValues) {
      document.getElementById("upload-failed").style.display = "inline-block"
    } else {
      document.getElementById("upload-failed").style.display = "none"
      this.props.upload8937(this.state)
      this.setState({
        fund_code: null,
        filepath: "",
        as_of: null,
        showSubmit: false
      })
      document.getElementsByName("s3-uploader")[0].value = ''
    }
  }

  render(){
    const { funds } = this.props
    const { errors } = this.props
    let options
    if(funds){
      options = funds.map( fund => ({
        value: fund.fund_code, label: fund.fundshortname
      }))
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Form 8937 successfully added"}
        />
        <div id="upload-form-container">
          <h3>Form 8937 Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Select Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.fund_code}
            />
            <br />
            <label className="upload-input-label">Date of Action:</label>
            <br />
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={this.state.as_of || "YYYY-M-D"}
            />
            <br />
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={{ "doc_type": "preferred_doc" }}
              apiPath="form8937s/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  funds: state.funds,
  forms: state.form8937s.forms,
  successfulUpload: state.form8937s.successfulUpload,
  errors: state.form8937s.errors
})

export default connect(mapStateToProps, { upload8937, uploadComplete, fetchAllFunds })(Form8937Uploader)
