import React, { useState } from 'react';
import AWS from 'aws-sdk';

const AttachmentUploader = () => {

	const [ state, setState ] = useState( { success: false, publicUrl: '', copied: false } );
	const [ progress, setProgress ] = useState();

	const handleProgress = ( { loaded, total } ) => setProgress( Math.round( ( loaded / total ) * 100 ) );

	const handleUpload = ( { target } ) => {
		AWS.config.update( {
			region: 'us-east-1',
			maxRetries: 3,
			httpOptions: { timeout: 30000, connectTimeout: 5000 },
			accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
		} );
		const s3Bucket = new AWS.S3();
		s3Bucket.upload( {
            Body: target.files[ 0 ],
            Bucket: 'gab-attachments',
            Key: target.files[ 0 ].name,
			ContentType: 'image',
			Metadata: { 'uploaded-by': JSON.parse( localStorage.getItem('admin') )?.full_name }
        }, console.log )
		.on('httpUploadProgress', handleProgress )
		.send( ( error, { Location } ) => {
			if ( error ) console.error( error );
		} );
		setState( { ...state, success: true, publicUrl: `https://gab-attachments.s3.amazonaws.com/${ target.files[ 0 ].name }` } );
	};

	return <div style={ { fontFamily: 'Gotham', textAlign: 'center', width: '90%', margin: '0 auto' } }>

		<h1>Host an Email Attachment</h1>
		<h3>If you need to send an image too large to attach to an email, click below to generate a permanent URL.</h3>

		<input style={ { display: 'initial !important', margin: '0 auto' } } type='file' onChange={ handleUpload } />
		<progress value={ progress } />

		{ state.publicUrl && <div style={ {
			width: '90%',
			margin: '0.5rem auto',
			textAlign: 'center',
			border: '1px dashed black'
		} }>
			<h3>Success! Your URL is: </h3>
			<h4><a href={ state.publicUrl } target='_blank' rel='noopener noreferrer'>{ state.publicUrl }</a></h4>
			<div>(click to view in a new window)</div>
			<button
				onClick={ () => {
					navigator.clipboard.writeText( state.publicUrl );
					setState( { ...state, copied: true } )
				} }
				disabled={ state.copied }
			>
				{ state.copied ? 'Copied!' : 'Copy URL to clipboard' }
			</button>
			<h4 style={ { color: '#f00' } }>Make sure to copy/paste and save the URL above before closing this window or uploading a new image!</h4>
		</div> }

	</div>;

}

export default AttachmentUploader;