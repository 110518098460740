import React from 'react'
import { Link } from 'react-router-dom'
import { parseFullDate } from '../TableTools'

// All links to '/full_report' page for testing
const Insight = (props) => {
  const { insight, aos, urlPrefix } = props
  return(
      <div className="report-card" data-aos={aos ? "zoom-out" : null} data-aos-duration={aos ? "500" : null} data-aos-once={true}>
        <Link to={`${urlPrefix}/insights/` + insight["s3_key"]}><img className="report-image" src={`https://s3.us-east-2.amazonaws.com/gab-commentary-images/${insight.photo}`} alt="Report"/></Link>
        <div className="report-info">
          <Link to={`${urlPrefix}/insights/` + insight["s3_key"]} className="report-title">{insight.title}</Link>
          <p className="report-date">{parseFullDate(insight.date)}</p>
          <p className="short-description">{insight["description"]}</p>
        </div>
      </div>
  )
}

export default Insight
