import AdminService from '../../../services/AdminService';

const successfulAdminsFetch = admins => {
	return {
	  type: 'SUCCESSFUL_ADMINS_FETCH',
	  admins
	}
}

const updateAdminRequest = () => {
	return {
	  type: 'UPDATE_ADMIN_REQUEST'
	}
  }

const deleteAdminRequest = () => {
	return {
	  type: 'DELETE_ADMIN_REQUEST'
	}
  }

const adminDeleted = () => {
	return {
	  type: 'ADMIN_DELETED_SUCCESSFULLY'
	}
  }

const failedAdminUpdate = () => {
	return {
	  type: 'ADMIN_UPDATE_FAILED'
	}
  }

const successfulAdminUpdate = () => {
	return {
	  type: 'SUCCESSFUL_ADMIN_UPDATE'
	}
  }

const authenticationRequest = () => {
  return {
    type: 'ADMIN_AUTHENTICATION_REQUEST'
  }
}

const adminAuthenticationSuccess = role => {
  return {
	  type: 'ADMIN_AUTHENTICATION_SUCCESS',
	  role
	}
}

const unsuccessfulFetchRequest = () => {
  return {
    type: 'ADMIN_UNSUCCESSFUL_REQUEST'
  }
}

const resetUnsuccessful = json => ({
  type: 'PASSWORD_RESET_FAILED',
  message: json.error
})

const resetSuccessful = () => ({
  type: 'PASSWORD_RESET_SUCCESS'
})

const startPasswordReset = () => ({
  type: 'START_PASSWORD_RESET'
})

export const fetchAdmins = () => {
	return dispatch => {
	  AdminService.fetchAdminsIndex()
	  .then(admins => {
		dispatch(successfulAdminsFetch(admins))
	  })
	  .catch( error => {
		dispatch(unsuccessfulFetchRequest())
	  })
	}
};

export const updateAdmin = (id, data) => {
	return dispatch => {
	  dispatch(updateAdminRequest())
	  AdminService.updateAdmin(id, data)
	  .then( admin => {
		if (admin.errors) {
			console.log(admin.errors)
		  dispatch(failedAdminUpdate())
		} else if (admin.error){
			console.log( 'updateAdmin error:', admin.error)
		  alert("Update failed: Server Error")
		} else {
		  dispatch(successfulAdminUpdate())
		}
	  })
	}
}

export const adminLostPassword = (id) => {
	return dispatch => {
	  dispatch(updateAdminRequest())
	  AdminService.lostPassword(id)
	  .then( admin => {
		if (admin.errors) {
			console.log(admin.errors)
		  dispatch(failedAdminUpdate())
		} else if (admin.error){
			console.log( 'updateAdmin error:', admin.error)
		  alert("Update failed: Server Error")
		} else {
		  dispatch(successfulAdminUpdate())
		}
	  })
	}
}
export const deleteAdmin = (id) => {
	return dispatch => {
	  dispatch(deleteAdminRequest())
	  AdminService.deleteAdmin(id).then( () => dispatch( adminDeleted() ) )
	}
}

export const login = (admin, history) => {
  return dispatch => {
    dispatch(authenticationRequest())
    AdminService.fetchLogin(admin)
      .then( r => r.json() )
      .then( json => {
		  console.log('login json: ', 'font-size:20px;background-color: #ED9EC7;color:#fff;', json);
        if(json.errors){
          dispatch(unsuccessfulFetchRequest())
        } else {
        //   dispatch( json.role === 0 ? superAuthenticationSuccess() : basicAuthenticationSuccess() )
          dispatch( adminAuthenticationSuccess( json.role ) )
          localStorage.setItem('admin', JSON.stringify(json))
          // localStorage.setItem('token', JSON.stringify(json.token))
          history.push('/')
        }
      }).catch( e => console.log(e))
  }
}

export const authenticate = (history) => {
  return dispatch => {
    dispatch(authenticationRequest());
    AdminService.refresh()
      .then(response => response.json())
      .then( json => {
          if(json.errors){
            dispatch(unsuccessfulFetchRequest())
          } else {
			// dispatch( json.admin?.role === 0 ? superAuthenticationSuccess() : basicAuthenticationSuccess() )
			dispatch( adminAuthenticationSuccess( json.admin?.role ) )
            localStorage.setItem('admin', JSON.stringify(json))
            history.push('/')
          }

      })
      // .catch(err => {
      //   console.log(err)
      //   localStorage.removeItem('admin');
      //   window.location = '/admin_login';
      // });
  }
}

export const resetPassword = (passwordObj) => {
  return dispatch => {
    dispatch(startPasswordReset())
    AdminService.resetPassword(passwordObj)
    .then( json => {
      if (json.error){
        dispatch(resetUnsuccessful(json))
      } else {
        dispatch(resetSuccessful())
      }
    })
  }
}

export const refreshLogin = () => {
  return dispatch => {
    dispatch(authenticationRequest())
    AdminService.refresh()
    .then( json => {
		// console.log('refresh json: ', 'font-size:20px;background-color: #6EC1C2;color:#fff;', json);
      if(json.error){
        dispatch(unsuccessfulFetchRequest())
      } else {
		// dispatch( json.admin?.role === 0 ? superAuthenticationSuccess() : basicAuthenticationSuccess() )
		dispatch( adminAuthenticationSuccess( json.admin?.role ) )
        localStorage.setItem('admin', JSON.stringify(json.admin))
      }
    })
  }
}

export const logout = (history) => {
  AdminService.fetchLogout()
  localStorage.removeItem('admin')
  // localStorage.removeItem('token')
  history.push('/admin_login')
  return { type: 'ADMIN_LOGOUT'}
}
