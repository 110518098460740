export default (state = [], action) => {
  switch(action.type) {

    case 'SUCCESSFUL_PORTFOLIO_MANAGERS_FETCH':
      return {
        ...state,
        pms: action.portfolioManagers,
      }

    case 'SUCCESSFUL_PORTFOLIO_MANAGER_FETCH':
      return  {
        ...state,
        pm: action.portfolioManager
      }

    case 'PM_UPDATING':
      return {
        ...state,
        updatingPm: true
      }

    case 'SUCCESSFUL_PORTFOLIO_MANAGER_ADD':
      return {
        ...state,
        pms: action.portfolioManagers
      }

    case 'SUCCESSFUL_PORTFOLIO_MANAGER_UPDATE':
      return {
        ...state,
        pm: action.portfolioManager,
        updatingPm: false,
        updateSuccessful: true,
        errors: null
      }

    case 'UNSUCCESSFUL_PORTFOLIO_MANAGER_UPDATE':
      return  {
        ...state,
        errors: action.errors,
        updatingPm: false,
        updateSuccessful: false
      }

    case 'PORTFOLIO_MANAGER_UPDATE_COMPLETE':
      return {
        ...state,
        updateSuccessful: false,
        updatingPm: false,
        errors: null
      }
          // videos: action.pm.videos,
          // managedFunds: action.pm.funds


    default:
      return state;
  }
}

// export default (state = [], action) => {
//   switch(action.type){
//     case 'SUCCESSFUL_PMS_FETCH':
//       return { pms: action.pms }
//     case 'SUCCESSFUL_PM_FETCH':
//       return {
//         pms: [...state.pms],
//         pm: {
//           pm: action.pm.pm,
//           videos: action.pm.videos,
//           managedFunds: action.pm.funds
//         }
//       }
//     default:
//       return state;
//   }
// }
