export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_INSIGHTS_FETCH':
      return {
        ...state,
        insights: action.insights,
        errors: []
      }
    case 'SUCCESSFUL_FUNDS_HOME_INSIGHTS_FETCH':
      return {
        ...state,
        fundsHomeInsights: action.fundsHomeInsights
      };
    case 'FETCHING_INSIGHTS':
      return {
        ...state,
        insights: action.insights
      }
    case 'INSIGHT_INVALID':
      return {
        ...state,
        errors: action.errors
      }
    case 'SUCCESSFUL_INSIGHT_FETCH':
      return {
        ...state,
        insight: action.insight,
        insightLoading: false
      }
    case 'FETCHING_INSIGHT':
      return {
        ...state,
        insightLoading: true
      }
    case 'CLEAR_INSIGHT':
      return {
        ...state,
        insight: null,
        success: false
      }
    case 'SUCCESSFUL_INSIGHT_POST':
      return {
        ...state,
        insight: action.insight,
        insights: action.insights,
        success: true
      }
    case 'SUCCESSFUL_INSIGHT_UPDATE':
      return {
        ...state,
        insight: action.insight,
        success: true
      }
    default:
      return state;
  }
}
