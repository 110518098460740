import { API_URL } from '../constants'


export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const SicavDocService = {
  uploadSicavDoc(doc) {
    return fetch(`${API_URL}/sicav_docs`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(doc),
      headers: headers()
    }).then( r => r.json() )
  },

  fetchSicavDocs() {
    return fetch(`${API_URL}/sicav_docs`)
    .then( r => r.json() )
  },

  updateSicavDoc(id, sicav_doc) {
    return fetch(`${API_URL}/sicav_docs/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: headers(),
      body: JSON.stringify({ sicav_doc })
    }).then( r => r.json() )
  },

  deleteSicavDoc(id) {
    return fetch(`${API_URL}/sicav_docs/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }
}

export default SicavDocService;
