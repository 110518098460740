export default (state = {}, action) => {
  switch(action.type){

    case 'SUCCESSFUL_PODCASTS_FETCH':
      return {
        ...state,
        podcasts: action.podcasts,
      }
    case 'SUCCESSFUL_NEWS_ARTICLES_FETCH':
      return {
        ...state,
        articles: action.articles,
      }
    case 'SUCCESSFUL_ARTICLE_UPDATE':
      return {
        ...state,
        articles: action.articles,
        successfulUpload: true
      }
    case 'RESET_ARTICLE_UPDATE':
      return {
        ...state,
        successfulUpload: false,
        isUploading: false
      }
    // case 'REPLACE_NEWS_ARTICLE':
    //   const index = state.articleIndex(article => article.id === action.article.id)
    //     return [
    //       ...state.slice(0, index),
    //       action.article,
    //       ...state.slice(index + 1)
    //     ];
    case 'POST_NEWS_ARTICLE':
      return {
        ...state,
        isUploading: true,
        successfulUpload: false
      }
    case 'UPLOADING_ARTICLE':
      return {
        isUploading: true,
        successfulUpload: false
      }
    case 'SUCCESSFUL_ARTICLE_UPLOAD':
      return {
        ...state,
        successfulUpload: true,
        isUploading: false
      }
    case 'ARTICLE_UPLOAD_COMPLETE':
      return {
        successfulUpload: false,
        isUploading: false
      }
    case 'ARTICLE_POST_COMPLETE':
      return {
        successfulArticleAdd: false,
      }
    case 'FAILED_ARTICLE_UPLOAD':
      return {
        ...state,
        errors: action.article.errors
      }
    default:
      return state;

  }
}
