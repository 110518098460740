const toCsv = ( arrayOfRows, columnsToInclude = Object.keys( arrayOfRows[ 0 ] ) ) => {
    return [ columnsToInclude.join(), ...arrayOfRows.map( row => columnsToInclude.map( column => !!row[ column ] ? `"${ row[ column ] }"` : '' ).join() ) ].join( "\n" );
}

export const downloadLeadsAsSpreadsheet = ( filter, arrayOfRowsToConvert, columnsToInclude ) => {
    const element = document.createElement( "a" );
    element.setAttribute( "href", "data:text/csv;charset=UTF-8," + encodeURIComponent( toCsv( arrayOfRowsToConvert, columnsToInclude ) ) );
    element.setAttribute( "download", `${ filter.replace( /\W/ug,"" ) }-leads-${ new Date().toISOString().split( "T" )[ 0 ] }.csv` );
    element.style.display = "none";
    document.body.appendChild( element );
    element.click();
    document.body.removeChild( element );
}
