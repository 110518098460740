import React, { Component } from 'react'
import { connect } from 'react-redux'
// import CreatableSelect from 'react-select'
import { uploadMedia, uploadComplete } from '../../redux/modules/MediaUploads/actions'
import { fetchTags } from '../../redux/modules/Tags/actions'
import SuccessModal from './SuccessModal'
import S3Uploader from './S3Uploader'


const createOption = (label) => ({
  label,
  value: label
})

class AdminUpload extends Component {
  constructor(props){
    super(props)
    //const whichForms = getWhichForms(props)
    this.state = {
       inputValue: '',
       value: [],
       formData: {
         title: "",
         author: "",
         category: "",
         company: ""
       },
       pathname: "",
       showSubmit: false
     }
  }

  componentDidMount(){
    this.props.fetchTags()
  }

  handleFormChange = (e) => {
    const { formData } = this.state
    formData[e.target.name] = e.target.value
    this.setState({ formData })
  }

  handleChange = (value, actionMeta) => {
    this.setState({ value })
  }

  handleInputChange = (inputValue) => {
    this.setState({ inputValue })
  }

  handleKeyDown = (event) => {
    const { inputValue, value } = this.state
    if(!inputValue) return;
    switch(event.key){
      case 'Enter':
      case 'Tab':
        this.setState({
          inputValue: '',
          value: [...value, createOption(inputValue) ]
        })
        event.preventDefault()
        break;
      default:
        return null
    }
  }

  handleResponse = (response) => {
    this.setState({ pathname: response.publicUrl })
  }

  handleProgress = (progress) => {
    if(progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = () => {
    const { formData, value, pathname } = this.state
    const mediaObj = {
      ...formData,
      tag_names: value,
      pathname
    }
    const hasEmptyValues = Object.values(mediaObj).some( val => val === "" || val.length === 0 )
    if(!!hasEmptyValues) {
      document.getElementById("upload-failed").style.display = "inline-block"
    } else {
      document.getElementById("upload-failed").style.display = "none"
      this.props.uploadMedia(mediaObj)
      this.setState({
        inputValue: '',
        value: [],
        formData: {
          title: "",
          author: "",
          category: "",
        },
        pathname: "",
        showSubmit: false
      })
    }
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  render(){
    // let options
    // if (this.props.tags){
    //   options = this.props.tags.map( tag => {
    //     return { label: tag.name, value: tag.id }
    //   })
    // }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Media successfully uploaded"}
        />
        <div id="upload-form-container">
          <h3>Upload Media (PDFs)</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          <form id="admin-upload-form">
            <label className="upload-input-label">Document Title</label>
            <input name="title" className="media-info-input" onChange={this.handleFormChange} value={this.state.formData.title}></input>
            <label className="upload-input-label">Author</label>
            <input name="author" className="media-info-input" onChange={this.handleFormChange} value={this.state.formData.author}></input>
            <label className="upload-input-label">Select a Category</label>
            <select name="category" className="media-info-input" onChange={this.handleFormChange} value={this.state.formData.category}>
              <option></option>
              <option value="insight">Insight</option>
              <option value="whitepaper">White paper</option>
              <option value="webinar">Webinar</option>
            </select>
            <label className="upload-input-label">Select a Company</label>
            <select name="company" className="media-info-input" onChange={this.handleFormChange} value={this.state.formData.company}>
              <option></option>
              <option value="GAMCO Asset Management">GAMCO Asset Management</option>
              <option value="Gabelli Funds">Gabelli Funds</option>
              <option value="GAMCO/Gabelli">Both</option>
            </select>
          </form>
          <label className="upload-input-label s3-upload-label">Upload the File</label>
          <S3Uploader
            handleResponse={this.handleResponse}
            accept='application/pdf'
            apiPath="media_filepaths/s3/sign"
            params={{}}
          />
          <button id="upload-button" type="button" onClick={this.handleSubmit} disabled={ this.state.showSubmit ? "" : "disabled" }>Submit</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tags: state.tags,
  isUploading: state.media.isUploading,
  successfulUpload: state.media.successfulUpload
})

export default connect(mapStateToProps, { fetchTags, uploadMedia, uploadComplete })(AdminUpload)
