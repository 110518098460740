import React from 'react'

const PmCard = (props) => {
  const person = props.pm
  let yearsWithCompany = props.currentYear - person.start_year
  if (person.name === "Vincent Hugonnard-Roche") { yearsWithCompany = (new Date().getFullYear() - 2000) }
  return(
    <div className="pm-profile-card" onClick={ () => props.showPM(person.id) }>
      <div className="pm-card-text">
        <h5 className="pm-funds-name" to={"/portfolio_managers/" + person.id}>{person.name}</h5>
        <span className="pm-title"> {person.title}</span>
        <p className="years-p-tag"><span className="years-with-gamco">{yearsWithCompany}</span> Years of Industry Experience</p>
      </div>
      <div className="img-container">
        <img src={`https://s3.us-east-2.amazonaws.com/gab-images/${person.img}`} alt={person.name}/>
      </div>
    </div>
  )
}



export default PmCard
