import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Spinner, Position, Toaster, Intent } from '@blueprintjs/core'
import {
  fetchPortfolioManagers,
  fetchPortfolioManager,
  completePortfolioManagerUpdate
} from '../../redux/modules/PortfolioManagers/actions';

import AdminPmCard from './AdminPmCard'
import PortfolioManagerEdit from './PortfolioManagerEdit'

const AppToaster = Toaster.create({
  className: "pm-toaster",
  position: Position.TOP,
  timeout: 0
})

class PortfolioManagerAdmin extends Component {
  constructor(){
    super()
    this.state = {
      showModal: false,
      filterTerm: ""
    }
  }

  componentDidMount() {
    this.props.fetchPortfolioManagers()
    if(this.props.pmId){
      this.props.fetchPortfolioManager(this.props.pmId)
      this.setState({ showModal: true })
    }
  }

  componentDidUpdate(prevProps){
    if (this.props.updateSuccessful) {
      AppToaster.show({ message: "PM Update Successful", intent: Intent.SUCCESS })
      this.props.completePortfolioManagerUpdate()
    } else if (this.props.portfolioManagers.length < prevProps.portfolioManagers.length){
      AppToaster.show({ message: "PM Deleted", intent: Intent.DANGER})
    }
  }

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal,
      filterTerm: ""
    })
  }

  handleHide = () => {
    this.toggleModal()
    this.props.history.push("/admin/portfolio-manager-admin")
  }

  handleClick = (pm) => {
    this.props.fetchPortfolioManager(pm.id)
    this.props.history.push(`/admin/portfolio-manager-admin/${pm.id}/edit`)
    this.toggleModal()
  }

  handleFilter = (e) => {
    const { value } = e.target
    this.setState({ filterTerm: value.toLowerCase() })
  }

  filterPortfolioManagers = (portfolioManagers) => {
      const currentYear = new Date().getFullYear()
      const filtered = portfolioManagers.filter( pm => {
        return pm.name.toLowerCase().includes(this.state.filterTerm)
      }).map(pm => <AdminPmCard pm={pm} handleClick={this.handleClick} currentYear={currentYear} key={pm.id}/>)
      return filtered
  }

  render() {
    const { portfolioManagers } = this.props
    const filteredPms = this.filterPortfolioManagers(portfolioManagers)
    return(
      <div id="admin-pm-container">
        <PortfolioManagerEdit
          portfolioManager={this.props.portfolioManager}
          handleHide={this.handleHide}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
        />
        {
          !portfolioManagers.length ? <Spinner size={100} className="funds-spinner"/> :
          <div id="pm-card-container" className="admin-pms-container">
            <h3>Select a Portfolio Manager</h3>
            <form id="filter-form" onSubmit={ e => e.preventDefault() }>
              <input id="search-bar" onChange={this.handleFilter} value={this.state.filterTerm} type="text" placeholder="Search"/>
            </form>
            <div className="pm-profile-card new-admin-card" onClick={ () => this.props.history.push("/admin/portfolio-manager-admin/new")}>
              <div className="pm-card-text">
                <h5 className="pm-funds-name">Create New</h5>
              </div>
              <div className="img-container">
                <span className="hover-inverse"></span>
                <img className="pm-img-placeholder" src='https://s3.us-east-2.amazonaws.com/gab-images/headshot_placeholder.jpg' alt="add new"/>
              </div>
            </div>
            {filteredPms}
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  portfolioManagers: state.portfolioManagers.pms || [],
  portfolioManager: state.portfolioManagers.pm || [],
  updateSuccessful: state.portfolioManagers.updateSuccessful || false
})

export default withRouter(connect(mapStateToProps, {
  fetchPortfolioManagers,
  fetchPortfolioManager,
  completePortfolioManagerUpdate
})(PortfolioManagerAdmin));
