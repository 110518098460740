import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Spinner } from '@blueprintjs/core'
import S3Uploader from './S3Uploader'
import { addTeam } from '../../redux/modules/Teams/actions'

class TeamsNew extends Component {
  constructor(){
    super()

    this.state = {
      name: "",
      summary: "",
      image_url: "",
      showSubmit: false
    }
  }

  componentDidUpdate(prevProps){
    if (prevProps.team.id !== this.props.team.id) {
      this.props.history.push(`/admin/teams/${this.props.team.id}`)
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.addTeam({ team: this.state })
    this.props.history.push("/admin/teams")
  }

  handleResponse = (response) => {
    this.setState({ image_url: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100) {
      this.setState({ showSubmit: true })
    }
  }

  render(){
    // const { errors } = this.props

    return(
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>New PM Team</h3>
          <span id="upload-failed" >Creation failed. All fields are required.</span>
          {
            this.props.currentlyUpdating ? <Spinner size={100} className="funds-spinner"/> :
            <form id="admin-upload-form" onSubmit={this.handleSubmit}>
              <label className="upload-input-label">Name:</label>
              <br />
              <input className="media-info-input" onChange={this.handleChange} name="name"type='text' value={this.state.name}/>
              <br />
              <label className="upload-input-label">Summary:</label>
              <br />
              <textarea className="media-info-input" onChange={this.handleChange} name="summary" value={this.state.summary}/>
              <br />
              <label className="upload-input-label s3-upload-label">Upload Group Photo:</label>
              <S3Uploader
                handleResponse={this.handleResponse}
                accept='image/*'
                apiPath="teams/s3/sign"
                handleProgress={this.handleProgress}
              />
              <input id="upload-button" type="submit" value="Create" disabled={ this.state.showSubmit ? "" : "disabled" }/>
            </form>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  team: state.teams.team || {}
})

export default withRouter(connect(mapStateToProps, { addTeam })(TeamsNew))
