import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { fetchTeams, updateTeam, deleteTeam } from '../../redux/modules/Teams/actions'
import { fetchPortfolioManagers } from '../../redux/modules/PortfolioManagers/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import TeamCard from './TeamCard'

class TeamsAdmin extends Component {

  componentDidMount(){
    this.props.fetchTeams()
    this.props.fetchPortfolioManagers()
    this.props.fetchFunds()
  }

  updateTeam = (id, data) => {
    this.props.updateTeam(id, data)
  }

  deleteTeam = (id) => {
    const confirmed = window.confirm("Are you sure you would like to delete this team?")
    if(confirmed){
      this.props.deleteTeam(id)
    }
  }

  render(){
    const teams = this.props.teams.map( team => {
      return (
        <TeamCard
          team={team}
          updateTeam={this.updateTeam}
          deleteTeam={this.deleteTeam}
          portfolioManagers={this.props.portfolioManagers || []}
          funds={this.props.funds}
        />
      )
    })
    return(
      <div id="admin-upload-container">
        <div id="carousel-card-container">
        <button
          className="new-team-button"
          onClick={() => this.props.history.push("/admin/teams/new")}>
          Add New Team
        </button>
          {teams}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  teams: state.teams,
  portfolioManagers: state.portfolioManagers.pms,
  funds: state.funds
})

export default withRouter(connect(mapStateToProps, { fetchTeams, updateTeam, deleteTeam, fetchPortfolioManagers, fetchFunds })(TeamsAdmin))
