import React from 'react'

const CodeError = props => {
  return (
    <div>
      <div>
        Sorry! There was an error.
      </div>
      <div>
        Click <a href="/">here</a> to return to the homepage.
      </div>
    </div>
  )
}

export default CodeError
