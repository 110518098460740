import React from 'react'
import FeaturedFunds from '../FeaturedFunds/index'

const FeaturedFundLanding = (props) => {
  const { changeFundType } = props
  return(
    <div id="ff-select-landing">
      <h2 id="ff-landing-intro">Which funds would you like to edit?</h2>
      <button id="open-ends-btn" className="ff-select-btn" value="Open End" onClick={changeFundType}>
        Open End
      </button>
      <button id="closed-ends-btn" className="ff-select-btn" value="Closed End" onClick={changeFundType}>
        Closed End
      </button>
      <div className="hidden-funds" id="open-ends-preview">
        <FeaturedFunds fundType="open_end"/>
      </div>
      <div className="hidden-funds" id="closed-ends-preview">
        <FeaturedFunds fundType="closed_end"/>
      </div>
    </div>
  )
}

export default FeaturedFundLanding
