import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import Select from '../../components/Select'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { fetchAllClosedEndFunds } from '../../redux/modules/Funds/actions'
import { addNewPreferredDoc, preferredDocUploadComplete } from '../../redux/modules/PreferredIssues/actions'
import S3Uploader from './S3Uploader'
import SuccessModal from './SuccessModal'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class PreferredDocUploader extends Component {
  constructor(){
    super()

    const adminName = JSON.parse(localStorage.getItem('admin'))['name']
    this.state = {
      name: "",
      fund_code: null,
      filepath: "",
      doc_type: "",
      uploaded_by: adminName,
      doc_date: null,
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchAllClosedEndFunds()
  }

  handleClose = () => {
    this.props.preferredDocUploadComplete()
  }

  handleChange = (e) => {
    const { state } = this
    const { name, value } = e.target
    state[name] = value
    this.setState(state)
  }

  handleDayChange = (date) => {
    this.setState({
      doc_date: date
    })
  }

  handleSelectChange = (e) => {
    if(e){
      this.setState({
        fund_code: e.value
      })
    } else {
      this.setState({
        fund_code: null
      })
    }
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
	const { name, fund_code, filepath, doc_type, doc_date } = this.state;
    if ( !name || !fund_code || !filepath || !doc_type || !doc_date ) document.getElementById("upload-failed").style.display = "inline-block";
    else {
      document.getElementById( "upload-failed" ).style.display = "none"
      this.props.addNewPreferredDoc( { name, fund_code, filepath, doc_type, doc_date } );
      this.setState( {
        name: "",
        fund_code: null,
        filepath: "",
        doc_type: "",
        doc_date: null,
        showSubmit: false
      } );
      document.getElementsByName( "s3-uploader" )[ 0 ].value = '';
    }
  }

  render(){
    const { funds } = this.props
    // const { errors } = this.props.preferredDoc
    let options
    if(funds){
      options = funds.map( fund => ({
        value: fund.fund_code, label: fund.fundshortname
      }))
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Document report successfully added"}
        />
        <div id="upload-form-container">
          <h3>Preferred Issue Document Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { /*!!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null*/ }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Select Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.fund_code}
            />
            <br />
            <label className="upload-input-label">Document Name:</label>
            <br />
            <input onChange={this.handleChange} name="name" className="media-info-input admin-input" value={this.state.name}></input>
            <br />
            <label className="upload-input-label">Report Date:</label>
            <br />
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input admin-input"
              value={this.state.doc_date || "YYYY-M-D"}
            />
            <br />
            <label className="upload-input-label">Document Type:</label>
            <br />
            <select onChange={this.handleChange} name="doc_type" className="media-info-input admin-select" value={this.state.doc_type}>
              <option></option>
              <option value="Prospectus">Prospectus</option>
              <option value="Prospectus Supplement">Prospectus Supplment</option>
              <option value="SAI">Statement of Additional Information</option>
              <option value="Statement of Preferences">Statement of Preferences</option>
              <option value="Other">Other</option>
            </select>
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={{ "doc_type": "preferred_doc" }}
              apiPath="preferred_docs/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  funds: state.funds,
  isUploading: state.prefDocs.isUploading || false,
  successfulUpload: state.prefDocs.successfulUpload || false
})

export default withRouter(connect(mapStateToProps, { fetchAllClosedEndFunds, addNewPreferredDoc, preferredDocUploadComplete })(PreferredDocUploader))
