import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Icon } from '@blueprintjs/core'
import Insight from '../Insights/Insight'
import { insightsFetch } from '../../redux/modules/Insights/actions'

class AdminInsights extends Component {

  componentDidMount(){
    this.props.insightsFetch()
  }

  handleClick = (id) => {
    this.props.history.push(`/admin/insights/${id}/edit`)
  }

  render(){
    const { insights } = this.props
    const insightCards = insights.map( i => {
      return(
        <div className="admin-edit-insight" key={i.id} onClick={ () => this.handleClick(i["s3_key"]) }>
          <div className="insight-overlay">
            <p>
              Click to Edit
              <br />
              <Icon icon="edit" iconSize={Icon.SIZE_LARGE}color="white"/>
            </p>
          </div>
          <Insight insight={i} />
        </div>
      )
    })
    return(
        <div id="admin-insight-container">
          <h3>Select an Insight</h3>
          {insightCards}
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
  insights: state.insights.insights || []
})

export default withRouter(connect(mapStateToProps, { insightsFetch })(AdminInsights))
