export default (state = [], action) => {
  switch(action.type){
    case 'SEARCHING':
      return {
        searching: true
      }
    case 'SUCCESSFUL_SEARCH_RESULTS_FETCH':
      return {
        results: action.search.results,
        searchTerm: action.search.search_term,
        searching: false
      }
    default:
      return state
  }
}
