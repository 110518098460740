import React, { Component } from 'react'
import { Icon, Tooltip } from '@blueprintjs/core'


class BlockStyleButton extends Component {

  onToggle = (e) => {
    e.preventDefault()
    this.props.onToggle(this.props.style)
  }

  listIcon = (label) => {
    return label === 'OL' ? <Icon icon="numbered-list"  color="#6dcff6"/> : <Icon icon="list" color="#6dcff6" />
  }
  render() {
    let className = "RichEditor-styleButton"
    if(this.props.active) {
      className += " RichEditor-activeButton"
    }
    const { label } = this.props
    return(
      <button className={className} onClick={this.onToggle}>
        <Tooltip content={label === "OL" ? "Ordered List" : "Unordered List"} className="editor-btn-tooltip">
          {this.listIcon(label)}
        </Tooltip>
      </button>
    )
  }
}

export default BlockStyleButton
