export default ( state = {}, action ) => {
	switch( action.type ) {
		case 'SUCCESSFUL_COMMENTARIES_FETCH':
			return {
				...state,
				commentaries: action.commentaries,
				errors: []
			}
		case 'SUCCESSFUL_COMMENTARY_FETCH':
		case 'SUCCESSFUL_COMMENTARY_POST':
		case 'SUCCESSFUL_COMMENTARY_PATCH':
		case 'SUCCESSFUL_COMMENTARY_DELETE':
			return {
				...state,
				commentary: action.commentary,
				errors: []
			}
		case 'FETCHING_COMMENTARIES':
			return {
				...state,
				commentaries: action.commentaries
			}
		default:
			return state;
	}
};
  