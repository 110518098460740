import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { fetchArticles, updateArticle, importArticles } from '../../redux/modules/Media/actions'
import AdminArticleCard from '../Media/AdminArticleCard'
import MediaAdminNav from './MediaAdminNav'
import EditMedia from './EditMedia'

class MediaAdminNews extends Component {
  constructor(){
    super()
      this.state = {
        selected: null,
      }
  }
  componentDidMount = () => {
    this.props.fetchArticles()
  }

  componentDidUpdate = prevProps => {
    if(!prevProps.success && !!this.props.success){
      this.setState({ selected: null })
    }
  }

  handleRefresh = () => {
    this.props.importArticles()
  }

  handleUpdateArticle = article => {
    this.props.updateArticle(article)
  }

  handleClick = id => {
    const selected = this.props.articles.find( a => a.id === id)
    this.setState({ selected })
  }

  clearSelected = e => {
    this.setState({ selected: null })
  }

  render (){
    const articles = this.props.articles.map(a => {
      return <AdminArticleCard article={ a } handleUpdateArticle={this.handleUpdateArticle} handleClick={() => this.handleClick(a.id)}/>
      }
    )
    return (
      <div id='below-search'>
        <MediaAdminNav />
        <EditMedia
          selected={this.state.selected}
          clearSelected={this.clearSelected}
          handleUpdateArticle={this.handleUpdateArticle}
        />
        <div className="article-buttons">
          <div className="refresh-button">
            <span onClick={this.handleRefresh}>Refresh</span>
          </div>
          <ArticleBtnDiv className="refresh-button">
            <NavLink to='/admin/media-admin/article_entry'><AddArticleBtn>Add Article</AddArticleBtn></NavLink>
          </ArticleBtnDiv>
        </div>

        <div className='article-div'>
          { articles }
        </div>
      </div>
    )
  }


}

const mapStateToProps = (state) => {
  return({
    articles: state.articles.articles || [],
    success: state.articles.successfulUpload
  })
}

export default connect(mapStateToProps, {fetchArticles, updateArticle, importArticles})(MediaAdminNews);

const ArticleBtnDiv = styled.span `
  &:hover {
    span {
      color: #005b97;
    }
  }
`

const AddArticleBtn = styled.span `
  color: white;
`
