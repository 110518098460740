import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { fetchTvAppearances, updateTvAppearance } from '../../redux/modules/TVAppearances/actions'
import AdminTvAppearanceCard from '../Media/AdminTvAppearanceCard'
import MediaAdminNav from './MediaAdminNav'
import EditMedia from './EditMedia'

class MediaAdminTv extends Component {
  constructor(){
    super()
      this.state = {
        selected: null
      }
  }
  componentDidMount = () => {
    this.props.fetchTvAppearances()
  }

  componentDidUpdate = prevProps => {
    if(!prevProps.success && !!this.props.success){
      this.setState({ selected: null })
    }
  }

  handleUpdateTvAppearance = tv => {
    this.props.updateTvAppearance(tv)
  }

  handleClick = id => {
    const selected = this.props.tvAppearances.find( a => a.id === id )
    this.setState({ selected })
  }

  clearSelected = e => {
    this.setState({ selected: null })
  }

  render (){
    const tvs = this.props.tvAppearances.filter(tv => tv.source !== "GabelliTV").map(t => (
      <AdminTvAppearanceCard tv={ t } handleUpdateTvAppearance={this.handleUpdateTvAppearance} handleClick={() => this.handleClick(t.id)}/>
    ))
    return (
      <div id='below-search'>
        <MediaAdminNav />
        <EditMedia
          selected={this.state.selected}
          clearSelected={this.clearSelected}
          handleUpdateArticle={this.handleUpdateTvAppearance}
          isTV={true}
        />
        <div className="article-buttons">
          <div className="refresh-button">
            <NavLink to='/admin/media-admin/tv_entry'><AddTvBtn>Add TV Appearance</AddTvBtn></NavLink>
          </div>
        </div>

        <div className='article-div'>
          { tvs }
        </div>
      </div>
    )
  }


}

const mapStateToProps = (state) => ({
  tvAppearances: state.tvAppearances.tvAppearances || [],
  success: state.tvAppearances.successfulUpload
})

export default connect(mapStateToProps, {fetchTvAppearances, updateTvAppearance})(MediaAdminTv);

const AddTvBtn = styled.span `
  color: white;
`
