import React, { Component } from 'react'
import S3Uploader from './S3Uploader'
import SuccessModal from './SuccessModal'

class LevAnalysisUpload extends Component {
  constructor(){
    super()

    this.state = {
      success: false
    }
  }

  handleResponse = () => {
    this.setState({ success: true })
  }

  handleClose = () => {
    this.setState({ success: false })
  }

  render(){
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={this.state.success}
          handleClose={this.handleClose}
          phrase={"Leverage Analysis successfully updated"}
        />
        <div id="upload-form-container">
          <h3>Leverage Analysis Upload</h3>
          <S3Uploader
            params={{}}
            accept='application/pdf'
            apiPath="uploads/s3/lev_analysis"
            handleResponse={this.handleResponse}
          />
        </div>
      </div>
    )
  }
}



export default LevAnalysisUpload
