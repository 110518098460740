import React, { Component } from 'react';
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
// import { Field, reduxForm } from 'redux-form'
import Toggle from 'react-toggle'
import { updateArticle } from '../../redux/modules/Media/actions'
import './media-theme.css';


class AdminArticleCard extends Component {
  constructor(props) {
    super(props)
    const adminName = JSON.parse(localStorage.getItem('admin'))['name']
    this.state = {
      display: props.article.display,
      modified_by: adminName,
    }
  }

  componentDidMount = () => {
    this.setState({
      ...this.props.article
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.display !== this.state.display){
      this.updateArticle()
    }
  }

  toggleDisplay = () => {
    this.setState({ display: !this.state.display})

  }

  updateArticle() {
    const article = this.state
    this.props.updateArticle(article)
  }




  render() {
    const { article, handleClick } = this.props
    return(
      <div className="admin-article-card" >
        <div onClick={handleClick}>
          <img className="article-image" src={article.image_url} alt={article.title}/>
          <div className="admin-article-info">
            <span className="video-title">{article.title}</span>
            <p>{article.source}</p>
            <p>{article.release_date}</p>
            <p>{article.article_description}</p>
          </div>
        </div>
        <div>

          <Toggle
            defaultChecked={this.state.display}
            onChange={this.toggleDisplay}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // article: state.article
  }
}

export default connect(mapStateToProps, {updateArticle})(AdminArticleCard);

// export default reduxForm({
//   form: 'updateArticle'
// })AdminArticleCard;
