import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import { fetchWishlist, updateStatus } from '../../../redux/modules/Features/actions'


class WishlistItem extends Component {
  constructor(props){
    super(props)
    this.state = {
      status: "",
    }
  }

  componentDidMount(){
    if(!this.props.wishlist.length) {
      this.props.fetchWishlist()
    }
  }


  handleSelect = e => {
    e.preventDefault()
    const { id } = this.props.matchParams
    const { value } = e.target
    this.setState({ status: value })
    this.props.updateStatus(id, value)
  }

  render(){
    const { id } = this.props.matchParams
    const feature = this.props.wishlist.find( item => item.id === parseInt(id) )
    const displayDate = (date) => {
      const dateObj = new Date(date)
      return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`
    }
    if (!feature) {
      return (
        <div id="admin-upload-container">
          <Spinner size={100} className="funds-spinner" />
        </div>
      )
    }
    return (
      <div id="admin-upload-container">
        <div id="upload-form-container">
          <h3>{feature.feature}</h3>
          <div>
            <select
              className="media-info-input"
              onChange={this.handleSelect}
              value={this.state.status || feature.status}
            >
              <option value="complete">Complete</option>
              <option value="ip">In Progress</option>
              <option value="new">New</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
          <p><span className="request-prop">Date:</span> {displayDate(feature['created_at'])}</p>
          <p><span className="request-prop">Requested by:</span> {feature.admin['full_name']}</p>
          <div>
            <h5><span className="request-prop">Description:</span></h5>
            <p>{feature.description}</p>
          </div>
          <Link to="/admin/wishlist" style={{ color: '#00aeef' }}>Return to Wishlist</Link>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  wishlist: state.features.wishlist || []
})

export default connect(mapStateToProps, { fetchWishlist, updateStatus })(WishlistItem)
