import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {
	Icon,
	Tooltip
} from '@blueprintjs/core'
import {
	Editor,
	EditorState,
	RichUtils,
	CompositeDecorator,
	AtomicBlockUtils,
	convertFromRaw,
	convertToRaw,
} from 'draft-js';
import {
	fetchS3Commentary,
	postS3Commentary,
	updateS3Commentary,
	deleteS3Commentary,
} from '../../redux/modules/S3Commentaries/actions';

const S3CommentariesEditor = ( props ) => {

	const dispatch = useDispatch();

	const history = useHistory();
	const isNew = useLocation().pathname.search( /new/ ) > 0;
	
	const { commentary: commentaryToEdit } = useSelector( state => state.s3Commentaries );
	
	const decorator = new CompositeDecorator( [ {
		strategy: findLinkEntities,
        component: Link,
	} ] );

	useEffect( () => {
		if ( !!props.matchParams.id ) dispatch( fetchS3Commentary( props.matchParams.id ) );
	}, [ dispatch, props.matchParams.id ] );

	useEffect( () => {
		if ( !!commentaryToEdit && !isNew ) setState( {
			...state,
			editorState: EditorState.createWithContent( convertFromRaw( commentaryToEdit.content ), decorator ),
			commentary: {
				...state.commentary,
				title: commentaryToEdit.title,
				description: commentaryToEdit.description,
				category: commentaryToEdit.category,
			},
		} );
	}, [ commentaryToEdit ] );

	const editorRef = useRef();
	// const selectRef = useRef();

	const [ state, setState ] = useState( {
		editorState: EditorState.createEmpty( decorator ),
		showInfoForm: false,
		showAddImage: false,
		showAddLink: false,
		showSubmit: false,
		imageURL: "",
		linkURL: "",
		showSuccess: false,
		commentary: {
		  title: '',
		  author: [],
		  date: '',
		  caption: '',
		  description: '',
		  photo: '',
		  pdf_link: '',
		  category: '',
		  display: ''
		}
	} );

	// console.log( state );
	// console.log( convertToRaw( state.editorState.getCurrentContent() ) );

	const onChange = editorState => setState( { ...state, editorState } );

	const onCommentaryStateChange = changeEvent => setState( { ...state, commentary: { ...state.commentary, [ changeEvent.target.name ]: changeEvent.target.value } } );

	const toggleInlineStyle = style => onChange( RichUtils.toggleInlineStyle( state.editorState, style ) );
	
	const toggleBlockType = blockType => onChange( RichUtils.toggleBlockType( state.editorState, blockType ) );
	
	const promptForImage = () => {
		setState( {
			...state,
			showAddImage: true,
			showAddLink: false,
			imageURL: ""
		} );
	};

	const onURLChange = urlChangeEvent => setState( { ...state, imageURL: urlChangeEvent.target.value } );

	const onURLInputKeyDown = keyDownEvent => {
		if ( keyDownEvent.which === 13 ) confirmMedia( keyDownEvent );
	};

	const confirmMedia = confirmEvent => {
		confirmEvent.preventDefault();
		const { editorState, imageURL } = state;
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity(
			"image",
			'IMMUTABLE',
			{ src: imageURL }
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set(
			editorState,
			{ currentContent: contentStateWithEntity }
		);
		setState( {
			...state,
			editorState: AtomicBlockUtils.insertAtomicBlock(
				newEditorState,
				entityKey,
				" "
			),
			showAddImage: false,
			imageURL: "",
		} );
	};

	const promptForLink = promptEvent => {
		promptEvent.preventDefault();
		const { editorState } = state;
		const selection = editorState.getSelection();
		if ( !selection.isCollapsed() ) {
			const contentState = editorState.getCurrentContent();
			const startKey = editorState.getSelection().getStartKey();
			const startOffset = editorState.getSelection().getStartOffset();
			const blockWithLinkAtBeginning = contentState.getBlockForKey( startKey );
			const linkKey = blockWithLinkAtBeginning.getEntityAt( startOffset );
			let url = '';
			if ( linkKey ) {
				const linkInstance = contentState.getEntity( linkKey );
				url = linkInstance.getData().url;
			}
			setState( {
				...state,
				showAddLink: true,
				showAddImage: false,
				linkURL: url
			} );
		}
	};

	const confirmLink = confirmEvent => {
		confirmEvent.preventDefault();
		const { editorState, linkURL } = state;
		const contentState = editorState.getCurrentContent();
		const contentStateWithEntity = contentState.createEntity(
			'LINK',
			'MUTABLE',
			{ url: linkURL }
		);
		const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
		const newEditorState = EditorState.set( editorState, { currentContent: contentStateWithEntity } );
		setState( {
			...state,
			editorState: RichUtils.toggleLink(
				newEditorState,
				newEditorState.getSelection(),
				entityKey
			),
			showURLInput: false,
			linkURL: ''
		} );
	};

	const onLinkInputKeyDown = keyDownEvent => {
		if ( keyDownEvent.which === 13 ) confirmLink( keyDownEvent );
	}

	const onLinkChange = linkChangeEvent => setState( { ...state, linkURL: linkChangeEvent.target.value } );

	// const removeLink = removeLinkEvent => {
	// 	removeLinkEvent.preventDefault();
	// 	const { editorState } = state;
	// 	const selection = editorState.getSelection();
	// 	if ( !selection.isCollapsed() ) setState( {
	// 		...state,
	// 		editorState: RichUtils.toggleLink( editorState, selection, null )
	// 	} );
	// };

	const handleKeyCommand = command => {
		const newState = RichUtils.handleKeyCommand( state.editorState, command );
		if ( newState ) {
		  onChange( newState );
		  return 'handled';
		}
		return 'not-handled'
	};

	const handleSave = () => {
		if ( isNew ) {
			dispatch( postS3Commentary( {
				...state.commentary,
				content: convertToRaw( state.editorState.getCurrentContent() )
			} ) );
			history.push( '/admin/s3_commentaries?success=Created successfully!' )
		}
		else {
			dispatch( updateS3Commentary( {
				...state.commentary,
				s3_key: commentaryToEdit.s3_key,
				content: convertToRaw( state.editorState.getCurrentContent() )
			} ) );
			history.push( '/admin/s3_commentaries?success=Saved successfully!' )
		}
	};

	const handleDelete = () => {
		const confirm = window.confirm( `Are you sure you want to delete this commentary "${ state.commentary.title }"? This action can't be undone!` );
		if ( confirm ) {
			dispatch( deleteS3Commentary( commentaryToEdit.s3_key ) );
			history.push( '/admin/s3_commentaries?success=Deleted successfully.' )
		}
	};

	return <>
	
		<div id='editor'>
			<div className="toolbar">

				{ state.showAddImage && <div className="url-prompt">
					<input
						className="url-input"
						placeholder="Image URL"
						onChange={ onURLChange }
						type="text"
						value={ state.imageURL }
						onKeyDown={ onURLInputKeyDown }
					/>
					<button className="add-btn" onMouseDown={ confirmMedia }>
						Add Image
					</button>
				</div> }

				{ state.showAddLink && <div className="url-prompt">
					<input
						className="url-input"
						placeholder="https://..."
						onChange={ onLinkChange }
						type='text'
						value={ state.linkURL }
						onKeyDown={ onLinkInputKeyDown }
					/>
					<button className="add-btn" onMouseDown={ confirmLink }>
						Add Link
					</button>
				</div> }
				
				<DraftJSToolbar
					state={ state }
					editorState={ state.editorState }
					onBoldClick={ () => toggleInlineStyle( 'BOLD' ) }
					onItalicClick={ () => toggleInlineStyle( 'ITALIC' ) }
					onUnderlineClick={ () => toggleInlineStyle( 'UNDERLINE' ) }
					onToggle={ toggleBlockType }
					onURLChange={ onURLChange }
					onURLInputKeyDown={ onURLInputKeyDown }
					onLinkInputKeyDown={ onLinkInputKeyDown }
					onLinkChange={ onLinkChange }
					promptForImage={ promptForImage }
					promptForLink={ promptForLink }
					confirmMedia={ confirmMedia }
				/>
			
			</div>

			<Editor
				editorState={ state.editorState }
				onChange={ onChange }
				handleKeyCommand={ handleKeyCommand }
				spellCheck={ true }
				blockRendererFn={ blockRenderer }
				ref={ editorRef }
			/>

		</div>

		<form
			id="info-form"
			style={ { position: 'relative', float: 'right', width: '80vw', overflow: 'hidden' } }
			// onSubmit={ null }
		>

			<label className="upload-info-label" >Title (70 character max):</label>&nbsp;
			<input
				className="admin-input"
				name="title"
				type="text"
				onChange={ onCommentaryStateChange }
				value={ state.commentary.title }
				maxLength="70"
				required
			/>

			<label className="upload-info-label" >Banner/Thumbnail Short Description (135 character max):</label>&nbsp;
			<input
				className="admin-input"
				name="description"
				type="text"
				onChange={ onCommentaryStateChange }
				value={ state.commentary.description }
				maxLength="70"
				required
			/>

			<label className="upload-info-label" >Category:</label>
			<select
				className="index-fund-filter-select admin-select"
				name='category'
				onChange={ onCommentaryStateChange }
				value={ state.commentary.category }
			>
				<option value=""></option>
				<option value="open_ends">Mutual funds</option>
				<option value="closed_ends">Closed-End Funds</option>
				<option value="money_markets">Money Markets</option>
				<option value="etfs">ETFs</option>
				<option value="sicav">SICAVs</option>
			</select>

			<div id="done-btn-container">
				<button onClick={ handleSave } id="done" ><b>Save</b></button>
				<button onClick={ () => history.push( '/admin/s3_commentaries' ) } id="insight-back-btn"><b>Cancel</b></button>
				{ !isNew && <button onClick={ handleDelete } id="insight-back-btn" className="insight-delete-btn"><b>Delete</b></button> }
			</div>

		</form>

	
	</>;

}

const DraftJSToolbar = ( {
	state,
	promptForImage,
	promptForLink,
	onUnderlineClick,
	onBoldClick,
	onItalicClick,
	onToggle,
} ) => {

    const selection = state.editorState.getSelection();
    const blockType = state.editorState.getCurrentContent().getBlockForKey( selection.getStartKey() ).getType();

    return <div id="list-btns">

		<span className="RichEditor-controls">
			<Tooltip content="Header" className="editor-btn-tooltip">
                <button onClick={ () => onToggle( 'header-one' ) } id="header-btn">ℍ</button>
			</Tooltip>
			<Tooltip content="Bold" className="editor-btn-tooltip">
                <button onClick={ onBoldClick } id="bold-btn"><b>B</b></button>
			</Tooltip>
			<Tooltip content="Italics" className="editor-btn-tooltip">
                <button onClick={ onItalicClick } id="italics-btn"><em>I</em></button>
			</Tooltip>
			<Tooltip content="Underline" className="editor-btn-tooltip">
                <button onClick={ onUnderlineClick } id="underline-btn">U</button>
			</Tooltip>
			<Tooltip content="Add URL" className="editor-btn-tooltip">
                <button onClick={ promptForLink }><Icon icon="link" color="#6dcff6"/></button>
			</Tooltip>
			<Tooltip content="Add Image" className="editor-btn-tooltip">
				<button onClick={ promptForImage }><Icon icon="media" color="#6dcff6" /></button>
			</Tooltip>
			<button className={ `RichEditor-styleButton${ blockType === 'ordered-list-item' ? ' RichEditor-activeButton' : '' }` } onClick={ () => onToggle( 'ordered-list-item' ) }>
				<Tooltip content="Ordered List" className="editor-btn-tooltip">
					<Icon icon="numbered-list"  color="#6dcff6"/>
				</Tooltip>
			</button>
			<button className={ `RichEditor-styleButton${ blockType === 'unordered-list-item' ? ' RichEditor-activeButton' : '' }` } onClick={ () => onToggle( 'unordered-list-item' ) }>
				<Tooltip content="Unordered List" className="editor-btn-tooltip">
					<Icon icon="list"  color="#6dcff6"/>
				</Tooltip>
			</button>
		</span>

	</div>;

};

export default S3CommentariesEditor;

// DraftJS functions for adding an image/link
const blockRenderer = block => {
	if ( block.getType() === 'atomic' ) return { component: Media, editable: false };
	return null;
};

const findLinkEntities = ( contentBlock, callback, contentState ) => {
	contentBlock.findEntityRanges( char => {
		const entityKey = char.getEntity();
		return entityKey !== null && contentState.getEntity( entityKey ).getType() === 'LINK';
	}, callback );
};
  
const Image = props => <img alt={ props.alt } src={ props.src } />;
  
const Media = props => {
	const entity = props.contentState.getEntity( props.block.getEntityAt( 0 ) );
	const { src } = entity.getData();
	const type = entity.getType();
	const media = type === 'image' ? <Image src={ src } /> : null;
	return media;
};

const Link = props => {
  const { url } = props.contentState.getEntity( props.entityKey ).getData();
  return <a href={ url }>{ props.children }</a>;
};
