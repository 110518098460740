import FeaturesService from '../../../services/FeaturesService';

const submissionFailed = errors => ({
  type: 'SUBMISSION_FAILED',
  errors
})

const successfullySubmitted = json => ({
  type: 'SUBMISSION_SUCCESS',
  json
})

const successfulWishlistFetch = items => ({
  type: 'SUCCESSFUL_WISHLIST_FETCH',
  items
})

export const fetchWishlist = () => {
  return dispatch => {
    FeaturesService.fetchWishlist()
    .then( json => dispatch(successfulWishlistFetch(json)) )
  }
}

export const updateStatus = (id, status) => {
  return dispatch => {
    FeaturesService.updateStatus(id, status)
    .then( json => dispatch(successfulWishlistFetch(json)) )
  }
}

export const submitFeatureRequest = data => {
  return dispatch => {
    FeaturesService.submitRequest(data)
    .then( json => {
      if (json.errors || json.error) {
        dispatch(submissionFailed(json.errors || json.error))
      } else {
        dispatch(successfullySubmitted(json))
      }
    })
  }
}

export const resetSubmission = () => {
  return dispatch => dispatch({ type: 'RESET_FEATURE_REQUEST' })
}
