import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createPublicEvent, fetchPublicEvents } from '../../../redux/modules/PublicEvents/actions';
import CoordinatesMap, { gamcoHqCoordinates } from './CoordinatesMap';

const CreateEvent = () => {

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect( () => dispatch( fetchPublicEvents() ), [ dispatch ] );

	const [ newEventFormState, setNewEventFormState ] = useState( null );
	// const [ editMessage, setShowEditMessage ] = useState( null );
	const [ newEventCoordinates, setNewEventCoordinates ] = useState( null );

	// const flashMessage = message => {
	// 	setShowEditMessage( message );
	// 	setTimeout( () => setShowEditMessage( null ), 3000 );
	// };	
	
	const handleNewEventFormChange = ( { target } ) => setNewEventFormState( { ...newEventFormState, [ target.name ]: target.value } );

	const handleNewEventFormSubmit = formSubmissionEvent => {
		formSubmissionEvent.preventDefault();
		dispatch( createPublicEvent( {
			...newEventFormState,
			coordinates: newEventCoordinates ? Object.values( newEventCoordinates ).join() : null
		} ) );
		history.push( "/admin/events", { editMessage: 'Event added successfully!' } )
	};

	return <div style={ { fontFamily: 'Gotham' } }>

		{/* { editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> } */}

		<h1>Create New Event</h1>

		<hr />

		<form
			style={ { display: 'flex', flexFlow: 'column nowrap' } }
			onSubmit={ handleNewEventFormSubmit }
		>
			<div>
				<label htmlFor='name'>Event name:&nbsp;</label>
				<input name='name' value={ newEventFormState?.name } required onChange={ handleNewEventFormChange }></input>
				&nbsp;
				<label htmlFor='location'>Location name:&nbsp;</label>
				<input name='location' value={ newEventFormState?.location } onChange={ handleNewEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='start_date'>Event start:&nbsp;</label>
				<input name='start_date' type='date' value={ newEventFormState?.start_date } required onChange={ handleNewEventFormChange }></input>
				&nbsp;
				<input name='start_time' type='time' value={ newEventFormState?.start_time } onChange={ handleNewEventFormChange }></input>
				&nbsp;
				<label htmlFor='start_date'>Event end:&nbsp;</label>
				<input name='end_date' type='date' value={ newEventFormState?.end_date } onChange={ handleNewEventFormChange }></input>
				&nbsp;
				<input name='end_time' type='time' value={ newEventFormState?.end_time } onChange={ handleNewEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='description'>Event description:&nbsp;</label>
				<textarea name='description' cols='50' value={ newEventFormState?.description } onChange={ handleNewEventFormChange }></textarea>
			</div>

			<div>
				<label htmlFor='zoom_id'>Zoom ID:*&nbsp;</label>
				<input name='zoom_id' value={ newEventFormState?.zoom_id } maxLength={ 11 }  onChange={ handleNewEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='virtual'>Virtual?&nbsp;</label>
				<input name='virtual' type='checkbox' checked={ newEventFormState?.virtual } onChange={ () => setNewEventFormState( { ...newEventFormState, checked: !newEventFormState?.checked } ) }></input>
				&nbsp;
				<label htmlFor='link_url'>Add a link:&nbsp;</label>
				<input name='link_url' value={ newEventFormState?.link_url } onChange={ handleNewEventFormChange }></input>
				&nbsp;
				<label htmlFor='img_url'>Add an image:&nbsp;</label>
				<input name='img_url' value={ newEventFormState?.img_url } onChange={ handleNewEventFormChange }></input>
			</div>

			<div>
				<label htmlFor='show_coords'>Add event location coordinates?&nbsp;</label>
				<input name='show_coords' type='checkbox' checked={ !!newEventCoordinates } onChange={ () => setNewEventCoordinates( !!newEventCoordinates ? null : gamcoHqCoordinates ) }></input>
			</div>

			{ newEventCoordinates && <CoordinatesMap
				coordinates={ newEventCoordinates }
				setCoordinates={ setNewEventCoordinates }
			/> }

			<div><input type='submit' value='Create' /></div>

			<br /><br />
			<div style={ { color: 'red' } }>* - Zoom ID field MUST be either left blank or filled in with a valid 11-digit Zoom Meeting ID!</div>

		</form>

	</div>;

};

export default CreateEvent;
