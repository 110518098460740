import { API_URL } from '../constants'

export const headers = () => {
  const token = localStorage.getItem('token');
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer: ${token}`,
  }
}
const LeadsService = {

  fetchLeads(){
    return fetch(`${API_URL}/leads`)
    .then( r => r.json() )
  },

  exportLeads(email){
    return fetch(`${API_URL}/leads_report?email=${email}`, {
      method: 'GET',
      credentials: 'include',
      headers: headers()
    })
    .then( r => r.json() )
  }

}

export default LeadsService
