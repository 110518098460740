import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const FundsService = {
  fetchFunds() {
    return fetch(`${API_URL}/funds`)
    .then(response => response.json())
  },

  fetchAllFunds() {
    return fetch(`${API_URL}/funds_all`)
    .then(response => response.json())
  },

  fetchSicavs() {
    return fetch(`${API_URL}/funds_sicav`)
    .then(response => response.json())
  },

  fetchClosedEndsWithFilings() {
    return fetch(`${API_URL}/funds_closed_end_flagships`)
    .then(response => response.json())
  },

  fetchAllClosedEndFunds() {
    return fetch(`${API_URL}/funds_all_closed_ends`)
    .then(response => response.json())
  },

  fetchClosedEndFlagshipsWithTaxDocs() {
    return fetch(`${API_URL}/closed_ends_tax_docs`)
    .then(response => response.json())
  }
}

export default FundsService;
