export default (state = {}, action) => {
  switch(action.type){
    case 'SUCCESSFUL_IMPORTANT_DOCS_FETCH':
      return {
        ...state,
        funds: action.funds
      }
    case 'SUCCESSFUL_IMPORTANT_DOCS_UPLOAD':
      return {
        ...state,
        funds: action.funds,
        successfulUpload: true
      }
    case 'FAILED_IMPORTANT_TAX_DOC_UPLOAD':
      return {
        ...state,
        errors: action.doc.errors,
      }
    case 'IMPORTANT_INFO_UPLOAD_COMPLETE':
      return {
        ...state,
        successfulUpload: false
      }
    default:
      return state
  }
}
