import React, { Component } from 'react'
import { withRouter } from 'react-router'
import InsightInfoForm from './InsightInfoForm'
import SuccessModal from '../AdminPortal/SuccessModal'
import { connect } from 'react-redux'
import { insightsFetch } from '../../redux/modules/Insights/actions'
import { fetchPortfolioManagers } from '../../redux/modules/PortfolioManagers/actions'
import { fetchAnalysts } from '../../redux/modules/Analysts/actions'
import { loadInsight, createOrUpdateInsight, clearInsight, deleteInsight } from '../../redux/modules/Insight/actions'
import {
   Editor,
   EditorState,
   RichUtils,
   CompositeDecorator,
   AtomicBlockUtils,
   convertFromRaw,
   convertToRaw
 } from 'draft-js'
import BlockStyleToolbar from './BlockStyleToolbar'
import { Icon, Tooltip } from '@blueprintjs/core'
import './index.css'


class InsightEditor extends Component {
  constructor(props){
    super(props)
    this.decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link
      }
    ])

    let startingContent = EditorState.createEmpty(this.decorator)
    if (!!localStorage.draft && !props.matchParams.id){
      const willContinueDraft = window.confirm("Would you like to continue working on your previous draft?")
      if (willContinueDraft) {
        const draftFromLocalStorage = convertFromRaw(JSON.parse(localStorage.getItem("draft")))
        startingContent = EditorState.createWithContent(draftFromLocalStorage, this.decorator)
      }
    }

    this.state = {
      editorState: startingContent,
      showInfoForm: false,
      showAddImage: false,
      showAddLink: false,
      showSubmit: false,
      imageURL: "",
      linkURL: "",
      showSuccess: false,
      insight: {
        title: '',
        author: [],
        date: '',
        caption: '',
        description: '',
        photo: '',
        pdf_link: '',
        category: '',
        display: ''
      }
    }

    this.editorRef = React.createRef()
    this.selectRef = React.createRef()
  }

  // IF there is an Insight ID in the route (ex: "/:id/edit"), component will load Insight
  componentDidMount(){
    this.props.insightsFetch()
    this.props.fetchPortfolioManagers()
    this.props.fetchAnalysts()

    if (this.props.matchParams.id && !this.props.insight) {
      this.props.loadInsight(this.props.matchParams.id)
    }

    this.interval = setInterval( () => {
      if (this.state.editorState.getCurrentContent().hasText()) {
        const content = convertToRaw( this.state.editorState.getCurrentContent() )
        localStorage.setItem( 'draft', JSON.stringify(content) )
      }
    }, 10000)

    this.editorRef.current.focus()
  }

  componentDidUpdate(prevProps){
    const contentEmpty = !this.state.editorState.getCurrentContent().hasText()
    if (contentEmpty && this.props.insight && this.props.insight.content){
      const converted = convertFromRaw(this.props.insight.content)
      const editorState = EditorState.createWithContent(converted, this.decorator)
      const { insight } = this.props
      this.setState({ editorState, insight })
    } else if(!prevProps.location.pathname.includes("new") && this.props.location.pathname.includes("new")) {
      this.props.clearInsight()
      const editorState = EditorState.createEmpty(this.decorator)
      const insight = {
        title: '',
        author: [],
        date: '',
        caption: '',
        description: '',
        photo: '',
        pdf_link: '',
        category: ''
      }
      this.setState({ editorState, insight })
    }
    // if(!!prevProps.insights && (prevProps.insights.length + 1 === this.props.insights.length)){
    //   this.props.history.push("/admin/insights/")
    // }

    if (this.props.success && !prevProps.success) {
      this.setState({ showSuccess: true })
    }
  }

  componentWillUnmount(){
    this.props.clearInsight()
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const content = convertToRaw( this.state.editorState.getCurrentContent() )
    const { insight } = this.state
    insight.content = content
    const insightId = this.props.matchParams.id || null
    this.props.createOrUpdateInsight(insight, insightId)
    clearInterval(this.interval)
    localStorage.removeItem('draft')
    // this.props.history.push("/admin/insights/")
  }

  handleDeleteInsight = id => {
    this.props.deleteInsight(id)
  }

  handleClose = () => {
    this.setState({ showSuccess: false })
    this.props.clearInsight()
    if (this.props.location.pathname.includes('new')) {
      this.props.history.push('/admin/insights')
    }
  }

  // Methods below toggle the info form with article details (title, author, etc.)
  showInfoForm = () => {
    if( this.state.editorState.getCurrentContent().hasText() ){
      this.setState({ showInfoForm: true })
    } else {
      alert("Article content cannot be blank!")
    }
  }

  handleBack = () => {
    this.setState({ showInfoForm: false })
  }

  handleProgress = (progress) => {
    if(progress === 100 && this.state.pdf_link) {
      this.setState({ showSubmit: true })
    }
  }

  handlePdfProgress = progress => {
    if(progress === 100 && this.state.photo) {
      this.setState({ showSubmit: true })
    }
  }

  // Handles changes on info form
  handleInfoChange = (e) => {
    const insight = this.state.insight || {}
    const { target } = e
    if(target.name.includes("Blueprint")){
      insight.display = target.value
    } else {
      insight[target.name] = target.value
    }
    this.setState({ insight })
  }

  handleMultiSelect = (e) => {
    const { insight } = this.state
    insight.author = e
    this.setState({ insight })
  }

//   handleAddAuthor = e => {
//     const { value } = e.target
//     const { portfolioManagers, analysts } = this.props
//     // const authors = [ ...portfolioManagers, ...analysts ]
//     if (value) {
//       const { insight } = this.state
//       const id = parseInt(value.split("-")[0])
//       const title = value.split("-")[1]

//       if (title.includes("Research") || title === "Blank") {
//         const analyst = analysts.find( a => a.id === id )
//         insight.author = [
//           ...insight.author,
//           (({id, name, img, title, start_year }) => ({ id, name, img, title, start_year}))(analyst)
//         ]
//       } else {
//         const pm = portfolioManagers.find( pm => pm.id === id )
//         insight.author = [
//           ...insight.author,
//           (({id, name, img, title, start_year }) => ({ id, name, img, title, start_year}))(pm)
//         ]
//       }
//       this.selectRef.current.value = ""
//       this.setState({ insight })
//     }
//   }

  handleAddAuthor = e => {
    const { value } = e.target
    const { portfolioManagers, analysts } = this.props
    if (value) {
      const { insight } = this.state
      const authorId = parseInt(value.split("-")[0])
	  const teamMember = [ ...portfolioManagers, ...analysts ].find( ( { id } ) => authorId === id )
	  insight.author = [
		...insight.author,
		( ( { id, name, img, title, start_year } ) => ( { id, name, img, title, start_year } ) )( teamMember )
	  ]
      this.selectRef.current.value = ""
      this.setState({ insight })
    }
  }


  handleRemoveAuthor = e => {
    const { insight } = this.state
    insight.author = insight.author.filter( sel => sel.id !== e.id || (sel.id === e.id && sel.title !== e.title))
    this.setState({ insight })
  }

  handleChangeCategory = e => {
    const { value } = e.target
    if (value) {
      const { insight } = this.state
      insight.category = value
      this.setState({ insight })
    }
  }

  handleDayChange = (date) => {
    const { insight } = this.state
    if(date){
      insight.date = date
      this.setState({ insight })
    } else {
      insight.date = ''
      this.setState({ insight })
    }
  }

  bindImageToInsight = fileName => {
    const { insight } = this.state
    this.setState({
      insight: { ...insight, photo: fileName }
    })
  }

  bindPdfToInsight = fileName => {
    const { insight } = this.state
    const pdf_link = `https://gab-commentary-pdf.s3.us-east-2.amazonaws.com/${fileName}`
    this.setState({
      insight: { ...insight, pdf_link }
    })
  }

  resetPhotoUrl = () => {
    const { insight } = this.state
    this.setState({
      insight: { ...insight, photo: '' }
    })
  }

  resetPdfUrl = () => {
    const { insight } = this.state
    this.setState({
      insight: { ...insight, pdf_link: '' }
    })
  }

  //DraftJS methods for styling (bold, underline, italic)
  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command)

    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  onUnderlineClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'UNDERLINE'))
  }

  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'))
  }

  onItalicClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC'))
  }

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType))
  }

  // Draft JS logic for adding an Image => https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/media/media.html
  promptForImage = () => {
    this.setState({
      showAddImage: true,
      showAddLink: false,
      imageURL: ""
    }, () => {
      setTimeout( () => this.refs.url.focus(), 100)
    })
  }

  onURLChange = (e) => {
    this.setState({ imageURL: e.target.value })
  }

  onURLInputKeyDown = (e) => {
    if (e.which === 13) {
      this.confirmMedia(e)
    }
  }

  confirmMedia = (e) => {
    e.preventDefault()
    const { editorState, imageURL } = this.state
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      "image",
      'IMMUTABLE',
      { src: imageURL }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity }
    )

    this.setState({
      editorState: AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        " "
      ),
      showAddImage: false,
      imageURL: "",
    })
  }

  // Draft JS logic for inserting a link into selected text => https://github.com/facebook/draft-js/blob/master/examples/draft-0-10-0/link/link.html
  promptForLink = (e) => {
    e.preventDefault()
    const { editorState } = this.state
    const selection = editorState.getSelection()
    if ( !selection.isCollapsed() ) {
      const contentState = editorState.getCurrentContent()
      const startKey = editorState.getSelection().getStartKey()
      const startOffset = editorState.getSelection().getStartOffset()
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey)
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset)
      let url = ''
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey)
        url = linkInstance.getData().url
      }

      this.setState({
        showAddLink: true,
        showAddImage: false,
        linkURL: url
      })
    }
  }

  confirmLink = (e) => {
    e.preventDefault()
    const { editorState, linkURL } = this.state
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: linkURL }
    )
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    this.setState({
      editorState: RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      ),
      showURLInput: false,
      linkURL: ''
    })
  }

  onLinkInputKeyDown = (e) => {
    if (e.which === 13) {
      this.confirmLink(e)
    }
  }

  onLinkChange = (e) => {
    this.setState({ linkURL: e.target.value })
  }

  removeLink = (e) => {
    e.preventDefault()
    const { editorState } = this.state
    const selection = editorState.getSelection()
    if ( !selection.isCollapsed() ) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null)
      })
    }
  }
  render(){
    console.log(this.state.insight.display)
    let imageInput
    const { pathname } = this.props.location
    if (this.state.showAddImage) {
      imageInput =
        <div className="url-prompt">
          <input
            className="url-input"
            placeholder="Image URL"
            onChange={this.onURLChange}
            ref="url"
            type="text"
            value={this.state.imageURL}
            onKeyDown={this.onURLInputKeyDown}
           />
          <button className="add-btn" onMouseDown={this.confirmMedia}>
            Add Image
          </button>
        </div>
    }
    let urlInput
    if (this.state.showAddLink) {
      urlInput =
        <div className="url-prompt">
          <input
            className="url-input"
            placeholder="https://..."
            onChange={this.onLinkChange}
            ref='url'
            type='text'
            value={this.state.linkURL}
            onKeyDown={this.onLinkInputKeyDown}
          />
          <button className="add-btn" onMouseDown={this.confirmLink}>
            Add Link
          </button>
        </div>
    }
    return(
      <div id="editor">
        {this.state.showInfoForm ?
          <InsightInfoForm
            insight={this.state.insight}
            errors={this.props.errors}
            handleSubmit={this.handleSubmit}
            handleChangeCategory={this.handleChangeCategory}
            handleInfoChange={this.handleInfoChange}
            handleAddAuthor={this.handleAddAuthor}
            handleRemoveAuthor={this.handleRemoveAuthor}
            handleDelete={this.handleDeleteInsight}
            selectedAuthors={this.state.insight.author}
            selectRef={this.selectRef}
            handleBack={this.handleBack}
            bindImageToInsight={this.bindImageToInsight}
            bindPdfToInsight={this.bindPdfToInsight}
            handleDayChange={this.handleDayChange}
            currentlyEditing={!!this.props.matchParams.id}
            handleProgress={this.handleProgress}
            handlePdfProgress={this.handlePdfProgress}
            showSubmit={this.state.showSubmit}
            resetPhotoUrl={this.resetPhotoUrl}
            resetPdfUrl={this.resetPdfUrl}
            portfolioManagers={this.props.portfolioManagers}
            analysts={this.props.analysts}
          /> :
          <div>
            <div className="toolbar">
              {imageInput}
              {urlInput}
              <Tooltip content="Add URL" className="editor-btn-tooltip">
                <button onClick={this.promptForLink}><Icon icon="link" color="#6dcff6"/></button>
              </Tooltip>
              <Tooltip content="Underline" className="editor-btn-tooltip">
                <button onClick={this.onUnderlineClick} id="underline-btn">U</button>
              </Tooltip>
              <Tooltip content="Bold" className="editor-btn-tooltip">
                <button onClick={this.onBoldClick} id="bold-btn"><b>B</b></button>
              </Tooltip>
              <Tooltip content="Italics" className="editor-btn-tooltip">
                <button onClick={this.onItalicClick} id="italics-btn"><em>I</em></button>
              </Tooltip>
              <BlockStyleToolbar
                editorState={this.state.editorState}
                onToggle={this.toggleBlockType}
                promptForImage={this.promptForImage}
              />
            </div>
            <Editor
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              spellCheck={true}
              handlePastedText={this.handlePastedText}
              blockRendererFn={blockRenderer}
              ref={this.editorRef}
            />
            <div id="done-btn-container">
              <button onClick={this.showInfoForm} id="done" ><b>Done</b></button>
            </div>
          </div>
        }
        <SuccessModal
          successfulUpload={!!this.props.success}
          handleClose={this.handleClose}
          phrase={pathname.includes("new") ? "Insight has been successfully added." : "Insight has been successfully updated."}
        />
      </div>
    )
  }
}

// DraftJS functions neccessary to add an image
const blockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: false
    }
  }
  return null
}

const Image = props => {
  return <img alt="" src={props.src}  />
}

const Media = props => {
  const entity = props.contentState.getEntity(
    props.block.getEntityAt(0)
  )
  const { src } = entity.getData()
  const type = entity.getType()

  let media;
  if(type === 'image') {
    media = <Image src={src} />
  }
  return media
}

// DraftJS functions for adding a link to text
function findLinkEntities(contentBlock, callback, contentState){
  contentBlock.findEntityRanges(
    (char) => {
      const entityKey = char.getEntity()
      return (
        entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
      )
    },
    callback
  )
}

const Link = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return(
    <a href={url}>
      {props.children}
    </a>
  )
}

//Access to store
const mapStateToProps = (state) => ({
  insight: state.insights.insight,
  insights: state.insights.insights || [],
  errors: state.insights.errors || [],
  success: state.insights.success || false,
  portfolioManagers: state.portfolioManagers.pms || [],
  analysts: state.analysts.analysts || []
})

export default withRouter(connect( mapStateToProps, { loadInsight, createOrUpdateInsight, clearInsight, deleteInsight, insightsFetch, fetchPortfolioManagers, fetchAnalysts } )(InsightEditor))
