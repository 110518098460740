export default (state = [], action) => {
  switch(action.type){

    case 'SUCCESSFUL_TV_APPEARANCES_FETCH':
      return {
        ...state,
        tvAppearances: action.tvAppearances,
        errors: []
      }
    case 'SUCCESSFUL_TV_UPDATE':
      return {
        ...state,
        tvAppearances: action.tvs,
        successfulUpload: true
      }
    case 'RESET_TV_UPDATE':
      return {
        ...state,
        successfulUpload: false,
        isUploading: false,
        errors: []
      }
    case 'START_VIDEO_LIST_REFRESH':
      return {
        ...state,
        tvAppearances: []
      }

    case 'SUCCESSFUL_TV_APPEARANCE_FETCH':
      return {
        ...state,
        tv: action.tvAppearance,
        errors: []
      }

    case 'REPLACE_TV_APPEARANCE':
      const index = state.tvAppearanceIndex(tv => tv.id === action.tv.id)
        return [
          ...state.slice(0, index),
          action.tv,
          ...state.slice(index + 1)
        ];

    case 'POSTING_TV_APPEARANCE':
      return {
        ...state,
        isUploading: true,
        successfulUpload: false
      }

    case 'POST_TV_APPEARANCE_SUCCESS':
      return {
        ...state,
        successfulUpload: true,
        isUploading: false,
        errors: []
      }
    case 'TV_POST_COMPLETE':
      return {
        successfulUpload: false,
        isUploading: false,
        errors: []
      }
    case 'TV_APPEARANCE_ERROR':
      return {
        ...state,
        errors: action.errors
      }
    default:
      return state;

  }
}
