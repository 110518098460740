import React from 'react'
import { Modal, Button } from 'react-bootstrap'

const SuccessModal = (props) => {
  const { successfulUpload, handleClose, link } = props
  return(
    <Modal show={successfulUpload} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Success!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.phrase}
        { link ? <p className="generated-link">{link}</p> : null }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal
