import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// import { Icon } from '@blueprintjs/core'
import AdminPmCard from './AdminPmCard'
import PortfolioManagerEdit from './PortfolioManagerEdit'
import { fetchTeams } from '../../redux/modules/Teams/actions'
import { fetchAdministrators } from '../../redux/modules/Administrators/actions'
import { fetchAnalysts } from '../../redux/modules/Analysts/actions'
// import {
//   fetchPortfolioManagers,
//   completePortfolioManagerUpdate
// } from '../../redux/modules/PortfolioManagers/actions';

class Personnel extends Component {
  constructor(props){
    super(props)
    this.state = {
      showModal: props.location.pathname.includes("edit"),
      selectedPerson: null
    }
  }

  componentDidMount = () => {
    this.props.fetchAdministrators()
    this.props.fetchAnalysts()
    this.props.fetchTeams()
  }

  componentDidUpdate(prevProps, prevState){
    let personnelType
    const { pathname } = this.props.location
    if(pathname.includes("administrators")){
      personnelType = "administrators"
    } else if (pathname.includes("analysts")){
      personnelType = "analysts"
    }

    if(pathname.includes("edit")){
      this.setPersonToEdit(personnelType, prevState)
    }

    if(prevProps.analysts.length && prevProps.analysts.length < this.props.analysts.length){
      this.getNewAnalyst(prevProps)
    } else if (prevProps.administrators.length && prevProps.administrators.length < this.props.administrators.length) {
      this.getNewAdministrator(prevProps)
    }
  }

  getNewAnalyst = (prevProps) => {
    const newAnalyst = this.props.analysts.find( a => !prevProps.analysts.some( prev => prev.id === a.id))
    this.setState({ selectedPerson: newAnalyst, showModal: true })
  }

  getNewAdministrator = (prevProps) => {
    const newAdmin = this.props.administrators.find( a => !prevProps.administrators.some( prev => prev.id === a.id))
    this.setState({ selectedPerson: newAdmin, showModal: true })
  }

  setPersonToEdit = (personnelType, prevState) => {
    const { id } = this.props.matchParams
    if(this.props[personnelType].length && !prevState.selectedPerson) {
      const selectedPerson = this.props[personnelType].find( p => p.id === parseInt(id) )
      this.setState({ selectedPerson })
    }
  }

  handleClick = (selectedPerson) => {
    this.setState({
      selectedPerson,
      showModal: true
    })
    const { pathname } = this.props.location
    this.props.history.push(`${pathname}/${selectedPerson.id}/edit`)
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  handleHide = () => {
    this.toggleModal()
    const { pathname } = this.props.location
    const indexPath = pathname.includes("analysts") ? "/admin/analysts" : "/admin/administrators"
    this.props.history.push(indexPath)
  }

  render(){
    const currentYear = new Date().getFullYear()
    const { administrators, analysts, location } = this.props
    const { selectedPerson, showModal } = this.state
    const adminCards = administrators.map(admin => <AdminPmCard key={admin.id} pm={admin} handleClick={this.handleClick} currentYear={currentYear} />)
    const analystCards = analysts.filter(a => a.name !== 'NO AUTHOR').map(a => <AdminPmCard key={a.id} pm={a} handleClick={this.handleClick} currentYear={currentYear} />)
    console.log(this.props)
    const personnelType = location.pathname.split("/")[2]
    return(
      <div id="admin-pm-container">
        {
          showModal && selectedPerson ?
          <PortfolioManagerEdit
            portfolioManager={selectedPerson}
            handleHide={this.handleHide}
            showModal={showModal}
            toggleModal={this.toggleModal}
            isAnalyst={true}
          /> : null
        }
        <div id="pm-card-container" className="admin-pms-container">
          <h3>Select an {personnelType.charAt(0).toUpperCase() + personnelType.slice(1, personnelType.length-1)}</h3>
          <div className="pm-profile-card new-admin-card" onClick={ () => this.props.history.push(`/admin/${personnelType}/new`)}>
            <div className="pm-card-text">
              <h5 className="pm-funds-name">Create New</h5>
            </div>
            <div className="img-container">
              <span className="hover-inverse"></span>
              <img className="pm-img-placeholder" src='https://s3.us-east-2.amazonaws.com/gab-images/headshot_placeholder.jpg' alt="add new"/>
            </div>
          </div>
          { location.pathname.includes("administrators") && adminCards }
          { location.pathname.includes("analysts") && analystCards }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  administrators: state.administrators,
  analysts: state.analysts.analysts || []
})


export default withRouter(connect(mapStateToProps, { fetchAdministrators, fetchTeams, fetchAnalysts })(Personnel))
