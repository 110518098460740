import React, { Component } from 'react'
import CheeseburgerMenu from 'cheeseburger-menu'
import { connect } from 'react-redux'
import { Icon } from '@blueprintjs/core'
import { logout } from '../../redux/modules/Admin/actions'
import AdminNavContent from './AdminNavContent'
import './AdminNav.css'
import styled from 'styled-components'

class AdminNav extends Component {
  constructor(){
    super()
    this.state = {
      menuOpen: false,
      uploadsCollapsed: true,
      carouselCollapsed: true,
      insightsCollapsed: true,
      personnelCollapsed: true,
      fundDataCollapsed: true
    }

    this.navRef = React.createRef()
  }

  handleCarouselClick = (e) => {
    e.stopPropagation()
    const carouselDivs = document.getElementsByClassName("hidden-carousel")
    if(this.state.carouselCollapsed){
      for(let i = 0; i < carouselDivs.length; i++){
        carouselDivs[i].style.display = "inline-block"
      }
      this.setState({ carouselCollapsed: !this.state.carouselCollapsed })
    } else {
      for(let i = 0; i < carouselDivs.length; i++){
        carouselDivs[i].style.display = "none"
      }
      this.setState({ carouselCollapsed: !this.state.carouselCollapsed })
    }
  }

  handleUploadClick = (e) => {
    e.stopPropagation()
    const uploadDivs = document.getElementsByClassName("hidden-uploads")
    if(this.state.uploadsCollapsed){
      for(let i = 0; i < uploadDivs.length; i++){
        uploadDivs[i].style.display = "inline-block"
      }
      this.setState({ uploadsCollapsed: !this.state.uploadsCollapsed })
    } else {
      for(let i = 0; i < uploadDivs.length; i++){
        uploadDivs[i].style.display = "none"
      }
      this.setState({ uploadsCollapsed: !this.state.uploadsCollapsed })
    }
  }

  handleInsightsClick = (e) => {
    e.stopPropagation()
    const insightDivs = document.getElementsByClassName("hidden-insights")
    if(this.state.insightsCollapsed){
      for(let i = 0; i < insightDivs.length; i++){
        insightDivs[i].style.display = "inline-block"
      }
      this.setState({ insightsCollapsed: !this.state.insightsCollapsed })
    } else {
      for(let i = 0; i < insightDivs.length; i++){
        insightDivs[i].style.display = "none"
      }
      this.setState({ insightsCollapsed: !this.state.insightsCollapsed })
    }
  }

  handlePersonnelClick = (e) => {
    e.stopPropagation()
    const personnelDivs = document.getElementsByClassName("hidden-personnel")
    if(this.state.personnelCollapsed){
      for(let i = 0; i < personnelDivs.length; i++){
        personnelDivs[i].style.display = "inline-block"
      }
      this.setState({ personnelCollapsed: !this.state.personnelCollapsed })
    } else {
      for(let i = 0; i < personnelDivs.length; i++){
        personnelDivs[i].style.display = "none"
      }
      this.setState({ personnelCollapsed: !this.state.personnelCollapsed })
    }
  }

  handleFundDataClick = (e) => {
    e.stopPropagation()
    const dataDivs = document.getElementsByClassName("hidden-fund-data")
    if(this.state.fundDataCollapsed){
      for(let i = 0; i < dataDivs.length; i++){
        dataDivs[i].style.display = "inline-block"
      }
      this.setState({ fundDataCollapsed: !this.state.fundDataCollapsed })
    } else {
      for(let i = 0; i < dataDivs.length; i++){
        dataDivs[i].style.display = "none"
      }
      this.setState({ fundDataCollapsed: !this.state.fundDataCollapsed })
    }
  }

  handleLogout = () => {
    this.props.logout(this.props.history)
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen})
  }

  closeMenu = () => {
    this.setState({ menuOpen: false})
  }

  render(){
    const uploadsChevron = (this.state.uploadsCollapsed ? <Icon icon="chevron-down" color="#00aeef" /> : <Icon icon="chevron-up" color="#00aeef" />)
    const carouselChevron = (this.state.carouselCollapsed ? <Icon icon="chevron-down" color="#00aeef" /> : <Icon icon="chevron-up" color="#00aeef" />)
    const insightsChevron = (this.state.insightsCollapsed ? <Icon icon="chevron-down" color="#00aeef" /> : <Icon icon="chevron-up" color="#00aeef" />)
    const personnelChevron = (this.state.personnelCollapsed ? <Icon icon="chevron-down" color="#00aeef" /> : <Icon icon="chevron-up" color="#00aeef" />)
    const fundDataChevron = (this.state.fundDataCollapsed ? <Icon icon="chevron-down" color="#00aeef" /> : <Icon icon="chevron-up" color="#00aeef" />)
    return ( <>
		<StyledAdminNavContainer menuOpen={ this.state.menuOpen } ref={this.navRef}>
			<StyledAdminNavMenuButton onClick={this.toggleMenu}>
				<Icon icon="menu" color="#00aeef"/>  Menu
			</StyledAdminNavMenuButton>
			<AdminNavContent
				handleUploadClick={this.handleUploadClick}
				handleCarouselClick={this.handleCarouselClick}
				handleInsightsClick={this.handleInsightsClick}
				handlePersonnelClick={this.handlePersonnelClick}
				handleFundDataClick={this.handleFundDataClick}
				handleLogout={this.handleLogout}
				uploadsChevron={uploadsChevron}
				carouselChevron={carouselChevron}
				insightsChevron={insightsChevron}
				personnelChevron={personnelChevron}
				fundDataChevron={fundDataChevron}
				adminRole={this.props.adminRole}
				state={this.state}
			/>
		</StyledAdminNavContainer>
	</> )
  }
}

const mapStateToProps = (state) => ( {
	adminRole: state.currentAdmin.role,
} );

export default connect(mapStateToProps, { logout })(AdminNav)

const StyledAdminNavContainer = styled.div`
	z-index: 10;
	height: auto;
	width: 20%;
	top: 0;
	left: 0;
	background-color: white;
	text-align: center;
	border-right: 1px solid lightgray;
	padding-bottom: 5em;
	float: left;
	@media ( max-width: 1000px ) {
		position: absolute;
		top: 0;
		left: ${ ( { menuOpen } ) => menuOpen ? '0' : '-200px' };
		width: 200px;
		// display: block;
		// border: none;
		// width: 100%;
		// padding-bottom: 0;
	}
`;

const StyledAdminNavMenuButton = styled.div`
width: 100px;
	opacity: 0;
	@media( max-width: 1000px ) {
		opacity: 100%;
		background-color: white;
		position: relative;
		top: 10px;
		left: 200px;
	}
`;