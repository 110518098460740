import React from 'react'

const PageSelector = (props) => {
  const { limit, docResults } = props
  const pageSelectors = []
  // const selectorLimit = (
  //   docResults.length < 200 ? docResults.length : 200
  // )
  const selectorLimit = docResults.length
  for(let i = 1; i <= (Math.ceil(selectorLimit/10)); i++){
    pageSelectors.push( <span className={`page-selector ${(limit/10) === i ? 'page-selected' : ''}`} key={i} onClick={props.changePage}>{i}  </span>)
  }
  const pages = () => {
    if (pageSelectors.length > 20) {
      const passedNinthPage = limit/10 > 9
      const firstSelector = passedNinthPage ? limit/10 - 9 : 0
      const lastSelector = passedNinthPage ? limit/10 + 8 : 17
      return <span>{passedNinthPage ? <span>{pageSelectors.slice(0, 1)} ... </span> : null}{pageSelectors.slice(firstSelector, lastSelector)} ... {pageSelectors[pageSelectors.length - 1]}</span>
    } else {
      return pageSelectors
    }
  }
  return(
    <div id="search-page-selectors">
      <span className="page-selector" id="prev-page" onClick={props.handlePrev}>Previous</span>
        {pages()}
      <span className="page-selector" id="next-page" onClick={props.handleNext}>Next</span>
    </div>
  )
}

export default PageSelector
