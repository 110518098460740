export default ( state = [], action ) => {
	switch( action.type ) {
		case 'PUBLIC_EVENTS_UPDATED_SUCCESSFULLY':
			return action.publicEvent;
		case 'PUBLIC_EVENTS_FETCHED_SUCCESSFULLY':
		case 'PUBLIC_EVENTS_DELETED_SUCCESSFULLY':
			return action.publicEvents;
		default:
			return state;
	}
}
