export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_CAROUSEL_FETCH':
      return {
        ...state,
        carousel: action.carousel
      }
	case 'SUCCESSFUL_CAROUSEL_ARCHIVE_FETCH':
      return {
        ...state,
        archive: action.archive
	}
	case 'SUCCESSFUL_CAROUSEL_ARCHIVE_UPDATE':
		return {
		  ...state,
		  carousel: action.carousel
	}
    case 'UPDATING_CAROUSEL':
      return {
        ...state,
        isUploading: true,
      }
    case 'SUCCESSFUL_CAROUSEL_UPDATE':
      return {
         ...state,
         newCarouselItem: action.newCarouselItem,
         isUploading: true,
         successfulUpload: true
       }
    case 'UPLOAD_COMPLETE':
      return {
        ...state,
        successfulUpload: false,
        isUploading: false,
      }
    case 'SUCCESSFUL_CAROUSEL_DELETE':
      return {
        ...state,
        deletedItemKey: action.deletedItemKey
      }
    case 'FAILED_CAROUSEL_ACTION':
      return {
        ...state,
        errors: action.carousel.errors
      }
    default:
      return state;
  }
}
