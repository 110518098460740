import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import SuccessModal from '../AdminPortal/SuccessModal'
// import Select from '../../components/Select'
import QuarterSelector from '../AdminPortal/QuarterSelector'
import AsOfSelector from '../AdminPortal/AsOfSelector'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import { addEstimate, failedEstimateAdd } from '../../redux/modules/DivEstimates/actions'
import { replaceEstimateWithPressRelease, uploadComplete, failedUpload } from '../../redux/modules/PressReleases/actions'
import S3Uploader from '../AdminPortal/S3Uploader'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class DivEstimatesNew extends Component {
  
  constructor() {
    super()

    this.state = {
      paid: false,
      record_date: null,
      fund_code: null,
      firm: "",
      long_term_cap_gains: 0,
      net_inv_income: 0,
      short_term_cap_gains: 0,
      total: 0,
      entity: "",
      title: "",
      date: null,
      contact: "",
      filepath: null,
      show: false,
      showSuccess: false,
      showSubmit: false,
    };
  }

  componentDidMount(){
    this.props.fetchFunds();
    if ( this.isReplacingEstimate() ) {
      this.setState( { paid: true, fund_code: parseInt( this.searchParams().get( "fund_code" ) ) } )
    }
  }

  componentDidUpdate( prevProps ) {
    if ( !( prevProps.estimatesSuccess || prevProps.prSuccess ) && !!( this.props.estimatesSuccess || this.props.prSuccess ) ) this.setState( { showSuccess: true } )
  }

  // To clear error messages
  componentWillUnmount() {
    this.props.failedEstimateAdd( [] );
    this.props.failedUpload( [] );
  }

  searchParams = () => new URLSearchParams( this.props.location.search );

  isReplacingEstimate = () => !!this.props.location.search.length;

  handleChange = e => {
    const { state } = this
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  handleSelectChange = e => {
    if (e){
      this.setState({
        fund_code: e.value,
        flagship_name: e.label
      })
    } else {
      this.setState({
        fund_code: null,
        flagship_name: null
      })
    }
  }

  handleDayChange = (e) => {
    if(e){
      this.setState({ record_date: e })
    } else {
      this.setState({ record_date: null})
    }
  }

  handlePaidSelect = (e, paid) => {
    e.preventDefault()
    this.setState({ paid })
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if(progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = submitEvent => {
    submitEvent.preventDefault();
    if ( this.isReplacingEstimate() ) {
      const idOfEstimateToReplace = this.searchParams().get( "replace" );
      this.props.replaceEstimateWithPressRelease( {
        title: this.state.title,
        entity: this.state.firm,
        fund_code: this.state.fund_code,
        pr_type: "Dividend Notice",
        filepath: this.state.filepath,
        pr_date: this.state.record_date,
        contact: this.state.contact
      }, parseInt( idOfEstimateToReplace ) );
    } else {
      this.props.addEstimate( {
        paid: this.state.paid,
        url: this.state.filepath || "N/A",
        record_date: this.state.record_date,
        fund_code: this.state.fund_code,
        firm: this.state.firm,
        long_term_cap_gains: this.state.long_term_cap_gains,
        net_inv_income: this.state.net_inv_income,
        short_term_cap_gains: this.state.short_term_cap_gains,
        total: this.state.total,
      } );
    }
  }

  parseAsOf = date => `0${date.getUTCMonth() + 1}`.slice( -2 ) + `0${date.getUTCDate()}`.slice( -2 ) + date.getUTCFullYear();

  render(){
    const { fundsList, estimatesErrors, prErrors } = this.props
    const { showSuccess } = this.state
    const { type } = this.props.matchParams
    const allErrors = [ ...( estimatesErrors || [] ), ...( prErrors || [] ) ];
    const options = fundsList?.map( ( { fund_code, fundshortname } ) => ( { value: fund_code, label: fundshortname } ) );
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={showSuccess}
          handleClose={ () => {
            this.setState( { showSuccess: false } );
            this.props.uploadComplete();
            this.props.history.push( `/admin/div_estimates` );
          } }
          phrase={ `Dividend estimate successfully ${ this.isReplacingEstimate() ? "replac" : "add" }ed!` }
        />
        <div id="upload-form-container">
          { this.isReplacingEstimate() ? (
            <span style={ { display: "flex", flexDirection: "row", alignItems: "baseline" } }>
              <h3>Replace Dividend Estimate</h3>
              &nbsp;
              (<Link to="/admin/div_estimates_new">or add estimate/notice without replacing</Link>)
            </span>
          ) : (
            <h3>Add Dividend Estimate</h3>
          ) }
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!allErrors.length && <span id="update-error-span">Error: {allErrors[0]}</span> }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Firm:</label>
            <select name="firm" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.firm}>
              <option></option>
              <option>Gabelli Funds</option>
              <option>TETON Westwood Funds</option>
            </select>
            <QuarterSelector
              handleChange={this.handleChange}
              state={this.state}
              type={type}
            />
            <AsOfSelector
              handleDayChange={this.handleDayChange}
              type={type}
              date={this.state.date}
              value={this.state.date || "YYYY-M-D"}
            />
            <label className="upload-input-label">Please select:</label>
            <div id="pr-double-btn">
              <button className={this.state.paid ? "selected-btn" : null} onClick={ e => this.handlePaidSelect(e, true) }>Paid</button>
              <button className={!this.state.paid ? "selected-btn" : null} onClick={ e => this.handlePaidSelect(e, false) }>Unpaid</button>
            </div>
            {
              this.state.paid &&
              <div>
                <label className="upload-input-label s3-upload-label">Select file to upload:</label>
                <S3Uploader
                  params={{}}
                  accept="application/pdf"
                  apiPath={"press_releases/s3/sign"}
                  handleResponse={this.handleResponse}
                  handleProgress={this.handleProgress}
                />
              </div>
            }
            <label className="upload-input-label">Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-input"
              placeholder={ this.isReplacingEstimate() ? fundsList.find( ( { fund_code } ) => fund_code === parseInt( this.searchParams().get( "fund_code" ) ) )?.fundshortname : "Enter a fund..." }
              handleChange={this.handleSelectChange}
              onClear={() => this.handleSelectChange(null)}
              value={this.state.fund_code}
              disabled={ this.isReplacingEstimate() }
            />
            <label className="upload-input-label">Title to display (usually the header or first line of the release):</label>
            <input name="title" className="media-info-input admin-input" onChange={this.handleChange} value={this.state.title}></input>
            <label className="upload-input-label">Contact Person:</label>
            <input name="contact"className="media-info-input admin-input" onChange={this.handleChange} value={this.state.contact}></input>
            <label className="upload-input-label">Net Inv. Income:</label>
            <input type="number" step="0.01" name="net_inv_income" className="media-info-input admin-input" onChange={this.handleChange} value={this.state["net_inv_income"]}></input>
            <label className="upload-input-label">Short Term Cap. Gains:</label>
            <input type="number" name="short_term_cap_gains" step="0.01" className="media-info-input admin-input" onChange={this.handleChange} value={this.state["short_term_cap_gains"]}></input>
            <label className="upload-input-label">Long Term Cap. Gains:</label>
            <input type="number" name="long_term_cap_gains" step="0.01" className="media-info-input admin-input" onChange={this.handleChange} value={this.state["long_term_cap_gains"]}></input>
            <label className="upload-input-label">Total:</label>
            <input type="number" name="total" step="0.01" className="media-info-input admin-input" onChange={this.handleChange} value={this.state["total"]}></input>
            <input id="upload-button" className="admin-input" type="submit" value="Upload" />
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ( {
  fundsList: state.funds,
  estimatesSuccess: state.estimates.success,
  estimatesErrors: state.estimates.errors,
  prSuccess: state.pr.successfulUpload,
  prErrors: state.pr.errors
} );

export default withRouter( connect( mapStateToProps, {
  fetchFunds,
  replaceEstimateWithPressRelease,
  uploadComplete,
  failedUpload,
  addEstimate,
  failedEstimateAdd
} )( DivEstimatesNew ) );
