import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const DocumentUploadService = {
  uploadDocument(doc, fundType) {
    return fetch(`${API_URL}/uploads${fundType ? ("?fund_type=" + fundType) : ""}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ doc }),
      headers: headers()
    }).then( r => r.json() )
    .catch( e => console.log(e) )
  },

  fetchDocuments(fundCode) {
    if(!!fundCode) {
      return fetch(`${API_URL}/funds/${fundCode}/uploads`)
      .then( r => r.json() )
    } else {
      return fetch(`${API_URL}/uploads`)
      .then( r => r.json() )
    }
  },

  fetchSicavDocuments(fundCode){
    return fetch(`${API_URL}/sicav_docs/${fundCode}`)
    .then( r => r.json())
  },

  fetchProxyStatements(){
    return fetch(`${API_URL}/uploads_ce_proxy_statements`)
    .then( r => r.json())
  },

  fetchCEQrepsByYear(year){
    return fetch(`${API_URL}/all_closed_end_qreps/${year}`)
    .then( r => r.json())
  },

  fetchOEQrepsByYear(year){
    return fetch(`${API_URL}/all_open_end_qreps/${year}`)
    .then( r => r.json())
  },

  fetchMMQrepsByYear(year){
    return fetch(`${API_URL}/all_mm_qreps/${year}`)
    .then( r => r.json())
  },

  fetchAllSois(){
    return fetch(`${API_URL}/all_sois`)
    .then( r => r.json() )
  },

  deleteUpload(id){
    return fetch(`${API_URL}/uploads/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  },

  updateDoc(id, doc){
    return fetch(`${API_URL}/uploads/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: headers(),
      body: JSON.stringify({ doc })
    }).then( r => r.json() )
  }
}

export default DocumentUploadService;
