import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Calendar from 'rc-year-calendar';
import Holidays from 'date-holidays'
import { Modal } from 'react-bootstrap'
import { fetchSicavs } from '../../redux/modules/Funds/actions';
import { fetchFundPrices } from '../../redux/modules/NavSicavs/actions';

const NavFundprices = () => {

	const dispatch = useDispatch();

	const luxembourgHolidays = new Holidays( 'LU' );
	// const ukHolidays = new Holidays( 'GB' );

	const [ currentYear, setCurrentYear ] = useState( new Date().getFullYear() );
	const [ currentHolidays, setCurrentHolidays ] = useState( null )
	const [ selectFundFormState, setSelectFundFormState ] = useState( { fund_code: 6201  } );
	const [ showModal, setShowModal ] = useState( null );

	useEffect( () => dispatch( fetchSicavs() ), [ dispatch ] );
	useEffect( () => {
		dispatch( fetchFundPrices( selectFundFormState.fund_code ) );
		setCurrentYear( new Date().getFullYear() );
	}, [ dispatch, selectFundFormState.fund_code ] );
	useEffect( () => setCurrentHolidays( [
		...luxembourgHolidays.getHolidays( currentYear ).map( ( { date, name } ) => ( { startDate: new Date( date ), endDate: new Date( date ), name: "Holiday", details: name, color: '#222' } ) ),
		// ...ukHolidays.getHolidays( currentYear ).map( ( { date, name } ) => ( { startDate: new Date( date ), endDate: new Date( date ), name: "Holiday", details: name, color: '#222' } ) ),
	] ), [ currentYear ] );

	const sicavs = useSelector( state => state.funds || [] );
	const navFundPrices = useSelector( state => state.sicavs.fundPrices );	
	const startRange = navFundPrices && new Date( navFundPrices[ 0 ].pricedate );

	return <div style={ { fontFamily: 'Gotham' } }>
		<h2>Check for missing SICAV NAV Fundprices</h2>
		<div style={ { display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-around' } }>
			<select
				placeholder='Select a fund'
				onChange={ ( { target } ) => setSelectFundFormState( { ...selectFundFormState, fund_code: parseInt( target.value ) } ) }
			>
				{ sicavs?.map( ( { fund_code, fundshortname } ) => <option key={ fund_code } value={ fund_code }>{ fundshortname } ({ fund_code })</option> ) }
			</select>
			<span><span style={ { color: '#080' } }>■</span> - Has data</span>
			<span>■ - Holiday</span>
		</div>
		{ navFundPrices && <Calendar
			dataSource={ [
				...currentHolidays,
				...navFundPrices.map( ( { pricedate, price } ) => {
					const formatPricedate = new Date( pricedate );
					formatPricedate.setDate( formatPricedate.getDate() + 1 );
					return {
						startDate: new Date( formatPricedate ),
						endDate: new Date( formatPricedate ),
						name: pricedate,
						details: price,
						color: '#080'
					}
				} ),
			] }
			minDate={ startRange }
			maxDate={ new Date( new Date().getFullYear(), 12, 0 ) }
			year={ currentYear }
			onDayClick={ ( { events } ) => setShowModal( events[ 0 ] ) }
			onYearChanged={ ( { currentYear: yearChange } ) => setCurrentYear( yearChange ) }
		/> }
		<Modal show={ showModal } onHide={ () => setShowModal( null ) }>
			<Modal.Header closeButton><Modal.Title>{ showModal?.name }</Modal.Title></Modal.Header>
			<Modal.Body>{ !!parseInt( showModal?.details ) && '$ ' }{ showModal?.details }</Modal.Body>
		</Modal>
	</div>;

};

export default NavFundprices;
