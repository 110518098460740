import S3CommentariesService from '../../../services/S3CommentariesService';
import {
//   makeFetchRequest,
//   finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulCommentariesFetch = commentaries => {
  return {
    type: 'SUCCESSFUL_COMMENTARIES_FETCH',
    commentaries
  };
};

const successfulCommentaryFetch = commentary => {
  return {
    type: 'SUCCESSFUL_COMMENTARY_FETCH',
    commentary
  };
};

const successfulCommentaryPost = commentary => {
  return {
    type: 'SUCCESSFUL_COMMENTARY_POST',
    commentary
  };
};

const successfulCommentaryPatch = commentary => {
  return {
    type: 'SUCCESSFUL_COMMENTARY_PATCH',
    commentary
  };
};

const successfulCommentaryDelete = commentary => {
  return {
    type: 'SUCCESSFUL_COMMENTARY_DELETE',
    commentary
  };
};

const currentlyFetching = () => {
  return {
    type: 'FETCHING_COMMENTARIES',
  };
};

export const fetchS3Commentaries = () => {
	return dispatch => {
		dispatch( currentlyFetching() );
		S3CommentariesService.fetchCommentaries().then( commentaries => {
			dispatch( successfulCommentariesFetch( commentaries ) );
		} ).catch( error => {
			dispatch( unsuccessfulFetchRequest() );
		} );
	}
}

export const fetchS3Commentary = ( s3Key ) => {
	return dispatch => {
		dispatch( currentlyFetching() );
		S3CommentariesService.fetchCommentary( s3Key ).then( commentary => {
			dispatch( successfulCommentaryFetch( commentary ) );
		} ).catch( error => {
			dispatch( unsuccessfulFetchRequest() );
		} );
	}
}

export const postS3Commentary = ( commentary ) => {
	return dispatch => {
		dispatch( currentlyFetching() );
		S3CommentariesService.postCommentary( commentary ).then( newCommentary => {
			dispatch( successfulCommentaryPost( newCommentary ) );
		} ).catch( error => {
			dispatch( unsuccessfulFetchRequest() );
		} );
	}
}

export const updateS3Commentary = ( commentary ) => {
	return dispatch => {
		dispatch( currentlyFetching() );
		S3CommentariesService.patchCommentary( commentary ).then( updatedCommentary => {
			dispatch( successfulCommentaryPatch( updatedCommentary ) );
		} ).catch( error => {
			dispatch( unsuccessfulFetchRequest() );
		} );
	}
}

export const deleteS3Commentary = ( s3Key ) => {
	return dispatch => {
		dispatch( currentlyFetching() );
		S3CommentariesService.deleteCommentary( s3Key ).then( deletedCommentary => {
			dispatch( successfulCommentaryDelete( deletedCommentary ) );
		} ).catch( error => {
			dispatch( unsuccessfulFetchRequest() );
		} );
	}
}

