import React from 'react'
// import Select from '../../components/Select'
import SelectWithAutofill from '../../components/SelectWithAutofill'

const FeaturedFundsSelect = (props) => {
  const { funds, fundsList, fundType } = props
  const options = (
    fundsList.filter( fund => fund.fund_type === fundType )
    .map( fund => ({ value: fund.fund_code, label: fund.fundshortname }) )
  )
  return(
    <div id="featured-select-form">
      <select onChange={props.changeFundType} value={fundType} id="ff-type-dropdown" className="admin-select">
        <option value="Open End">Open Ends</option>
        <option value="Closed End">Closed Ends</option>
      </select>
      <h3 id="change-funds-header">Set Featured Funds</h3>
      <div className="select-fund-label">Fund #1</div>
      <SelectWithAutofill
        options={options}
        className="select-tag media-info-input admin-select"
        handleChange={props.handleChange1}
        value={funds.fund1}
      />
      <div className="select-fund-label">Fund #2</div>
      <SelectWithAutofill
        options={options}
        className="select-tag media-info-input admin-select"
        handleChange={props.handleChange2}
        value={funds.fund2}
      />
      <div className="select-fund-label">Fund #3</div>
      <SelectWithAutofill
        options={options}
        className="select-tag media-info-input admin-select"
        handleChange={props.handleChange3}
        value={funds.fund3}
      />
      <button onClick={props.handleSubmit}>Set Featured Funds</button>
    </div>
  )
}

export default FeaturedFundsSelect
