import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SuccessModal from '../SuccessModal'
import { submitFeatureRequest, resetSubmission } from '../../../redux/modules/Features/actions'

class RequestForm extends Component {
  constructor(){
    super()

    this.state = {
      feature: "",
      description: ""
    }
  }

  handleChange = e => {
    const { state } = this
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.submitFeatureRequest(this.state)
  }

  handleClose = () => {
    this.setState({ feature: "", description: "" })
    this.props.resetSubmission()
  }

  render() {
    const { errors } = this.props
    const { feature, description } = this.state
    const anyBlankFields = () => !feature || !description
    const localStorageAdmin = JSON.parse(localStorage.getItem('admin'))
    const isKirstenOrSean = localStorageAdmin.name === "Sean Clifford" || localStorageAdmin.admin === "Kirsten O'Farrell"
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.success}
          handleClose={this.handleClose}
          phrase={"Feature request submitted."}
        />
        <div id="upload-form-container">
          <h3>Feature Request</h3>
          { isKirstenOrSean ? <div><Link to="/admin/wishlist" style={{ color: '#00aeef' }}>See Wishlist</Link></div> : null }
          <br />
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Feature name:</label>
            <input name="feature" className="media-info-input admin-input" onChange={this.handleChange} value={this.state.feature}></input>
            <label className="upload-input-label">Feature description:</label>
            <textarea type="textarea" name="description"className="media-info-input admin-input" onChange={this.handleChange} value={this.state.description}></textarea>
            <input id="upload-button" type="submit" value="Submit Request" disabled={ anyBlankFields() ? "disabled" : "" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  success: state.features.success || false,
  errors: state.features.errors || null
})

export default connect(mapStateToProps, { submitFeatureRequest, resetSubmission })(RequestForm)
