import InsightsService from '../../../services/InsightsService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulInsightsFetch = insights => {
  return {
    type: 'SUCCESSFUL_INSIGHTS_FETCH',
    insights
  }
}

const successfulFundsHomeInsightsFetch = fundsHomeInsights => {
  return {
    type: 'SUCCESSFUL_FUNDS_HOME_INSIGHTS_FETCH',
    fundsHomeInsights
  }
}

const currentlyFetching = () => {
  return {
    type: 'FETCHING_INSIGHTS',
  }
}


export const insightsFetch = () => {
  return dispatch => {
      dispatch(currentlyFetching())
    InsightsService.fetchInsights()
    .then(insights => {
      dispatch(successfulInsightsFetch(insights))
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundsHomeInsights = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    InsightsService.fetchFundsHomeInsights()
    .then(fundsHomeInsights => {
      dispatch(finishFetchRequest())
      dispatch(successfulFundsHomeInsightsFetch(fundsHomeInsights))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}
