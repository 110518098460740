import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Spinner } from '@blueprintjs/core'
import CarouselAdd from './CarouselAdd'
import CarouselEdit from './CarouselEdit'
import CarouselCard from './CarouselCard'
import SuccessModal from './SuccessModal'
import {
	fetchCarousel,
	fetchCarouselArchive,
	addCarouselObject,
	deleteCarouselObject,
	updateCarouselObject,
	uploadComplete,
	archiveCarouselObject,
	unarchiveCarouselObject
} from '../../redux/modules/FundsCarousel/actions'

class CarouselUpload extends Component {
  constructor(){
    super()

    this.state = {
      header: "",
      caption: "",
      summary: "",
      imagePath: "",
      link: "",
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchCarousel()
    this.props.fetchCarouselArchive()
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleResponse = (r) => {
    this.setState({ imagePath: r.publicUrl})
  }

  handleProgress = (progress) => {
    if(progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { state } = this
    const hasEmptyValues = Object.values(state).some( val => !val )
    if (!!hasEmptyValues){
      document.getElementById("upload-failed").style.display = "inline-block"
    } else {
      if (!state.link.includes("s3") && state.link.includes(".com/")){
        state.link = state.link.split(".com/")[1]
      }
      document.getElementById("upload-failed").style.display = "none"
      this.props.addCarouselObject(state)
      setTimeout(() => { this.props.fetchCarousel() }, 1000)
      this.setState({
        header: "",
        caption: "",
        summary: "",
        imagePath: "",
        link: "",
        showSubmit: false
      })
    }
  }

  handleUpdate = (key, data) => {
    this.props.updateCarouselObject(key, data)
    setTimeout(() => { this.props.fetchCarousel() }, 1000)
  }

  handleDelete = (id) => {
    this.props.deleteCarouselObject(id)
    setTimeout(() => { this.props.fetchCarousel() }, 1000)
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  handleArchiveCarouselObject = s3key => {
	this.props.archiveCarouselObject( s3key );
	// this.props.history.push( '/admin/carousel/edit' );
	// window.location.reload();
  };

  handleUnarchiveCarouselObject = s3key => {
	this.props.unarchiveCarouselObject( s3key );
	// this.props.history.push( '/admin/carousel/edit' );
	// window.location.reload();
  };
  
  render(){
    const { method } = this.props.matchParams
    const { carousel } = this.props
    if (method === "edit" && carousel){
      const carouselCards = carousel.map( c =>{
        const key = Object.keys(c)[0]
        return (
          <CarouselCard
            handleEdit={this.handleEdit}
            handleDelete={this.handleDelete}
            handleUpdate={this.handleUpdate}
			handleArchiveCarouselObject={ this.handleArchiveCarouselObject }
            key={key}
            s3key={key}
            data={c[key]}
            index={carousel.indexOf(c)}
          />
        )
      })
      return (
        <CarouselEdit
			carouselCards={carouselCards}
			handleUnarchiveCarouselObject={ this.handleUnarchiveCarouselObject }
			archive={this.props.archive}
        />
      )
    } else if (method === "new"){
      return(
        <div>
          <SuccessModal
            successfulUpload={!!this.props.successfulUpload && !!this.props.isUploading}
            handleClose={this.handleClose}
            phrase={"Carousel successfully updated"}
          />
          <CarouselAdd
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            handleResponse={this.handleResponse}
            handleProgress={this.handleProgress}
            state={this.state}
          />
        </div>
      )
    } else {
      return <Spinner className="funds-spinner" size={100} />
    }
  }
}

const mapStateToProps = (state) => ({
  carousel: state.carousel.carousel,
  archive: state.carousel.archive,
  deleted: state.carousel.deletedItemKey,
  isUploading: state.carousel.isUploading,
  successfulUpload: state.carousel.successfulUpload,
  newCarouselItem: state.carousel.newCarouselItem
})

export default withRouter(connect(mapStateToProps, {
	uploadComplete,
	fetchCarousel,
	fetchCarouselArchive,
	addCarouselObject,
	deleteCarouselObject,
	updateCarouselObject,
	archiveCarouselObject,
	unarchiveCarouselObject
})(CarouselUpload))
