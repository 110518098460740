import MediaUploadService from '../../../services/MediaUploadService';
import {
  unsuccessfulFetchRequest
} from '../../appTransactions';

const currentlyUploading = () => {
  return {
    type: 'START_MEDIA_UPLOAD',
  }
}

const successfulMediaUpload = () => {
  return {
    type: 'MEDIA_UPLOAD_SUCCESSFUL'
  }
}

const completeMediaUpload = () => {
  return {
    type: 'COMPLETE_MEDIA_UPLOAD'
  }
}

const successfulMediaFetch = media => {
  return {
    type: 'SUCCESSFUL_MEDIA_UPLOADS_FETCH',
    media
  }
}


export const uploadMedia = (media) => {
  return dispatch => {
    dispatch(currentlyUploading())
    MediaUploadService.uploadNewmedia(media)
    .then(media => {
      dispatch(successfulMediaUpload())
    })
    .catch( error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeMediaUpload())
  }
}

export const fetchMedia = () => {
  return dispatch => {
    MediaUploadService.fetchMedia()
    .then( media => dispatch(successfulMediaFetch(media)) )
  }
}
