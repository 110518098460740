import {
  createStore,
  applyMiddleware,
  combineReducers
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducer as form } from 'redux-form';
import thunk from 'redux-thunk';
import navDailies from './modules/NavDailies/reducer';
import closedEnds from './modules/NavClosedEnds/reducer';
import openEnds from './modules/NavOpenEnds/reducer';
import moneyMarkets from './modules/NavMoneyMarkets/reducer';
import sicavs from './modules/NavSicavs/reducer';
import gavs from './modules/NavGavs/reducer';
import recipients from './modules/NavRecipients/reducer';
import articles from './modules/Media/reducer';
import tvAppearances from './modules/TVAppearances/reducer';
import marketData from './modules/MarketData/reducer';
import distributionData from './modules/NavDistribution/reducer';
import taxInfo from './modules/TaxInfo/reducer';
import etfData from './modules/NavEtfs/reducer';
import inceptData from './modules/NavInceptPerf/reducer';
import ytdData from './modules/NavYtdPerf/reducer';
import fiveyrData from './modules/NavFiveYrPerf/reducer';
import threeyrData from './modules/NavThreeYrPerf/reducer';
import oneyrData from './modules/NavOneYrPerf/reducer';
import tenyrData from './modules/NavTenYrPerf/reducer';
import featuredFunds from './modules/FeaturedFunds/reducer'
import currentGabRep from './modules/GabReps/reducer';
import insights from './modules/Insights/reducer'
import latestInsight from './modules/LatestInsight/reducer'
import investorRelations from './modules/InvestorRelations/reducer';
import portfolioManagers from './modules/PortfolioManagers/reducer';
import media from './modules/MediaUploads/reducer'
import tags from './modules/Tags/reducer'
import docs from './modules/DocumentUploads/reducer'
import multiPerfs from './modules/MultiPerfs/reducer'
import pr from './modules/PressReleases/reducer'
import carousel from './modules/FundsCarousel/reducer'
import currentAdmin from './modules/Admin/reducer'
import funds from './modules/Funds/reducer'
import teams from './modules/Teams/reducer'
import administrators from './modules/Administrators/reducer'
import analysts from './modules/Analysts/reducer'
import xbrls from './modules/Xbrl/reducer'
import filings from './modules/Filings/reducer'
import sicavDocs from './modules/SicavDocs/reducer'
import search from './modules/Search/reducer'
import totalYEAssets from './modules/TotalYEAssets/reducer'
import mmLiquidity from './modules/MmLiquidity/reducer'
import taxDocs from './modules/TaxDocs/reducer'
import prefDocs from './modules/PreferredIssues/reducer'
import form8937s from './modules/Form8937s/reducer'
import importantTaxDocs from './modules/ImportantTaxDocs/reducer'
import sectors from './modules/FundsSectors/reducer'
import holdings from './modules/FundHoldings/reducer'
import admin from './modules/Admin/reducer'
import features from './modules/Features/reducer'
import leads from './modules/Leads/reducer'
import estimates from './modules/DivEstimates/reducer'
import chats from './modules/GabChat/reducer'
import composites from './modules/Composites/reducer'
import publicEvents from './modules/PublicEvents/reducer'
import publicEventRegistrations from './modules/PublicEventRegistrations/reducer'
import publicEventSpeakers from './modules/PublicEventSpeakers/reducer'
import s3Commentaries from './modules/S3Commentaries/reducer'

const reducers = combineReducers({
  form,
  articles,
  tvAppearances,
  navDailies,
  closedEnds,
  openEnds,
  moneyMarkets,
  sicavs,
  gavs,
  recipients,
  marketData,
  distributionData,
  taxInfo,
  etfData,
  inceptData,
  ytdData,
  fiveyrData,
  oneyrData,
  threeyrData,
  tenyrData,
  featuredFunds,
  insights,
  latestInsight,
  currentGabRep,
  investorRelations,
  portfolioManagers,
  media,
  tags,
  totalYEAssets,
  docs,
  pr,
  carousel,
  currentAdmin,
  funds,
  teams,
  administrators,
  analysts,
  xbrls,
  filings,
  sicavDocs,
  search,
  multiPerfs,
  mmLiquidity,
  taxDocs,
  prefDocs,
  form8937s,
  importantTaxDocs,
  sectors,
  holdings,
  admin,
  features,
  leads,
  estimates,
  chats,
  composites,
  publicEvents,
  publicEventRegistrations,
  publicEventSpeakers,
  s3Commentaries
});

const middleware = [thunk];

export default createStore(
  reducers,
  // compose(applyMiddleware(...middleware))
  composeWithDevTools(applyMiddleware(...middleware))
)
