import React from 'react'
import { yearOptions } from '../TableTools'

const QuarterSelector = (props) => {
  const { handleChange, state, type } = props
  if(
      type === "holdings" ||
      type === "quarterly_report" ||
      type === "annual_report" ||
      type === "factsheet" ||
      type === "commentary" ||
      type === "corporate_filings" ||
      type === "corporate_filing_update"
    ) {
    return (
      <div>
        <label className="upload-input-label">Select a Quarter:</label>
        <br />
        <select className="media-info-input quarter-select admin-select" name="year" onChange={handleChange} value={state.year}>
          <option></option>
          {yearOptions()}
        </select>
        <select className="media-info-input quarter-select admin-select" name="quarter" onChange={handleChange} value={state.quarter}>
          <option></option>
          <option>Annual</option>
          <option value={ type === "corporate_filing_update" ? '4' : '4Q' }>4Q</option>
          <option value={ type === "corporate_filing_update" ? '3' : '3Q' }>3Q</option>
          <option value={ type === "corporate_filing_update" ? '2' : '2Q' }>2Q</option>
          <option value={ type === "corporate_filing_update" ? '1' : '1Q' }>1Q</option>
        </select>
      </div>
    )
  } else {
    return null
  }
}

export default QuarterSelector
