import SicavService from '../../../services/SicavService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';


const successfulSicavsFetch = sicavs => {
  return {
    type: 'SUCCESSFUL_SICAVS_FETCH',
    sicavs
  }
}

const successfulSicavs2Fetch = sicavs => {
  return {
    type: 'SUCCESSFUL_SICAVS_2_FETCH',
    sicavs
  }
}

const successfulSicavFetch = sicav => {
  return {
    type: 'SUCCESSFUL_SICAV_FETCH',
    sicav
  }
}

const successFundPricesFetch = fundPrices => {
  return {
    type: 'SUCCESSFUL_FUND_PRICES_FETCH',
    fundPrices
  }
}

const successFundAssetsFetch = fundAssets => {
  return {
    type: 'SUCCESSFUL_FUND_ASSETS_FETCH',
    fundAssets
  }
}

const successfulFundSuppsFetch = fundSupps => {
  return {
    type: 'SUCCESSFUL_FUND_SUPPS_FETCH',
    fundSupps
  }
}

const successCalendarYrReturnsFetch = returns => {
  return {
    type: 'SUCCESSFUL_CALENDAR_YR_RETURNS_FETCH',
    returns
  }
}

const successGrowthOf10kFetch = growth => {
  return {
    type: 'SUCCESSFUL_GROWTH_OF_10K_FETCH',
    growth
  }
}

const successHoldingsFetch = holdings => {
  return {
    type: 'SUCCESSFUL_HOLDINGS_FETCH',
    holdings
  }
}

const successPerformanceFetch = payload => {
  return {
    type: 'SUCCESSFUL_PERFORMANCE_FETCH',
    payload
  }
}

const successManagersFetch = managers => {
  return {
    type: 'SUCCESSFUL_MANAGERS_FETCH',
    managers
  }
}

const successMediaFetch = media => {
  return {
    type: 'SUCCESSFUL_MEDIA_FETCH',
    media
  }
}

const successDistributionsFetch = distributions => {
  return {
    type: 'SUCCESSFUL_DISTRIBUTIONS_FETCH',
    distributions
  }
}

const successSiblingsFetch = siblings => {
  return {
    type: 'SUCCESSFUL_SIBLINGS_FETCH',
    siblings
  }
}

const replaceSicav = sicav => {
  return {
    type: 'REPLACE_SICAV',
    sicav
  }
}

export const clearFund = () => {
  return dispatch => dispatch({ type: 'CLEAR_FUND' })
}

export const fetchSicavs = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchSicavs()
      .then(sicavs => {
        dispatch(finishFetchRequest())
        dispatch(successfulSicavsFetch(sicavs))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchSicavs2 = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchSicavs2()
    .then(sicavs => {
      dispatch(finishFetchRequest())
      dispatch(successfulSicavs2Fetch(sicavs))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchSicav= (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchSicav(fund_code)
    .then(sicav => {
      dispatch(finishFetchRequest())
      dispatch(successfulSicavFetch(sicav))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundPrices = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchFundPrices(fund_code)
    .then(fundPrices => {
      dispatch(finishFetchRequest())
      dispatch(successFundPricesFetch(fundPrices))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundAssets = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchFundAssets(fund_code)
    .then(fundAssets => {
      dispatch(finishFetchRequest())
      dispatch(successFundAssetsFetch(fundAssets))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundSupps = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchFundSupps(fund_code)
    .then(fundSupps => {
      dispatch(finishFetchRequest())
      dispatch(successfulFundSuppsFetch(fundSupps))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchGrowthOf10k = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchGrowthOf10k(fund_code)
    .then(growth => {
      dispatch(finishFetchRequest())
      dispatch(successGrowthOf10kFetch(growth))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchCalendarYearReturns = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchCalendarYearReturns(fund_code)
    .then(returns => {
      dispatch(finishFetchRequest())
      dispatch(successCalendarYrReturnsFetch(returns))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchHoldings = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchHoldings(fund_code)
    .then(holdings => {
      dispatch(finishFetchRequest())
      dispatch(successHoldingsFetch(holdings))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchPerformance = (fund_code, dates) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchPerformance(fund_code, dates)
    .then(payload => {
      dispatch(finishFetchRequest())
      dispatch(successPerformanceFetch(payload))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchManagers = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchManagers(fund_code)
    .then(managers => {
      dispatch(finishFetchRequest())
      dispatch(successManagersFetch(managers))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchSiblings = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchSiblings(fund_code)
    .then(siblings => {
      dispatch(finishFetchRequest())
      dispatch(successSiblingsFetch(siblings))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchMedia = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchMedia(fund_code)
    .then(media => {
      dispatch(finishFetchRequest())
      dispatch(successMediaFetch(media))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchDistributions = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.fetchDistributions(fund_code)
    .then(distributions => {
      dispatch(finishFetchRequest())
      dispatch(successDistributionsFetch(distributions))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const updateSicav = (sicav, routerHistory) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    SicavService.updateSicav(sicav)
    .then(order => {
      dispatch(replaceSicav(sicav))
      dispatch(finishFetchRequest())
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}
