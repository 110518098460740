import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSicavs } from '../../redux/modules/NavSicavs/actions';
import { fetchSicavDocs, addSicavDoc, updateSicavDoc, deleteSicavDoc } from '../../redux/modules/SicavDocs/actions';
import styled from 'styled-components';

const languages = [ 'Danish', 'Dutch', 'English', 'French', 'German', 'Italian', 'Norwegian', 'Spanish', 'Swedish' ];

const ManageKIIDs = () => {

	const dispatch = useDispatch();

	const [ editKiidFormState, setEditKiidFormState ] = useState( null );
	const [ addKiidFormState, setAddKiidFormState ] = useState( null );

	useEffect( () => {
		dispatch( fetchSicavs() );
		dispatch( fetchSicavDocs() );
	}, [ dispatch ] );
	
	const sicavs = useSelector( state => state.sicavs.funds || [] );
	const { docs } = useSelector( state => state.sicavDocs || {} );

	const sicavMap = sicavs?.reduce( ( result, { fund_code, fundshortname } ) => {
		result[ fund_code ] = {
			fundshortname,
			docs: docs?.filter( ( { doc_type, fund_code: docFundCode } ) => doc_type === 'kiid' && fund_code === docFundCode )
		};
		return result;
	}, {} );

	const renderSicavs = Object.keys( sicavMap || {} ).map( fundCode => {
		const { fundshortname, docs } = sicavMap[ fundCode ];
		const docLanguages = languages.reduce( ( result, language ) => docs?.map( ( { language } ) => language ).indexOf( language ) < 0 ? [ ...result, <option key={ language } value={ language }>{ language }</option> ] : result, [ <option key='' value='' disabled>Language</option>] );
		return <StyledSicavRow key={ fundCode }>
			<h3>{ fundshortname }</h3>
			<ul>
				{ docs?.map( ( { id, filepath, currency, fund_code, language, name } ) => <li key={ id }>
					<label>{ language }</label>
					&nbsp;
					<input
						size={ 50 }
						value={ editKiidFormState?.id === id ? editKiidFormState.filepath : filepath }
						disabled={ editKiidFormState?.id !== id }
						onChange={ ( { target } ) => setEditKiidFormState( { ...editKiidFormState, filepath: target.value } ) }
					/>
					<div>
						<button onClick={ () => setEditKiidFormState( editKiidFormState?.id === id ? null : { id, filepath } ) }>
							{ editKiidFormState?.id !== id ? '✎ Edit' : 'Cancel' }
						</button>
						<button
							disabled={ editKiidFormState?.id !== id }
							onClick={ () => {
								dispatch( updateSicavDoc( id, { doc_type: 'kiid', filepath: editKiidFormState?.filepath, currency, fund_code, language, name } ) );
								setEditKiidFormState( null );
							} }
						>
							⍻ Save
						</button>
						<button
							disabled={ editKiidFormState?.id !== id }
							onClick={ () => {
								if ( window.confirm( 'Delete this KIID? Can\'t be undone!' ) ) dispatch( deleteSicavDoc( id ) ) 
							} }
						>
							✘ Delete
						</button>
					</div>
				</li> ) }
				{ !!docLanguages.length && <button onClick={ () => setAddKiidFormState( addKiidFormState?.fundCode === fundCode ? null : { fundCode, filepath: '' } ) }>Add new KIID</button> }
				{ addKiidFormState?.fundCode === fundCode && <form onSubmit={ () => {
							dispatch( addSicavDoc( {
								fund_code: fundCode,
								doc_type: 'kiid',
								language: addKiidFormState?.language,
								filepath: addKiidFormState?.filepath,
								name: `GAMCO ${ fundshortname } KIID - ${ addKiidFormState?.language }`,
							} ) );
							setAddKiidFormState( null );
							dispatch( fetchSicavDocs() );
						} }>
					<select defaultValue='' onChange={ ( { target } ) => setAddKiidFormState( { ...addKiidFormState, language: target.value } ) }>{ docLanguages }</select>
					&nbsp;
					<input value={ addKiidFormState?.filepath } onChange={ ( { target } ) => setAddKiidFormState( { ...addKiidFormState, filepath: target.value } ) } />
					<input type='submit' value='⍻ Save' />
				</form> }
			</ul>
		</StyledSicavRow>;
	} );

	return <div id='admin-upload-container'>
		{ renderSicavs }
	</div>;

};

export default ManageKIIDs;

const StyledSicavRow = styled.div`
	display: flex;
	flex-flow: row nowrap;
	padding-top: 5px;
	&:not( :last-child ) {
		padding-bottom: 5px;
		border-bottom: 1px solid #ddd;
	}
`;
