import ClosedEndService from '../../../services/ClosedEndService';
import {
  makeFetchRequest,
  finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulClosedEndsFetch = closedEnds => {
  return {
    type: 'SUCCESSFUL_CLOSED_ENDS_FETCH',
    closedEnds
  }
}

const successfulClosedEndFetch = closedEnd => {
  return {
    type: 'SUCCESSFUL_CLOSED_END_FETCH',
    closedEnd
  }
}

const successFundPricesFetch = fundPrices => {
  return {
    type: 'SUCCESSFUL_FUND_PRICES_FETCH',
    fundPrices
  }
}

const successFundAssetsFetch = fundAssets => {
  return {
    type: 'SUCCESSFUL_FUND_ASSETS_FETCH',
    fundAssets
  }
}

const successfulFundSuppsFetch = fundSupps => {
  return {
    type: 'SUCCESSFUL_FUND_SUPPS_FETCH',
    fundSupps
  }
}

const successCalendarYrReturnsFetch = returns => {
  return {
    type: 'SUCCESSFUL_CALENDAR_YR_RETURNS_FETCH',
    returns
  }
}

const successGrowthOf10kFetch = growth => {
  return {
    type: 'SUCCESSFUL_GROWTH_OF_10K_FETCH',
    growth
  }
}

const successHoldingsFetch = holdings => {
  return {
    type: 'SUCCESSFUL_HOLDINGS_FETCH',
    holdings
  }
}

const successHoldingsUpdate = holdings => {
  return {
    type: 'SUCCESSFUL_HOLDINGS_UPDATE',
    holdings
  }
}

const successPerformanceFetch = payload => {
  return {
    type: 'SUCCESSFUL_PERFORMANCE_FETCH',
    payload
  }
}

const successManagersFetch = managers => {
  return {
    type: 'SUCCESSFUL_MANAGERS_FETCH',
    managers
  }
}

const successMediaFetch = media => {
  return {
    type: 'SUCCESSFUL_MEDIA_FETCH',
    media
  }
}

const replaceClosedEnd = closedEnd => {
  return {
    type: 'REPLACE_CLOSED_END',
    closedEnd
  }
}

export const clearFund = () => {
  return dispatch => dispatch({ type: 'CLEAR_FUND' })
}

export const fetchClosedEnds = () => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchClosedEnds()
      .then(closedEnds => {
        dispatch(finishFetchRequest())
        dispatch(successfulClosedEndsFetch(closedEnds))
      })
      .catch(error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}

export const fetchClosedEnd = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchClosedEnd(fund_code)
    .then(closedEnd => {
      dispatch(finishFetchRequest())
      dispatch(successfulClosedEndFetch(closedEnd))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundPrices = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchFundPrices(fund_code)
    .then(fundPrices => {
      dispatch(finishFetchRequest())
      dispatch(successFundPricesFetch(fundPrices))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundAssets = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchFundAssets(fund_code)
    .then(fundAssets => {
      dispatch(finishFetchRequest())
      dispatch(successFundAssetsFetch(fundAssets))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchFundSupps = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchFundSupps(fund_code)
    .then(fundSupps => {
      dispatch(finishFetchRequest())
      dispatch(successfulFundSuppsFetch(fundSupps))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchGrowthOf10k = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchGrowthOf10k(fund_code)
    .then(growth => {
      dispatch(finishFetchRequest())
      dispatch(successGrowthOf10kFetch(growth))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchCalendarYearReturns = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchCalendarYearReturns(fund_code)
    .then(returns => {
      dispatch(finishFetchRequest())
      dispatch(successCalendarYrReturnsFetch(returns))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchHoldings = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchHoldings(fund_code)
    .then(holdings => {
      dispatch(finishFetchRequest())
      dispatch(successHoldingsFetch(holdings))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const updateClosedEndHoldings = updatedHoldings => {
	return dispatch => {
		dispatch( makeFetchRequest() )
		ClosedEndService.updateClosedEndHoldings( updatedHoldings )
		.then( allUpdatedHoldings => {
		  dispatch( finishFetchRequest() )
		  dispatch( successHoldingsUpdate( allUpdatedHoldings ) )
		} )
		.catch( error => {
		  dispatch( unsuccessfulFetchRequest() )
		} )
	}
}

export const fetchPerformance = (fund_code, dates) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchPerformance(fund_code, dates)
    .then(payload => {
      dispatch(finishFetchRequest())
      dispatch(successPerformanceFetch(payload))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchManagers = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchManagers(fund_code)
    .then(managers => {
      dispatch(finishFetchRequest())
      dispatch(successManagersFetch(managers))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchMedia = (fund_code) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.fetchMedia(fund_code)
    .then(media => {
      dispatch(finishFetchRequest())
      dispatch(successMediaFetch(media))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const updateClosedEnd = (closedEnd, routerHistory) => {
  return dispatch => {
    dispatch(makeFetchRequest())
    ClosedEndService.updateClosedEnd(closedEnd)
    .then(order => {
      dispatch(replaceClosedEnd(closedEnd))
      dispatch(finishFetchRequest())
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}
