import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

  const AnalystsService = {
    fetchAnalysts() {
      return fetch(`${API_URL}/analysts`)
      .then(response => response.json())
    },

    fetchAnalyst(id){
      return fetch(`${API_URL}/analysts/${id}`)
      .then(response => response.json())
    },

    createAnalyst(data) {
      return fetch(`${API_URL}/analysts`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: headers()
      }).then(response => response.json())
    },

    updateAnalyst(data) {
      return fetch(`${API_URL}/analysts/${data.id}`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: headers()
      }).then(response => response.json())
    },

    deleteAnalyst(id) {
      return fetch(`${API_URL}/analysts/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: headers()
      }).then(response => response.json())
    }

//   fetchPortfolioManager(id) {
//     return(fetch(`${API_URL}/portfolio_managers/${id}`))
//     .then(response => response.json())
//   },
//
//   fetchManagedFunds(id) {
//     return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
//     .then(response => response.json())
//   },
//
//   newPortfolioManager(pmData) {
//     return fetch(`${API_URL}/portfolio_managers`,{
//       method: 'POST',
//       credentials: 'include',
//       body: JSON.stringify(pmData),
//       headers: headers()
//     }).then(response => response.json())
//     .catch( e => console.log(e) )
//   },
//
//   updatePortfolioManager(pmData) {
//     return fetch(`${API_URL}/portfolio_managers/${pmData.id}`, {
//       method: 'PUT',
//       credentials: 'include',
//       body: JSON.stringify(pmData),
//       headers: headers()
//     }).then( r => r.json() )
//   },
//
//   deletePortfolioManager(pmId) {
//     return fetch(`${API_URL}/portfolio_managers/${pmId}`, {
//       method: 'DELETE',
//       credentials: 'include',
//       headers: headers()
//     }).then(r => r.json())
//   }
}

export default AnalystsService;

// const PortfolioManagersService = {
//   fetchPortfolioManagers() {
//     return fetch(`${API_URL}/pms`)
//     .then(response => response.json())
//   },
//
//   fetchPortfolioManager(id) {
//     return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
//     .then(response => response.json())
//   },
//
//   fetchManagedFunds(id) {
//     return(fetch(`${API_URL}/pms/` + id + "/pm_fnd"))
//     .then(response => response.json())
//   }
// }
