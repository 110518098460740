import PortfolioManagersService from '../../../services/PortfolioManagersService';

import {
  //makeFetchRequest,
  //finishFetchRequest,
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulPortfolioManagersFetch = portfolioManagers => {
  return {
    type: 'SUCCESSFUL_PORTFOLIO_MANAGERS_FETCH',
    portfolioManagers
  }
}

const successfulPortfolioManagerFetch = portfolioManager => {
  return {
    type: 'SUCCESSFUL_PORTFOLIO_MANAGER_FETCH',
    portfolioManager
  }
}

const startPmUpdate = () => {
  return {
    type: 'PM_UPDATING',
  }
}

const successfulPmUpdate = portfolioManager => {
  return {
    type: 'SUCCESSFUL_PORTFOLIO_MANAGER_UPDATE',
    portfolioManager,
  }
}

const unsuccessfulPmUpdate = errors => {
  return {
    type: 'UNSUCCESSFUL_PORTFOLIO_MANAGER_UPDATE',
    errors,
  }
}

const updateComplete = () => {
  return {
    type: 'PORTFOLIO_MANAGER_UPDATE_COMPLETE',
  }
}

export const fetchPortfolioManagers = () => {
  return dispatch => {
    PortfolioManagersService.fetchPortfolioManagers()
    .then(portfolioManagers => {
      dispatch(successfulPortfolioManagersFetch(portfolioManagers))
    })
    .catch(error => {
      dispatch(unsuccessfulFetchRequest())
    })
  }
}

export const fetchPortfolioManager = (id) => {
  return dispatch => {
    PortfolioManagersService.fetchPortfolioManager(id)
    .then( portfolioManager => {
      dispatch(successfulPortfolioManagerFetch(portfolioManager))
    })
    .catch( error => dispatch(unsuccessfulFetchRequest()) )
  }
}

export const addNewPortfolioManager = (pmData) => {
  return dispatch => {
    dispatch(startPmUpdate())
    PortfolioManagersService.newPortfolioManager(pmData)
    .then( json => {
      if(json.errors){
        dispatch(unsuccessfulPmUpdate(json.errors))
      } else {
        dispatch(successfulPmUpdate(json))
      }
    })
  }
}

export const updatePortfolioManager = (portfolio_manager) => {
  return dispatch => {
    dispatch(startPmUpdate())
    PortfolioManagersService.updatePortfolioManager(portfolio_manager)
    .then( portfolioManager => {
      if(portfolioManager.errors){
        dispatch(unsuccessfulPmUpdate(portfolioManager.errors))
      } else {
        dispatch(successfulPmUpdate(portfolioManager))
      }
    })
  }
}

export const deletePortfolioManager = (pmId) => {
  return dispatch => {
    PortfolioManagersService.deletePortfolioManager(pmId)
    .then(portfolioManagers => dispatch(successfulPortfolioManagersFetch(portfolioManagers)))
  }
}

export const completePortfolioManagerUpdate = () => {
  return dispatch => dispatch(updateComplete())
}

// const successfulPortfolioManagersFetch = pms => {
//   return {
//     type: 'SUCCESSFUL_PMS_FETCH',
//     pms
//   }
// }
//
// const successfulPortfolioManagerFetch = pm => {
//   return {
//     type: 'SUCCESSFUL_PM_FETCH',
//     pm
//   }
// }
//
// export const fetchPortfolioManagers = () => {
//   return dispatch => {
//     PortfolioManagersService.fetchPortfolioManagers()
//     .then(pms => {
//       dispatch(successfulPortfolioManagersFetch(pms))
//     })
//     .catch(error => {
//       dispatch(unsuccessfulFetchRequest())
//     })
//   }
// }
//
// export const fetchPortfolioManager = (id) => {
//   return dispatch => {
//     PortfolioManagersService.fetchPortfolioManager(id)
//     .then( pm => dispatch(successfulPortfolioManagerFetch(pm)) )
//     .catch( error => dispatch(unsuccessfulFetchRequest()) )
//   }
// }
