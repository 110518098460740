import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const ImportantTaxDocService = {
  fetchFlagshipsWithDocs(){
    return fetch(`${API_URL}/tax_informations`)
    .then( r => r.json() )
  },

  uploadImportant(data){
    return fetch(`${API_URL}/tax_informations`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  }
}

export default ImportantTaxDocService
