import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const SicavService = {
  fetchSicavs() {
    return fetch(`${API_URL}/nav_sicavs`)
      .then(response => response.json())
  },

  fetchSicav(fund_code){
    return fetch(`${API_URL}/nav_sicavs/${fund_code}`)
      .then(response => response.json())
  },

  fetchFundPrices(fund_code){
    return fetch(`${API_URL}/nav_fundprices/${fund_code}`)
      .then(response => response.json())
  },

  fetchFundAssets(fund_code){
    return fetch(`${API_URL}/nav_fund_assets/${fund_code}`)
    .then(response => response.json())
  },

  fetchFundSupps(fund_code){
    return fetch(`${API_URL}/funds/${fund_code}`)
    .then(response => response.json())
  },

  fetchGrowthOf10k(fund_code){
    return fetch(`${API_URL}/nav_growth_2/${fund_code}`)
      .then(response => response.json())
  },

  fetchCalendarYearReturns(fund_code){
    return fetch(`${API_URL}/nav_calendar_yr_returns/${fund_code}`)
      .then(response => response.json())
  },

  fetchHoldings(fund_code){
    return fetch(`${API_URL}/fund_holdings/${fund_code}`)
      .then(response => response.json())
  },

  fetchManagers(fund_code){
    return fetch(`${API_URL}/funds/${fund_code}/portfolio_managers`)
      .then(response => response.json())
  },

  fetchMedia(fund_code){
    return fetch(`${API_URL}/fund_press_releases/${fund_code}`)
    .then(response => response.json())
  },

  fetchSiblings(fund_code){
    return fetch(`${API_URL}/siblings_sicavs/${fund_code}`)
    .then(response => response.json())
  },

  fetchDistributions(fund_code){
    return fetch(`${API_URL}/tax_info/${fund_code}`)
    .then(response => response.json())
  },

}

export default SicavService;
