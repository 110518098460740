import TagsService from '../../../services/TagsService';
import {
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulTagsFetch = tags => {
  return {
    type: 'SUCCESSFUL_TAGS_FETCH',
    tags
  }
}

const currentlyFetching = () => {
  return {
    type: 'FETCHING_TAGS',
  }
}


export const fetchTags = () => {
  return dispatch => {
    dispatch(currentlyFetching())
    TagsService.fetchTags()
    .then(tags => {
        dispatch(successfulTagsFetch(tags))
      })
      .catch( error => {
        dispatch(unsuccessfulFetchRequest())
      })
  }
}
