// const { utils, writeFile } = require( 'xlsx' );
// const { aoa_to_sheet, book_new } = utils;

// export const generateRegistrantSpreadsheet = ( eventName, registrants ) => {

// 	const result = book_new();
// 	const sheetname = `Event registrations: ${ eventName }`;
// 	result.SheetNames.push( sheetname );

// 	const buildTable = Object();
// 	registrants.forEach( ( { firstname, lastname, email, phone, organization, title, virtual, comments, created_at } ) => buildTable[ `${ firstname } ${ lastname }` ] = { email, phone, organization, title, virtual, comments, created_at } );

// 	result.Sheets[ sheetname ] = aoa_to_sheet( [
// 		[ 'Name', 'Email', 'Phone', 'Organization', 'Job Title', 'Attending', 'Comments', 'Created at' ],
// 		...Object.keys( buildTable ).map( name => [
// 			name,
// 			buildTable[ name ].email,
// 			buildTable[ name ].phone,
// 			buildTable[ name ].organization,
// 			buildTable[ name ].title,
// 			buildTable[ name ].virtual ? 'Virtually' : 'In-person',
// 			buildTable[ name ].comments,
// 			buildTable[ name ].created_at,
// 		] ),
// 	] );
// 	result.Sheets[ sheetname ][ '!cols' ] = [
// 		{ wch: 16 },
// 		{ wch: 12 },
// 		{ wch: 12 },
// 		{ wch: 12 },
// 		{ wch: 12 },
// 		{ wch: 6 },
// 		{ wch: 24 },
// 		{ wch: 12 },
// 	];
// 	writeFile( result, `registrants_${ eventName.replace( /\s/, '' ) }.xlsx`, { raw: true } );

// };

const toCsv = ( arrayOfRows, columnsToInclude = Object.keys( arrayOfRows[ 0 ] ) ) => {
	return [ columnsToInclude.join(), ...arrayOfRows.map( row => columnsToInclude.map( column =>
		row[ column ] ? `"${ row[ column ].toString() }"` : row[ column ] == null && column === 'virtual' ? 'null' : ''
	).join() ) ].join( '\n' );
};

export const generateRegistrantSpreadsheet = ( eventName, registrants ) => {
	const element = document.createElement( 'a' );
	element.setAttribute( 'href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent( toCsv( registrants, [ 'firstname', 'lastname', 'email', 'phone', 'organization', 'title', 'virtual', 'comments', 'created_at' ] ) ) );
	element.setAttribute( 'download', `${ eventName.replace( /\W/ug,'' ) }-registrants-${ new Date().toISOString().split( 'T' )[ 0 ] }.csv` );
	element.style.display = 'none';
	document.body.appendChild( element );
	element.click();
	document.body.removeChild( element );
};

export const generateSpeakersSpreadsheet = ( eventName, speakers ) => {
	const element = document.createElement( 'a' );
	element.setAttribute( 'href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent( toCsv( speakers, [ 'company', 'contact', 'phone', 'email', 'virtual', 'one_on_one_availability', 'other_contacts', 'departure', 'speaker_bio', 'slide_deck', 'created_at' ] ) ) );
	element.setAttribute( 'download', `${ eventName.replace( /\W/ug,'' ) }-speakers-${ new Date().toISOString().split( 'T' )[ 0 ] }.csv` );
	element.style.display = 'none';
	document.body.appendChild( element );
	element.click();
	document.body.removeChild( element );
};

export const generateSympoisumSpreadsheet = ( year, registrants ) => {
	const element = document.createElement( 'a' );
	element.setAttribute( 'href', 'data:text/csv;charset=UTF-8,' + encodeURIComponent( toCsv( registrants, [ 'first_name', 'last_name', 'email', 'phone', 'account_name', 'manager', 'attended_last_year', 'thursday_guest', 'friday_guest', 'last_year_comments', 'suggested_topics', 'suggested_speaker', 'account_comments', 'uses_first_clearing', 'suggestions', 'referrals', 'thursday_in_person', 'friday_in_person', 'friday_virtual', 'friday_lunch', 'created_at' ] ) ) );
	element.setAttribute( 'download', `${ year.replace( /\W/ug,'' ) }SymposiumRegistrants-${ new Date().toISOString().split( 'T' )[ 0 ] }.csv` );
	element.style.display = 'none';
	document.body.appendChild( element );
	element.click();
	document.body.removeChild( element );
};
