import React, { Component } from 'react'
// import Select from '../../components/Select'
import { Popover } from '@blueprintjs/core'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class PrResult extends Component {
  constructor(props){
    super()

    const { pr } = props

    this.state = {
      isEditing: false,
      formData: {
        title: pr.title,
        fund_code: pr.fund_code,
        pr_date: pr.pr_date,
        pr_type: pr.pr_type,
        filepath: pr.filepath,
        entity: pr.entity
      }
    }
  }

  toggleEditing = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  handleChange = (e) => {
    const { formData } = this.state
    const { name, value } = e.target
    formData[name] = value
    this.setState({ formData })
  }

  handleSelectChange = (e) => {
    const { formData } = this.state
    if(e){
      const { value } = e
      formData.fund_code = value
      this.setState({ formData })
    } else {
      formData.fund_code = null
      this.setState({ formData })
    }
  }

  render(){
      const { pr, funds, deletePr } = this.props
      const { isEditing, formData } = this.state
      if(isEditing){
        return (
          <div>
            <form>
              <label className="upload-input-label">Title:</label>
              <input className="media-info-input admin-input" name="title" value={formData.title} onChange={this.handleChange}></input>
              <label className="upload-input-label">Fund:</label>
              <SelectWithAutofill
                options={funds}
                className="select-tag media-info-input admin-input"
                placeholder="Enter a fund..."
                handleChange={this.handleSelectChange}
                onClear={(e) => this.handleSelectChange(null)}
                value={formData.fund_code}
              />
              <label className="upload-input-label">As of:</label>
              <input className="media-info-input admin-input" name="pr_date" value={formData.pr_date} onChange={this.handleChange}></input>
              <label className="upload-input-label">Doc type:</label>
              <select className="media-info-input admin-select" name="pr_type" value={formData.pr_type} onChange={this.handleChange}>
                <option value=""></option>
                <option>Dividend Notice</option>
                <option>Press Release</option>
              </select>
              <label className="upload-input-label">Entity:</label>
              <select name="entity" className="media-info-input admin-input" onChange={this.handleChange} value={formData.entity}>
                <option></option>
                <option>Associated Capital</option>
                <option>Comstock Funds</option>
                <option>GAMCO Investors</option>
                <option>Gabelli & Company</option>
                <option>Gabelli Closed End Funds</option>
                <option>Gabelli Funds</option>
                <option>Gabelli Open End Funds</option>
                <option>NextShares</option>
                <option>TETON Westwood Investments</option>
              </select>
              <label className="upload-input-label">Filepath:</label>
              <input className="media-info-input admin-input" name="filepath" value={formData.filepath} onChange={this.handleChange}></input>
            </form>
            <div className="admin-docs-btn-container">
              <button className="admin-docs-edit-btn" onClick={ () => this.props.updatePr(pr.id, formData) }>Update</button>
              <button className="admin-docs-edit-btn cancel-btn" onClick={this.toggleEditing}>Cancel</button>
            </div>
            <hr style={{ width: '100%' }}/>
          </div>
        )
      } else {
        console.log(pr);
        return(
          <div className="admin-docs-result">
            <h5>
              <a href={pr.filepath} target="_blank" rel="noreferrer noopener">{pr.title}</a>
            </h5>
            <p className="admin-docs-result-data">Type: {pr.pr_type}</p>
          <p className="admin-docs-result-data">Uploaded on {pr.updated_at.split("T")[0]}{ !!pr.uploaded_by ? ` by ${ pr.uploaded_by }` : "" }</p>
            <p className="admin-docs-result-data">PR Date: {pr.pr_date}</p>
            <div className="admin-docs-btn-container">
              <button className="admin-docs-edit-btn" onClick={this.toggleEditing}>Edit</button>
              <Popover minimal={true}>
                <button className="admin-docs-dlt-btn" >Delete</button>
                <div className="popover-content">
                  <h4>Are you sure?</h4>
                  <p>Would you like to delete this item?</p>
                  <button className=" bp3-popover-dismiss">Cancel </button>
                  <button className="cc-delete-btn popover-btn" onClick={() => deletePr( pr.id)}>Delete</button>
                </div>
              </Popover>
            </div>
            <hr style={{ width: '100%' }}/>
          </div>
        )
      }
  }
}

export default PrResult
