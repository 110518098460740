export default (state = [], action) => {
  switch(action.type){
    case 'SUCCESSFUL_INSIDER_FILINGS_FETCH':
      return {
        ...state,
        insiderFilings: action.insiderFilings
      }
    case 'SUCCESSFUL_COMPANY_PRESS_RELEASES_FETCH':
      return {
        ...state,
        pressReleases: action.pressReleases
      }
    default:
      return state;
  }
}
