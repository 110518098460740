import InsightsService from '../../../services/InsightsService';


const successfulInsightsFetch = insights => {
  return {
    type: 'SUCCESSFUL_INSIGHTS_FETCH',
    insights
  }
}

const successfulInsightFetch = insight => {
  return {
    type: 'SUCCESSFUL_INSIGHT_FETCH',
    insight
  }
}

const currentlyFetching = () => {
  return {
    type: 'FETCHING_INSIGHT'
  }
}

const successfulInsightUpdate = (json) => {
  return {
    type: 'SUCCESSFUL_INSIGHT_UPDATE',
    insight: json
  }
}

const invalidInsight = errors => ({
  type: 'INSIGHT_INVALID',
  errors
})

const successfulInsightPost = (json) => {
  return {
    type: 'SUCCESSFUL_INSIGHT_POST',
    insight: json.insight,
    insights: json.insights
  }
}

const insightReset = () => {
  return {
    type: 'CLEAR_INSIGHT',
    insight: null,
    url: null
  }
}

export const loadInsight = (id) => {
  return dispatch => {
    dispatch(currentlyFetching())
    InsightsService.fetchInsight(id)
    .then( i => {
      dispatch(successfulInsightFetch(i))
    })
    .catch(e => {
    })
  }
}

export const createOrUpdateInsight = (insight, id = null) => {
  if(id){
    return dispatch => {
      InsightsService.updateInsight(insight, id)
      .then( json => {
        if (json.errors && json.errors.length){
          dispatch(invalidInsight(json.errors))
        } else if(json.insight){
          dispatch(successfulInsightUpdate(json.insight))
        } else if(json.error){
          alert("Upload failed. Please contact web team.")
        }
      })
    }
  } else {
    return dispatch => {
      InsightsService.postNewInsight(insight)
      .then( json => {
        if (json.errors && json.errors.length) {
          dispatch(invalidInsight(json.errors))
        } else if (json.error) {
          alert("Upload failed. Please contact web team.")
        } else {
          dispatch(successfulInsightPost(json))
        }
      })
    }
  }
}

export const deleteInsight = id => {
  return dispatch => {
    InsightsService.deleteInsight(id)
    .then( json => dispatch(successfulInsightsFetch(json)) )
  }
}

export const clearInsight = () => {
  return dispatch => dispatch(insightReset())
}
