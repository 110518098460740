import React, { Component } from 'react'
import { connect } from 'react-redux'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Modal } from 'react-bootstrap'
import { Spinner } from '@blueprintjs/core'
import { uploadHoldingsCSV, resetHoldingsUpload } from '../../redux/modules/FundHoldings/actions'
import SuccessModal from './SuccessModal'

class HoldingsUpdate extends Component {
  constructor(){
    super()

    this.state = {
      csv: null,
      asOf: ""
    }
  }

  handleChange = (e) => {
    const csv = e.target.files[0]
    this.setState({ csv })
  }

  handleDayChange = (e) => {
    if(e){
      this.setState({ asOf: e })
    } else {
      this.setState({ asOf: null })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('file', this.state.csv)
    formData.append('asof_date', this.state.asOf)
    this.props.uploadHoldingsCSV(formData)
  }

  render(){
    const { asOf, csv } = this.state
    const { successfulUpload, currentlyUploading,resetHoldingsUpload } = this.props
    const loadingModal = (
      <Modal show={currentlyUploading} onHide={null}>
        <Modal.Header closeButton>
          <Modal.Title>Please Wait</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Importing CSV file. This could take a few minutes ... </p>
          <Spinner className="funds-spinner" size={75}/>
        </Modal.Body>
      </Modal>
    )
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={successfulUpload}
          handleClose={ () => resetHoldingsUpload() }
          phrase={"CSV successfully uploaded"}
        />
        {loadingModal}
        <div id="upload-form-container">
          <h3>Upload Holdings Spreadsheet</h3>
          <form id="admin-upload-form" className="sector-selector-form" onSubmit={this.handleSubmit}>
          <label className="upload-input-label">Date: </label>
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={asOf || "YYYY-M-D"}
              style={{ color: "Gray" }}
            />
            <input className="upload-field" type='file' onChange={this.handleChange}/>
            <h5><i>*File must be a .CSV</i></h5>
            <input id="upload-button" type="submit" value="Upload" disabled={ csv && asOf ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  successfulUpload: state.holdings.successfulUpload,
  currentlyUploading: state.holdings.currentlyUploading
})

export default connect(mapStateToProps, { uploadHoldingsCSV, resetHoldingsUpload })(HoldingsUpdate)
