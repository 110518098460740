export default (state = [], action) => {
  switch(action.type){

    case 'SUCCESSFUL_OE_TAX_INFO_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyTaxInfo: action.yearlyTaxInfo
      }

    case 'SUCCESSFUL_CE_TAX_INFO_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyTaxInfoCE: action.yearlyTaxInfoCE
      }

    case 'SUCCESSFUL_MM_TAX_INFO_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyTaxInfoMM: action.yearlyTaxInfoMM
      }

    default:
      return state;
  }
}
