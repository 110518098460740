import { API_URL } from '../constants'

export const headers = () => {
	const token = localStorage.getItem('token');
	return {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json',
	  'Authorization': `Bearer: ${token}`,
	}
}

const PublicEventService = {
	fetchPublicEvents() {
		return fetch( `${API_URL}/public_events` ).then( response => response.json() );
	},
	fetchPublicEventRegistrations( publicEventId ) {
		return fetch( `${API_URL}/public_events/${ publicEventId }/registrations` ).then( response => response.json() );
	},
	createPublicEvent( publicEventParams ) {
		return fetch( `${ API_URL }/public_events`, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify( publicEventParams ),
			headers: headers()
		} ).then( response => response.json() );
	},
	updatePublicEvent( id, event ) {
		return fetch( `${ API_URL }/public_events/${ id }`, {
			method: 'PATCH',
			body: JSON.stringify( event ),
			headers: headers()
		} ).then( response => response.json() );
	},
	deletePublicEvent( id ) {
		return fetch( `${ API_URL }/public_events/${ id }`, {
			method: 'DELETE',
			headers: headers()
		} ).then( response => response.json() );
	}
};

export default PublicEventService;
