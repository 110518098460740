import XbrlService from '../../../services/XbrlService';

const successfulUpload = xbrl => {
  return {
    type: 'SUCCESSFUL_XBRL_UPLOAD',
    xbrl
  }
}

const currentlyUploading = () => {
  return {
    type: 'UPLOADING_XBRL',
  }
}

const successfulXbrlsFetch = (xbrls) => {
  return {
    type: "SUCCESSFUL_XBRLS_FETCH",
    xbrls
  }
}

const completeUpload = () => {
  return {
    type: "XBRL_UPLOAD_COMPLETE"
  }
}

const unsuccessfulXbrlAdd = (xbrl) => {
  return {
    type: "UNSUCCESSFUL_XBRL_ADD",
    xbrl
  }
}


export const uploadXbrl = (xbrl) => {
  return dispatch => {
      dispatch(currentlyUploading())
    XbrlService.uploadXbrl(xbrl)
      .then(xbrl => {
        if(xbrl.errors){
          dispatch(unsuccessfulXbrlAdd(xbrl))
        } else if (xbrl.error){
          console.log(xbrl.error)
          alert("Upload failed: Server Error")
        } else {
          dispatch(successfulUpload(xbrl))
      }
    })
  }
}

export const fetchXbrls = (fundCode = null) => {
  return dispatch => {
    XbrlService.fetchXbrl(fundCode)
    .then( xbrls => {
      dispatch(successfulXbrlsFetch(xbrls))
    })
    .catch( error => {
      console.log(error)
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
