export default (state = [], action) => {
  switch(action.type){
    case 'SUCCESSFUL_SICAV_DOCS_FETCH':
      return {
        ...state,
        docs: action.docs,
      }
    case 'SUCCESSFUL_SICAV_DOC_UPLOAD':
      return {
        ...state,
        successfulUpload: true
      }
    case 'SICAV_DOC_UPLOAD_FAILED':
      return {
         ...state,
         errors: action.doc.errors,
         successfulUpload: false
       }
    case 'SICAV_DOC_UPLOAD_COMPLETE':
      return {
        ...state,
        successfulUpload: false
      }
    case 'CLEAR_SICAV_DOCS':
      return {
        ...state,
        docs: []
      }
    default:
      return state
  }
}
