import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const Form8937Service = {
  fetchForms(data) {
    return fetch(`${API_URL}/form8937s`)
    .then( r => r.json() )
  },

  fetchFormsList(){
    return fetch(`${API_URL}/form8937s/all_forms`)
    .then( r => r.json() )
  },

  uploadNew(data) {
    return fetch(`${API_URL}/form8937s`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  },

  updateForm(id, data) {
    return fetch(`${API_URL}/form8937s/${id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  },

  deleteForm(id) {
    return fetch(`${API_URL}/form8937s/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }

}

export default Form8937Service;
