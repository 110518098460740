import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Select from '../../components/Select'
import S3Uploader from './S3Uploader'
import SuccessModal from './SuccessModal'
import { addSicavDoc, fetchSicavDocs, uploadComplete } from '../../redux/modules/SicavDocs/actions'
import { fetchSicavs } from '../../redux/modules/Funds/actions'
import SelectWithAutofill from '../../components/SelectWithAutofill'


class SicavUpload extends Component {
  constructor(){
    super()

    this.state = {
      name: "",
      fund_code: null,
      filepath: "",
      doc_type: "",
      language: "",
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchSicavs()
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.addSicavDoc(this.state)
    this.setState({
      name: "",
      fund_code: null,
      filepath: "",
      doc_type: "",
      language: "",
      showSubmit: false
    })
    document.getElementsByName("s3-uploader")[0].value = ''
  }

  handleChange = (e) => {
    const { state } = this
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  handleSelectChange = (e) => {
    const fund_code = ( e ? e.value : null )
    this.setState({ fund_code })
  }

  handleResponse = (e) => {
    this.setState({ filepath: e.publicUrl })
  }

  handleProgress = progress => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  render(){
    let options = this.props.funds || []
    if (options.length) {
      options = options.map( fund => ({
        value: fund.fund_code, label: fund.fundshortname
      }) )
    }
    const uploadWarning = () => {
      switch (this.state.doc_type){
        case 'annual_report':
        case 'semi_annual_report':
        case 'notice':
        case 'prospectus':
          return <p style={{ color: 'red' }}>*Annual/Semi-Annual Reports/Shareholder Notice/Prospectus will upload for ALL SICAVs regardless of fund selection</p>
        case 'commentary':
        case 'factsheet':
        case 'french_factsheet':
          return <p style={{ color: 'red' }}>*Commentary/Factsheet will upload for all share classes of a fund (either GACV, GCS, or GMA)</p>
        case 'kiid':
          return <p style={{ color: 'red' }}>*KIID will only be attached to one specific share class</p>
        default:
          return null;
      }
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Document successfully uploaded"}
        />
        <div id="upload-form-container">
        <h3>SICAV Upload</h3>
        <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!this.props.errors ? <span id="update-error-span">Error: {this.props.errors[0]}</span> : null }
        <form id="admin-upload-form" onSubmit={this.handleSubmit}>
          <label className="upload-input-label">Document Name:</label>
          <input name="name" className="media-info-input admin-input" onChange={this.handleChange} value={this.state.name}/>
          <label className="upload-input-label">Document Type:</label>
          <select name="doc_type" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.doc_type}>
            <option value=""></option>
            <option value="annual_report">Annual Report</option>
            <option value="commentary">Commentary</option>
            <option value="factsheet">Factsheet</option>
            <option value="french_factsheet">Factsheet (French)</option>
            <option value="incorporation">Articles of Incorporation</option>
            <option value="kiid">KIID</option>
            <option value="notice">Notice to Shareholders</option>
            <option value="prospectus">Prospectus</option>
            <option value="semi_annual_report">Semi-Annual Report</option>
            <option value="sfdr">SFDR Disclosure</option>
          </select>
          <label className="upload-input-label">Language:</label>
          <select name="language" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.language}>
            <option value=""></option>
            <option>Danish</option>
            <option>Dutch</option>
            <option>English</option>
            <option>French</option>
            <option>German</option>
            <option>Greek</option>
            <option>Italian</option>
            <option>Latvian</option>
            <option>Lithuanian</option>
            <option>Norwegian</option>
            <option>Portuguese</option>
            <option>Slovene</option>
            <option>Spanish</option>
            <option>Swedish</option>
          </select>
          {uploadWarning()}
          <label className="upload-input-label">Select Fund: </label>
          <SelectWithAutofill
            options={options}
            className="select-tag media-info-input admin-select"
            placeholder="Enter a fund..."
            handleChange={this.handleSelectChange}
            onClear={(e) => this.handleSelectChange(null)}
            value={this.state.fund_code}
          />
          <label className="upload-input-label s3-upload-label">Upload the File</label>
          <S3Uploader
            params={{}}
            accept='application/pdf'
            apiPath='sicav_docs/s3/sign'
            handleResponse={this.handleResponse}
            handleProgress={this.handleProgress}
          />
          <input id="upload-button" className="admin-input" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
        </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  funds: state.funds,
  sicavDocs: state.sicavDocs.docs || [],
  successfulUpload: state.sicavDocs.successfulUpload,
  errors: state.sicavDocs.errors
})

export default connect(mapStateToProps, { fetchSicavDocs, fetchSicavs, addSicavDoc, uploadComplete })(SicavUpload)
