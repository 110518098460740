import React, { useState } from 'react';

const SelectWithAutofill = ( { handleChange, onClear, options, placeholder, value, style = { width: '100%' } } ) => {

	const [ state, setState ] = useState( {
		input: options?.find( option => option.value === value )?.label || ''
	} );

	const handleSelection = selectionEvent => {
		const label = selectionEvent.target.value;
		const selected = options?.find( option => option.label === label || !!option.label.match( label ) );
		setState( { input: label } );
		handleChange( selected );
	};

	const handleClear = () => {
		setState( { input: '' } );
		onClear();
	};

	return <>
		<input
			type='text'
			name='custom-select'
			list='datalist'
			onChange={ handleSelection }
			placeholder={ placeholder }
			value={ state.input }
			style={ style }
		/>
		<datalist id='datalist' key={ placeholder }>
			{ options?.length && options?.map( option =>
				<option key={ option.value } data-value={ option.value } >{ option.label }</option>
			) }
		</datalist>
		{ state.input.length && onClear ? <button
			style={ {
				cursor: 'pointer',
				background: 'none',
				border: 'none',
				position: 'absolute',
				transform: 'translate( -110%, 12.5% )',
				// top: '15px',
				// right: '15px',
				'&:hover': { cursor: 'pointer' }
			} }
			onClick={ handleClear }
		>✖</button> : null }
	</>;

};

export default SelectWithAutofill;
