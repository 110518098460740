import React, { Component } from 'react'
import { connect } from 'react-redux'
import { login } from '../../redux/modules/Admin/actions'
import Login from '../Login'
// import './Admin.css'
import './AdminNav.css'
import '../GabChat/Login.css'

class AdminLogin extends Component {

  constructor(props){
    super()

    this.timeoutRef = React.createRef()

    if (props.refreshing) {
      this.timeoutRef = setTimeout(() => { this.showErrorMsg() }, 10000)
    }
  }

  showErrorMsg = () => {
    window.alert("The server is not responding. Please contact the web team.")
  }

  componentWillUnmount(){
    clearTimeout(this.timeoutRef)
  }

  handleLogin = (e) => {
    e.preventDefault()
    const children = e.target.childNodes
    const username = children[0].value
    const password = children[1].value
    this.props.login({ username, password }, this.props.history)
    children[0].value = ""
    children[1].value = ""
  }
  render(){
    return(
      <div id="admin-login-container">
        <div id="login-container">
          <img id="login-logo" src={"https://s3.us-east-2.amazonaws.com/gab-images/gabellifundswhitehorizontal.jpg"} alt="gabelli-logo"></img>
          <Login
            refreshing={this.props.refreshing}
            handleLogin={this.handleLogin}
            authFailed={this.props.authFailed}
            isAuthenticating={this.props.isAuthenticating}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isAuthenticating: state.currentAdmin.isAuthenticating,
  authFailed: state.currentAdmin.authFailed
})

export default connect(mapStateToProps, { login })(AdminLogin)
