import React from 'react'
import ReactS3Uploader from 'react-s3-uploader'

import { API_URL } from '../../constants'

export const headers = (docType) => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const S3Uploader = (props) => {

  const { apiPath, params, handleResponse, accept, handleProgress, isDisabled } = props

  const handlePreprocess = (file, next) => {
    next(file)
  }

  return(
    <ReactS3Uploader
	  style={ { width: 'fit-content' } }
      signingUrl={`${API_URL}/${apiPath}`}
      signingUrlMethod='GET'
      signingUrlWithCredentials={ false }
      signingUrlHeaders={headers}
      signingUrlQueryParams={params}
      onError={ () => alert("There was an error uploading this file. Please refresh the page and try again.")}
      onFinish={ response => handleResponse(response) }
      s3path="/"
      accept={accept}
      uploadRequestHeaders={{}}
      className="upload-field admin-input"
      onProgress={handleProgress}
      name="s3-uploader"
      preprocess={handlePreprocess}
      disabled={isDisabled}
    />
  )
}

export default S3Uploader
