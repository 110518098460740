export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_CLOSED_ENDS_FETCH':
      return {
        ...state,
        funds: action.closedEnds
      };

    case 'SUCCESSFUL_CLOSED_END_FETCH':
      return {
        ...state,
        closedEnd: action.closedEnd
      }

    case 'SUCCESSFUL_FUND_PRICES_FETCH':
      return {
        ...state,
        fundPrices: action.fundPrices
      }

    case 'SUCCESSFUL_FUND_ASSETS_FETCH':
      return {
        ...state,
        fundAssets: action.fundAssets
      }

    case 'SUCCESSFUL_FUND_SUPPS_FETCH':
      return {
        ...state,
        fundSupps: action.fundSupps
      }

    case 'SUCCESSFUL_CALENDAR_YR_RETURNS_FETCH':
      return {
        ...state,
        calendarYearReturns: action.returns
      }

    case 'SUCCESSFUL_GROWTH_OF_10K_FETCH':
      return {
        ...state,
        growthOf10k: action.growth
      }

    case 'SUCCESSFUL_HOLDINGS_FETCH':
      return {
        ...state,
        holdings: action.holdings
      }

    case 'SUCCESSFUL_MEDIA_FETCH':
    return {
      ...state,
      media: action.media
    }

    case 'SUCCESSFUL_MANAGERS_FETCH':
      return {
        ...state,
        managers: action.managers
      }

    case 'SUCCESSFUL_PERFORMANCE_FETCH':
      return {
        ...state,
        start_date: action.payload.start_date,
        end_date: action.payload.end_date,
        starting_price: action.payload.starting_price,
        ending_price: action.payload.ending_price,
        ttl_return: action.payload.ttl_return,
        avg_annual: action.payload.avg_annual,
        time_calculated: action.payload.time
      }

    case 'REPLACE_CLOSED_END':
      const index = state.findIndex(closedEnd => closedEnd.id === action.closedEnd.id)
        return [
          ...state.slice(0, index),
          action.closedEnd,
          ...state.slice(index + 1)
        ];

    case 'CLEAR_FUND':
      return {
        funds: state.funds
      }
    default:
      return state;
  }
}
