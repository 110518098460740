import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const TaxDocService = {
  fetchTaxDocs() {
    return fetch(`${API_URL}/gab_tax_docs`)
    .then( r => r.json() )
  },

  addNewTaxDoc(data) {
    return fetch(`${API_URL}/gab_tax_docs`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  },

  updateTaxDoc(id, data) {
    return fetch(`${API_URL}/gab_tax_docs/${id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({ data }),
      headers: headers()
    }).then( r => r.json() )
  },

  deleteTaxDoc(id) {
    return fetch(`${API_URL}/gab_tax_docs/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }


}

export default TaxDocService;
