export default (state = {}, action) => {
  switch(action.type) {
    case 'UPLOADING_HOLDINGS':
      return {
        ...state,
        successfulUpload: false,
        currentlyUploading: true
      }
    case 'SUCCESSFUL_HOLDINGS_UPLOAD':
      return {
        ...state,
        successfulUpload: true,
        currentlyUploading: false
      }
    case 'RESET_HOLDINGS_UPLOAD':
      return {
        ...state,
        successfulUpload: false
      }
    default:
      return state
  }
}
