import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const MediaUploadService = {
  uploadNewmedia(media) {
    return fetch(`${API_URL}/media_filepaths`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ media }),
      headers: headers()
    }).then( r => r.json() )
  },

  fetchMedia() {
    return fetch(`${API_URL}/media_filepaths`)
    .then( r => r.json() )

  }
}

export default MediaUploadService;
