export default (state = [], action) => {
  switch(action.type) {
    case 'UPLOADING_DOC':
      return {
        ...state,
        isUploading: true,
        successfulUpload: false
      }
    case 'CURRENTLY_FETCHING':
      return {
        ...state,
        documents: []
      }
    case 'SUCCESSFUL_UPLOAD':
      return {
        ...state,
        successfulUpload: true,
        isUploading: false
      }
    case 'SUCCESSFUL_DOCS_FETCH':
      return {
        ...state,
        documents: action.docs
      }
    case 'SUCCESSFUL_MM_QREPS_BY_YEAR_FETCH':
      return {
        ...state,
        qrepsMM: action.qreps
      }
    case 'SUCCESSFUL_CE_QREPS_BY_YEAR_FETCH':
      return {
        ...state,
        qrepsCE: action.qreps
      }
    case 'SUCCESSFUL_OE_QREPS_BY_YEAR_FETCH':
      return {
        ...state,
        qrepsOE: action.qreps
      }
    case 'SUCCESSFUL_SICAV_DOCS_FETCH':
      return {
        ...state,
        sicavDocuments: action.docs
      }
    case 'SUCCESSFUL_PROXY_STATEMENTS_FETCH':
      return {
        ...state,
        proxyStatements: action.docs
      }
    case 'UPLOAD_COMPLETE':
      return {
        documents: [],
        successfulUpload: false,
        isUploading: false
      }
    case 'UPLOAD_FAILED':
      return {
        ...state,
        errors: action.doc.errors,
        successfulUpload: false,
        isUploading: false,
      }
    case 'SUCCESSFUL_SOIS_FETCH':
      return {
        ...state,
        sois: action.sois
      }
    default:
      return state;
  }
}
