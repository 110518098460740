import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import S3Uploader from './S3Uploader'

class EditMedia extends Component {
  constructor(){
    super()
    this.state = {
      media: {}
    }
  }

  componentDidUpdate(prevProps){
    const { selected } = this.props
    if(!prevProps.selected && !!selected){
      this.setState({ media: selected })
    }
  }

  handleResponse = r => {
    const { media } = this.state
    media["image_url"] = r.publicUrl
    this.setState({ media })
  }

  handleChange = e => {
    const { name, value } = e.target
    const { media } = this.state
    media[name] = value
    this.setState({ media })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.handleUpdateArticle(this.state.media)
  }

  render(){
    const { selected, clearSelected, isTV } = this.props
    const { media } = this.state
    return (
      <Modal
        dialogClassName="pm-edit-modal"
        show={selected}
        onHide={clearSelected}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Media
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            {
              isTV ?
              null
              :
              <div>
                <label className="upload-input-label">Author:</label>
                <input className="media-info-input admin-input" type="text" name="author" value={media.author} onChange={this.handleChange}></input>
              </div>
            }
            <label className="upload-input-label">Description:</label>
            <input className="media-info-input admin-input" type="text" name={ isTV ? "tv_description" : "article_description" } value={isTV ? media["tv_description"] : media["article_description"]} onChange={this.handleChange}></input>
            <label className="upload-input-label">Article/Video URL:</label>
            <input className="media-info-input admin-input" type="text" name={ isTV ? "video_url" :"article_url" } value={isTV ? media["video_url"] : media["article_url"]} onChange={this.handleChange}></input>
            <label className="upload-input-label">Upload New Image <small>(3:2 aspect ratio recommended)</small></label>
            <S3Uploader
              params={{}}
              apiPath={"carousel_objects/s3/sign"}
              handleResponse={this.handleResponse}
              handle
            />
            <input type="submit" value="Update" id="upload-button" />
          </form>
        </Modal.Body>
      </Modal>
    )
  }
}

export default EditMedia
