export default (state = [], action) => {
  switch(action.type){
    case 'SUCCESSFUL_NAV_DISTRIBUTION_FETCH':
      return action.distributionData

    case 'SUCCESSFUL_OE_DISTRIBUTIONS_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyDistributions: action.yearlyDistributions
      }
    case 'SUCCESSFUL_CE_DISTRIBUTIONS_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyDistributionsCE: action.yearlyDistributionsCE
      }
    case 'SUCCESSFUL_MM_DISTRIBUTIONS_BY_YEAR_FETCH':
      return {
        ...state,
        yearlyDistributionsMM: action.yearlyDistributionsMM
      }
    default:
      return state;
  }
}
