import React from 'react'
import { Link } from 'react-router-dom'
// import routes from '../../routes'

const AdminNavContent = (props) => {
  const {
    handleCarouselClick,
    handleUploadClick,
    handleInsightsClick,
    handlePersonnelClick,
    handleFundDataClick,
    handleLogout,
    uploadsChevron,
    insightsChevron,
    carouselChevron,
    personnelChevron,
    fundDataChevron,
    closeMenu,
	adminRole,
  } = props
  const [ loggedInAsBasicUser, loggedInAsSuperUser ] = [ adminRole > 1, adminRole === 0 ];
//   const navLinksToRender = routes.reduce( ( result, { path, component, excludeBasicUsers, allowOnlySuperUsers }, index ) => {}, [] );
  return(
    <div onClick={closeMenu}>
      <img  data-aos-once="true" id="admin-nav-img" src="https://s3.us-east-2.amazonaws.com/gab-images/gabelliwhitevertical.jpg" alt="GAMCO Investors"/>
      { !loggedInAsBasicUser && <Link to="/admin/uploads/sec_filing">
        <div className="admin-nav-btn">Corporate Filings</div>
      </Link> }
      { !loggedInAsBasicUser && <Link to="/admin/div_estimates">
        <div className="admin-nav-btn">Dividend Estimates</div>
      </Link> }
      <Link to="/admin/request">
        <div className="admin-nav-btn">Feature Request</div>
      </Link>
      { !loggedInAsBasicUser && <Link to="/admin/featured-funds">
        <div className="admin-nav-btn">Featured Funds</div>
      </Link> }
      { !loggedInAsBasicUser && <><div className="admin-nav-btn" onClick={handleCarouselClick}>Funds Carousel {carouselChevron}</div>
      <div className="hidden-carousel admin-nav-hidden">
        <Link to="/admin/carousel/new">
          <div className="admin-nav-btn">Add New</div>
        </Link>
        <Link to="/admin/carousel/edit">
          <div className="admin-nav-btn">Edit Existing</div>
        </Link>
      </div></> }
      { !loggedInAsBasicUser && <><div className="admin-nav-btn" onClick={handleFundDataClick}>Fund Data {fundDataChevron}</div>
      <div className="admin-nav-hidden hidden-fund-data">
        <Link to="/admin/update_holdings">
          <div className="admin-nav-btn">Upload Holdings</div>
        </Link>
        <Link to="/admin/edit_holdings">
          <div className="admin-nav-btn">Edit Holdings</div>
        </Link>
        <Link to="/admin/etf_holdings">
          <div className="admin-nav-btn">ETF Holdings</div>
        </Link>
        <Link to="/admin/update_return_data">
          <div className="admin-nav-btn">Return Data</div>
        </Link>
        <Link to="/admin/update_sectors">
          <div className="admin-nav-btn">Sectors Update</div>
        </Link>
      </div></> }
      { !loggedInAsBasicUser && <><div className="admin-nav-btn" onClick={handleInsightsClick}>Insights {insightsChevron}</div>
      <div className="admin-nav-hidden hidden-insights">
        <Link to="/admin/insights">
          <div className="admin-nav-btn">View All</div>
        </Link>
        <Link to="/admin/insights/new">
          <div className="admin-nav-btn">Add New</div>
        </Link>
      </div></> }
      <Link to="/admin/leads">
        <div className="admin-nav-btn">Leads</div>
      </Link>
      { !loggedInAsBasicUser && <Link to="/admin/composites">
        <div className="admin-nav-btn">Strategy Composites</div>
      </Link> }
      <Link to="/admin/link_generator">
        <div className="admin-nav-btn">Link Generator</div>
      </Link>
      <Link to="/admin/attachments">
        <div className="admin-nav-btn">Host Attachment</div>
      </Link>
      <Link to="/admin/events">
        <div className="admin-nav-btn">Events</div>
      </Link>
      { !loggedInAsBasicUser && <Link to="/admin/docs">
        <div className="admin-nav-btn">Manage Uploads</div>
      </Link> }
      { !loggedInAsBasicUser && <Link to="/admin/media-admin/news">
        <div className="admin-nav-btn">Media Admin</div>
      </Link> }
      { !loggedInAsBasicUser && <><div className="admin-nav-btn" onClick={handlePersonnelClick}>Personnel {personnelChevron}</div>
      <div className="hidden-personnel admin-nav-hidden">
        <Link to="/admin/portfolio-manager-admin">
          <div className="admin-nav-btn">Portfolio Managers</div>
        </Link>
        <Link to="/admin/teams">
          <div className="admin-nav-btn">Teams</div>
        </Link>
        <Link to="/admin/analysts">
          <div className="admin-nav-btn">Analysts</div>
        </Link>
        <Link to="/admin/administrators">
          <div className="admin-nav-btn">Administration</div>
        </Link>
      </div></> }
      { !loggedInAsBasicUser && <Link to="/admin/uploads/sicav">
        <div className="admin-nav-btn">SICAV</div>
      </Link> }
      { !loggedInAsBasicUser && <Link to="/admin/nav_fundprices">
        <div className="admin-nav-btn">Check SICAV NAVs</div>
      </Link> }
      { !loggedInAsBasicUser && <Link to="/admin/kiids">
        <div className="admin-nav-btn">Manage KIIDs</div>
      </Link> }
      { !loggedInAsBasicUser && <><div className="admin-nav-btn" onClick={handleUploadClick}>Upload {uploadsChevron}</div>
      <div className="admin-nav-hidden hidden-uploads">
        <Link to="/admin/uploads/annual_report">
          <div className="admin-nav-btn">Annual Reports</div>
        </Link>
        <Link to="/admin/uploads/leverage">
          <div className="admin-nav-btn">Closed End Leverage</div>
        </Link>
        <Link to="/admin/uploads/commentary">
          <div className="admin-nav-btn">Commentary</div>
        </Link>
        <Link to="/admin/uploads/factsheet">
          <div className="admin-nav-btn">Factsheet</div>
        </Link>
        <Link to="/admin/uploads/form8937">
          <div className="admin-nav-btn">Form 8937</div>
        </Link>
        <Link to="/admin/uploads/holdings">
          <div className="admin-nav-btn">Holdings Report</div>
        </Link>
        <Link to="/admin/uploads/important_tax_info">
          <div className="admin-nav-btn">Important Tax Info</div>
        </Link>
        <Link to="/admin/uploads/media">
          <div className="admin-nav-btn">Media</div>
        </Link>
        <Link to="/admin/uploads/soi">
          <div className="admin-nav-btn">Sched. of Investment</div>
        </Link>
        <Link to="/admin/uploads/off_quarter_report">
          <div className="admin-nav-btn">Off-Quarter Reports</div>
        </Link>
        <Link to="/admin/uploads/preferred_doc">
          <div className="admin-nav-btn">Pref. Issue Docs</div>
        </Link>
        <Link to="/admin/uploads/press_release">
          <div className="admin-nav-btn">Press Release/Dividend Notice</div>
        </Link>
        <Link to="/admin/uploads/prospectus">
          <div className="admin-nav-btn">Prospectus</div>
        </Link>
        <Link to="/admin/uploads/prospectus_supplement">
          <div className="admin-nav-btn">Prospectus Supplement</div>
        </Link>
        <Link to="/admin/uploads/proxy_statement">
          <div className="admin-nav-btn">Proxy Statement</div>
        </Link>
        <Link to="/admin/uploads/quarterly_report">
          <div className="admin-nav-btn">Quarterly Reports</div>
        </Link>
        <Link to="/admin/uploads/sai">
          <div className="admin-nav-btn">SAI</div>
        </Link>
        <Link to="/admin/uploads/semi_annual_report">
          <div className="admin-nav-btn">Semi-Annual Report</div>
        </Link>
        <Link to="/admin/uploads/summary_prospectus">
          <div className="admin-nav-btn">Summary Prospectus</div>
        </Link>
        <Link to="/admin/uploads/tax_doc">
          <div className="admin-nav-btn">Tax Form 19A-1</div>
        </Link>
        <Link to="/admin/uploads/xbrl">
          <div className="admin-nav-btn">XBRL</div>
        </Link>
      </div></> }
      { !loggedInAsBasicUser && <Link to="/admin/s3_commentaries">
        <div className="admin-nav-btn">Web Commentaries</div>
      </Link> }
	  { loggedInAsSuperUser && <Link to="/admin/admin_portal_users">
		<div id="admin-settings-btn" className="admin-nav-btn">Users</div>
	  </Link> }
      <Link to="/admin/settings">
        <div id="admin-settings-btn" className="admin-nav-btn">Settings</div>
      </Link>
      <div className="admin-nav-btn" onClick={handleLogout}>Logout</div>
    </div>
  )
}

export default AdminNavContent

// <Link to="/admin/chat/login">
//   <div  data-aos-once="true" data-aos-delay="100" className="admin-nav-btn">Chat</div>
// </Link>
