import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const XbrlService = {
  uploadXbrl(xbrl) {
    return fetch(`${API_URL}/xbrl_reports`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(xbrl),
      headers: headers()
    }).then( r => r.json() )
    .catch( e => console.log(e) )
  },

  fetchXbrls(fundCode) {
    if(!!fundCode) {
      return fetch(`${API_URL}/gf_fund_domain/${fundCode}/xbrl_reports`)
      .then( r => r.json() )
    } else {
      return fetch(`${API_URL}/xbrl_reports`)
      .then( r => r.json() )
    }
  }
}

export default XbrlService;
