import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adminLostPassword, deleteAdmin, fetchAdmins, updateAdmin } from "../../redux/modules/Admin/actions";

const AdminPortalUsers = () => {

	const dispatch = useDispatch();

	const adminPortalUsers = useSelector( state => state.admin.admins );

	useEffect( () => dispatch( fetchAdmins() ), [ dispatch ] );

	const [ userFormState, setUserFormState ] = useState();
	const [ showResetPasswordWarning, setShowResetPasswordWarning ] = useState( null );
	const [ editMessage, setShowEditMessage ] = useState( null );

	const handleEditFormChange = ( { target } ) => setUserFormState( { ...userFormState, [ target.name ]: target.value } );

	const flashMessage = message => {
		setShowEditMessage( message );
		setTimeout( () => setShowEditMessage( null ), 3000 );
	};

	const handleEditFormSubmit = submissionEvent => {
		submissionEvent.preventDefault();
		const confirmDeletion = window.confirm( 'Are you sure you want to make these changes?' );
		if ( confirmDeletion ) {
			dispatch( updateAdmin( userFormState.id, {
				username: userFormState.username,
				full_name: userFormState.full_name,
				role: userFormState.role,
			} ) );
			dispatch( fetchAdmins() );
			flashMessage( 'Changes saved successfully' );
		}
	}

	const handleResetPassword = user => {
		window.scrollTo( 0, 0, { behavior: 'smooth' } );
		setUserFormState( null );
		setShowResetPasswordWarning( user.id );
		const confirmPasswordReset = window.confirm( 'This will reset this user\'s password to \'123456\'. Are you sure you want to reset this password? This cannot be undone!' )
		if ( confirmPasswordReset ) {
			dispatch( adminLostPassword( user.id ) )
			flashMessage( 'Password reset successfully' );
		} else {
			setShowResetPasswordWarning( null );
			flashMessage( 'Password reset cancelled' );
		}
	};

	const handleDeleteUser = userId => {
		const confirmDeletion = window.confirm( 'Are you sure you want to delete this user? This user\'s contributions will remain live, but this cannot be undone!' );
		if ( confirmDeletion ) {
			dispatch( deleteAdmin( userId ) );
			dispatch( fetchAdmins() );
			flashMessage( 'User successfully deleted' );
		} else flashMessage( 'User deletion cancelled' );
	};

	return <div>

		{ editMessage && <div style={ { position: 'absolute', top: 10, right: 10, padding: '10px', backgroundColor: '#f00', color: 'white', fontWeight: 600 } }>{ editMessage }</div> }
		
		<h3 style={ { marginTop: '50px', fontFamily: 'Gotham', position: 'relative', width: '80%', margin: '3.5% auto' } }>Edit Admin Portal users</h3>

		{/* <button onClick={ () => flashMessage( 'test' ) }>Test message</button> */}

		{ userFormState && <form onSubmit={ handleEditFormSubmit }>
			<label htmlFor='username'>Username: </label>
			<input name='username' required value={ userFormState.username } onChange={ handleEditFormChange } />
			<label htmlFor='full_name'>Full name: </label>
			<input name='full_name' required value={ userFormState.full_name } onChange={ handleEditFormChange } />
			<label htmlFor='role'>Role: </label>
			<select name='role' value={ userFormState.role } onChange={ handleEditFormChange }>
				<option value={ 2 }>Basic user</option>
				<option value={ 1 }>Regular user</option>
				<option value={ 0 }>Superuser</option>
			</select>
			<input type='submit' value="✓ Save" />
			<button onClick={ () => setUserFormState( null ) }>✗ Cancel</button>
			{ [ 0, '0' ].includes( userFormState.role ) && <div style={ { color: '#f00' } }>&nbsp;⚠ A superuser can make extensive and possibly irreversible changes to the Gabelli website/related database! </div> }
		</form> }

		<div style={ { display: 'flex', flexFlow: 'row wrap' } }>
			{ adminPortalUsers?.map( user => <div style={ {
				backgroundColor: ( userFormState?.id || showResetPasswordWarning ) === user.id ? '#ddd' : 'white',
				width: '15vw',
				minHeight: '10vh',
				border: ( userFormState?.id || showResetPasswordWarning ) === user.id ? '3px solid red' : '1px solid #ddd',
				padding: '10px',
				margin: '10px'
			} } key={ user.id }>
				<button onClick={ () => {
					window.scrollTo( 0, 0, { behavior: 'smooth' } );
					setUserFormState( user );
					setShowResetPasswordWarning( null );
				} }>✎ Edit</button>
				<button onClick={ () => {
					handleResetPassword( user );
				} }>● Reset Password</button>
				<button onClick={ () => handleDeleteUser( user.id ) }>🗑 Delete</button>
				<div style={ { fontSize: '14pt' } }>{ user.username }</div>
				<div>{ user.full_name }</div>
				<div style={ { fontSize: '8pt', fontWeight: user.role === 0 ? 'bold' : 'initial', fontStyle: user.role > 1 ? 'italic' : 'initial' } }>
					{ user.role === 0 ? 'Super' : user.role > 1 ? 'Basic user' : '' }
				</div>
			</div> ) }
		</div>

	</div>;

};

export default AdminPortalUsers;

const adminCardStyle = { width: '15vw', minHeight: '10vh', border: '1px solid #ddd', padding: '10px', margin: '10px' }