export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_8937S_FETCH':
      return {
        ...state,
        forms: action.forms,
      }
    case 'SUCCESSFUL_8937S_LIST_FETCH':
      return {
        ...state,
        formsList: action.forms
      }
    case 'SUCCESSFUL_8937_UPLOAD':
      return {
        ...state,
        successfulUpload: true
      }
    case '8937_UPLOAD_COMPLETE':
      return {
        ...state,
        successfulUpload: false
      }
    case 'FAILED_8937_UPLOAD':
      return {
        ...state,
        errors: action.form.errors
      }
    case 'CLEAR_FORMS_DURING_UPDATE':
      return {
        ...state,
        formsList: []
      }
    default:
      return state
  }
}
