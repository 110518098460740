export default (state = {}, action) => {
  switch(action.type) {
    case 'SUCCESSFUL_WISHLIST_FETCH':
      return {
        ...state,
        wishlist: action.items
      }
    case 'SUBMISSION_FAILED':
      return {
        errors: action.errors,
        success: false
      }
    case 'SUBMISSION_SUCCESS':
      return {
        success: true,
        errors: null
      }
    case 'RESET_FEATURE_REQUEST':
     return {
       success: null,
       errors: null
     }
    default:
      return state;
  }
}
