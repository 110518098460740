// import { API_URL } from '../constants'
import { API_URL } from '../constants'

export const headers = () => {
	const token = localStorage.getItem('token')
	return {
	  'Accept': 'application/json',
	  'Content-Type': 'application/json',
	  'Authorization': `Bearer: ${token}`
	}
}

const FundsCarouselService = {
  fetchCarousel() {
    return fetch(`${API_URL}/carousel_objects`)
    .then(response => response.json())
  },

  fetchCarouselArchive() {
    return fetch(`${API_URL}/carousel_objects/archive`)
    .then(response => response.json())
  },

  addCarouselObject(carouselObject) {
    return fetch(`${API_URL}/carousel_objects`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(carouselObject),
      headers: headers()
    }).then( r => r.json() )
  },

  deleteCarouselObject(id) {
    return fetch(`${API_URL}/carousel_objects/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then(r => r.json() )
  },

  updateCarouselObject(id, data) {
    return fetch(`${API_URL}/carousel_objects/${id}`, {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(data),
      headers: headers()
    }).then( r => r.json() )
  },

  archiveCarouselObject(id) {
    return fetch(`${API_URL}/carousel_objects/archive/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  },

  unarchiveCarouselObject(id) {
    return fetch(`${API_URL}/carousel_objects/unarchive/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  }

}

export default FundsCarouselService;
