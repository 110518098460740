import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import SuccessModal from '../AdminPortal/SuccessModal'
import { fetchEstimates, deleteEstimate } from '../../redux/modules/DivEstimates/actions'
import styled from 'styled-components'

class DivEstimates extends Component {
  constructor(){
    super()

    this.state = {
      showSuccess: false
    }
  }

  componentDidMount(){
    this.props.fetchEstimates()
  }

  componentDidUpdate(prevProps){
    if (!prevProps.success && !!this.props.success) {
      this.setState({
        showSuccess: true
      })
    }
  }

  handleRemoveClick = ( id, fund_name ) => {
    const confirmRemoval = window.confirm( `Are you sure you want to remove this estimate for ${ fund_name }?` )
    if ( confirmRemoval ) this.props.deleteEstimate( id );
  }

  render() {
    const { estimates } = this.props
    const { showSuccess } = this.state
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={showSuccess}
          handleClose={ () => this.setState({ showSuccess: false }) }
          phrase={"Dividend estimate removed."}
        />
        <div id="upload-form-container">
          <h3>Dividend Estimates</h3>
          <BodyContainer>
            <Link to="/admin/div_estimates_new">Add new</Link><LinkSpan><Link to="/admin/div_estimates_intro">Update Intro</Link></LinkSpan>
            <List>
              { estimates?.map( ( { id, fund_name, fund_code, record_date, paid } ) => (
                <ListItem key={id}>
                  <span>{fund_name} ({record_date})</span>
                  <span> -<RemoveBtn onClick={() => this.handleRemoveClick(id, fund_name)}>Remove</RemoveBtn></span>
                  { !paid && <span>-<RemoveBtn onClick={ () => this.props.history.push( `/admin/div_estimates_new?replace=${ id }&fund_code=${ fund_code }` ) }>Replace with notice</RemoveBtn></span> }
                </ListItem>
              ) ) }
            </List>
          </ BodyContainer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  estimates: state.estimates.estimates || [],
  success: state.estimates.success
})

export default withRouter( connect( mapStateToProps, {
  fetchEstimates,
  deleteEstimate
} )( DivEstimates ) );

const List = styled.ul `
  padding-left: 0px;
  margin-top: 15px;
`

const ListItem = styled.li `
  list-style-type: none;
  margin-bottom: 10px;
`

const BodyContainer = styled.div `
  margin-top: 10px;
`

const LinkSpan = styled.span `
  margin-left: 10%;
`

const RemoveBtn = styled.button `
  color: #005b97;
  border: none;
  background-color: white;
`
