import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}


const ClosedEndService = {
  fetchClosedEnds() {
    return fetch(`${API_URL}/nav_closed_ends`)
    .then(response => response.json())
  },

  fetchClosedEnd(fund_code){
    return fetch(`${API_URL}/nav_closed_ends/${fund_code}`)
      .then(response => response.json())
  },

  fetchFundPrices(fund_code){
    return fetch(`${API_URL}/nav_fundprices/${fund_code}`)
      .then(response => response.json())
  },

  fetchFundAssets(fund_code){
    return fetch(`${API_URL}/nav_fund_assets/${fund_code}`)
    .then(response => response.json())
  },

  fetchFundSupps(fund_code){
    return fetch(`${API_URL}/funds/${fund_code}`)
    .then(response => response.json())
  },

  fetchGrowthOf10k(fund_code){
    return fetch(`${API_URL}/nav_growth_2/${fund_code}`)
      .then(response => response.json())
  },

  fetchCalendarYearReturns(fund_code){
    return fetch(`${API_URL}/nav_calendar_yr_returns/${fund_code}`)
      .then(response => response.json())
  },

  fetchHoldings(fund_code){
    return fetch(`${API_URL}/fund_holdings/${fund_code}`)
      .then(response => response.json())
  },

  updateClosedEndHoldings( ce_holdings ){
    return fetch( `${ API_URL }/fund_holdings/${ ce_holdings.id }`, {
		method: 'PATCH',
		headers: headers(),
		body: JSON.stringify( ce_holdings )
	  } )
      .then( response => response.json() );
  },

  fetchManagers(fund_code){
    return fetch(`${API_URL}/funds/${fund_code}/portfolio_managers`)
      .then(response => response.json())
  },

  fetchMedia(fund_code){
    return fetch(`${API_URL}/fund_press_releases/${fund_code}`)
    .then(response => response.json())
  },

  fetchPerformance(fund_code, dates){
    return fetch(`${API_URL}/nav_performance/${fund_code}`, {
      method: 'POST',
      headers: headers(),
      body: JSON.stringify({
        'start_date': dates.start_date,
        'end_date': dates.end_date
      })
    })
    .then(response => response.json())
  }
}

export default ClosedEndService;
