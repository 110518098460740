// import { API_URL } from '../constants'
import { API_URL } from '../constants'


export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const PressReleaseService = {
  uploadPressRelease(pr) {
    return fetch(`${API_URL}/press_releases`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ pr }),
      headers: headers()
    }).then( r => r.json() )
  },

  fetchPressReleases(limit) {
    return fetch(`${API_URL}/press_releases?limit=null&filter_term=null&fund_code=null`)
    .then( r => r.json() )
  },

  fetchCePressReleases(){
    return fetch(`${API_URL}/ce_press_releases`)
    .then(response => response.json())
  },

  updatePr(id, pr){
    return fetch(`${API_URL}/press_releases/${id}`, {
      method: 'PUT',
      credentials: 'include',
      headers: headers(),
      body: JSON.stringify({ pr })
    }).then( r => r.json() )
  },

  deletePr(id){
    return fetch(`${API_URL}/press_releases/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: headers()
    }).then( r => r.json() )
  },

}



export default PressReleaseService;
