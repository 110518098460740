import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Select from '../../components/Select'
import { Icon } from '@blueprintjs/core'
import { fetchSectors, updateSectors } from '../../redux/modules/FundsSectors/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import SuccessModal from './SuccessModal'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class SectorUpdate extends Component {
  constructor(){
    super()

    this.state = {
      fundCode: null,
      sectors: {},
      showSuccess: false
    }
  }

  componentDidMount(){
    this.props.fetchSectors()
    this.props.fetchFunds()
  }

  handleSelectFund = (e) => {
    if(e){
      this.setState({ fundCode: e.value })
    } else {
      this.setState({ fundCode: null })
    }
  }

  handleSelectSector = (e) => {
    if(e){
      const { sectors } = this.state
      sectors[e.value] = 0
      this.setState({ sectors })
    }
  }

  removeSector = (sectorId) => {
    const { sectors } = this.state
    delete sectors[sectorId]
    this.setState({ sectors })
  }

  handlePctChange = (sectorId, e) => {
    const { sectors } = this.state
    sectors[sectorId] = e.target.value
    this.setState({ sectors })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { fundCode, sectors } = this.state
    this.props.updateSectors(fundCode, sectors)
    this.setState({
      fundCode: null,
      sectors: {},
      showSuccess: true
    })
  }

  handleClose = () => {
    this.setState({ showSuccess: false })
  }

  render(){
    const activeSectors = this.state.sectors
    const { sectors, funds } = this.props
    let sectorOptions = []
    let fundOptions = []
    if(sectors.length){
      sectorOptions = sectors.filter( s => !Object.keys(activeSectors).some( a => {
          return s.id === parseInt(a)
        })
      ).map( s => {
        return { value: s.id, label: s.name }
      })
    }
    if(funds.length){
      fundOptions = funds.map( f => {
        return { value: f.fund_code, label: f.fundshortname }
      })
    }
    const selectedSectors = Object.keys(activeSectors).map( a => {
      const sector = sectors.find( s => parseInt(a) === s.id )
      return (
        <li className="sectors" key={a}>
          <Icon className="remove-fund-x" icon="cross" onClick={ () => this.removeSector(a) }/>
          {sector.name}
          <input type="number" value={activeSectors[a]} step=".1" max="100" min="0" className="sector-input media-info-input" onChange={ (e) => this.handlePctChange(a, e) }/>
        </li>
      )
    })
    return (
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={this.state.showSuccess}
          handleClose={this.handleClose}
          phrase="Sectors successfully updated."
        />
        <div id="upload-form-container">
          <h3>Update Fund Sectors</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { /*!!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null */ }
          <form id="admin-upload-form" className="sector-selector-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Select a Fund:</label>
            <SelectWithAutofill
              options={fundOptions}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectFund}
              onClear={(e) => this.handleSelectFund(null)}
              value={this.state.fundCode}
              selectKey={1}
            />
            <label className="upload-input-label">Add a Sector:</label>
            <SelectWithAutofill
              options={sectorOptions}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a sector..."
              handleChange={this.handleSelectSector}
              onClear={(e) => this.handleSelectSector(null)}
              value={this.state.sectors}
              selectKey={2}
            />
            <ul>
              {selectedSectors}
            </ul>

            <input id="upload-button" type="submit" value="Upload" disabled={(Object.keys(activeSectors).length === 0) || !this.state.fundCode}/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  funds: state.funds,
  sectors: state.sectors
})

export default connect(mapStateToProps, { fetchSectors, fetchFunds, updateSectors })(SectorUpdate)
