import React from 'react'
import BlockStyleButton from './BlockStyleButton'
import { Icon, Tooltip } from '@blueprintjs/core'

export const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item'}
]

export function getBlockStyle(block){
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote"
    default:
      return null
  }
}

class BlockStyleToolbar extends React.Component {
  render() {
    const { editorState } = this.props
    const selection = editorState.getSelection()
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType()
    return (
      <div id="list-btns">
        <span className="RichEditor-controls">
        <Tooltip content="Add Image" className="editor-btn-tooltip">
          <button onClick={this.props.promptForImage}><Icon icon="media" color="#6dcff6" /></button>
        </Tooltip>

        {BLOCK_TYPES.map( type => {
          return (
            <BlockStyleButton
              active={type.style === blockType}
              label={type.label}
              onToggle={this.props.onToggle}
              style={type.style}
              key={type.label}
              type={type}
            />
          )
        })}

        </span>
      </div>
    )
  }
}

export default BlockStyleToolbar
