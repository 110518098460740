import TeamService from '../../../services/TeamService';
import {
  unsuccessfulFetchRequest
} from '../../appTransactions';

const successfulTeamsFetch = teams => {
  return {
    type: 'SUCCESSFUL_TEAMS_FETCH',
    teams
  }
}

const successfulTeamAdd = teams => {
  return {
    type: 'SUCCESSFUL_TEAM_ADD',
    teams
  }
}

const successfulTeamDelete = teams => {
  return {
    type: 'SUCCESSFUL_TEAM_DELETE',
    teams
  }
}

const successfulTeamFetch = (teams) => {
  return {
    type: 'SUCCESSFUL_TEAM_FETCH',
    teams
  }
}

export const fetchTeam = (id) => {
  return dispatch => {
    TeamService.fetchTeam(id)
    .then( team => dispatch(successfulTeamFetch(team)))
  }
}

export const fetchTeams = () => {
  return dispatch => {
    TeamService.fetchTeams()
      .then( teams => dispatch(successfulTeamsFetch(teams)) )
      .catch( error => dispatch(unsuccessfulFetchRequest()) )
  }
}

export const addTeam = (newTeam) => {
  return dispatch => {
    TeamService.addNewTeam(newTeam)
    .then( teams => dispatch(successfulTeamAdd(teams)) )
  }
}

export const updateTeam = (id, teamData) => {
  return dispatch => {
    TeamService.updateTeam(id, teamData)
    .then( teams => dispatch(successfulTeamAdd(teams)) )
  }
}

export const deleteTeam = (id) => {
  return dispatch => {
    TeamService.deleteTeam(id)
    .then( teams => dispatch(successfulTeamDelete(teams)) )
  }
}
