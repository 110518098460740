import React, { Component } from 'react'
import { connect } from 'react-redux'
import QuarterSelector from './QuarterSelector'
import S3Uploader from './S3Uploader'
import { uploadFiling, uploadComplete } from '../../redux/modules/Filings/actions'
import SuccessModal from './SuccessModal'

class GamcoFilingsUpload extends Component {
  constructor(){
    super()

    this.state = {
      doc_type: "",
      year: "",
      quarter: "",
      filepath: "",
      showSubmit: false
    }
  }

  handleChange = (e) => {
    const { state } = this
    const { name, value } = e.target
    state[name] = value
    this.setState(state)
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100) {
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.uploadFiling(this.state)
    this.setState ({
      doc_type: "",
      year: "",
      quarter: "",
      filepath: "",
      showSubmit: false
    })
    document.getElementsByName("s3-uploader")[0].value = ''
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  render(){
    const { errors } = this.props
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase={"Document successfully uploaded"}
        />
        <div id="upload-form-container">
          <h3>{"10Q, 10K, Annual Report Upload"}</h3>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Upload Type:</label>
            <select name="doc_type" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.doc_type}>
              <option value=""></option>
              <option value="10Q">{"10Q"}</option>
              <option value="10K">{"10K"}</option>
              <option value="Annual Report">Annual Report</option>
              <option value="Proxy">Proxy</option>
              <option value="XBRL">XBRL</option>
            </select>
            <br/>
            <QuarterSelector
              handleChange={this.handleChange}
              state={this.state}
              type={"corporate_filings"}
            />
            <label className="upload-input-label s3-upload-label">Select a File:</label>
            <S3Uploader
              params={{ "doc_type": "coporate_filings" }}
              accept='application/pdf'
              apiPath="corporate_filings/s3/sign"
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" className="admin-input" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  successfulUpload: state.filings.successfulUpload,
  errors: state.filings.errors
})

export default connect(mapStateToProps, { uploadFiling, uploadComplete })(GamcoFilingsUpload)
