import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { withCookies } from 'react-cookie'
import { ScrollContext } from 'react-router-scroll-4';
import { connect } from 'react-redux'
import { authenticate } from '../redux/modules/Admin/actions'
// import { routes } from '../routes'
import CodeError from '../views/ErrorHandling/CodeError'
import PageNotFound from '../views/ErrorHandling/PageNotFound'
import MatchAuthenticated from '../components/MatchAuthenticated'
// import ChatAuthenticated from '../components/ChatAuthenticated'
import InsightEditor from '../views/InsightEditor/index'
import AdminLogin from '../views/AdminPortal/AdminLogin'
import AdminPortal from '../views/AdminPortal'
import AdminNav from '../views/AdminPortal/AdminNav'
import AdminUpload from '../views/AdminPortal/AdminUpload'
import DocumentUpload from '../views/AdminPortal/DocumentUpload'
import XBRLUpload from '../views/AdminPortal/XBRLUpload'
import PreferredDocUploader from '../views/AdminPortal/PreferredDocUploader'
import PressReleaseUpload from '../views/AdminPortal/PressReleaseUpload'
import AdminFeaturedFunds from '../views/AdminPortal/AdminFeaturedFunds'
import MediaAdminNews from '../views/AdminPortal/MediaAdminNews'
import MediaAdminTv from '../views/AdminPortal/MediaAdminTv'
import MediaAdminPodcast from '../views/AdminPortal/MediaAdminPodcast'
import TvEntry from '../views/AdminPortal/TvEntry'
import ArticleEntry from '../views/AdminPortal/ArticleEntry'
import PortfolioManagerAdmin from '../views/AdminPortal/PortfolioManagerAdmin'
import PortfolioManagerNew from '../views/AdminPortal/PortfolioManagerNew'
import TeamsNew from '../views/AdminPortal/TeamsNew'
import TeamsAdmin from '../views/AdminPortal/TeamsAdmin'
import Personnel from '../views/AdminPortal/Personnel'
import PersonnelNew from '../views/AdminPortal/PersonnelNew'
import GamcoFilingsUpload from '../views/AdminPortal/GamcoFilingsUpload'
import SicavUpload from '../views/AdminPortal/SicavUpload'
import AdminInsights from '../views/AdminPortal/AdminInsights'
import Carousel from '../views/AdminPortal/Carousel'
import TaxDocUploader from '../views/AdminPortal/TaxDocUploader'
import Form8937Uploader from '../views/AdminPortal/Form8937Uploader'
import ImportantTaxUploader from '../views/AdminPortal/ImportantTaxUploader'
import LevAnalysisUpload from '../views/AdminPortal/LevAnalysisUpload'
import DocViewer from '../views/DocViewer'
import SectorUpdate from '../views/AdminPortal/SectorUpdate'
import PerfStatsUpdate from '../views/AdminPortal/PerfStatsUpdate'
import HoldingsUpdate from '../views/AdminPortal/HoldingsUpdate'
import AdminSettings from '../views/AdminPortal/AdminSettings'
import LinkGenerator from '../views/AdminPortal/LinkGenerator'
import RequestForm from '../views/AdminPortal/Wishlist/RequestForm'
import Wishlist from '../views/AdminPortal/Wishlist'
import WishlistItem from '../views/AdminPortal/Wishlist/WishlistItem'
import Leads from '../views/Leads'
import DivEstimates from '../views/DivEstimates'
import DivEstimatesNew from '../views/DivEstimates/new'
import DivEstimatesIntro from '../views/DivEstimates/Intro'
// import GabChat from '../views/GabChat'
// import ChatLogin from '../views/GabChat/ChatLogin'
import '../views/App.css'
// import '../views/style.css'
// import '../views/style2.css'
// import '../views/AdminPortal/Admin.css'
import '../views/AdminPortal/AdminNav.css'
import '../views/Insights/index.css'
import '../views/PortfolioManagers/index.css'
import ManageKIIDs from '../views/AdminPortal/ManageKIIDs';
import AdminPortalUsers from '../views/AdminPortal/AdminPortalUsers';
import EventsPage from '../views/AdminPortal/Events';
import CompositesPage from '../views/AdminPortal/Composites';
// import ImageGallery from '../views/AdminPortal/ImageGallery';
import CreateEvent from '../views/AdminPortal/Events/CreateEvent';
import NavFundprices from '../views/AdminPortal/NavFundprices';
import EditHoldings from '../views/AdminPortal/EditHoldings';
import AttachmentUploader from '../views/AdminPortal/SendAttachment';
import S3Commentaries from '../views/AdminPortal/S3Commentaries'
import S3CommentariesEditor from '../views/AdminPortal/S3CommentariesEditor';
import ETFHoldings from '../views/AdminPortal/ETFHoldings';

class App extends Component {
  render(){
    const {
      adminAuthenticated,
      adminAuthenticating,
	  adminRole
    //   chatAuthenticated,
    //   chatAuthenticating,
    //   cableApp
    } = this.props
    const adminAuthProps = {
      isAuthenticated: adminAuthenticated,
      isAuthenticating: adminAuthenticating,
	  adminRole,
    //   cableApp
    }
    // const chatAuthProps = {
    //   isAuthenticated: chatAuthenticated,
    //   isAuthenticating: chatAuthenticating,
    //   cableApp
    // }
    return(
      <Router>
        <ScrollContext>
        <div>
          <Route path='/' render={ props => adminAuthenticated ? <AdminNav {...props}/> : null } />
          <Switch>
            <Route path='/admin_login' exact component={ AdminLogin }/>
            {/* <Route path='/chat_login' exact component={ ChatLogin } /> */}
            <MatchAuthenticated path="/" exact component={ AdminPortal } { ...adminAuthProps }/>
            <MatchAuthenticated path='/admin/update_sectors' exact excludeBasicUsers component={ SectorUpdate } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/update_return_data' exact excludeBasicUsers component={ PerfStatsUpdate } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/update_holdings' exact excludeBasicUsers component={ HoldingsUpdate } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/edit_holdings' exact excludeBasicUsers component={ EditHoldings } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/etf_holdings' exact excludeBasicUsers component={ ETFHoldings } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/carousel/:method' exact excludeBasicUsers component={ Carousel } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/form8937" exact excludeBasicUsers component={ Form8937Uploader } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/media" exact excludeBasicUsers component={ AdminUpload } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/uploads/press_release' exact excludeBasicUsers component={ PressReleaseUpload } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/xbrl" excludeBasicUsers component={ XBRLUpload } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/preferred_doc" excludeBasicUsers component={ PreferredDocUploader } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/tax_doc" excludeBasicUsers component={ TaxDocUploader } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/uploads/sec_filing' excludeBasicUsers component={ GamcoFilingsUpload } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/uploads/sicav' excludeBasicUsers component={ SicavUpload } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/kiids' excludeBasicUsers component={ ManageKIIDs } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/uploads/important_tax_info' excludeBasicUsers component={ ImportantTaxUploader } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/leverage" excludeBasicUsers component={ LevAnalysisUpload } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/uploads/:type" excludeBasicUsers component={ DocumentUpload } {...adminAuthProps}/>
            <MatchAuthenticated path="/admin/featured-funds" exact excludeBasicUsers component={ AdminFeaturedFunds } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/media-admin/news' exact excludeBasicUsers component={ MediaAdminNews } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/media-admin/article_entry' exact excludeBasicUsers component={ ArticleEntry }  {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/media-admin/tv' exact excludeBasicUsers component={ MediaAdminTv } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/media-admin/podcast' exact excludeBasicUsers component={ MediaAdminPodcast } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/media-admin/tv_entry' exact excludeBasicUsers component={ TvEntry }  {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/portfolio-manager-admin' exact excludeBasicUsers component={ PortfolioManagerAdmin } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/portfolio-manager-admin/new' exact excludeBasicUsers component={ PortfolioManagerNew } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/portfolio-manager-admin/:id/edit' exact excludeBasicUsers component={ PortfolioManagerAdmin } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/analysts' exact excludeBasicUsers component={ Personnel } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/analysts/:id/edit' exact excludeBasicUsers component={ Personnel } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/teams/new' exact excludeBasicUsers component={ TeamsNew } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/teams' exact excludeBasicUsers component={ TeamsAdmin } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/administrators' exact excludeBasicUsers component={ Personnel } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/administrators/:id/edit' exact excludeBasicUsers component={ Personnel } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/s3_commentaries' exact excludeBasicUsers component={ S3Commentaries } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/s3_commentaries/new' exact excludeBasicUsers component={ S3CommentariesEditor } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/s3_commentaries/:id' exact excludeBasicUsers component={ S3CommentariesEditor } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/insights' exact excludeBasicUsers component={ AdminInsights } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/insights/new' exact excludeBasicUsers component={ InsightEditor } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/insights/:id/edit' exact excludeBasicUsers component={ InsightEditor } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/docs' exact excludeBasicUsers component={ DocViewer } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/settings' exact component={ AdminSettings } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/leads' exact component={ Leads } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/link_generator' exact component={ LinkGenerator } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/attachments' exact component={ AttachmentUploader } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/:personnelType/new' exact component={ PersonnelNew } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/request' exact component={ RequestForm } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/wishlist' exact component={ Wishlist } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/wishlist/:id' exact component={ WishlistItem } {...adminAuthProps}/>
            <MatchAuthenticated path='/admin/div_estimates' exact excludeBasicUsers component={ DivEstimates } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/div_estimates_new' exact component={ DivEstimatesNew } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/div_estimates_intro' exact component={ DivEstimatesIntro } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/admin_portal_users' exact allowOnlySuperUsers component={ AdminPortalUsers } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/events' exact component={ EventsPage } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/events/create' exact excludeBasicUsers component={ CreateEvent } {...adminAuthProps} />
            <MatchAuthenticated path='/admin/composites' exact excludeBasicUsers component={ CompositesPage } {...adminAuthProps} />
            {/* <MatchAuthenticated path='/admin/image_gallery' exact excludeBasicUsers component={ ImageGallery } {...adminAuthProps} /> */}
            <MatchAuthenticated path='/admin/nav_fundprices' exact excludeBasicUsers component={ NavFundprices } {...adminAuthProps} />
            {/*
				<MatchAuthenticated path='/admin/chat' exact component={GabChat} {...adminAuthProps} />
				<MatchAuthenticated path='/admin/chat_login' exact component={ChatLogin} {...adminAuthProps} />
				<ChatAuthenticated path='/chat' exact component={GabChat} {...chatAuthProps} />
            */}

            <Route path='/error' exact component={ CodeError }/>
            <Route path='/page_not_found' exact component={ PageNotFound } />
            <Route render={() => <Redirect to='/page_not_found' />} />
          </Switch>
        </div>
        </ScrollContext>
      </Router>
    )
  }
}

const mapStateToProps = state => ({
    // chatAuthenticated: state.currentGabRep.isAuthenticated,
    // chatAuthenticating: state.currentGabRep.isAuthenticating,
    gabRepID: state.currentGabRep.gabRepID,
    adminAuthenticated: state.currentAdmin.isAuthenticated,
    adminAuthenticating: state.currentAdmin.isAuthenticating,
	adminRole: state.currentAdmin.role,
  })

export default withCookies(connect(mapStateToProps, {authenticate})(App))

/* routes.map( ( { path, component, excludeBasicUsers, allowOnlySuperUsers } ) => <MatchAuthenticated
	path={ path }
	exact
	component={ component }
	excludeBasicUsers={ excludeBasicUsers }
	allowOnlySuperUsers={ allowOnlySuperUsers }
	{...adminAuthProps}
/> ) */
