import React, { Component } from 'react'
// import Select from '../../components/Select'
import SelectWithAutofill from '../../components/SelectWithAutofill'

// const isFirefox = typeof InstallTrigger !== 'undefined'

class Filter extends Component {
  constructor(){
    super()

    this.state = {

    }
  }
  render(){
    const {
      fundsOptions,
      handleFundSelect,
      filterFund,
      handleTypeChange,
      handleSubCategoryClick,
      subCategories,
      clearAllSubCats,
      setAllSubCats,
      docType
    } = this.props
    return(
      <div id="fund-filter-container" className="admin-docs-filter">
        <div id="archive-filter-panel">
          <div id="filter-header">Filter</div>
          <div id="fund-filter-select-container" style={{ width: '90%', margin: '0 auto' }}>
            <SelectWithAutofill
              options={fundsOptions}
              className="select-tag media-info-input archive-fund-select admin-select"
              placeholder="Enter a fund..."
              handleChange={handleFundSelect}
              onClear={(e) => handleFundSelect(null)}
              value={filterFund}
            />
          </div>
          <div id="filter-radio-container">
            <label className="radio-container" onChange={handleTypeChange} name="Product Literature">
              Product Literature
              <input type="radio" value=""name="radio" defaultChecked={docType === "Product Literature"}/>
              <span className="checkmark"></span>
            </label>
            <div id="filter-sub-categories" style={{ maxHeight: (docType === "Product Literature" ? "1000px" : "0px"), overflow: 'hidden' }}>
              <ul>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="annual_report" checked={subCategories.includes("annual_report")}/>
                  <label className="sub-cat-filter-checkbox">Annual Report</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="commentary" checked={subCategories.includes("commentary")}/>
                  <label className="sub-cat-filter-checkbox">Commentary</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="factsheet" checked={subCategories.includes("factsheet")}/>
                  <label className="sub-cat-filter-checkbox">Factsheet</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="holdings" checked={subCategories.includes("holdings")}/>
                  <label className="sub-cat-filter-checkbox">Holdings Report</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="prospectus" checked={subCategories.includes("prospectus")}/>
                  <label className="sub-cat-filter-checkbox">Prospectus</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="prospectus_supp" checked={subCategories.includes("prospectus_supp")}/>
                  <label className="sub-cat-filter-checkbox">Prospectus Supplement</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="proxy_statement" checked={subCategories.includes("proxy_statement")}/>
                  <label className="sub-cat-filter-checkbox">Proxy Statement</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="quarterly_report" checked={subCategories.includes("quarterly_report")}/>
                  <label className="sub-cat-filter-checkbox">Quarterly Report</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="sai" checked={subCategories.includes("sai")}/>
                  <label className="sub-cat-filter-checkbox">Statement of Additional Information</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="semi_annual_report" checked={subCategories.includes("semi_annual_report")}/>
                  <label className="sub-cat-filter-checkbox">Semi-Annual Report</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="soi" checked={subCategories.includes("soi")}/>
                  <label className="sub-cat-filter-checkbox">Schedule of Investment</label>
                </li>
                <li>
                  <input type="checkbox" onChange={handleSubCategoryClick} value="summary_prospectus" checked={subCategories.includes("summary_prospectus")}/>
                  <label className="sub-cat-filter-checkbox">Summary Prospectus</label>
                </li>
                <li>
                  {
                    subCategories.length ?
                    <button onClick={clearAllSubCats}>Clear All</button> :
                    <button onClick={setAllSubCats}> Select All</button>
                  }
                </li>
              </ul>
            </div>
            <label className="radio-container" onChange={handleTypeChange} name="Press Releases">
              Press Releases/Dividend Notices
              <input type="radio" value=""name="radio" defaultChecked={docType === "Press Releases"}/>
              <span className="checkmark"></span>
            </label>
            <label className="radio-container" onChange={handleTypeChange} name="Corporate Filings">
              Corporate Filings
              <input type="radio" value=""name="radio" defaultChecked={docType === "Corporate Filings"}/>
              <span className="checkmark"></span>
            </label>
            <label className="radio-container" onChange={handleTypeChange} name="SICAV Documents">
              SICAV Documents
              <input type="radio" value=""name="radio" defaultChecked={docType === "SICAV Documents"}/>
              <span className="checkmark"></span>
            </label>
            <label className="radio-container" onChange={handleTypeChange} name="Form 8937s">
              Form 8937s
              <input type="radio" value=""name="radio" defaultChecked={docType === "Form 8937s"}/>
              <span className="checkmark"></span>
            </label>
            <label className="radio-container" onChange={handleTypeChange} name="Tax Documents">
              Tax Documents
              <input type="radio" value=""name="radio" defaultChecked={docType === "Tax Documents"}/>
              <span className="checkmark"></span>
            </label>

          </div>
        </div>
      </div>
    )
  }
}

export default Filter
