import React, { Component } from 'react'
import { Popover } from '@blueprintjs/core'
import S3Uploader from './S3Uploader'

class CarouselCard extends Component {
  constructor(props){
    super(props)

    this.state = {
      isEditing: false,
      form: {
        header: props.data.header,
        caption: props.data.caption,
        summary: props.data.summary,
        imagePath: props.data.imagePath,
        link: props.data.link
      }
    }
  }

  toggleForm = () => {
    this.setState({ isEditing: !this.state.isEditing})
  }

  handleChange = (e) => {
    const { target } = e
    const { form } = this.state
    form[target.name] = target.value
    this.setState({ form })
  }

  handleUpdate = (s3key, data) => {
    this.props.handleUpdate(s3key, data)
    this.toggleForm()
  }

  render(){
    const { data, s3key, /* handleDelete, */ index} = this.props
	console.log(this.state);
    if (this.state.isEditing) {
      return(
        <div className="carousel-card">
          <form className="cc-card-edit">
            <label>Headline</label>
            <input className="media-info-input admin-input" onChange={this.handleChange} name="header" value={this.state.form.header}></input>
            <br />
            <label>Caption</label>
            <input className="media-info-input admin-input" onChange={this.handleChange} name="caption" value={this.state.form.caption}></input>
            <br />
            <label>Summary</label>
            <input className="media-info-input admin-input" onChange={this.handleChange} name="summary" value={this.state.form.summary}></input>
            <br />
            <label>Link to Insight/Commentary</label>
            <input className="media-info-input admin-input" onChange={this.handleChange} name="link" value={this.state.form.link}></input>
            <br />
            <label>Replace image</label>
			<S3Uploader
				handleResponse={ ( { publicUrl } ) => this.setState( { form: { ...this.state.form, imagePath: publicUrl } } ) }
				accept='image/*'
				apiPath="carousel_objects/s3/sign"
			/>
          </form>
          <div id="cc-btn-container">
            <button id="cc-cancel-btn" className="cc-btn" onClick={this.toggleForm}>Cancel</button>
            <button id="cc-edit-btn" className="cc-btn" onClick={ () => this.handleUpdate(s3key, this.state.form) }>Update</button>
          </div>
        </div>
      )
    } else {
      return(
        <div className="carousel-card" s3key={s3key}>
          <div className="cc-index">{index + 1}</div>
          <div className="cc-data-container">
            <span className="cc-label">Header</span>
            <br />
            <span className="cc-item-data">{data.header}</span>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Caption</span>
            <br />
            <span className="cc-item-data">{data.caption}</span>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Summary</span>
            <br />
            <span className="cc-item-data">{data.summary}</span>
          </div>
          <div className="cc-data-container">
            <span className="cc-label">Link to Insight/Report</span>
            <br />
            <span className="cc-item-data"><a href={data.link} target="_blank" rel="noopener noreferrer">{data.link}</a></span>
          </div>
          <div id="cc-btn-container">
            <button onClick={this.toggleForm} >Edit</button>
            {/* <Popover>
              <button className="cc-delete-btn">Delete</button>
              <div className="popover-content">
                <h4>Are you sure?</h4>
                <p>Would you like to delete this item?</p>
                <button className=" bp3-popover-dismiss">Cancel </button>
                <button className="cc-delete-btn popover-btn" onClick={() => handleDelete(s3key)}>Delete</button>
              </div>
            </Popover> */}
            <Popover>
              <button className="cc-delete-btn">Archive</button>
              <div className="popover-content">
                <h4>Are you sure?</h4>
                <p>Would you like to move this item to the archive? It will no longer display on the website, but will remain saved.</p>
                <button className=" bp3-popover-dismiss">Cancel </button>
                <button className="cc-delete-btn popover-btn" onClick={ () => this.props.handleArchiveCarouselObject( this.props.s3key ) }>Move to archive</button>
              </div>
            </Popover>
          </div>
        </div>
      )
    }
  }
}

export default CarouselCard
