import { API_URL } from '../constants'

export const headers = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
}

const S3CommentariesService = {

	fetchCommentaries() {
		return fetch( `${API_URL}/s3_commentaries` ).then( response => response.json() );
	},

	fetchCommentary( s3Key ) {
		return fetch( `${API_URL}/s3_commentaries/${ s3Key }` ).then( response => response.json() );
	},

	postCommentary( commentary ) {
		return fetch( `${API_URL}/s3_commentaries`, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify( commentary ),
			headers: headers()
		} ).then( response => response.json() );
	},

	patchCommentary( commentary ) {
		return fetch( `${API_URL}/s3_commentaries/${ commentary.s3_key }`, {
			method: 'PATCH',
			credentials: 'include',
			body: JSON.stringify( commentary ),
			headers: headers()
		} ).then( response => response.json() );
	},

	deleteCommentary( s3_key ) {
		return fetch( `${API_URL}/s3_commentaries/${ s3_key }`, {
			method: 'DELETE',
			credentials: 'include',
			headers: headers()
		} ).then( response => response.json() );
	},

}

export default S3CommentariesService;
