export default (state = [], action) => {
  switch(action.type) {
    case 'UPLOADING_XBRL':
      return {
        isUploading: true,
        successfulUpload: false
      }
    case 'SUCCESSFUL_XBRL_UPLOAD':
      return {
        ...state,
        xbrl: action.xbrl,
        successfulUpload: true,
        isUploading: false
      }
    case 'SUCCESSFUL_XBRLS_FETCH':
      return action.xbrls
    case 'UNSUCCESSFUL_XBRL_ADD':
      return {
        ...state,
        xbrl: action.xbrl
      }
    case "XBRL_UPLOAD_COMPLETE":
      return {
        ...state,
        successfulUpload: false
      }
    default:
      return state;
  }
}
