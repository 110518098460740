import React, { Component } from 'react'
import { connect } from 'react-redux'
import Toggle from 'react-toggle'
import '../Media/media-theme.css'
import { updateTvAppearance } from '../../redux/modules/TVAppearances/actions'

const moment = require('moment');

class AdminTvAppearanceCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: props.tv.display,
    }
  }

  componentDidMount = () => {
    this.setState({
      ...this.props.tv
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.display !== this.state.display){
      this.updateTvAppearance()
    }
  }

  toggleDisplay = () => {
    this.setState({ display: !this.state.display})

  }

  updateTvAppearance() {
    const tv = this.state
    this.props.updateTvAppearance(tv)
  }
  // <iframe title={tv.id} className="video-image" src={tv.video_url} allow="accelerometer; autoPlay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" />
        // <a href={tv.video_url} target="_blank" rel="noopener noreferrer"><img alt='video' className="video-image" src={tv.image_url} /></a>

  render(){
    const { tv, handleClick }= this.props
    return(
      <div className="tv-card">
        <div onClick={handleClick}>
          <img className="article-image" src={tv.image_url} alt={tv.title}/>
          <div className="video-info">
            <span>Source: {tv.source}</span><br />
            <a className="video-title" href={tv.source_url} target="_blank" rel="noopener noreferrer">{tv.title}</a>
            <p>{tv.tv_description}</p>
            <p>Published: {moment(tv.publish_date).format('MMM D, YYYY')}</p>
          </div>
        </div>
        <div className="toggle-div">
          <Toggle
            defaultChecked={this.state.display}
            onChange={this.toggleDisplay}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return ({

  })
}

export default connect(mapStateToProps, {updateTvAppearance})(AdminTvAppearanceCard);
