import FilingsService from '../../../services/FilingsService';

const currentlyUploading = () => {
  return {
    type: 'UPLOADING_FILING',
  }
}

const clearFilingsWhileFetching = () => ({
  type: 'CURRENTLY_FETCHING_FILINGS'
})

const successfulUpload = filing => {
  return {
    type: 'SUCCESSFUL_FILING_UPLOAD',
    filing
  }
}

const successfulFilingsFetch = (filings) => {
  return {
    type: 'SUCCESSFUL_FILINGS_FETCH',
    filings
  }
}

const completeUpload = () => {
  return {
    type: 'FILING_UPLOAD_COMPLETE'
  }
}

const failedUpload = (filing) => {
  return {
    type: 'FILING_UPLOAD_FAILED',
    filing
  }
}

export const fetchFilings = () => {
  return dispatch => {
    FilingsService.fetchFilings()
    .then(filings => dispatch(successfulFilingsFetch(filings)))
  }
}

export const uploadFiling = (filing) => {
  return dispatch => {
    dispatch(currentlyUploading())
    FilingsService.uploadFiling(filing)
    .then(filing => {
      if (filing.errors){
        dispatch(failedUpload(filing))
      } else if (filing.error){
        console.log(filing.error)
        alert("Upload failed: Server Error")
      } else {
        dispatch(successfulUpload(filing))
      }
    })
  }
}

export const updateFiling = (id, data) => {
  return dispatch => {
    dispatch(clearFilingsWhileFetching())
    FilingsService.updateFiling(id, data)
    .then(filings => {
      if(filings.error){
        dispatch(failedUpload(filings))
      } else {
        dispatch(successfulFilingsFetch(filings))
      }
    })
  }
}

export const deleteFiling = (id) => {
  return dispatch => {
    dispatch(clearFilingsWhileFetching())
    FilingsService.deleteFiling(id)
    .then( filings => {
      if(filings.error) {
        dispatch(failedUpload(filings))
      } else {
        dispatch(successfulFilingsFetch(filings))
      }
    })
  }
}

export const uploadComplete = () => {
  return dispatch => {
    dispatch(completeUpload())
  }
}
