import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Select from '../../components/Select'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { uploadPressRelease, uploadComplete } from '../../redux/modules/PressReleases/actions'
import { fetchFunds, fetchAllFunds } from '../../redux/modules/Funds/actions'
import SuccessModal from './SuccessModal'
import S3Uploader from './S3Uploader'
import 'react-day-picker/lib/style.css'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class PressReleaseUpload extends Component {
  constructor(){
    super()

    this.state = {
      entity: "",
      type: "",
      title: "",
      date: null,
      contact: "",
      fund_code: "0",
      filepath: "",
      show: false,
      showSubmit: false
    }
  }

  componentDidMount(){
    this.props.fetchAllFunds()
    // this.props.fetchFunds()
  }

  handleChange = (e) => {
    const { state } = this
    state[e.target.name] = e.target.value
    this.setState(state)
  }

  handleTypeSelect = (e) => {
    e.preventDefault()
    this.setState({ type: e.target.innerText})
  }

  handleDayChange = (e) => {
    if(e){
      this.setState({ date: e })
    } else {
      this.setState({ date: null })
    }
  }

  handleSelectChange = (e) => {
    if(e){
      this.setState({ fund_code: e.value })
    } else {
      this.setState({ fund_code: null })
    }
  }

  handleResponse = (response) => {
    this.setState({ filepath: response.publicUrl })
  }

  handleProgress = (progress) => {
    if (progress === 100){
      this.setState({ showSubmit: true })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { entity, type, title, date, contact, fund_code, filepath } = this.state
    const pr = {
      entity,
      pr_type: type,
      fund_code,
      pr_date: date,
      title,
      contact,
      filepath,
      uploaded_by: JSON.parse( localStorage.getItem( "admin" ) ).full_name
    }
    this.props.uploadPressRelease(pr)
    this.setState({
      entity: "",
      type: "",
      fund_code: "0",
      date: null,
      title: "",
      contact: "",
      filepath: "",
      showSubmit: false
    })
    document.getElementsByName("s3-uploader")[0].value = ''
  }

  handleClose = () => {
    this.props.uploadComplete()
  }

  render(){
    const { errors } = this.props
    let options = this.props.fundsList || []
    if (options.length) {
      options = options.map( fund => ({
        value: fund.fund_code, label: fund.fundshortname
      }))
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={!!this.props.successfulUpload}
          handleClose={this.handleClose}
          phrase="Press release successfully uploaded."
        />
        <div id="upload-form-container">
          <h3>Press Release Upload</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }
          <form id="admin-upload-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Entity:</label>
            <select name="entity" className="media-info-input admin-select" onChange={this.handleChange} value={this.state.entity}>
              <option></option>
              <option>Associated Capital</option>
              <option>Comstock Funds</option>
              <option>GAMCO Investors</option>
              <option>Gabelli & Company</option>
              <option>Gabelli Closed End Funds</option>
              <option>Gabelli Funds</option>
              <option>Gabelli Open End Funds</option>
              <option>NextShares</option>
              <option>TETON Westwood Investments</option>
            </select>
            <label className="upload-input-label">This is a:</label>
            <div id="pr-double-btn">
              <button className={this.state.type === "Dividend Notice" ? "selected-btn" : null} onClick={this.handleTypeSelect}>Dividend Notice</button>
              <button className={this.state.type === "Press Release" ? "selected-btn" : null} onClick={this.handleTypeSelect}>Press Release</button>
            </div>
            <label className="upload-input-label">Title:</label>
            <input name="title" className="media-info-input admin-input" onChange={this.handleChange} value={this.state.title}></input>
            <label className="upload-input-label">Date: </label>
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input"
              value={this.state.date || "YYYY-M-D"}
              style={{ color: "Gray" }}
            />
            <label className="upload-input-label">Contact Person:</label>
            <input name="contact"className="media-info-input admin-input" onChange={this.handleChange} value={this.state.contact}></input>
            <label className="upload-input-label">Select Fund:</label>
            <SelectWithAutofill
              options={options}
              className="select-tag media-info-input admin-select"
              placeholder="Leave blank for general firm press release"
              handleChange={this.handleSelectChange}
              onClear={(e) => this.handleSelectChange(null)}
              value={this.state.fund_code}
            />
            <label className="upload-input-label s3-upload-label">Upload the File</label>
            <S3Uploader
              params={{}}
              apiPath={"press_releases/s3/sign"}
              handleResponse={this.handleResponse}
              handleProgress={this.handleProgress}
            />
            <input id="upload-button" type="submit" value="Upload" disabled={ this.state.showSubmit ? "" : "disabled" }/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fundsList: state.funds,
  isUploading: state.pr.isUploading,
  successfulUpload: state.pr.successfulUpload,
  errors: state.pr.errors
})

export default connect(mapStateToProps, { fetchFunds, fetchAllFunds, uploadPressRelease, uploadComplete })(PressReleaseUpload)
