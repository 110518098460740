import React, { useState } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import S3Uploader from './S3Uploader';
import AsOfSelector from './AsOfSelector';
import { uploadDocument, uploadComplete } from '../../redux/modules/DocumentUploads/actions'
import SuccessModal from './SuccessModal';

const ETFHoldings = ( {
	errors,
	uploadDocument,
	// isUploading,
	successfulUpload,
	uploadComplete
} ) => {

	const [ state, setState ] = useState( {
		fund_code: 5008,
		fundshortname: 'Gabelli Financial Opportunities ETF',
		filepath: null,
		date: null,
		uploaded_by: JSON.parse( localStorage.getItem( 'admin' ) ).full_name,
		showSubmit: false,
	} );
	console.log(state);

	const fundSelectDropdownOptions = [
		{ fund_code: 5008, fundshortname: 'Gabelli Financial Opportunities ETF' }
	].reduce( ( result, { fund_code, fundshortname } ) => [ ...result, <option key={ fund_code } value={ fund_code }>{ fundshortname }</option> ], [] );

	const handleS3Response = ( { publicUrl } ) => setState( { ...state, filepath: `https://gab-holdings.s3.us-east-2.amazonaws.com/${ state.fund_code }/Holdings_${ state.fund_code }_${ new Date().toISOString().split( 'T' )[ 0 ] }.pdf` } );

	const handleSubmit = submitEvent => {
		submitEvent.preventDefault()
		const {	fund_code, fundshortname, filepath, date, uploaded_by, } = state;
		const doc = { fund_code, flagship_name: fundshortname, doc_type: 'etf_holdings', as_of: date, uploaded_by, filepath, };
		uploadDocument( doc, 'etf' )
	};

	return <div id="admin-upload-container">

		<SuccessModal
			successfulUpload={ !!successfulUpload }
			handleClose={ () => uploadComplete() }
			phrase="ETF Holdings successfully uploaded"
        />

		<h3>Upload ETF holdings</h3>
	
		<span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>

		{ !!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null }

		<div id="upload-form-container"><form id="admin-upload-form" onSubmit={ handleSubmit }>

			<label className="upload-input-label s3-upload-label" htmlFor='select_fund'>Select a fund:&nbsp;</label>
			<select 
				name='select_fund'
				value={ state.fund_code } 
				// onChange={ handleFundToEditChange }
				disabled
			>
				<option key={ 0 } value={ 0 } disabled>Select a fund</option>
				{ fundSelectDropdownOptions }
			</select>

			<AsOfSelector
				handleDayChange={ date => setState( { ...state, date } ) }
				type={ 'etf' }
				date={ state.date }
				value={ state.date || "YYYY-MM-DD" }
			/>

			<label className="upload-input-label s3-upload-label">Upload holdings file:</label>
			<S3Uploader
				params={ {
					objectName: `${ state.fund_code }/Holdings_${ state.fund_code }_${ new Date().toISOString().split( 'T' )[ 0 ] }.pdf`
				} }
				accept='application/pdf'
				apiPath="fund_holdings/s3/sign"
				handleResponse={ handleS3Response }
				isDisabled={ !state.date }
			/>

			<input id="upload-button" className="admin-input" type="submit" value="Upload" disabled={ !state.filepath }/>

		</form></div>

	</div>;	

};

const mapStateToProps = (state) => ( {
	isUploading: state.docs.isUploading,
	successfulUpload: state.docs.successfulUpload,
	errors: state.docs.errors
} )

export default withRouter( connect( mapStateToProps, { uploadDocument, uploadComplete } )( ETFHoldings ) );
