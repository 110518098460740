import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Select from '../../components/Select'
import { updateReturnData } from '../../redux/modules/FundReturnData/actions'
import { fetchFunds } from '../../redux/modules/Funds/actions'
import SuccessModal from './SuccessModal'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import SelectWithAutofill from '../../components/SelectWithAutofill'

class PerfStatsUpdate extends Component {
  constructor(){
    super()

    this.state = {
      fund_code: null,
      ann_return: "",
      std_deviation: "",
      sp_beta: "",
      alpha: "",
      capture_ratio: "",
      capture_ratio_down: "",
      sp_rsq: "",
      showSuccess: false,
      info_date: null
    }
  }

  componentDidMount(){
    this.props.fetchFunds()
  }

  handleSelectFund = (e) => {
    if(e){
      this.setState({ fund_code: e.value })
    } else {
      this.setState({ fund_code: null })
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target
    const { state } = this
    state[name] = value
    this.setState(state)
  }

  handleDayChange = e => {
    if(e){
      this.setState({ info_date: e })
    } else {
      this.setState({ info_date: null })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.props.updateReturnData(this.state)
    this.setState({
      fund_code: null,
      ann_return: "",
      std_deviation: "",
      sp_beta: "",
      alpha: "",
      capture_ratio: "",
      capture_ratio_down: "",
      sp_rsq: "",
      showSuccess: true,
      info_date: null
    })
  }

  handleClose = () => {
    this.setState({ showSuccess: false })
  }

  render(){
    const { funds } = this.props
    let fundOptions = []
    if(funds.length){
      fundOptions = funds.map( f => {
        return { value: f.fund_code, label: f.fundshortname }
      })
    }
    return(
      <div id="admin-upload-container">
        <SuccessModal
          successfulUpload={this.state.showSuccess}
          handleClose={this.handleClose}
          phrase="Sectors successfully updated."
        />
        <div id="upload-form-container">
          <h3>Update Fund Return Data</h3>
          <span id="upload-failed" >Upload failed. All fields are required AND a file must be chosen.</span>
          { /*!!errors ? <span id="update-error-span">Error: {errors[0]}</span> : null */ }
          <form id="admin-upload-form" className="sector-selector-form" onSubmit={this.handleSubmit}>
            <label className="upload-input-label">Return:</label>
            <input type="number" step="0.01"  name="ann_return" className="media-info-input admin-input" value={this.state.ann_return} onChange={this.handleChange}></input>
            <label className="upload-input-label">Standard Deviation:</label>
            <input type="number" step="0.01"  name="std_deviation" className="media-info-input admin-input" value={this.state.std_deviation} onChange={this.handleChange}></input>
            <label className="upload-input-label">Beta:</label>
            <input type="number"  step="0.01" name="sp_beta" className="media-info-input admin-input" value={this.state.sp_beta} onChange={this.handleChange}></input>
            <label className="upload-input-label">Alpha:</label>
            <input type="number"  step="0.01" name="alpha" className="media-info-input admin-input" value={this.state.alpha} onChange={this.handleChange}></input>
            <label className="upload-input-label">Capture Ratio Up:</label>
            <input type="number"  step="0.01" name="capture_ratio" className="media-info-input admin-input" value={this.state.capture_ratio} onChange={this.handleChange}></input>
            <label className="upload-input-label">Capture Ratio Down:</label>
            <input type="number"  step="0.01" name="capture_ratio_down" className="media-info-input admin-input" value={this.state.capture_ratio_down} onChange={this.handleChange}></input>
            <label className="upload-input-label">R-Squared:</label>
            <input type="number"  step="0.01" name="sp_rsq" className="media-info-input admin-input" value={this.state.sp_rsq} onChange={this.handleChange}></input>
            <label className="upload-input-label">Select a Fund:</label>
            <SelectWithAutofill
              options={fundOptions}
              className="select-tag media-info-input admin-select"
              placeholder="Enter a fund..."
              handleChange={this.handleSelectFund}
              onClear={(e) => this.handleSelectFund(null)}
              value={this.state.fund_code}
            />
            <label className="upload-input-label">As of Date:</label>
            <DayPickerInput
              onDayChange={this.handleDayChange}
              className="media-info-input admin-select"
              value={this.state.info_date || "YYYY-M-D"}
              style={{ color: "Gray" }}
            />
            <input id="upload-button" type="submit" value="Update" disabled={!this.state.fund_code}/>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  funds: state.funds
})

export default connect(mapStateToProps, { updateReturnData, fetchFunds })(PerfStatsUpdate)
