export default (state = [], action) => {
  switch(action.type) {
    case 'SUCCESSFUL_TAX_DOC_UPLOAD':
      return {
        ...state,
        successfulUpload: true,
        isUploading: false
      }
    case 'STARTING_TAX_DOC_UPLOAD':
      return {
        ...state,
        successfulUpload: false,
        isUploading: true
      }
    case 'TAX_DOC_UPLOAD_COMPLETE':
      return {
        ...state,
        successfulUpload: false,
        isUploading: false
      }
    case 'FAILED_TAX_DOC_UPLOAD':
      return {
        ...state,
        errors: action.doc.errors
      }
    case 'SUCCESSFUL_TAX_DOCS_FETCH':
      return {
        ...state,
        docs: action.docs
      }
    case 'CLEAR_TAX_DOCS':
      return {
        ...state,
        docs: []
      }
    default:
      return state;
  }
}
