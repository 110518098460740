import PublicEventSpeakersService from "../../../services/PublicEventSpeakersService";
import { generateSpeakersSpreadsheet } from "../../../views/generateEventRegistrantSpreadsheet";

const successfulPublicEventSpeakersFetch = publicEventSpeakers => {
	return { type: 'PUBLIC_EVENT_SPEAKERS_FETCHED_SUCCESSFULLY', publicEventSpeakers }
};

const publicEventSpeakersFetchFailed = () => {
	return { type: 'PUBLIC_EVENT_SPEAKERS_FETCH_FAILED' }
};

export const fetchPublicEventSpeakers = publicEvent => {
	return dispatch => {
		PublicEventSpeakersService.fetchPublicEventSpeakers( publicEvent.id )
		.then( publicEventSpeakers => {
			dispatch( successfulPublicEventSpeakersFetch( publicEventSpeakers ) );
			generateSpeakersSpreadsheet( publicEvent.name.replace( /\s/g, '' ).slice( 0, 10 ), publicEventSpeakers );
		} )
		.catch( () => dispatch( publicEventSpeakersFetchFailed() ) );
	};
};
